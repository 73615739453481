import { insertDatapointChunks } from '../helpers/Pushers/generalPusherChunks';
import { COMPANY_UPDATE_ERROR } from './companyHandler';

export const DB_START_WRITING = 'db_start_writing';
export const DB_END_WRITING = 'db_end_writing';
export const DB_TABLES_PUSHED = 'db_tables_pushed';
export const DB_CLEAR_HANDLER = 'db_clear_handler';

const startWriting = () => ({
    type: DB_START_WRITING
});

const endWriting = () => ({
    type: DB_END_WRITING
});

const showResultPush = (messagePushedDB, resultPushDB, importResult) => ({
    type: DB_TABLES_PUSHED,
    payload: { messagePushedDB, resultPushDB, importResult }
});

export const clearDbHandler = () => {
    return dispatch => {
        dispatch({
            type: DB_CLEAR_HANDLER
        });
    }
};

export const pushTables = (tables, datapoint_id, numTopRowsCleaned) => {
    return async dispatch => {
        dispatch(startWriting());
        let result = null;
        result = await insertDatapointChunks(
            tables,
            datapoint_id,
            numTopRowsCleaned
        );
        await result;

        let resultPushDB = false;
        //let resultPushDBRowsSubmitted = 0;
        //let iconPushedDB = 'error';
        let messagePushedDB = 'Something went wrong :(';

        switch (result.code) {
            case 200:
                resultPushDB = true;
                //resultPushDBRowsSubmitted = result.rows;
                //iconPushedDB = 'info';
                messagePushedDB = 'Congratulation! ' + result.numRowsSuccess + ' successfully imported!';
                break;
            case 500:
                resultPushDB = false;
                //iconPushedDB = 'error';
                messagePushedDB = 'Something went wrong :(';
                break;
            default:
                //iconPushedDB = 'error';
                messagePushedDB = 'Something went wrong :(';
                resultPushDB = false;
                break;
        }

        // let databaseErrorMessage =  result.dbErrorMessage,
        // iconPushedDB: iconPushedDB,
        // resultPushDBRowsSubmitted: resultPushDBRowsSubmitted,

        // openDialogFinishedImport: true,

        dispatch(showResultPush(messagePushedDB, resultPushDB, result));
        dispatch(endWriting());
    };
};
