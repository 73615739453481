import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { confirmEmail, confirmEmailToError, confirmEmailToSuccess } from '../../actions/emailHandler';
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../config/history';
import CustomPaper from '../../components/Paper';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import Spacer from '../../components/Spacer/Spacer';
import Button from '../../components/UI/ButtonExtended/ButtonExtended';

class confirmEmailPage extends Component {

    componentDidMount() {
        const email = this.props.match.params.email;
        const confirmationId = this.props.match.params.confirmationId;

        this.props.confirmEmail(email, confirmationId); // TODO FEDE to COMMENT
        // this.props.confirmEmailToSuccess(); // TODO FEDE to decomment
        //this.props.confirmEmailToError(); // TODO FEDE to decomment
        //
        /*if (this.props.emailConfirmed) {
            setTimeout(function() {
                history.push({
                    pathname: '/overview'
                });
            }, 3000);
        }*/
    }

    onClickButton(){
        history.push({
            pathname: '/overview'
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.emailConfirmed === true) {
                setTimeout(function() {
                    history.push({
                        pathname: '/overview'
                    });
                }, 3000);
            }
        }
    }

    render() {
        return (
            <Grid
                container
                spacing={2}
                xs
                alignItems={'center'}
                justify={'center'}
                style={{ marginTop: 40 }}
            >
                <Grid item xs={11} sm={6} lg={4}>
                    <CustomPaper style={{ minHeight: 200 }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '20px'
                        }}
                        >
                            {/*<Typography variant="h4">*/}
                            {/*    CONFIRMING EMAIL*/}
                            {/*    please wait ...*/}
                            {/*</Typography>*/}
                            {this.props.confirmingEmail === true &&
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            >
                                <Spacer small/>
                                <CircularProgress/>
                                <Spacer small/>
                                <Typography align={'center'} variant="h4">
                                    <p>CONFIRMING EMAIL</p>
                                    <br/>
                                    <p>please wait ...</p>
                                </Typography>
                                <Spacer/>
                                <Button variant="contained" color="primary" disableElevation onClick={this.onClickButton}>
                                    Go To The Homepage
                                </Button>
                            </div>
                            }

                            {this.props.emailConfirmed === true &&
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            >
                                <Spacer small/>
                                <CircularProgress/>
                                <Spacer small/>
                                <Typography align={'center'} variant="h4">
                                    <p>EMAIL CONFIRMED!</p>
                                    <br/>
                                    <p>redirecting to the main page ...</p>
                                </Typography>
                            </div>
                            }

                            {this.props.confirmEmailError !== null &&
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            >
                                <Spacer small/>
                                <ErrorIcon fontSize={'large'}/>
                                <Spacer small/>
                                <Typography align={'center'} variant="h4">
                                    <p>EMAIL CONFIRMATION ERROR</p>
                                    <br/>
                                        <p>Something went wrong</p>
                                    {/*Error: {this.props.confirmEmailError}*/}
                                </Typography>
                                <Spacer/>
                                <Button variant="contained" color="primary" disableElevation onClick={this.onClickButton}>
                                    Go To The Homepage
                                </Button>
                            </div>
                            }
                        </div>
                    </CustomPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = {
    confirmEmail,
    confirmEmailToSuccess,
    confirmEmailToError
};

const mapStateToProps = (state) => {
    return {
        myUser: state.authHandler.myUser,

        confirmingEmail: state.emailHandler.confirmingEmail,
        confirmEmailError: state.emailHandler.confirmEmailError,
        emailConfirmed: state.emailHandler.emailConfirmed
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(confirmEmailPage);
