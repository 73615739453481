import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import logo from '../../../assets/images/uk_nigeria_tech_hub_logo.png';
import Spacer from '../../Spacer/Spacer';
import GoogleSignIn from '../GoogleSignIn';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GOOGLE } from '../../../auth/authUtils';
import LinkedInSignIn from '../LinkedinSignIn';
import AuthForm, { FORGOT_PASSWORD_STATE, SIGN_IN_STATE } from './AuthForm';
import { Grid, Typography } from '@material-ui/core';

export default function AuthBoxModal(props) {

    const [authState, setAuthState] = React.useState(SIGN_IN_STATE);

    const {
        open, handleClose,
        iAmAuthenticated,
        authenticating,
        receiveProviderTokenLinkedin,
        loginMySelf,
        fetching,
        emailAlreadyExists,
        wrongPassword,
        emailNotExists,
        submitSign,
        sendResetPassword
    } = props;
    const classes = useStyles();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                // onBackdropClick={() => console.log('')}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 250
                }}
            >
                <Fade in={open}>
                    <Grid item xs={11} sm={8} lg={4}>
                        <Paper className={classes.paper} style={{ marginTop: 0 }}>
                            <div className={classes.logoWrapper}>
                                <Link className={classes.logoLink} to="/">
                                    <img alt="logo" className={classes.logoImage} src={logo}/>
                                </Link>
                            </div>
                            {authState !== FORGOT_PASSWORD_STATE ?
                                <Typography variant="h4">
                                    {'Join for free to access the platform'}
                                </Typography> :
                                <Typography variant="h4">
                                    {'Recover Password'}
                                </Typography>
                            }
                            <AuthForm
                                submitSign={submitSign}
                                fetching={fetching}
                                emailAlreadyExists={emailAlreadyExists}
                                wrongPassword={wrongPassword}
                                emailNotExists={emailNotExists}
                                changeState={(STATE) => setAuthState(STATE)}
                                sendResetPassword={sendResetPassword}
                            />
                            <Spacer small/>
                            {authState !== FORGOT_PASSWORD_STATE ?
                                <div>
                                    <GoogleSignIn
                                        onSuccess={(socialAccessToken) => {
                                            if (!iAmAuthenticated) {
                                                loginMySelf(socialAccessToken, GOOGLE, '');
                                            }
                                        }}
                                    />
                                    {authenticating ? <LinearProgress/> : null}
                                    {/*<Spacer small/>*/}
                                    {/*<LinkedInSignIn*/}
                                    {/*    receiveProviderToken={receiveProviderTokenLinkedin}*/}
                                    {/*    // failToReceiveProviderToken={this.failToReceiveProviderTokenLinkedin}*/}
                                    {/*/>*/}
                                </div>
                                : null}
                        </Paper>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '58px',
        flexShrink: 0,
        padding: '5px'
    },
    logoLink: {
        fontSize: 0,
        display: 'contents',
        flexDirection: 'row'
    },
    logoImage: {
        cursor: 'pointer',
        width: '158px',
        marginRight: theme.spacing(1)
    }
}));
