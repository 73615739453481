import React from 'react';
import styles from './styles';
import { Grid, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { investorTypeListFilter } from '../../models/typeList/investorTypes';
import { investmentStageListFilter } from '../../models/typeList/investmentStages';
import { businessModelListFilter } from '../../models/typeList/businessModels';
import { getIn } from 'formik';
import { milestoneTypes, productDevelopmentStages } from '../../models/typeList/milestoneTypes';
import { capitalSoughtTypes } from '../../models/typeList/extCapitalSoughtTypes';

class FilterFormFloatFields extends React.Component {

    render() {
        return (
            <div>
                <Grid
                    container
                    style={styles.form_container}
                    xs={12}
                    spacing={2}
                >
                    {this.props.category === 'Startup' || this.props.category === 'Company' ?
                        <Grid
                            style={styles.field}
                            item
                            xs={12}
                        >
                            <Autocomplete
                                multiple
                                id="business-models-tags"
                                name="businessModels"
                                size="small"
                                style={styles.textField}
                                options={businessModelListFilter}
                                value={this.props.formik.values.businessModels}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('businessModels', value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        return (
                                            <Chip
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        );
                                    })
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Business Model"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        : null
                    }
                    {this.props.category === 'Investor' ?
                        <Grid
                            style={styles.field}
                            item
                            xs={12}
                        >
                            <Autocomplete
                                multiple
                                id="investor-types-tags"
                                name="investorTypes"
                                size="small"
                                style={styles.textField}
                                options={investorTypeListFilter}
                                value={this.props.formik.values.investorTypes}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('investorTypes', value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        return (
                                            <Chip
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        );
                                    })
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Type"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        : null
                    }

                    {/*{this.props.category === 'Startup' || this.props.category === 'Company' ?*/}
                    {/*    <Grid*/}
                    {/*        style={styles.field}*/}
                    {/*        item*/}
                    {/*        xs={12}*/}
                    {/*    >*/}
                    {/*        <TextField*/}
                    {/*            label="Product Development"*/}
                    {/*            name="product_development"*/}
                    {/*            select*/}
                    {/*            SelectProps={{ native: true }}*/}
                    {/*            value={this.props.formik.values.product_development}*/}
                    {/*            onChange={this.props.formik.handleChange}*/}
                    {/*            onBlur={this.props.formik.handleBlur}*/}
                    {/*            size="small"*/}

                    {/*            variant="outlined"*/}
                    {/*            fullWidth*/}
                    {/*        >*/}
                    {/*            {productDevelopmentStages.map(option => (*/}
                    {/*                <option*/}
                    {/*                    key={option.id}*/}
                    {/*                    value={option.value}*/}
                    {/*                >*/}
                    {/*                    {option.name}*/}
                    {/*                </option>*/}
                    {/*            ))}*/}
                    {/*        </TextField>*/}
                    {/*    </Grid>*/}
                    {/*    : null}*/}


                    {this.props.category === 'Investor' ?
                        <Grid
                            style={styles.field}
                            item
                            xs={12}
                        >
                            <TextField
                                label="Number Investments (greater than)"
                                name="numPortfolios"
                                value={this.props.formik.values.numPortfolios}
                                onChange={this.props.formik.handleChange}
                                autoComplete='off'
                                variant="outlined"
                                type="number"
                                size="small"
                                fullWidth

                                onBlur={this.props.formik.handleBlur}
                                helperText={getIn(this.props.formik.errors, 'numPortfolios')
                                && this.props.formik.errors.numPortfolios
                                }
                                error={getIn(this.props.formik.errors, 'numPortfolios')
                                && this.props.formik.errors.categorySpec.investor.number_investment
                                }
                            />
                        </Grid>
                        : null
                    }

                    {this.props.category === 'Hub' ?
                        <Grid
                            style={styles.field}
                            item
                            xs={12}
                        >
                            <Autocomplete
                                multiple
                                id="hub-types-tags"
                                name="hubTypes"
                                size="small"
                                style={{ ...styles.textField, ...style.textField }}
                                options={investmentStageListFilter}
                                value={this.props.formik.values.hubTypes}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('hubTypes', value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        return (
                                            <Chip
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        );
                                    })
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Type"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        : null
                    }

                    <Grid
                        style={styles.field}
                        item
                        xs={12}
                    >
                        <Autocomplete
                            multiple
                            id="milestones-types-tags"
                            name="milestoneIds"
                            size="small"
                            style={styles.textField}
                            options={milestoneTypes}
                            value={this.props.formik.values.milestoneIds}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('milestoneIds', value);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={
                                        (this.props.category === 'Startup' || this.props.category === 'Company')
                                            ? 'Key Milestones' : 'Milestones Required'
                                    }
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                    {
                        this.props.category === 'Startup' ||
                        this.props.category === 'Company' ||
                        this.props.category === 'Investor' ?
                            <Grid
                                style={styles.field}
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    multiple
                                    id="ecst-tags"
                                    name="ext_capital_sought_types"
                                    size="small"
                                    options={capitalSoughtTypes}
                                    style={styles.textField}
                                    value={this.props.formik.values.ext_capital_sought_types}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => {
                                        this.props.formik.setFieldValue('ext_capital_sought_types', value);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                color="primary"
                                                style={{ backgroundColor: '#888FCA' }}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={
                                                (this.props.category === 'Startup' || this.props.category === 'Company')
                                                    ? 'Type of external capital sought'
                                                    : 'Type of investment'
                                            }
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            : null}

                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    xs={12}*/}
                    {/*>*/}
                    {/*    <Tooltip title="(Any Patent, Copyright, Trademark, etc)" placement="top">*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={*/}
                    {/*                <Switch*/}
                    {/*                    checked={this.props.formik.values.own_ip}*/}
                    {/*                    onChange={*/}
                    {/*                        () => this.props.formik.setFieldValue('own_ip',*/}
                    {/*                            !this.props.formik.values.own_ip)*/}
                    {/*                    }*/}
                    {/*                    value={this.props.formik.values.own_ip}*/}
                    {/*                    color="primary"*/}
                    {/*                />*/}
                    {/*            }*/}
                    {/*            label="Owns IP and or Patents"*/}
                    {/*        />*/}
                    {/*    </Tooltip>*/}
                    {/*</Grid>*/}


                </Grid>
            </div>
        );
    }
};

const style = {
    textField: {
        minWidth: 300
    },
    switch: {
        marginLeft: 15,
    }
};

export default withStyles(styles)(FilterFormFloatFields);
