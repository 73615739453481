import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import { actionTypes } from '../../../helpers/actionTypes';
import styles from '../styles';
import ActionsBox from '../../ActionsBox';
import { can, DELETE_ROW, EDIT_ROW } from '../../../helpers/Roles/roleActions';

class SubSectorRow extends Component {

    onClickNameSubSector = () => {
        history.push({
            pathname: '/sub_sector/' + actionTypes.EDIT + '/' + this.props.row.sub_sector_id
        });
    };

    render() {
        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            myUser,
            activeSelection,
            onClickDelete
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={event => onClickCheckbox(event, row.idrow)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={this.onClickNameSubSector}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    {row.name}
                </TableCell>
                <TableCell align={'right'}>
                    <ActionsBox
                        disabled={activeSelection}
                        onEdit={can(myUser, EDIT_ROW) ? this.onClickNameSubSector : null}
                        onDelete={can(myUser, DELETE_ROW) ? () => onClickDelete(row) : null}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(SubSectorRow);
