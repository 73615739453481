export const milestoneTypes = [
    {
        id: 5,
        name: 'Building new technology',
        value: 5
    },
    {
        id: 6,
        name: 'Expanding to new markets',
        value: 6
    },
    {
        id: 1,
        name: 'Generating revenue',
        value: 1
    },
    {
        id: 3,
        name: 'Raising funding',
        value: 3
    },
    {
        id: 4,
        name: 'Releasing a product',
        value: 4
    },
    {
        id: 2,
        name: 'User retention',
        value: 2
    },
];

export const productDevelopmentStages = [
    {
        id: 0,
        name: '',
        value: null
    },
    {
        id: 1,
        name: 'No (Idea stage)',
        value: 1
    },
    {
        id: 2,
        name: 'Yes (Beta or MVP)',
        value: 2
    },
    {
        id: 3,
        name: 'Yes (Development and selling)',
        value: 3
    }
];
