import FormData from 'form-data';
import axios from 'axios';
import { BASE_URL } from '../axiosConfig';

export const uploadFile = (doc, companyId, myUser, callback) => {
    let formData = new FormData();
    const user_id = myUser.user_id;
    const company_id = companyId;

    formData.append('selectedFile', doc.file);
    formData.append('doc_id', doc.doc_id);
    formData.append('user_id', user_id);
    formData.append('company_id', company_id);
    formData.append('name', doc.name);
    formData.append('extension', doc.extension);
    formData.append('size', doc.size);
    formData.append('type', doc.type);

    axios.post(BASE_URL + '/file/upload',
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + myUser.user_token
            }
        }
    )
        .then(res => {
            return callback(res);
        })
        .catch(err => {
            // console.log(err);
            return callback('error');
        });
};
