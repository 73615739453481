import React from 'react'

const Divider = ({absolute}) => {
    const dividerStyle = absolute? [styles.border, styles.absolute] : styles.border;
    return(
        <div
            style={dividerStyle}
        />
    )
};

const styles = {
    border:{
        color: '#eee',
        alignSelf: 'stretch',
        borderBottomWidth: 1,
        minHeight:1,
    },
    absolute: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        flex:1,
        width: '100%',
    }
};

export default Divider;
