import React from 'react';
import history from '../../config/history';

class HomePageNew extends React.Component {

    goToCharts = () => {
        history.push({
            pathname: '/overview'
        });
    };

    componentDidMount() {
        this.goToCharts();
    }


    render() {
        return (
            <div>
            </div>
        );
    }
}

export default HomePageNew;
