import 'core-js';
import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { chartFontFamily } from '../../theme';
import { aggregateColumns } from '../../helpers/Stats/aggregator';
import { getDummyBars } from '../../helpers/Stats/dummyData';

am4core.useTheme(am4themes_animated);

export default class ColumnChart extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.updateData(this.props.chartData);
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.chartData !== this.props.chartData) {
            return true;
        }
        return false;
    };


    updateData = (data) => {
        if (data.length > 0) {
            this.chart.data = aggregateColumns(data, this.props.categoryField, this.props.valueField, this.props.maxColumns);
            // this.series.columns.template.stroke = am4core.color(this.props.innerColor);
            this.series.columns.template.fill = am4core.color(this.props.innerColor);
            this.series.columns.template.fillOpacity = .8;
            this.categoryAxis.renderer.labels.template.disabled = false;
            // this.series.tooltip.disabled = true; // TODO
        } else {
            this.chart.data = getDummyBars(5, this.props.categoryField, this.props.valueField);
            this.series.columns.template.stroke = am4core.color('#E1E1E1');
            this.series.columns.template.fill = am4core.color('#E1E1E1');
            this.series.columns.template.fillOpacity = .4;
            this.categoryAxis.renderer.labels.template.disabled = true;
            // this.series.tooltip.disabled = true;
        }
    };

    componentDidMount() {
        const {
            idChart,
            chartData,
            categoryField,
            valueField,
            innerColor
        } = this.props;

        const chart = am4core.create(idChart, am4charts.XYChart);
        chart.fontFamily = chartFontFamily;
        chart.fontSize = 11;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = categoryField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.invalidateLabels();

        this.categoryAxis = categoryAxis;

        //categoryAxis.autoGridCount = false;
        // categoryAxis.gridCount = 3;

        categoryAxis.renderer.labels.template.maxWidth = 150;
        categoryAxis.renderer.labels.template.truncate = true;

        categoryAxis.renderer.labels.template.adapter.add('dy', function(dy, target) {
            if (target.dataItem && target.dataItem.index & 2 == 2) {
                return dy + 25;
            }
            return dy;
        });

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.maxPrecision = 0;
        this.valueAxis = valueAxis;

        // Create series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = valueField;
        series.dataFields.categoryX = categoryField;
        series.name = valueField;
        series.columns.template.fontFamily = chartFontFamily; // TODO tooltip fontFamily not set
        series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
        series.tooltip.label.fontFamily = chartFontFamily;
        series.columns.template.fillOpacity = .7;

        series.columns.template.column.cornerRadiusTopLeft = 4;
        series.columns.template.column.cornerRadiusTopRight = 4;
        series.columns.template.column.fillOpacity = 0.7;

        if (innerColor) {
            // series.columns.template.stroke = am4core.color(innerColor);
            series.columns.template.stroke = am4core.color('rgba(0, 0, 0, 0.0)');
            series.columns.template.fill = am4core.color(innerColor);
        }

        // on hover, make corner radiuses bigger
        let hoverState = series.columns.template.column.states.create('hover');
        hoverState.properties.cornerRadiusTopLeft = 0;
        hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 1;

        // series.columns.template.adapter.add('fill', function(fill, target) {
        //     return chart.colors.getIndex(target.dataItem.index);
        // });

        series.zIndex = 0;

        const columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        this.chart = chart;
        this.series = series;

        this.updateData(chartData);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={this.props.idChart} style={{ width: '100%', height: '230px' }}></div>
        );
    }
}
