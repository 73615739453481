import { withStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const progressCompleted = (theme) => {
    return {
        backgroundColor: '#74C0B2'
    };
};

const progressDanger = (theme) => {
    return {
        backgroundColor: '#ED4740'
    };
};

const progressWarning = (theme) => {
    return {
        backgroundColor: '#FFB822'
    };
};

const ProgressBar = withStyles((theme) => {
    return {
        bar: props =>
            props.color === 'completed'
                ? progressCompleted(theme)
                : props.color === 'warning'
                ? progressWarning(theme)
                : props.color === 'danger'
                    ? progressDanger(theme)
                    : {}
    };
})(LinearProgress);

export default ProgressBar;
