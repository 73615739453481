import 'core-js';
import React from 'react';
import {
    Paper,
    PortletContent
} from '../../index';
import classNames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';
import styles from '../../Company/styles';
import SensitiveMap from '../SensitiveMap';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Spacer from '../../Spacer/Spacer';

const CountryInvYearsMap = (props) => {
    const { classes, className, idChart, data, fetching, handleChangeSlider, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const [value, setValue] = React.useState([1990, 2020]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const filterData = data.filter((d) => d.country !== null).map((d) => ({...d, value: parseInt(d.value)}));
    console.log(filterData);
    const handleChangeCommited = (event, newValue) => {
        // console.log(newValue);
        handleChangeSlider(newValue);
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent
                disablePadding
            >
                <SensitiveMap
                    idChart={idChart}
                    data={filterData}
                    // data={[]}
                />
                {fetching ? <LinearProgress/> : null}
            </PortletContent>
        </Paper>

    );

};

export default withStyles(styles)(CountryInvYearsMap);
