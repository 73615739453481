import React, { useEffect, useState } from 'react';
import { withStyles, Grid, TextField } from '@material-ui/core';
import styles from '../styles';
import Spacer from '../../Spacer/Spacer';
import DatePicker from '../../DatePicker';
import 'date-fns';
import { investmentTypeList } from '../../../models/typeList/investmentTypes';
import { investmentStageList } from '../../../models/typeList/investmentStages';
import IconButton from '@material-ui/core/IconButton';
import { Delete } from '@material-ui/icons';
import { TEAM } from '../../../helpers/Roles/roleActions';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { investmentObj } from '../../../data/tableObjects';
import { cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import CompanyCard from '../../CompanyCard';
import history from '../../../config/history';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isNumeric } from '../../../helpers/Validation/fieldForm';

const DealRow = (props) => {

    const {
        classes, className,
        investmentIndex,
        investment,
        onClickDeleteDeal,
        myUser,
        onBlur,
        handleAddCompanyClick,
        formDisabled,
        isMyCompany,
        investedCompany, // TODO
        ...rest
    } = props;

    const [date, setDate] = useState(investment.date);
    const [stage, setStage] = useState(investment.stage);
    const [amount, setAmount] = useState(investment.amount);
    const [type, setType] = useState(investment.amount);
    const [preMoneyValuation, setPreMoneyValuation] = useState(investment.pre_money_valuation);
    const [equitySoldPercentage, setEquitySoldPercentage] = useState(investment.equity_sold_percentage);
    const [investors, setInvestors] = useState(investment.investors);

    useEffect(() => {
        setDate(investment.date);
        setStage(investment.stage);
        setAmount(investment.amount);
        setType(investment.type);
        setPreMoneyValuation(investment.pre_money_valuation);
        setEquitySoldPercentage(investment.equity_sold_percentage);
        setInvestors(investment.investors);
    }, [investment]);

    const handleBlur = () => {
        let newInvestment = cloneDeep(investmentObj);
        newInvestment.date = date;
        newInvestment.stage = stage;
        newInvestment.investment_id = investment.investment_id;
        newInvestment.amount = amount;
        newInvestment.type = type;
        newInvestment.pre_money_valuation = preMoneyValuation;
        newInvestment.equity_sold_percentage = equitySoldPercentage;
        newInvestment.investors = investors;
        onBlur(newInvestment);
    };

    const handleClickCompany = (company) => {
        history.push({
            pathname: '/redirect/show/' + company.company_id
        });
    };

    const onClickRemoveCompany = (company) => {
        let currentInvestment = cloneDeep(investment);
        let currentInvestors = cloneDeep(investment.investors);
        let indexCompanyToRemove = -1;
        let i = 0;
        for (let investor of currentInvestors) {
            if (investor.company_id === company.company_id) {
                indexCompanyToRemove = i;
            }
            i++;
        }
        if (indexCompanyToRemove !== -1) {
            currentInvestors.splice(indexCompanyToRemove, 1);
            currentInvestment.investors = currentInvestors;
            onBlur(currentInvestment);
        }
    };

    return (
        <Grid
            container
            item
            xs={12}
        >
            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <DatePicker
                    label={'Date'}
                    selectedDate={date}
                    handleDateChange={(event, value) => {
                        let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                        let localISOTime = (new Date(new Date(value) - tzoffset)).toISOString();
                        setDate(localISOTime);
                    }}
                />
                {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*    <KeyboardDatePicker*/}
                {/*        margin="normal"*/}
                {/*        id="date-picker-dialog"*/}
                {/*        label="Date"*/}
                {/*        format="dd/MM/yyyy"*/}
                {/*        value={date}*/}
                {/*        variant="inline"*/}
                {/*        inputVariant="outlined"*/}
                {/*        // onChange={handleDateChange}*/}
                {/*        KeyboardButtonProps={{*/}
                {/*            'aria-label': 'change date'*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</MuiPickersUtilsProvider>*/}
            </Grid>
            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <TextField
                    className={classes.textField}
                    label="Stage"
                    select
                    SelectProps={{ native: true }}
                    value={stage}
                    onChange={(e) => setStage(e.target.value)}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                >
                    {investmentStageList.map(option => (
                        <option
                            key={option.id}
                            value={option.value}
                        >
                            {option.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <CurrencyTextField
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat='number'
                    digitGroupSeparator=","
                    decimalPlaces={0}

                    label="Amount"
                    className={classes.textField}
                    value={amount}
                    onChange={(e, v) => {
                        // e.target.value = v;
                        setAmount(v);
                    }}
                    onBlur={handleBlur}
                    autoComplete='off'
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <TextField
                    className={classes.textField}
                    label="Type"
                    select
                    SelectProps={{ native: true }}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                >
                    {investmentTypeList.map(option => (
                        <option
                            key={option.id}
                            value={option.value}
                        >
                            {option.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <CurrencyTextField
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat='number'
                    digitGroupSeparator=","
                    decimalPlaces={0}

                    label="Pre-Money Valuation"
                    className={classes.textField}
                    value={preMoneyValuation}
                    onChange={(e, v) => {
                        // e.target.value = v;
                        setPreMoneyValuation(v);
                    }}
                    onBlur={handleBlur}
                    autoComplete='off'
                    variant="outlined"
                    fullWidth
                />
            </Grid>

            <Grid
                className={classes.field}
                container
                item
                xs={12} sm={6} lg={4}
            >
                <CurrencyTextField
                    currencySymbol="%"
                    minimumValue="0"
                    maximumValue="100"
                    outputFormat='number'
                    decimalPlaces={0}

                    label="Equity Sold"
                    className={classes.textField}
                    value={equitySoldPercentage}
                    onChange={(e, v) => {
                        setEquitySoldPercentage(v);
                    }}
                    onBlur={handleBlur}
                    autoComplete='off'
                    variant="outlined"
                    fullWidth
                />

                {/*<TextField*/}
                {/*    label="Equity Sold"*/}
                {/*    className={classes.textField}*/}
                {/*    value={equitySoldPercentage}*/}
                {/*    onChange={(e, v) => {*/}
                {/*        setEquitySoldPercentage(v);*/}
                {/*    }}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    autoComplete='off'*/}

                {/*    variant="outlined"*/}
                {/*    InputProps={{*/}
                {/*        startAdornment: <InputAdornment position="start">%</InputAdornment>*/}
                {/*    }}*/}
                {/*    fullWidth*/}
                {/*    // error={!isNumeric(equitySoldPercentage)}*/}
                {/*/>*/}
            </Grid>


            {/*{(((myUser && TEAM.includes(myUser.role)) || isMyCompany === true) && !investedCompany) ?*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        onClick={() => handleAddCompanyClick('company')}*/}
            {/*        style={{*/}
            {/*            maxHeight: '35px',*/}
            {/*            alignSelf: 'center',*/}
            {/*            marginLeft: '25px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        + ADD INVESTED COMPANY*/}
            {/*    </Button>*/}
            {/*    : null*/}
            {/*}*/}
            {
                investedCompany &&
                <Grid
                    container
                    spacing={2}
                >
                    <Grid container item xs={9} spacing={2}>
                        <Grid item xs={6} sm={6} lg={6}>
                            <CompanyCard
                                onClickName={() => handleClickCompany(investedCompany)}
                                onClickDelete={() => this.props.removeInvestedCompany(investedCompany)}
                                companyInfo={investedCompany}
                                canDelete={((myUser && TEAM.includes(myUser.role)) || isMyCompany === true) && !formDisabled}
                                fromDeals
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {((myUser && TEAM.includes(myUser.role)) || isMyCompany === true) ?
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddCompanyClick('investor')}
                    style={{
                        maxHeight: '35px',
                        alignSelf: 'center',
                        marginLeft: '25px'
                    }}
                >
                    + ADD INVESTOR
                </Button>
                : null
            }
            {((myUser && TEAM.includes(myUser.role)) || isMyCompany === true) ?
                <IconButton
                    onClick={onClickDeleteDeal}
                    style={{
                        marginLeft: '25px'
                    }}
                >
                    <Delete/>
                </IconButton>
                : null
            }
            <Spacer small/>
            <Grid
                container
                spacing={2}
            >
                <Grid container item xs={12} spacing={2}>
                    {investors ?
                        investors.map(company => {
                            if (company) {
                                return (
                                    <Grid item xs={6} sm={4} lg={4}>
                                        <CompanyCard
                                            onClickName={() => handleClickCompany(company)}
                                            onClickDelete={() => onClickRemoveCompany(company)}
                                            companyInfo={company}
                                            canDelete={((myUser && TEAM.includes(myUser.role)) || isMyCompany === true) && !formDisabled}
                                            fromDeals
                                        />
                                    </Grid>
                                );
                            }
                        })
                        : null
                    }
                </Grid>
            </Grid>
            <Spacer divider/>
        </Grid>
    );
};

export default withStyles(styles)(DealRow);
