import { cloneDeep } from 'lodash';
import {
    companyObj,
    geographyObj, hubObj, impactObj, investorObj, ngo_charityObj,
    socialObj, startupObj
} from '../../data/tableObjects';
import { getFakeFinancialMetrics, getFakeOperationalMetrics } from '../../helpers/Initializer/fakeData';

export const SORTING = {
    COMPANY_PREVIEW_DEFAULT: 'name',
    CLAIM_PREVIEW_DEFAULT: 'date_request',
    DEAL_PREVIEW_DEFAULT: 'date'
};

export const CATEGORIES = {
    STARTUP: {
        category_id: 1,
        category_name: 'startup',
        name: 'Company'
    },
    HUB: {
        category_id: 2,
        category_name: 'hub',
        name: 'Hub'
    },
    INVESTOR: {
        category_id: 3,
        category_name: 'investor',
        name: 'Investor'
    },
    NGO_CHARITY: {
        category_id: 4,
        category_name: 'ngo_charity',
        name: 'NGO or Foundation'
    }
};

export const statusList = [
    {
        status_id: 1,
        status_name: 'Active',
        value: true
    },
    {
        status_id: 2,
        status_name: 'Review',
        value: false
    }
];

export const categoriesList = [
    {
        category_id: 1,
        name: CATEGORIES.STARTUP.name,
        value: CATEGORIES.STARTUP.category_name
    },
    {
        category_id: 2,
        name: CATEGORIES.HUB.name,
        value: CATEGORIES.HUB.category_name
    },
    {
        category_id: 3,
        name: CATEGORIES.INVESTOR.name,
        value: CATEGORIES.INVESTOR.category_name
    },
    {
        category_id: 4,
        name: CATEGORIES.NGO_CHARITY.name,
        value: CATEGORIES.NGO_CHARITY.category_name
    }
];

export const categoriesStrings = ['Company', 'Hub', 'Investor', 'NGO or Foundation'];

export const categoriesListFilter = [
    {
        category_id: 0,
        name: 'All',
        value: 'all'
    },
    {
        category_id: 1,
        name: CATEGORIES.STARTUP.name,
        value: CATEGORIES.STARTUP.category_name
    },
    {
        category_id: 2,
        name: CATEGORIES.HUB.name,
        value: CATEGORIES.HUB.category_name
    },
    {
        category_id: 3,
        name: CATEGORIES.INVESTOR.name,
        value: CATEGORIES.INVESTOR.category_name
    },
    {
        category_id: 4,
        name: CATEGORIES.NGO_CHARITY.name,
        value: CATEGORIES.NGO_CHARITY.category_name
    }
];

// export const foundingYearListFilter = [ "2020", "2019",
// ];
const now = new Date().getUTCFullYear();
export const foundingYearListFilter = Array(now - (now - 121)).fill('').map((v, idx) => (now - idx).toString());

export const companyDataEmpty = {
    company: cloneDeep(companyObj),
    social: cloneDeep(socialObj),
    geography: cloneDeep(geographyObj),
    financial_metrics: cloneDeep(getFakeFinancialMetrics()),
    operational_metrics: cloneDeep(getFakeOperationalMetrics()),
    sectors: [],
    sub_sectors: [],
    countries: [],
    sub_states: [],
    SDGs: [],
    categorySpec: {
        startup: cloneDeep(startupObj),
        hub: cloneDeep(hubObj),
        investor: cloneDeep(investorObj),
        ngo: cloneDeep(ngo_charityObj)
    },
    impact: cloneDeep(impactObj),
    deals: [],
    types: [],
    stagesOfInterest: []
};

export const parseTeam = (teamInfos) => {
    const teamUsers = [];
    for (let user of teamInfos) {
        const personaObject = {
            persona_id: user.persona_id,
            name: user.name,
            lastname: user.lastname,
            role: user.role,
            ethnicity: user.ethnicity,
            gender: user.gender,
            level_education: user.level_education,
            degree: user.degree,
            university: user.university,
            email: user.email,
            social_id: user.social_id,
            last_update: user.last_update
        };
        teamUsers.push(personaObject);
    }
    return teamUsers;
};

export const getCategoryFromValue = (value) => {
    for (let categoryKey of Object.keys(CATEGORIES)) {
        if (CATEGORIES[categoryKey]['category_name'] === value) {
            return CATEGORIES[categoryKey];
        }
    }
};

export const ownershipList = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 1,
        name: 'Private Company',
        value: 'Private Company'
    },
    {
        id: 2,
        name: 'Acquired',
        value: 'Acquired'
    },
    {
        id: 3,
        name: 'Public Company',
        value: 'Public Company'
    },
    {
        id: 4,
        name: 'Other',
        value: 'Other'
    }
];

export const hasUsersList = [
    {
        id: 1,
        name: 'No, still in idea or production phase',
        value: false
    },
    {
        id: 2,
        name: 'Yes, the product is being used',
        value: true
    }
];

export const legalRegistrationList = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 1,
        name: 'No',
        value: false
    },
    {
        id: 2,
        name: 'Yes',
        value: true
    }
];
