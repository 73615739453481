import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../../layouts';
import EnhancedTable from '../../../components/Table/EnhanceTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import history from '../../../config/history';
import { actionTypes, tableActionTypes } from '../../../helpers/actionTypes';
import {
    cleanTable,
    deleteRow,
    fetchTablePreview
} from '../../../actions/tableHandler';
import { getHeaderCells } from '../../../models/headerCells';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/axiosConfig';
import { authHeader } from '../../../auth/authUtils';
import { cloneDeep } from 'lodash';
import ActionDialog from '../../../components/ChangeStatusDialog';
import SimpleSnackbar from '../../../components/Feedback/SimpleSnackbar';
import { capitalizeFirstLetter } from '../../../helpers/stringUtils';
import { Grid } from '@material-ui/core';
import CustomPaper from '../../../components/Paper';
import FilterFormBox from '../../../components/FilterFormOverview/FilterFormBox';
import Spacer from '../../../components/Spacer/Spacer';
import { SORTING } from '../../../models/company/company';
import { can, DOWNLOAD_CSV, INNER_TEAM } from '../../../helpers/Roles/roleActions';
import { downloadCsvDeals } from '../../../actions/downloadCsvHandler';


class GenericManageTable extends Component {

    constructor(props) {
        super(props);

        let tableName = this.props.tableName;

        this.state = {
            pagination: {
                page: 0,
                pageSize: 25,
                sorting: {
                    column: tableName === 'deal' ? SORTING.DEAL_PREVIEW_DEFAULT : SORTING.COMPANY_PREVIEW_DEFAULT,
                    desc: tableName === 'deal' ? true : false
                },
                filterFields: null
            },
            dialogActionVisible: false,
            snackVisible: false,
            snackbarVariant: '',
            rowClicked: null,
            rowsSelected: null,
            activeActionType: null,
            multipleAction: false,

            tableName: tableName,

            searchQuery: '',
            searchResult: [],
            searching: false,

            downloadType: null
        };

        this.props.cleanTable();
    }

    componentDidMount() {
        const pagination = this.props.location.pagination;
        if (pagination) {
            this.setState({
                pagination: pagination
            }, () => this.init());
        } else {
            this.init();
        }
    }

    init() {
        this.fetchTablePreview();
    }

    fetchTablePreview() {
        this.props.fetchTablePreview(
            this.state.tableName,
            this.state.pagination.page,
            this.state.pagination.pageSize,
            this.state.pagination.sorting,
            this.state.pagination.filterFields,
            this.props.myUser
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pagination.pageSize !== this.state.pagination.pageSize
            || prevState.pagination.sorting !== this.state.pagination.sorting
            || prevState.pagination.page !== this.state.pagination.page
            || prevState.pagination.filterFields !== this.state.pagination.filterFields) {
            this.fetchTablePreview();
        }

        if (prevProps.tableUpdating && !this.props.tableUpdateError) {
            let variant = 'info';
            let successful = false;
            if (!this.props.tableUpdateError) {
                variant = 'success';
                successful = true;
                if (this.state.activeActionType === tableActionTypes.DELETE) {
                    this.init();
                }
            } else {
                variant = 'error';
            }
            this.openSnack(successful, variant);
        }
    }

    handleAddTable = () => {
        history.push({
            pathname: `/${this.state.tableName}/` + actionTypes.ADD
        });
    };

    handleClickDownloadAll = () => {
        this.setState({
            downloadType: 'all',
            activeActionType: tableActionTypes.DOWNLOAD
        }, () => this.openDialogAction());
    };

    handleClickMultipleDownload = (rows) => {
        this.setState({
            downloadType: 'multiple',
            rowsSelected: rows,
            activeActionType: tableActionTypes.DOWNLOAD
        }, () => this.openDialogAction());
    };

    handleChangePageSize = (pageSize) => {
        const pagination = { ...this.state.pagination };
        pagination.pageSize = pageSize;
        pagination.page = this.state.pagination.page;
        this.setState({
            pagination: pagination
        });
    };

    handleChangeSorting = (columnName, isDesc) => {
        const pagination = { ...this.state.pagination };
        pagination.page = this.state.pagination.page;
        pagination.sorting = {
            column: columnName,
            desc: isDesc
        };
        this.setState({
            pagination: pagination
        });
    };

    handlePageChange = (newPage) => {
        const pagination = { ...this.state.pagination };
        pagination.page = newPage;
        this.setState({
            pagination: pagination
        });
    };


    startSearch = () => {
        axios.get(BASE_URL + `/${this.props.tableName}/` + 'active-search', {
            params: {
                pattern: this.state.searchQuery
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateSearchResult(data.results);
            });
    };

    updateSearchResult = (results) => {
        let newResults = cloneDeep(results);

        this.setState({
            searchResult: newResults,
            searching: false
        });
    };

    handleInputSearchChange = (e) => {
        this.setState({
            searchQuery: e.target.value
        }, () => {
            if (this.state.searchQuery && this.state.searchQuery.length > 0) {
                this.setState({
                        searching: true
                    }, () => this.startSearch()
                );
            }
        });
    };

    onClickDelete = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.DELETE
        }, () => this.openDialogAction());
    };

    openDialogAction = () => {
        this.setState({
            dialogActionVisible: true
        });
    };

    closeActionDialog = (force) => {
        if (!this.props.personaUpdating || force) {
            this.setState({
                dialogActionVisible: false
            });
        }
    };


    handleConfirmAction = () => {
        switch (this.state.activeActionType) {
            case tableActionTypes.DELETE:
                if (this.state.multipleAction) {
                    this.props.deleteRow(
                        this.state.tableName,
                        this.state.rowsSelected,
                        this.props.myUser);
                    if (this.state.searchQuery !== '') {
                        this.setState({
                            ...this.state,
                            searchQuery: ''
                        });
                    }
                } else {
                    this.props.deleteRow(
                        this.state.tableName,
                        [this.state.rowClicked[`${this.state.tableName}_id`]],
                        this.props.myUser);
                    if (this.state.searchQuery !== '') {
                        this.setState({
                            ...this.state,
                            searchQuery: ''
                        });
                    }
                }
                break;
            case tableActionTypes.DOWNLOAD:
                if (this.state.downloadType === 'all') {
                    this.props.downloadCsvDeals({
                        myUser: this.props.myUser,
                        pagination: this.state.pagination,
                        type: 'all'
                    });

                } else {
                    this.props.downloadCsvDeals({
                        myUser: this.props.myUser,
                        pagination: this.state.pagination,
                        type: 'selected',
                        ids_selected: this.state.rowsSelected
                    });
                }
                this.closeActionDialog();
        }
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (successful, variant) => {
        this.setState({
            ...this.state,
            dialogActionVisible: false,
            snackbarVariant: variant,
            snackVisible: true,
            updateSuccess: successful,
            rowClicked: null,
            rowsSelected: null
        });
    };

    handleMultipleDelete = (rows) => {
        this.setState({
            rowsSelected: rows,
            activeActionType: tableActionTypes.DELETE,
            multipleAction: true
        }, () => this.openDialogAction());
    };

    getTitle = () => {
        switch (this.props.tableName) {
            case 'sector':
                return 'Sector List';
            case 'sub_sector':
                return 'Sub Sectors';
            case 'persona':
                return 'Team Member';
            default:
                return capitalizeFirstLetter(this.props.tableName);
        }
    };

    handleOnSubmitFilterForm = (filterFields) => {
        const pagination = { ...this.state.pagination };
        pagination.filterFields = filterFields;
        pagination.page = 0;
        this.setState({
            pagination: pagination
        });
    };

    onCompanyRowClicked = (company_id) => {
        if (this.state.tableName === 'deal') {
            if (INNER_TEAM.includes(this.props.myUser.role) ||
                (this.props.activeClaims && this.props.activeClaims.includes(company_id))) {
                history.push({
                    pathname: '/company/' + actionTypes.EDIT + '/' + company_id,
                    pagination: this.state.pagination,
                    origin: 'deals'
                });
            } else {
                history.push({
                    pathname: '/company/' + actionTypes.SHOW + '/' + company_id,
                    pagination: this.state.pagination,
                    origin: 'deals'
                });
            }
        }
    };

    render() {
        return (
            <DashboardLayout title={this.getTitle()}>
                {this.state.tableName === 'deal' ?
                    <div>
                        <Grid item xs={12}>
                            <CustomPaper style={{ padding: 15 }}>
                                <FilterFormBox
                                    submit={this.handleOnSubmitFilterForm}
                                    sectors={this.props.sectors}
                                    subSectors={this.props.subSectors}
                                    myUser={this.props.myUser}
                                    fromCompare={false}
                                    initFields={this.state.pagination.filterFields}
                                    fromDeals={this.state.tableName === 'deal'}
                                    subStates={this.props.subStates}
                                />
                            </CustomPaper>
                        </Grid>
                        < Spacer/>
                    </div>
                    : null}
                {this.props.fetchingTablePreview ? <LinearProgress/> : null}
                <EnhancedTable
                    rows={(this.state.searchQuery.length > 0)
                        ? this.state.searchResult
                        : this.props.tablePreview
                    }
                    headCells={getHeaderCells(this.state.tableName)}
                    tableType={this.state.tableName}

                    page={this.state.pagination.page}
                    onChangePageSize={this.handleChangePageSize}
                    onChangeSorting={this.handleChangeSorting}
                    onPageChange={this.handlePageChange}
                    numTotalRows={this.props.numRows}

                    myUser={this.props.myUser}

                    handleClickDelete={this.onClickDelete}
                    multipleDelete={this.handleMultipleDelete}
                    onUpdateSuccess={this.state.updateSuccess}

                    searching={this.state.searching && this.state.searchQuery.length > 0}
                    handleInputSearchChange={this.handleInputSearchChange}

                    onClickRow={this.onCompanyRowClicked}

                    toolbarVisible={this.state.tableName !== 'logs'} // TODO handle cases
                    paginationVisible
                    checkable={this.state.tableName !== 'logs' && (this.props.myUser && can(this.props.myUser, DOWNLOAD_CSV))}
                    sortable={this.state.tableName !== 'logs' && this.state.tableName !== 'deal'}

                    handleAddEntry={this.handleAddTable}

                    addEnabled={this.state.tableName !== 'deal'}
                    deleteEnabled={this.state.tableName !== 'deal'}
                    onClickInvestor={this.onCompanyRowClicked}

                    downloadEnabled={can(this.props.myUser, DOWNLOAD_CSV) && (this.state.tableName === 'deal')}
                    handleClickMultipleDownload={this.handleClickMultipleDownload}
                    handleClickDownloadAll={this.handleClickDownloadAll}
                />
                <ActionDialog
                    tableName={this.props.tableName}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogActionVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    rowClicked={this.state.rowClicked}
                    updating={this.props.tableUpdating}
                    onConfirm={() => this.handleConfirmAction()}
                    isMultiple={this.state.multipleAction}
                    downloadType={this.state.downloadType}
                />
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchTablePreview,
        cleanTable,
        deleteRow,
        downloadCsvDeals
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        tablePreview: state.tableHandler.tablePreview,
        fetchingTablePreview: state.tableHandler.fetchingTablePreview,
        numRows: state.tableHandler.numRows,

        tableUpdating: state.tableHandler.tableUpdating,
        tableUpdateError: state.tableHandler.tableUpdateError,

        myUser: state.authHandler.myUser,

        sectors: state.mainHandler.sectors,
        subSectors: state.mainHandler.subSectors,
        countriesArray: state.mainHandler.countries,
        subStates: state.mainHandler.subStates,
        SDGs: state.mainHandler.SDGs
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericManageTable);
