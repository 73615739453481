import * as Yup from 'yup';

export const companySchema = Yup.object().shape({
    company: Yup.object().shape({
        name: Yup.string().required('Required').nullable(),
        email: Yup.string().email().nullable(),
        contact_number: Yup.string().nullable(),
        number_employees: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable(),
        ownership_structure: Yup.string().nullable(),
        website: Yup.string().url().nullable(),
        description: Yup.string().nullable()
    }),
    social: Yup.object().shape({
        // facebook: Yup.string().url().nullable(),
        // linkedin: Yup.string().url().nullable(),
        // twitter: Yup.string().url().nullable(),
        // instagram: Yup.string().url().nullable()
        facebook: Yup.string().nullable(),
        linkedin: Yup.string().nullable(),
        twitter: Yup.string().nullable(),
        instagram: Yup.string().nullable()
    }),
    geography: Yup.object().shape({
        hq_city: Yup.string().nullable(),
        hq_country: Yup.string().nullable(),
        hq_address: Yup.string().nullable()
    }),
    categorySpec: Yup.object().shape({
        investor: Yup.object().shape({
            // fund_size: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable(),
            // ticket_fund_size_min: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable(),
            // ticket_fund_size_max: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable(),
            number_exits: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable(),
            number_investment: Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable()
        })
    }),
    // deals: Yup.object().shape({
    //     'amount': Yup.number().typeError('be a number').moreThan(-1, ('must be positive')).nullable()
    // })
});
