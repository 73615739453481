import React from 'react';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import { Formik } from 'formik';
import { signInSchema, signUpSchema } from '../../../helpers/Validation/authForm';
import ForgotPasswordForm from './ForgotPasswordForm';

export const SIGN_IN_STATE = 'sign_in_state';
export const SIGN_UP_STATE = 'sign_up_state';
export const FORGOT_PASSWORD_STATE = 'forgot_password_state';

export default class AuthForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formState: SIGN_IN_STATE
        };
    }

    setFormState = (newState) => {
        this.setState({
            formState: newState
        });
        this.props.changeState(newState);
    };

    onSubmitForm = (values, actions) => {
        this.props.submitSign(values, this.state.formState);
    };

    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        email_SignIn: null,
                        password_SignIn: null,

                        firstName_SignUp: null,
                        lastName_SignUp: null,
                        email_SignUp: null,
                        password_SignUp: null
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={this.state.formState === SIGN_IN_STATE ? signInSchema : signUpSchema}
                    onSubmit={this.onSubmitForm}
                >
                    {formikProps => (
                        <form id="sign-form"
                              onSubmit={formikProps.handleSubmit}
                        >
                            {this.state.formState === SIGN_IN_STATE ?
                                <SignInForm
                                    changeState={(STATE) => this.setFormState(STATE)}
                                    formik={formikProps}
                                    fetching={this.props.fetching}
                                    wrongPassword={this.props.wrongPassword}
                                    emailNotExists={this.props.emailNotExists}
                                /> : this.state.formState === SIGN_UP_STATE ?
                                    <SignUpForm
                                        changeState={() => this.setFormState(SIGN_IN_STATE)}
                                        formik={formikProps}
                                        fetching={this.props.fetching}
                                        emailAlreadyExists={this.props.emailAlreadyExists}
                                    /> : <ForgotPasswordForm
                                        changeState={() => this.setFormState(SIGN_IN_STATE)}
                                        formik={formikProps}
                                        fetching={this.props.fetching}
                                        emailAlreadyExists={this.props.emailAlreadyExists}
                                        sendResetPassword={this.props.sendResetPassword}
                                    />
                            }
                        </form>
                    )}
                </Formik>

            </div>
        );
    }
};
