import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import EllipsisText from 'react-ellipsis-text';

const useStyles = makeStyles(theme => ({
    logo: {
        width: '38px',
        maxHeight: '42px',
        maxWidth: '42px'
        // borderRadius: 8
    }
}));

export default function AvatarNameCell(props) {
    const classes = useStyles();
    const { avatarUrl, name, truncateLength } = props;

    return (
        <div>
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                {avatarUrl && avatarUrl !== '' ?
                    <Grid item>
                        <img
                            className={classes.logo}
                            src={avatarUrl ? avatarUrl : null}
                            alt="new"
                        />
                    </Grid>
                    :
                    // <Grid item>
                    //     <div style={{ 'width': '38px' }}/>
                    // </Grid>
                    null
                }
                <Grid item xs>
                    {/*{name}*/}
                    {name ?
                        <EllipsisText
                            tooltip
                            text={name}
                            length={truncateLength}
                        /> : null
                    }
                </Grid>
            </Grid>
        </div>
    );
}
