export const ADD_ROW = {
    'sector': 'Sector',
    'sub_sector': 'SubSector',
    'sdg': 'SDG',
    'deal': 'Deal'
};

export const DIALOG_CONTENT_ACTIONS = {
    company: {
        status: {
            review: 'Do you want to set this company in review?',
            activate: 'Do you want to activate the company?'
        },
        delete: 'Do you want to delete this company?',
        download: {
            all: 'Do you want to download all the companies?',
            multiple: 'Do you want to download the companies selected?',
            single: 'Do you want to download this company?'
        }
    },
    people: {
        delete: 'Do you want to delete this person?'
    },
    user: {
        status: {
            review: 'Do you want to set this user in review?',
            activate: 'Do you want to activate this user?'
        },
        delete: 'Do you want to delete this user?'
    },
    sector: {
        delete: 'Do you want to delete this sector?'
    },
    sub_sector: {
        delete: 'Do you want to delete this sub-sector?'
    },
    sdg: {
        delete: 'Do you want to delete this SDG?'
    },
    deal: {
        download: {
            all: 'Do you want to download all the deals?',
            multiple: 'Do you want to download the deals selected?'
        }
    }
};

export const TITLE_ROW = {
    'sector': 'Sector',
    'sub_sector': 'SubSector',
    'sdg': 'Sustainable Development Goal',
    'deal': 'Deal'
};

export const getAssessmentTabName = (category) => {
    switch (category) {
        case 'startup':
            return 'Assessment';
        default:
            return 'Support'
    }
};

export const getAssessmentPortletHeader = (category) => {
    switch (category) {
        case 'startup':
            return 'Support';
        default:
            return 'Support Criteria'
    }
};

export const getMilestonePortletHeader = (category) => {
    switch (category) {
        case 'startup':
            return 'Milestones';
        default:
            return 'Milestones Required'
    }
};
