import React, { Component } from 'react';
import { LINKEDIN_STATE, queryToObject } from '../../auth/linkedinUtils';


if (window.location.search) {
    const params = queryToObject(window.location.search);
    if (params.state === LINKEDIN_STATE && window.opener) {
        window.opener.postMessage(params)
    }
}

class linkedinAuthPage extends Component {

   render() {
        return (
            <div>
                LINKEDIN AUTH PAGE
            </div>
        );
    }
}

export default linkedinAuthPage;
