import classNames from 'classnames';
import { Paper, PortletContent, PortletHeader, PortletLabel } from '../../index';
import { Grid, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { getIn } from 'formik';
import styles from '../styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import InputAdornment from '@material-ui/core/InputAdornment';

const CompanySocialForm = (props) => {
    const { classes, className, formik, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Socials"
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <TextField
                                label="Website"
                                name="company.website"
                                placeholder="Website"
                                className={classes.textField}
                                value={formik.values.company.website}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LanguageIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    getIn(formik.errors, 'company.website')
                                    && formik.errors.company.website
                                }
                                error={getIn(formik.errors, 'company.website')
                                && formik.errors.company.website
                                }
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <TextField
                                label="Facebook Page"
                                name="social.facebook"
                                placeholder="Facebook"
                                className={classes.textField}
                                value={formik.values.social.facebook}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FacebookIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    getIn(formik.errors, 'social.facebook')
                                    && formik.errors.social.facebook
                                }
                                error={getIn(formik.errors, 'social.facebook')
                                && formik.errors.social.facebook
                                }
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <TextField
                                label="Linkedin Page"
                                name="social.linkedin"
                                placeholder="Linkedin"
                                className={classes.textField}
                                value={formik.values.social.linkedin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LinkedInIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    getIn(formik.errors, 'social.linkedin')
                                    && formik.errors.social.linkedin
                                }
                                error={getIn(formik.errors, 'social.linkedin')
                                && formik.errors.social.linkedin
                                }
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >

                            <TextField
                                label="Twitter Page"
                                name="social.twitter"
                                placeholder="Twitter"
                                className={classes.textField}
                                value={formik.values.social.twitter}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TwitterIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    getIn(formik.errors, 'social.twitter')
                                    && formik.errors.social.twitter
                                }
                                error={getIn(formik.errors, 'social.twitter')
                                && formik.errors.social.twitter
                                }
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <TextField
                                label="Instagram Page"
                                name="social.instagram"
                                placeholder="Instagram"
                                className={classes.textField}
                                value={formik.values.social.instagram}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InstagramIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                helperText={
                                    getIn(formik.errors, 'social.instagram')
                                    && formik.errors.social.instagram
                                }
                                error={getIn(formik.errors, 'social.instagram')
                                && formik.errors.social.instagram
                                }
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(CompanySocialForm);
