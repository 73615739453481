import React, { useRef } from 'react';
import classNames from 'classnames';
import { withStyles, Grid } from '@material-ui/core';

import {
    Paper,
    PortletContent
} from '../index';

import styles from './styles';
import Spacer from '../Spacer/Spacer';
import IconButton from '@material-ui/core/IconButton';
import { Email, Facebook, Instagram, Language, LinkedIn, Twitter } from '@material-ui/icons';

const ContactsBoxProfile = (props) => {
    const fileInput = useRef(null);
    const {
        classes, className,
        website,
        socials,
        email,
        ...rest
    } = props;

    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent noPadding>
                {/*<Spacer/>*/}
                {/*<Typography variant="subtitle1" align="center">*/}
                {/*    {'Contacts'}*/}
                {/*</Typography>*/}
                {/*<Spacer/>*/}
                <Grid container spacing={1} alignItems="center" style={{ padding: '3px', margin: '3px' }}>
                    <Grid item xs>
                        <IconButton
                            disabled={!website}
                            size={'small'}
                            href={website}
                            target="_blank"
                        >
                            <Language/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        < IconButton
                            disabled={!email}
                            size={'small'}
                            href={`mailto:${email}`}>
                            <Email/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <IconButton
                            disabled={!socials.linkedin}
                            size={'small'}
                            href={socials.linkedin}
                            target="_blank">
                            <LinkedIn/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <IconButton
                            disabled={!socials.twitter}
                            size={'small'}
                            href={socials.twitter}
                            target="_blank">
                            <Twitter/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <IconButton
                            disabled={!socials.facebook}
                            size={'small'}
                            href={socials.facebook}
                            target="_blank">
                            <Facebook/>
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <IconButton
                            disabled={!socials.instagram}
                            size={'small'}
                            href={socials.instagram}
                            target="_blank">
                            <Instagram/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Spacer small/>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(ContactsBoxProfile);
