import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import {
    Facebook,
    Instagram,
    Twitter,
    LinkedIn,
    Email,
    Language
} from '@material-ui/icons';

export default function ContactsBox(props) {

    const { classes, className, dataInfo, noMail, justMail, user, noWebsite, fromCompare, userEmail, ...rest } = props;

    const getEmail = () => {
        if (userEmail) {
            return dataInfo.user_email;
        } else {
            return dataInfo.email;
        }
    };

    return (
        <div>
            {dataInfo ?
                <div>
                    {!user && !justMail && !noWebsite ?
                        <IconButton
                            disabled={!props.dataInfo.website} href={props.dataInfo.website}
                            size={'small'}
                            target="_blank">
                            <Language/>
                        </IconButton>
                        : null}
                    {!noMail ?
                        < IconButton
                            disabled={!props.dataInfo.email}
                            size={'small'}
                            href={`mailto:${getEmail()}`}>
                            <Email/>
                        </IconButton>
                        : null}
                    {props.dataInfo.linkedin && !fromCompare ?
                        <IconButton disabled={!props.dataInfo.linkedin} href={props.dataInfo.linkedin}
                                    size={'small'}
                                    target="_blank">
                            <LinkedIn/>
                        </IconButton>
                        : null
                    }
                    {props.dataInfo.twitter && !fromCompare ?
                        <IconButton disabled={!props.dataInfo.twitter} href={props.dataInfo.twitter}
                                    size={'small'}
                                    target="_blank">
                            <Twitter/>
                        </IconButton>
                        : null
                    }
                    {props.dataInfo.facebook && !fromCompare ?
                        <IconButton disabled={!props.dataInfo.facebook} href={props.dataInfo.facebook}
                                    size={'small'}
                                    target="_blank">
                            <Facebook/>
                        </IconButton>
                        : null
                    }
                    {props.dataInfo.instagram && !fromCompare ?
                        <IconButton disabled={!props.dataInfo.instagram} href={props.dataInfo.instagram}
                                    size={'small'}
                                    target="_blank">
                            <Instagram/>
                        </IconButton>
                        : null
                    }
                </div>
                : null
            }

        </div>
    );
}
