import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import ContactsBox from '../../Contacts';
import { actionTypes } from '../../../helpers/actionTypes';
import styles from '../styles';
import StatusButton from '../../StatusButton';
import ActionsBox from '../../ActionsBox';
import AvatarNameCell from '../AvatarNameCell';
import { can, EDIT_ROW, INNER_TEAM } from '../../../helpers/Roles/roleActions';

import EllipsisText from 'react-ellipsis-text';
import { capitalizeFirstLetter, getCategory } from '../../../helpers/stringUtils';
import OperatingStatusButton from '../../OperatingStatusButton';
import moment from 'moment';
import { getBadgeCompanyVisibility } from '../../../helpers/mainPreviews';
import { StyledBadge } from '../../Badge/StyledBadge';

class CompanyRow extends Component {
    // onClickNameCompany = () => {
    //     if (INNER_TEAM.includes(this.props.myUser.role)) {
    //         history.push({
    //             pathname: '/company/' + actionTypes.EDIT + '/' + this.props.row.company_id,
    //             pagination: this.props.pagination
    //         });
    //     } else {
    //         history.push({
    //             pathname: '/company/' + actionTypes.SHOW + '/' + this.props.row.company_id,
    //             pagination: this.props.pagination
    //         });
    //     }
    // };

    render() {
        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            onClickStatus,
            myUser,
            activeSelection,
            onClickDelete,
            onClickRow
        } = this.props;

        // console.log("ROW", row.idrow);

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                {INNER_TEAM.includes(myUser.role) ?
                    <TableCell padding="checkbox">
                        <Checkbox
                            onClick={event => onClickCheckbox(event, row.idrow)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </TableCell>
                    : null
                }
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={() => onClickRow(row.company_id)}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.avatar_url}
                        name={row.name}
                        truncateLength={14}
                    />
                    {/*{row.visit_counter}*/}
                </TableCell>
                <TableCell style={styles.noWrapCell}>
                    {row.category && getCategory(row.category)}
                </TableCell>
                <TableCell style={styles.noWrapCell}>
                    {row.sector_name}
                </TableCell>
                {/*<TableCell style={styles.noWrapCell}>{row.hq_country}</TableCell>*/}
                <TableCell style={styles.noWrapCell}>
                    {/*{row && row.hq_country ? row.hq_country : null}*/}
                    {row && row.hq_country ?
                        <EllipsisText
                            tooltip
                            text={row.hq_country}
                            length={'20'}
                        /> : null}
                </TableCell>
                {/*<TableCell style={styles.noWrapCell}>{row.hq_city}</TableCell>*/}
                {INNER_TEAM.includes(myUser.role) ?
                    <TableCell size={'small'}>
                        <StyledBadge badgeContent={"!"} color="secondary" invisible={getBadgeCompanyVisibility(row.last_update)}>
                            <StatusButton
                                status={row.status}
                                onClick={onClickStatus ? () => onClickStatus(row) : null}
                            />
                        </StyledBadge>
                    </TableCell>
                    : null
                }
                {INNER_TEAM.includes(myUser.role) ?
                    <TableCell size={'small'}>
                        {capitalizeFirstLetter(row.creator_type)}
                    </TableCell>
                    : null
                }
                <TableCell size={'small'}>
                    <OperatingStatusButton
                        status={row.operating_status}
                        // onClick={onClickStatus ? () => onClickStatus(row) : null}
                    />
                </TableCell>
                <TableCell size={'small'}>
                    <ContactsBox dataInfo={row}/>
                </TableCell>
                {INNER_TEAM.includes(myUser.role) ?
                    <TableCell>
                        {moment(row.creation_date).utc().format('DD/MM/YYYY - HH:mm')}
                    </TableCell>
                    : null}
                {INNER_TEAM.includes(myUser.role) ?
                    <TableCell>
                        {moment(row.last_update).utc().format('DD/MM/YYYY - HH:mm')}
                    </TableCell>
                    : null}
                <TableCell align={'right'}>
                    <ActionsBox
                        disabled={activeSelection}
                        onEdit={can(myUser, EDIT_ROW) ? this.onClickNameCompany : null}
                        onDelete={can(myUser, EDIT_ROW) ? () => onClickDelete(row) : null}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(CompanyRow);

