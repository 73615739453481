import axios from 'axios';
import { authHeader } from '../auth/authUtils';
import { BASE_URL } from '../helpers/axiosConfig';
import { fetchFollows } from './followHandler';

export const SECTORS_FETCH = 'sectors_fetch';
export const SECTORS_FETCH_SUCCESS = 'sectors_fetch_success';
export const SECTORS_FETCH_ERROR = 'sectors_fetch_error';

export const SUBSECTORS_FETCH = 'subsectors_fetch';
export const SUBSECTORS_FETCH_SUCCESS = 'subsectors_fetch_success';
export const SUBSECTORS_FETCH_ERROR = 'subsectors_fetch_error';

export const TECHNOLOGIES_FETCH = 'technologies_fetch';
export const TECHNOLOGIES_FETCH_SUCCESS = 'technologies_fetch_success';
export const TECHNOLOGIES_FETCH_ERROR = 'technologies_fetch_error';

export const COUNTRIES_FETCH = 'countries_fetch';
export const COUNTRIES_FETCH_SUCCESS = 'countries_fetch_success';
export const COUNTRIES_FETCH_ERROR = 'countries_fetch_error';

export const SUB_STATES_FETCH = 'sub_states_fetch';
export const SUB_STATES_FETCH_SUCCESS = 'sub_states_fetch_success';
export const SUB_STATES_FETCH_ERROR = 'sub_states_fetch_error';

export const SDGs_FETCH = 'sdgs_fetch';
export const SDGs_FETCH_SUCCESS = 'sdgs_fetch_success';
export const SDGs_FETCH_ERROR = 'sdgs_fetch_error';

export const MAIN_DATA_FETCH = 'main_data_fetch';
export const MAIN_DATA_FETCH_SUCCESS = 'main_data_fetch_succes';
export const MAIN_DATA_FETCH_ERROR = 'main_data_fetch_error';

export const CLICK_UPGRADE = 'click_upgrade';
export const CLICK_SUGGEST_UPDATE = 'click_suggest_update';
export const CLICK_REQUEST_MANAGE = 'click_request_manage';

export const UPDATE_MY_CLAIMS = 'update_my_claims';

export const fetchSectors = (myUser) => {
    return dispatch => {
        dispatch({ type: SECTORS_FETCH });
        axios.get(BASE_URL + '/sector/all', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: SECTORS_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: SECTORS_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchSDGs = (myUser) => {
    return dispatch => {
        dispatch({ type: SDGs_FETCH });
        axios.get(BASE_URL + '/sdg/all', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: SDGs_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: SDGs_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchCountries = (myUser) => {
    return dispatch => {
        dispatch({ type: COUNTRIES_FETCH });
        axios.get(BASE_URL + '/country/all', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: COUNTRIES_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: COUNTRIES_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchSubStates = (myUser) => {
    return dispatch => {
        let params = {
            country_code: 566 // NIGERIA
        };
        dispatch({ type: SUB_STATES_FETCH });
        axios.get(BASE_URL + '/sub_states', { params, headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: SUB_STATES_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: SUB_STATES_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchSubSectors = (myUser) => {
    return dispatch => {
        dispatch({ type: SUBSECTORS_FETCH });
        axios.get(BASE_URL + '/subsector/all', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: SUBSECTORS_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: SUBSECTORS_FETCH_ERROR,
                    payload: err
                });
            });
    };
};


export const fetchTechnologies = (myUser) => {
    return dispatch => {
        dispatch({ type: TECHNOLOGIES_FETCH });
        axios.get(BASE_URL + '/technology/all', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: TECHNOLOGIES_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: TECHNOLOGIES_FETCH_ERROR,
                    payload: err
                });
            });
    };
};


export const clickUpgrade = () => {
    return dispatch => {
        dispatch({
            type: CLICK_UPGRADE
        });
    };
};

export const clickSuggestUpdate = (companyName = null) => {
    return dispatch => {
        dispatch({
            type: CLICK_SUGGEST_UPDATE,
            payload: companyName
        });
    };
};

export const clickRequestManage = (companyName = null, companyId = null) => {
    return dispatch => {
        dispatch({
            type: CLICK_REQUEST_MANAGE,
            payload: {
                companyName,
                companyId
            }
        });
    };
};

export const getMyClaims = (myUser) => {
    return dispatch => {
        axios.get(BASE_URL + '/claim/get-my-claims',
            {
                params: {
                    user_id: myUser.user_id
                },
                headers: authHeader(myUser)
            })
            .then(res => {
                dispatch({
                    type: UPDATE_MY_CLAIMS,
                    payload: res.data
                });
            })
            .catch(err => {
            });
    };
};

export const fetchMainData = (myUser) => {
    return dispatch => {
        dispatch({
            type: MAIN_DATA_FETCH
        });
        dispatch(fetchSectors(myUser));
        dispatch(fetchSubSectors(myUser));
        dispatch(fetchTechnologies(myUser));
        dispatch(fetchCountries(myUser));
        dispatch(fetchSubStates(myUser));
        dispatch(fetchSDGs(myUser));
        if (myUser && myUser.user_id) {
            dispatch(getMyClaims(myUser));
            dispatch(fetchFollows(myUser));
        }
        dispatch({
            type: MAIN_DATA_FETCH_SUCCESS
        });
    };
};
