import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Clear, Delete } from '@material-ui/icons';

import {
    Paper,
    PortletHeader,
    PortletLabel
} from '../index';

import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import EllipsisText from 'react-ellipsis-text';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    logo: {
        width: '48px'
    }
}));

const CompanyCard = (props) => {
    const { className, canDelete, companyInfo, onClickName, onClickDelete, truncateLength, fromDeals, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);
    const classes = useStyles();

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={0}
        >
            <PortletHeader>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                >
                    {companyInfo.avatar_url && companyInfo.avatar_url !== '' ?
                        <Grid item
                              style={{ cursor: 'pointer' }}
                              onClick={onClickName}
                        >
                            <img
                                className={classes.logo}
                                src={companyInfo.avatar_url ? companyInfo.avatar_url : null}
                                alt="new"
                            />
                        </Grid>
                        :
                        <Grid item>
                            <div style={{ 'width': '38px' }}/>
                        </Grid>}
                    <Grid item xs>
                        {/*{name}*/}
                        {companyInfo.name ?
                            <EllipsisText
                                tooltip
                                text={companyInfo.name}
                                length={26}
                            /> : null
                        }
                    </Grid>
                </Grid>

                {canDelete ?
                    <IconButton
                        onClick={onClickDelete}
                    >
                        {fromDeals ? <Clear/> : <Delete/>}
                    </IconButton>
                    : null}
            </PortletHeader>
        </Paper>

    );
};

export default withStyles(styles)(CompanyCard);
