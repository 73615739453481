import 'core-js';
import React from 'react';
import {
    Paper,
    PortletContent
} from '../../index';
import classNames from 'classnames';
import PieChart from '../PieChart';
import { Typography, withStyles } from '@material-ui/core';
import styles from '../styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const PieChartBox = (props) => {
    const {
        classes, className,
        categoryField, valueField, title, idChart, data, fetching,
        legend, legendAlignment,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent>
                <Typography>
                    {title}
                </Typography>
                <PieChart
                    idChart={idChart}
                    chartData={data}
                    categoryField={categoryField}
                    valueField={valueField}
                    legend={!!legend}
                    legendAlignment={legendAlignment}
                />
            </PortletContent>
            {fetching ? <LinearProgress/> : null}
        </Paper>
    );
};

export default withStyles(styles)(PieChartBox);
