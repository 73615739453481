import {
    CLEAR_CSV_HANLDER,
    DOWNLOAD_CSV_ERROR,
    DOWNLOAD_CSV_REQUEST,
    DOWNLOAD_CSV_SUCCESS
} from '../actions/downloadCsvHandler';

const initialState = {
    requestingDownloadCsv: null
};

export default function downloadCsvHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAR_CSV_HANLDER:
            return initialState;
        case DOWNLOAD_CSV_REQUEST:
            return {
                ...state,
                requestingDownloadCsv: true
            };
        case DOWNLOAD_CSV_SUCCESS:
            return {
                ...state,
                requestingDownloadCsv: false
            };
        case DOWNLOAD_CSV_ERROR:
            return {
                ...state,
                requestingDownloadCsv: false
            };
        default:
            return state;
    }

}
