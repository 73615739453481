export const getIndexDoc = (documents, doc_id) => {
    let indexDoc = -1;
    let iid = 0;
    for (let doc of documents) {
        if (doc.doc_id === doc_id) {
            indexDoc = iid;
        }
        iid++;
    }
    return indexDoc;
};

export const getIndexMention = (mentions, mention_id) => {
    let indexMention = -1;
    let iim = 0;
    for (let mention of mentions) {
        if (mention.mention_id === mention_id) {
            indexMention = iim;
        }
        iim++;
    }
    return indexMention;
};

export const getIndexInvestment = (investments, investment_id) => {
    let indexInvestment = -1;
    let iin = 0;
    for (let investment of investments) {
        if (investment.investment_id === investment_id) {
            indexInvestment = iin;
        }
        iin++;
    }
    return indexInvestment;
};
