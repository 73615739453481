import { white, black } from '../common/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    briter_yellow: '#fff6dc',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    main: '#74C0B2',
    light: '#F6F9FD',
    dark: '#4B8476'
  },
  secondary: {
    contrastText: white,
    main: '#888FCA',
    light: '',
    dark: '#4A5181'
  },
  success: {
    contrastText: white,
    main: '#74C0B2',
    light: '#F1FAF5',
    dark: '#4B8476'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  neutral: {
    contrastText: white,
    main: '#777777',
    light: '#FDF8F3',
    dark: '#403f3f'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  text: {
    /*primary: '#12161B',*/
    primary: '#333333',
    secondary: '#66788A',
    disabled: '#A6B1BB'
  },
  background: {
    default: '#f8fafc',
    dark: '#172B4D',
    paper: white
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};
