export const LINKEDIN_STATE = 'asjkjddbasjdb23324';
const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress';
// export const LINKEDIN_RIDERECT = 'http://localhost:3000/auth/linkedin/';
export const LINKEDIN_RIDERECT = 'https://www.briterintelligence.com/auth/linkedin/';
const LINKEDIN_CLIENT_ID = '77on7mp9bfk0y8';

export const getURLWithQueryParams = (base, params) => {
    const query = Object
        .entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    return `${base}?${query}`
};

export const queryToObject = queryString => {
    const pairsString = queryString[0] === '?' ? queryString.slice(1) : queryString
    const pairs = pairsString
        .split('&')
        .map(str => str.split('=').map(decodeURIComponent));
    return pairs.reduce((acc, [key, value]) => key ? { ...acc, [key]: value } : acc, {})
};

export const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_RIDERECT,
    state: LINKEDIN_STATE,
    scope: LINKEDIN_SCOPE
});
