export default {
    '3D Printing': 1,
    'Accounting': 2,
    'Advisory': 3,
    'Aid': 4,
    'Aquaculture': 5,
    'Art': 6,
    'Artificial Intelligence': 7,
    'Bikes': 8,
    'Biofuels': 9,
    'Blogging': 10,
    'Blue Collar': 11,
    'Booking': 12,
    'Bot': 13,
    'Brokerage': 14,
    'Bus Services': 15,
    'Bus Renting': 16,
    'Cargo': 17,
    'Carpooling': 18,
    'Child Care': 19,
    'Church Management': 20,
    'Clothing': 21,
    'Cold Chain': 22,
    'Cold Storage': 23,
    'Communications': 24,
    'Construction': 25,
    'Construction Equipment Marketplace': 26,
    'Consulting': 27,
    'Cooking Equipment': 28,
    'Cosmetics': 29,
    'Crowdfunding': 30,
    'Cryptocurrency': 31,
    'Data Visualisation Framework': 32,
    'Dating App': 33,
    'Delivery': 34,
    'Developers and Designers': 35,
    'Diagnostics': 36,
    'Directory': 37,
    'Discounts': 38,
    'Domains': 39,
    'Drinks on Delivery': 40,
    'Drones': 41,
    'E-Pharmacy': 42,
    'E-Wallet': 43,
    'E-Waste': 44,
    'EdTech': 45,
    'eLearning Platform': 46,
    'Electric Vehicles': 47,
    'Electricity': 48,
    'Electronics': 49,
    'Emergency Services': 50,
    'Energy': 51,
    'Entertainment': 52,
    'Escrow Services': 53,
    'Events': 54,
    'Exchange': 55,
    'Fashion': 56,
    'Feedback': 57,
    'Fertilisers and Inputs': 58,
    'Films': 59,
    'Financial Inclusion': 60,
    'Financial Services': 61,
    'FinTech': 62,
    'Fish': 63,
    'Food Delivery': 64,
    'Food Production': 65,
    'Freelance': 66,
    'Fuel Distributors': 67,
    'Fuel Management': 68,
    'Gaming': 69,
    'Geolocation': 70,
    'Gig Economy': 71,
    'GPS': 72,
    'Health Tech': 73,
    'Housing': 74,
    'Human Resources': 75,
    'Identity': 76,
    'Informal Sector': 77,
    'Insurance': 78,
    'Internet': 79,
    'Investment': 80,
    'Jobs': 81,
    'Language': 82,
    'Last Mile Delivery': 83,
    'Laundry Services': 84,
    'Legal Services': 85,
    'Lending': 86,
    'Livestock': 87,
    'Loans': 88,
    'Logistics Management': 89,
    'Magazine': 90,
    'Management': 91,
    'Manufacturing': 92,
    'Market Access': 93,
    'Marketing': 94,
    'Marketplace for Photographers': 95,
    'Maternal Health': 96,
    'Media': 97,
    'Medical Delivery': 98,
    'Medical Equipment': 99,
    'Microcredit': 100,
    'Mobile App': 101,
    'Monitoring': 102,
    'Music': 103,
    'Network': 104,
    'News': 106,
    'Online Marketplace': 107,
    'Online Retail': 108,
    'Outsourcing': 109,
    'Parking': 110,
    'Payments': 111,
    'Point of Sale': 112,
    'Point of Service': 113,
    'Professional Services': 114,
    'Public Transport': 115,
    'Radio': 116,
    'Property': 117,
    'Recruitment': 118,
    'Recycling': 119,
    'Remittances': 120,
    'Renewables': 121,
    'Rentals': 122,
    'Reproductive Health': 123,
    'Research': 124,
    'Reviews': 125,
    'Rewards': 126,
    'Rickshaw Ridehailing': 127,
    'Ride Hailing': 128,
    'Ride Sharing': 129,
    'Sanitation': 130,
    'Savings': 131,
    'Search Engine': 132,
    'Security': 133,
    'Sensors': 134,
    'Services': 135,
    'Shipping': 136,
    'Snacks': 137,
    'Social Media': 138,
    'Social Network': 139,
    'Software': 140,
    'Software Development': 141,
    'Solar Energy': 142,
    'Sport': 143,
    'Storage': 144,
    'Streaming': 145,
    'Supply Chain Management': 146,
    'Taxi': 147,
    'Telecommunications': 148,
    'Ticketing': 149,
    'Tracking System': 150,
    'Trade': 151,
    'Training': 152,
    'Transfers': 153,
    'Transport': 154,
    'Travel': 156,
    'Trucking': 157,
    'Tutoring': 158,
    'TV': 159,
    'Urban Farming': 160,
    'Vaccination': 161,
    'Vehicle Marketplace': 162,
    'Vehicle Services': 163,
    'Vouchers': 164,
    'Warehouse Management': 165,
    'Waste to Energy': 166,
    'Water Access': 167,
    'Web Development': 168,
    'Automotive': '60fa37c9-96a7-4a1f-a01c-12dbe07ac8da',
    'Wind Energy': '2b4824df-f312-4192-9b7d-7ad6b37eabad',
    'Consumer Analytics': 'ff103c27-221a-4b3c-9096-7648ec03caf1',
    'Telemedicine': 'e0bb04c1-8c3d-48d8-876f-37fa347897c1',
    'mHealth': 'fea400c3-32ef-4938-8b46-3550f31b7c53',
    'Accomodation': 'cfcdbd34-207d-46e3-aef2-4890e443e030',
    'Aeroponics': 'baf0aa92-48bf-4bf9-b2b2-409374b2a33a',
    'Toilets': 'df2a2c38-15e8-4921-9033-43145c72c0ef',
    'Textile': 'dfaed6ae-d731-4b7a-ba4d-f738093690c1',
    'Teacher Training': 'e8085ad3-fb50-4fd8-b669-e48936d8c152',
    'Sustainable Housing': '5aeaeacc-d1e2-42a5-905a-0931fc224efe',
    'Surveys': '88588d81-4a01-4033-896b-9b8af1bf5dda',
    'STEM': '74cb9021-e104-4e6d-9821-999a0c938773',
    'SportTech': '640c573b-efb1-4310-b822-e4a14aafe586',
    'Solar Kiosk': '72b63110-b7fd-499d-a35f-f2bb4fb33576',
    'Smart Contracts': 'da268fab-169a-4b84-9540-af1bf88606f5',
    'Seeds': '71b6bffa-f2da-421f-80f7-4f29c100c3e8',
    'School Management': 'f3c78e94-1f1c-4a3f-a806-41130555f416',
    'Robotics': '801bdc3a-daa4-4a95-ad48-479ed9f85379',
    'Remote Sensing': '1d114101-132e-49df-99cf-1859773ebc86',
    'Recycled Products': '7715c343-3971-48ff-9139-c599613f9232',
    'Publishing': '1932fab8-2bad-48c8-812b-58d62cb6d384',
    'Professional Skills Development': '3dbbf714-ff07-4991-bf23-1f70209dccc5',
    'Precision Agriculture': '379f3a8b-f96d-48d4-a534-fbed28e774c6',
    'Photography': '0071cdbe-295a-478c-988f-bd443875f94a',
    'Phones': '7f43fed8-a212-4542-b2f7-6da9c0958d85',
    'Pest Detection': '95e881e4-dfd6-44b3-9a18-4c7b8690878f',
    'Pensions': '3f073a1f-8dda-4d58-839c-85feeac0e0b6',
    'Peer to Peer': 'ec6c382a-1428-4bf1-b97d-5c9ea4134611',
    'Payroll': '95d56118-46e1-4119-b28b-ad3cc2da81bc',
    'Marketplace for Agricultural Produce': '9884e5cf-99b3-462b-8124-a5f0c13c4731',
    'Marketplace for Agricultural Inputs': 'cdf30d68-423f-465b-af78-96d982951b36',
    'Market Research': 'bb8a8086-a20d-49e8-b11d-ff5ce6cd9b1d',
    'Market Linkages': '2a564532-e50e-4d47-af56-9f4df9f8fc7f',
    'Market Information': 'a4a41dd8-3ec4-4485-9a0a-20c01ffef780',
    'Jewellery': '1fd0f9a1-9d85-40ad-89ac-74676c2c97e6',
    'Irrigation': '5c264d80-a9df-4e23-acb9-1fc8efd46931',
    'Internet of Things': 'be61744b-0a34-46c0-8795-3a2b5f1f4844',
    'Insurtech': '481650cb-c022-40ed-ac51-56df310a561d',
    'Inputs': '7dc7bb0b-e8a6-4771-b6d8-19eb624ceb19',
    'Hydropower': '0057a97d-12be-41eb-aeb2-181766608444',
    'Hydroponics': 'e411cf14-93df-4466-a3cb-72d2de97feb8',
    'Household Waste': 'f26106b1-bdcc-453b-8ed6-c775eaec9287',
    'Hotels': '38c62d83-ec6a-4d05-91e7-a90d7ac86450',
    'Heating': '902020c9-51b8-4316-9ac0-063f12cfcb7a',
    'Gas': '6f739e41-cbd3-4e57-9774-a21fad3b5a07',
    'Furniture': 'b1070838-7e8b-4634-98b9-eab57c9957c5',
    'Forestry': 'a8fdd3b1-5383-4e23-8a90-82f722400bc1',
    'Forecasting': '98d579c0-b238-40b5-a9c5-d1e6ac7a0f9e',
    'Food': 'f76486fa-f497-45d0-b042-92f0f062a02c',
    'Fitness': 'a5d08c14-cdd6-41ef-b048-26150e4de878',
    'Fish Farming': 'dc5fcff4-e776-41f2-92d3-5cb1dceb1637',
    'Financial Literacy': '63c7624a-a419-41e8-9601-8f702479da9a',
    'Fertiliser': 'bd6c31a5-e816-4022-ab7e-dad5611a026c',
    'Farm Management': '88c96b16-9ee1-445a-b4b1-511fd1969f1a',
    'Escrow': '0c870e7a-37ff-46af-a2a3-5bd4781e17bb',
    'Equipment Sharing': '8227c505-7044-4de2-82ae-43ac0a99aa94',
    'Equipment': '45b3420a-c8f5-4f26-a625-4591598844dd',
    'Education': '049546bd-d7bf-40f6-b29c-5547d9f75b0c',
    'e-Learning': 'b83b51d6-e39a-4902-ac77-9046ab43c8ac',
    'Debt': '666ba5e8-98f7-46cd-98f6-8cdcbe10475a',
    'Customer Services': 'c51c8b14-ae63-4ca9-ba40-35fa0b329a0a',
    'Crowdsourcing': '21ba34f4-a864-4ff3-abaa-5133995af363',
    'Credit Scoring': 'dc68d076-4a24-4ddb-9a93-9c4c34a404ec',
    'Classifieds': '20b4185a-43b7-4dc3-a570-a1696455a748',
    'CivicTech': 'eefe8fd4-07fa-4d83-92d9-34abaf6dfd6f',
    'Car Insurance': '537a0afd-6912-4c75-9a39-49d9d7625750',
    'Books': '990b5d0a-3598-4b2e-a76a-56474af2aec5',
    'Blockchain': '9b9f7c07-73df-45c9-a0a1-6ed07b5b65c2',
    'Bio Material': '11eb8d2a-75e5-43b4-b3e3-f45dc92522b4',
    'Billing': '73aa1ed6-48a1-47fa-9ab0-e22026e22557',
    'Animals': 'b5f487d6-89d7-45c0-93af-dd01a9174aa4',
    'Neobank': 'df92830a-57b9-43bd-82d1-f75249a1ad85',
    'API': 'e6b29209-353e-4da3-bbd9-91ff8994ee26',
    'Infrastructure': 'dfb06655-653b-4c6b-a7dc-3479370c76fa',
    'Addressing': '75d38fa0-a45b-4d92-825d-717d743c2913',
    'Job boards': '07b2eb85-2943-46de-b9d4-ef34c87c30b8',
    'Working Capital': 'e2508c59-2da5-4d5b-88b5-79d1e6cebbe8',
    'Weather Forecast': 'f89fe1d8-e801-44b6-b17f-074c912b10e8',
    'Watches': '2512b682-ef33-4751-b3f5-4ec5dd7db607',
    'Waste Water': '5fc35594-1610-40cb-a857-a293f0469266',
    'Waste Recycling': 'd879b26d-a81a-46a7-9262-d13fcb5c6a15',
    'Waste Management': '54083e6d-bb59-49fd-a37d-c9f23eff167e',
    'WASH': '93b860ad-4b04-4bf7-820b-1e5f30ccdd65',
    'PoS': 'f33d9d57-1ce4-4263-961c-5dac1da277e2',
    'Advertising': '9736d3d9-e0cc-41d4-a7e3-e19002888bab',
    'Advice': 'a5d9f31a-a84e-4527-8246-8a7a61e6b957',
    'Advisory Services': 'eda5fb9b-c13a-48b0-9052-0313690a5408',
    'Wallet': 'a3a0800d-8623-47c5-9e25-6f273f2547aa',
    'Videos': 'f9162598-5a80-4c3d-b3b6-33692037381e',
    'Vertical Farming': '95ee6cb1-5002-48de-b1ec-1615ec6eb98a',
    'Utilities': 'c0d3b101-21e4-4346-b0a6-a0628c908ef3',
    'Urban Agriculture': '447c03b6-0f49-44d3-b5ec-085783feabb4',
    'Transparency': 'f791b908-1cea-4343-a078-575a89d094b3',
    'Topography': '5b77cb53-3026-4157-9112-b7b023a46ae5',
    'Revision & Examinations': '53f174df-9cda-4b99-9ebd-0771fff3d774',
    'Retreat': '9fa8b108-208b-4c6f-a22d-eace079e5931',
    'Rent to Own': '3cb414ce-3ce5-4f10-b934-b8f601fadf62',
    'Organic Waste': '9ba58243-45f6-4385-8d6d-0d1c6d3a197e',
    'Natural Resource Management': '60f089a4-3c98-4e4c-a151-f3eb2d61ce8b',
    'Mobile Money': '40309388-03db-433d-8583-c3bf5b63ffbb',
    'Hardware': 'a78a2889-090c-4f12-b354-9c0cbb224c48',
    'Groceries Delivery': 'b597663c-f1cf-4465-ab6a-a57f949c40d1',
    'GIS': '7319e4ff-3577-4abd-ad7e-356661e2d20b',
    'Geospatial': 'f78ad4e8-51c3-42a5-9f62-d6b697143920',
    'General Retail': '00aae528-9257-43fa-8024-a64fd6524255',
    'E-Commerce': '53d3cd15-4209-4e1f-baa7-8a385bc38cd8',
    'Domestic Farming': 'ca6db2d8-5789-4bab-8d8b-4faae6a2c48e',
    'Disease Detection': '25527985-3498-4dde-9a7a-1502c298435e',
    'Design': '3a34039e-b09d-41b1-a3d2-c2dd68097756',
    'Credit Score': 'b167bc1b-2028-4fde-9cd7-8ea06645a7e2',
    'Cooking': '33f43d57-4974-4e45-9f77-4cfa7dec0c18',
    'Contract farming': 'ede17f47-c1aa-40bc-b887-be84fc557e04',
    'Competitions': 'b00376e4-d6e4-466a-911c-bc0567cdd745',
    'Commodity Exchange': 'd331b722-f072-435e-8c9f-72aac9a3abe8',
    'Commodities Marketplace': 'ac8d50c0-37f7-4e41-b9ec-c2e78118d14b',
    'Comics': '4233abc6-0eb4-422b-b647-196286b4eda4',
    'Cloud Management': '6158f189-cecd-4459-a14e-f8cd09c422e0',
    'Click and Collect': 'fe063a24-6a7c-4dce-b9fc-4de2c57de52c',
    'CleanTech': '00bca083-be9e-46c4-8b73-c1e3f2d56e24',
    'Beauty': '2a1a5da5-8df5-474c-b986-04ea7ef745db',
    'Banking': '2efd4a4c-cff6-45c0-a659-16dc74c2a70d',
    'Assistive Tech': '11c1004f-959a-47f4-95eb-5ea02c8dcf76',
    'Apiculture': '9c184d8d-84c0-449a-81dd-ebf1333d7842',
    'Animation': '1bbf94d5-d7d8-402c-a870-057e3af0f6c3'

};
