const lastDay = function(y, m) {
    return new Date(y, m + 1, 0);
};

export const aggregateTrends = (investments) => {
    let aggregatedMonths = [];

    for (let m = 0; m <= 11; m++) {
        let entry = {
            'date': lastDay(2021, m),
            '2021': 0,
            '2020': 0,
            '2019': 0,
            '2018': 0,
            '2017': 0
        };
        aggregatedMonths.push(entry);
    }

    for (let investment of investments) {
        let dt = new Date(investment.date);
        let month = dt.getMonth();
        let year = dt.getFullYear();
        aggregatedMonths[month][year] += investment.amount;
    }

    return aggregatedMonths;
};
