import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FinancialCompanyTab from './financialCompanyTab';
import SocialCompanyTab from './socialCompanyTab';
import ProfileCompany from './profileCompanyTab';
import { connect } from 'react-redux';
import {
    cleanCompany,
    createCompany,
    fetchCompanyData,
    fetchCompanyDataEmpty, removeCompanyUrl,
    updateCompany
} from '../../actions/companyHandler';
import { clickRequestManage, clickSuggestUpdate, fetchMainData } from '../../actions/mainHandler';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import TeamCompany from './teamCompanyTab';
import PartnershipTab from './partnershipTab';
import { actionTypes, isFormDisabled, tableActionTypes } from '../../helpers/actionTypes';
import { Formik } from 'formik';
import SimpleSnackbar from '../../components/Feedback/SimpleSnackbar';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import history from '../../config/history';
import { getCountryByName } from '../../data/location/utilsLocation';
import { checkAvailabilityCompanyName } from '../../helpers/Validation/fieldForm';
import ImpactCompanyTab from './impactCompanyTab';
import DealsCompanyTab from './dealsCompanyTab';
import UploadFileTab from './uploadFileTab';
import { companySchema } from '../../models/company/companyValidationSchema';
import Spacer from '../../components/Spacer/Spacer';
import PortfolioTab from './portfolioTab';
import MarketTab from './marketTab';
import { INNER_TEAM, PAYING_USER } from '../../helpers/Roles/roleActions';
import { getTitleOnCompanyCreated } from '../../helpers/Strings/ActionTitleStrings';
import MentionsTab from './mentionsTab';
import { tabPositions } from '../../helpers/Tabs/tabPositions';
import { isMyCompany } from '../../helpers/Roles/claimUtils';
import { ArrowBack, Visibility } from '@material-ui/icons';
import ButtonExtended from '../../components/UI/ButtonExtended/ButtonExtended';
import { clickFollowCompany } from '../../actions/followHandler';
import { downloadCsvCompany, clearCsvHandler } from '../../actions/downloadCsvHandler';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import AssessmentTab from './assessmentTab';
import { getPercentageCompletitionCompany } from '../../helpers/formCompletitionCheck';
import LinearWithValueLabel from '../../components/FormProgressBar/FormProgressBarMUI';
import ActionDialog from '../../components/ChangeStatusDialog';
import { getAssessmentTabName } from '../../strings/genericStrings';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

class Company extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actionMode: actionTypes.SHOW,
            activeTab: 0,
            snackVisible: false,
            snackbarVariant: '',
            dialogOpen: false,
            titleDialog: '',
            contentDialog: '',
            avatarLoaded: null,

            nameCompanyAvailable: true,

            category: 'startup',

            pagination: null,

            formCompletedPercentage: 0,
            progressEnabled: false,

            dialogCsvVisible: false,
            navigatedToTab: false
        };

        this.props.cleanCompany();
    }

    getTabPosition(tabName) {
        let category = this.state.category;
        if (this.state.category !== 'startup' && this.state.category !== 'investor') {
            category = 'other';
        }

        let mainRole = '';
        if (INNER_TEAM.includes(this.props.myUser.role)) {
            mainRole = 'inner_team';
        } else {
            mainRole = 'users';
        }


        let position = tabPositions[mainRole][category][this.state.actionMode][tabName];
        return position;
    }

    handleClickBack = () => {
        if (this.state.origin === 'manage') {
            history.push({
                pathname: '/managecompanies/',
                pagination: this.state.pagination
            });
        } else if (this.state.origin === 'deals') {
            history.push({
                pathname: '/deals/',
                pagination: this.state.pagination
            });
        } else {
            history.push({
                pathname: '/overview/'
            });
        }

    };

    handleChangeTab = (event, newValue) => {
        this.setState({
            ...this.state,
            activeTab: newValue
        });
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (variant) => {
        this.setState({
            ...this.state,
            snackbarVariant: variant,
            snackVisible: true
        });
    };

    checkPercentageForm = (formikValues) => {
        let teamLength = this.props.companyTeam ? this.props.companyTeam.length : 0;
        let docsLength = this.props.companyDocuments ? this.props.companyDocuments.length : 0;
        let mentionsLength = this.props.companyMentions ? this.props.companyMentions.length : 0;
        let dealsLenght = this.props.companyInvestments ? this.props.companyInvestments.length : 0;

        let newPercentage = getPercentageCompletitionCompany(formikValues, teamLength, docsLength, mentionsLength, dealsLenght);
        if (newPercentage !== this.state.formCompletedPercentage)
            this.setState({
                formCompletedPercentage: newPercentage
            });
    };

    submitForm = (values, actions) => {
        // todo check _.isEqual(a, b);

        // alert(JSON.stringify(values, null, 2));

        if (this.state.nameCompanyAvailable) {

            switch (this.state.actionMode) {
                case actionTypes.ADD:
                    this.props.createCompany(
                        this.props.companyData,
                        this.state.avatarLoaded,
                        values,
                        this.props.companyTeam,
                        this.props.companyInvestments,
                        this.props.partnerships,
                        this.props.accelerators,
                        this.props.portfolios,
                        this.props.companyDocuments,
                        this.props.companyMentions,
                        this.props.myUser
                    );
                    break;
                case actionTypes.EDIT:
                    this.props.updateCompany(
                        this.props.companyData,
                        this.state.avatarLoaded,
                        values,
                        this.props.companyTeam,
                        this.props.companyInvestments,
                        this.props.partnerships,
                        this.props.accelerators,
                        this.props.portfolios,
                        this.props.companyDocuments,
                        this.props.companyMentions,
                        this.props.companyData.company.company_id,
                        this.props.myUser
                    );
                    break;
                default:
                    break;
            }

        }
    };

    handleClickOkDialog() {
        history.push({
            pathname: '/managecompanies'
        });
    }

    componentDidMount() {
        const actionType = this.props.match.params.actionType;
        const company_id = this.props.match.params.company_id;
        const pagination = this.props.location.pagination;
        const origin = this.props.location.origin;

        this.setState({
            ...this.state,
            actionMode: actionType,
            pagination: pagination,
            origin: origin
        });

        this.props.clearCsvHandler();

        switch (actionType) {
            case actionTypes.SHOW:
                this.props.fetchCompanyData(company_id, this.props.myUser);
                break;
            case actionTypes.ADD:
                this.props.fetchCompanyDataEmpty();
                break;
            case actionTypes.EDIT:
                if (INNER_TEAM.includes(this.props.myUser.role) ||
                    this.props.activeClaims && this.props.activeClaims.includes(this.props.match.params.company_id)) {
                    this.props.fetchCompanyData(company_id, this.props.myUser);
                } else {
                    this.goToDashboard();
                }
                break;
            default:
                break;
        }

        this.props.fetchMainData(this.props.myUser);

        setTimeout(function() {
            this.setState({ progressEnabled: true });
        }.bind(this), 2900);
    }

    goToDashboard = () => {
        history.push({
            pathname: '/overview'
        });
    };

    openDialogCreation(success) {
        const titleDialog = getTitleOnCompanyCreated(this.props.myUser.role);
        if (success) {
            this.setState({
                ...this.state,
                dialogOpen: true,
                titleDialog: titleDialog
            });
        }
    }

    checkCategoryChange(newCategory, targetName) {
        if (targetName === 'company.category') {
            if (newCategory !== this.state.category) {
                this.setState({
                    category: newCategory
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const destinationTab = this.props.location.destinationTab;
        if (prevProps !== this.props) {
            if (this.props.companyData && this.props.companyData.company.category.toLowerCase() !== this.state.category) {
                this.setState({
                    category: this.props.companyData.company.category.toLowerCase(),
                    activeTab: destinationTab ? this.getTabPosition(destinationTab) : this.state.activeTab
                });
            }
        }

        if (!this.state.navigatedToTab && prevState.category !== this.state.category) {
            this.setState({
                navigatedToTab: true,
                activeTab: destinationTab ? this.getTabPosition(destinationTab) : this.state.activeTab
            });
        }

        if (prevProps !== this.props && this.props.companyFetched !== prevProps.companyFetched) {
            this.setState({
                activeTab: destinationTab ? this.getTabPosition(destinationTab) : this.state.activeTab
            });
        }

        if (prevProps.companyUpdating && !this.props.companyUpdating) {
            let variant = 'info';

            switch (this.state.actionMode) {
                case actionTypes.ADD:
                    if (this.props.companyUpdateError === true) {
                        variant = 'error';
                        this.openSnack(variant);
                    } else {
                        this.openDialogCreation(true);
                    }
                    break;
                case actionTypes.EDIT:
                    if (this.props.companyUpdateError === true) {
                        variant = 'error';
                    } else {
                        variant = 'success';
                    }
                    this.openSnack(variant);
                    break;
                default:
                    break;
            }
            // TODO check notistack library
        }
    }

    handleRemoveAvatarClick = () => {
        this.setState({
            avatarLoaded: null
        }, () => {
            if (this.props.companyData.company.avatar_url) {
                this.props.removeCompanyUrl();
            }
        });
    };

    handleAvatarLoaded = (file) => {
        this.setState({
            avatarLoaded: file
        });
    };

    checkCompanyName = (name) => {
        if (name.length > 0) {
            checkAvailabilityCompanyName(name.trim(), this.props.match.params.company_id, this.props.myUser, (isAvailable) => {
                this.setState({
                    nameCompanyAvailable: isAvailable
                });
            });
        }
    };

    handleClickDownloadCsv = () => {
        this.setState({
            activeActionType: tableActionTypes.DOWNLOAD
        }, () => this.openDialogCsv());
    };

    openDialogCsv = () => {
        this.setState({
            dialogCsvVisible: true
        });
    };

    closeActionDialog = () => {
        this.setState({
            dialogCsvVisible: false
        });

    };

    getColorTab(tabName) {
        let colorTabName = null;
        // if (this.state.actionMode === actionTypes.SHOW) {
        switch (tabName) {
            case 'media':
                colorTabName = '#C4C4C4';
                break;
            case 'team':
                if (this.props.companyTeam && !this.props.companyTeam.length > 0) {
                    colorTabName = '#C4C4C4';
                }
                break;
            case 'portfolio':
                if (this.props.portfolios && !this.props.portfolios.length > 0) {
                    colorTabName = '#C4C4C4';
                }
                break;
            case 'documents':
                if (this.props.companyDocuments && !this.props.companyDocuments.length > 0) {
                    colorTabName = '#C4C4C4';
                }
                break;
            case 'partnerships':
                if (this.props.partnerships && !this.props.partnerships.length > 0) {
                    colorTabName = '#C4C4C4';
                }
                break;
            case 'funding':
                if (this.props.companyInvestments && !this.props.companyInvestments.length > 0) {
                    colorTabName = '#C4C4C4';
                }
                break;
            default:
                colorTabName = null;
                break;
            // }
        }
        return colorTabName;
    }

    isAlreadyClaimed = () => {
        let isClaimIncluded = false;
        if (this.props.activeClaims && this.props.activeClaims.includes(this.props.match.params.company_id)) {
            isClaimIncluded = true;
        } else if (this.props.pendingClaims && this.props.pendingClaims.includes(this.props.match.params.company_id)) {
            isClaimIncluded = true;
        } else {
            isClaimIncluded = false;
        }
        return isClaimIncluded;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    handleConfirmAction = () => {
        switch (this.state.activeActionType) {
            case tableActionTypes.DOWNLOAD:
                this.props.downloadCsvCompany({
                    myUser: this.props.myUser,
                    ids_selected: [this.props.match.params.company_id],
                    type: 'single'
                });
                this.closeActionDialog();
        }
    };

    render() {
        let {
            companyData,
            countriesArray,
            subStatesArray,
            sectors,
            subSectors,
            technologies,
            companyUpdating,
            companyFetched,
            geographyFetched
        } = this.props;

        return (

            <DashboardLayout title="Company">
                {(companyFetched && geographyFetched) || this.state.actionMode === actionTypes.ADD
                    ?
                    <div>
                        <Grid container justify='space-between' direction={'row'}>

                            <Grid item md={3} xs={12}>
                                {INNER_TEAM.concat(PAYING_USER).includes(this.props.myUser.role) ?
                                    <ButtonExtended
                                        startIcon={<ArrowBack/>}
                                        variant="outlined"
                                        color='success'
                                        style={{
                                            'text-transform': 'none',
                                            'maxHeight': '40px',
                                            'paddingRight': '25px',
                                            borderWidth: 0
                                        }}
                                        onClick={this.handleClickBack}
                                    >
                                        Back
                                    </ButtonExtended>
                                    : null}
                            </Grid>

                            <Grid container item alignItems="center"
                                  md={9} xs={12} justify={'flex-end'} spacing={4} style={{ marginRight: 4 }}>
                                {this.props.companyData.company.visit_counter ?
                                    <Grid item>
                                        <Chip
                                            icon={<Visibility size='small'/>}
                                            label={this.props.companyData.company.visit_counter + ' views'}
                                            color="primary"
                                            style={{ marginRight: 25, paddingLeft: '4px', paddingRight: '4px' }}
                                        />
                                    </Grid>
                                    : null}
                                {(this.state.actionMode === actionTypes.EDIT || this.state.actionMode === actionTypes.ADD) ?
                                    <Grid item>
                                        {/*<FormProgressBar key={1}*/}
                                        {/*                 completed={this.state.progressEnabled ? this.state.formCompletedPercentage : 0}*/}
                                        {/*/>*/}
                                        {/*<CustomizedProgressBars/>*/}
                                        <div>
                                            <LinearWithValueLabel
                                                completed={this.state.progressEnabled ? this.state.formCompletedPercentage : 0}
                                            />
                                        </div>
                                    </Grid>
                                    : null}
                            </Grid>
                        </Grid>
                        <AppBar position="static"
                                color="inherit"
                                style={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    borderBottom: 'solid 1px #ddd'
                                }}
                        >

                            <div>
                                <Tabs value={this.state.activeTab} onChange={this.handleChangeTab}
                                      aria-label="simple tabs example"
                                      forceRenderTabPanel={false}
                                      variant="scrollable"
                                      scrollButtons="auto"
                                >

                                    <Tab label="Profile" {...a11yProps(0)} />

                                    {this.state.category === 'startup' ?
                                        <Tab label="Funding" {...a11yProps(1)}
                                             style={{ color: this.getColorTab('funding') }}
                                        /> : null}

                                    {this.state.actionMode !== actionTypes.SHOW ?
                                        <Tab label="Social" {...a11yProps(3)} />
                                        : null
                                    }

                                    <Tab label="Team" {...a11yProps(4)}
                                         style={{ color: this.getColorTab('team') }}
                                    />

                                    {this.state.category === 'startup' ?
                                        <Tab label="Partnerships" {...a11yProps(5)}
                                             style={{ color: this.getColorTab('partnerships') }}
                                        /> : null}

                                    <Tab label="Impact" {...a11yProps(6)} />

                                    {
                                        INNER_TEAM.includes(this.props.myUser.role)
                                        && (this.state.category.toLowerCase() === 'startup' || this.state.category.toLowerCase() === 'investor') ?
                                            <Tab
                                                label={this.state.category.toLowerCase() === 'startup' ? 'Finance and Ops' : 'Fund Details'} {...a11yProps(2)}
                                                style={{ color: '#C4C4C4' }}
                                            /> : null
                                    }


                                    {/*{this.state.category === 'startup' ?*/}
                                    {/*    <Tab label="KPIs" {...a11yProps(7)} /> : null}*/}

                                    <Tab label={getAssessmentTabName(this.state.category)} {...a11yProps(7)} />

                                    <Tab label="Docs" {...a11yProps(8)}
                                         style={{ color: this.getColorTab('documents') }}
                                    />

                                    {this.state.category !== 'startup' ?
                                        <Tab label="Portfolio" {...a11yProps(9)}
                                             style={{ color: this.getColorTab('portfolio') }}
                                        /> : null}

                                    {this.state.category === 'startup' ?
                                        <Tab label="Market" {...a11yProps(10)} /> : null}

                                    <Tab label="Media Mentions" {...a11yProps(11)}
                                         style={{ color: '#C4C4C4' }}
                                    />


                                </Tabs>
                            </div>
                            {companyUpdating ? <LinearProgress/> : null}
                        </AppBar>
                        {(companyFetched && geographyFetched) || this.state.actionMode === actionTypes.ADD ?
                            <Formik
                                enableReinitialize

                                initialValues={companyData ? {
                                    company: {
                                        name: companyData.company.name,
                                        status: companyData.company.status ? companyData.company.status : false,
                                        email: companyData.company.email,
                                        category: companyData.company.category.toLowerCase(),
                                        contact_number: companyData.company.contact_number,
                                        founding_date: companyData.company.founding_date,
                                        number_employees: companyData.company.number_employees,
                                        ownership_structure: companyData.company.ownership_structure,
                                        website: companyData.company.website,
                                        description: companyData.company.description,
                                        operating_status: companyData.company.operating_status,
                                        incorporation_number: companyData.company.incorporation_number,
                                        reference: companyData.company.reference,
                                        fundraising: companyData.company.fundraising,
                                        stage: companyData.company.stage,
                                        has_users: companyData.company.has_users,
                                        legal_registration: companyData.company.legal_registration,
                                        own_ip: companyData.company.own_ip,
                                        generating_revenue: companyData.company.generating_revenue,
                                        raised_capital: companyData.company.raised_capital,
                                        ext_capital_sought: companyData.company.ext_capital_sought,
                                        past_accelerated: companyData.company.past_accelerated,
                                        looking_acceleration: companyData.company.looking_acceleration,
                                        product_development: companyData.company.product_development,
                                        product_development_last_update: companyData.company.product_development_last_update
                                    },
                                    key_milestones_ids: companyData.company.key_milestones_ids ? companyData.company.key_milestones_ids : [],
                                    ext_capital_sought_types: companyData.company.ext_capital_sought_types ? companyData.company.ext_capital_sought_types : [],
                                    investment_stages_ids: companyData.company.investment_stages_ids ? companyData.company.investment_stages_ids : [],
                                    looking_acceleration_ids: companyData.company.looking_acceleration_ids ? companyData.company.looking_acceleration_ids : [],
                                    categorySpec: {
                                        startup: {
                                            business_model: companyData.categorySpec.startup ? companyData.categorySpec.startup.business_model : null
                                        },
                                        hub: {
                                            fund_provided: companyData.categorySpec.hub ? companyData.categorySpec.hub.fund_provided : null
                                            // type: companyData.categorySpec.hub ? companyData.categorySpec.hub.type : null
                                        },
                                        investor: {
                                            // type: companyData.categorySpec.investor.type,
                                            fund_size: companyData.categorySpec.investor.fund_size,
                                            ticket_fund_size_min: companyData.categorySpec.investor.ticket_fund_size_min,
                                            ticket_fund_size_max: companyData.categorySpec.investor.ticket_fund_size_max,
                                            actively_investing: companyData.categorySpec.investor.actively_investing,
                                            post_revenue_only: companyData.categorySpec.investor.post_revenue_only,
                                            lead_rounds: companyData.categorySpec.investor.lead_rounds,
                                            number_exits: companyData.categorySpec.investor.number_exits,
                                            number_investment: companyData.categorySpec.investor.number_investment,
                                            stage_criteria: companyData.categorySpec.investor.stage_criteria
                                        },
                                        ngo: {
                                            organization_type: companyData.categorySpec.ngo.organization_type
                                        }
                                    },
                                    social: {
                                        facebook: companyData.social.facebook,
                                        linkedin: companyData.social.linkedin,
                                        twitter: companyData.social.twitter,
                                        instagram: companyData.social.instagram
                                    },
                                    geography: {
                                        hq_city: companyData.geography.hq_city,
                                        hq_country: getCountryByName(companyData.geography.hq_country),
                                        hq_address: companyData.geography.hq_address
                                    },
                                    sectors: companyData.sectors,
                                    sub_sectors: companyData.sub_sectors,
                                    technologies: companyData.technologies,
                                    countries: companyData.countries,
                                    sub_states: companyData.sub_states,
                                    financial_metrics: companyData.financial_metrics,
                                    operational_metrics: companyData.operational_metrics,
                                    impact: companyData.impact,
                                    SDGs: companyData.SDGs,
                                    types: companyData.types,
                                    stagesOfInterest: companyData.stagesOfInterest
                                } : null}

                                onSubmit={this.submitForm}
                                validationSchema={companySchema}
                            >
                                {formikProps => (
                                    <form id="general-company-form"
                                          onKeyDown={onKeyDown}
                                          onSubmit={formikProps.handleSubmit}
                                          onChange={(e) => {
                                              // console.log('FORMIK CHANGE');
                                              this.checkCategoryChange(e.target.value, e.target.name);
                                          }}
                                          onBlur={this.checkPercentageForm(formikProps.values)}
                                    >

                                        <TabPanel value={this.state.activeTab} index={this.getTabPosition('profile')}
                                                  forceRenderTabPanel={false}>
                                            <ProfileCompany
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                formikProps={formikProps}
                                                companyData={companyData}
                                                sectors={sectors}
                                                subSectors={subSectors}
                                                technologies={technologies}
                                                onRemoveClick={this.handleRemoveAvatarClick}
                                                urlAvatarSaved={companyData.company.avatar_url}
                                                avatarFile={this.state.avatarLoaded}
                                                onAvatarLoaded={this.handleAvatarLoaded}
                                                checkCompanyName={this.checkCompanyName}
                                                nameCompanyAvailable={this.state.nameCompanyAvailable}
                                                countriesArray={countriesArray}
                                                subStatesArray={subStatesArray}
                                                myUser={this.props.myUser}
                                                category={this.props.companyData.company.category.toLowerCase()}
                                                actionMode={this.state.actionMode}
                                                requestManageDisabled={this.isAlreadyClaimed()}
                                                pendingClaims={this.props.pendingClaims}
                                                activeClaims={this.props.activeClaims}
                                                handleSuggestUpdate={() => this.props.clickSuggestUpdate(companyData.company.name)}
                                                handleRequestManage={() => this.props.clickRequestManage(companyData.company.name, companyData.company.company_id)}

                                                handleFollowCompany={() => this.props.clickFollowCompany(companyData.company.company_id, this.props.companiesFollowed, this.props.myUser)}

                                                companiesFollowed={this.props.companiesFollowed}
                                                disabledFollow={this.props.followStatusUpdating}
                                                visitCounter={this.props.companyData.company.visit_counter}
                                                handleClickDownloadCsv={this.handleClickDownloadCsv}
                                                requestingDownloadCsv={this.props.requestingDownloadCsv}
                                                companyInvestments={this.props.companyInvestments}
                                            />
                                        </TabPanel>

                                        {this.state.category === 'startup' ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('funding')}>
                                                <DealsCompanyTab
                                                    formikProps={formikProps}
                                                    formDisabled={isFormDisabled(this.state.actionMode)}
                                                    myUser={this.props.myUser}
                                                    actionMode={this.state.actionMode}
                                                    isMyCompany={isMyCompany(this.props.activeClaims, this.props.match.params.company_id)}
                                                />
                                            </TabPanel> : null}

                                        {this.state.actionMode !== actionTypes.SHOW ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('socials')}>
                                                <SocialCompanyTab
                                                    formDisabled={isFormDisabled(this.state.actionMode)}
                                                    formikProps={formikProps}
                                                    actionMode={this.state.actionMode}
                                                />
                                            </TabPanel>
                                            : null
                                        }


                                        <TabPanel value={this.state.activeTab} index={this.getTabPosition('team')}>
                                            <TeamCompany
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                actionMode={this.state.actionMode}
                                            />
                                        </TabPanel>

                                        {this.state.category === 'startup' ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('partnership')}>
                                                <PartnershipTab
                                                    formDisabled={isFormDisabled(this.state.actionMode)}
                                                    actionMode={this.state.actionMode}
                                                />
                                            </TabPanel> : null}

                                        <TabPanel value={this.state.activeTab} index={this.getTabPosition('impact')}>
                                            <ImpactCompanyTab
                                                formikProps={formikProps}
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                SDGsArray={this.props.SDGs}
                                                actionMode={this.state.actionMode}
                                            />
                                        </TabPanel>

                                        {this.state.category.toLowerCase() === 'startup' || this.state.category.toLowerCase() === 'investor' ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('financial')}>
                                                <FinancialCompanyTab
                                                    formikProps={formikProps}
                                                    formDisabled={isFormDisabled(this.state.actionMode)}
                                                    numInvestments={this.props.portfolios ? this.props.portfolios.length : null}
                                                    actionMode={this.state.actionMode}
                                                />
                                            </TabPanel> : null}

                                        {/*{this.state.category === 'startup' ?*/}
                                        {/*    <TabPanel value={this.state.activeTab} index={this.getTabPosition('kpi')}>*/}
                                        {/*        <KPICompanyTab*/}
                                        {/*            actionMode={this.state.actionMode}*/}
                                        {/*        />*/}
                                        {/*    </TabPanel> : null}*/}

                                        <TabPanel value={this.state.activeTab}
                                                  index={this.getTabPosition('assessment')}>
                                            <AssessmentTab
                                                formikProps={formikProps}
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                capitalSoughtTypesArray={this.props.capitalSoughtTypesArray}
                                                actionMode={this.state.actionMode}
                                            />
                                        </TabPanel>

                                        <TabPanel value={this.state.activeTab} index={this.getTabPosition('docs')}>
                                            <UploadFileTab
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                companyId={this.props.match.params.company_id ? this.props.match.params.company_id : null}
                                                actionMode={this.state.actionMode}
                                            />
                                        </TabPanel>

                                        {this.state.category !== 'startup' ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('portfolio')}>
                                                <PortfolioTab
                                                    formDisabled={isFormDisabled(this.state.actionMode)}
                                                    actionMode={this.state.actionMode}
                                                />
                                            </TabPanel> : null}

                                        {this.state.category === 'startup' ?
                                            <TabPanel value={this.state.activeTab}
                                                      index={this.getTabPosition('market')}>
                                                <MarketTab
                                                    actionMode={this.state.actionMode}
                                                />
                                            </TabPanel> : null}

                                        <TabPanel value={this.state.activeTab} index={this.getTabPosition('mentions')}>
                                            <MentionsTab
                                                formDisabled={isFormDisabled(this.state.actionMode)}
                                                actionMode={this.state.actionMode}
                                            />
                                        </TabPanel>

                                    </form>
                                )}
                            </Formik>
                            : null}
                    </div>
                    : <LinearProgress/>
                }
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
                <AlertDialog
                    openDialog={this.state.dialogOpen}
                    title={this.state.titleDialog}
                    content={this.state.contentDialog}
                    clicked={this.handleClickOkDialog.bind(this)}
                />
                <ActionDialog
                    tableName={'company'}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogCsvVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    onConfirm={() => this.handleConfirmAction()}
                    downloadType={'single'}
                />
                <Spacer small divider/>
                <Box display="flex" flexDirection="row-reverse" style={{ marginRight: 20 }}>
                    {this.state.actionMode === actionTypes.SHOW ? null
                        : this.state.actionMode === actionTypes.EDIT &&
                        (this.props.myUser.role !== 'user' || isMyCompany(this.props.activeClaims, this.props.match.params.company_id)) ?
                            <Button
                                variant="contained"
                                disabled={companyUpdating}
                                form="general-company-form"
                                type="submit"
                                value="Submit"
                                color="primary"
                                style={{ backgroundColor: '#45B880' }}
                            >
                                {'SAVE COMPANY'}
                            </Button>
                            : this.state.actionMode === actionTypes.ADD ?
                                <Button
                                    variant="contained"
                                    disabled={companyUpdating}
                                    form="general-company-form"
                                    type="submit"
                                    value="Submit"
                                    color="primary"
                                    style={{ backgroundColor: '#45B880' }}
                                >
                                    {'CREATE COMPANY'}
                                </Button> :
                                null
                    }
                </Box>
            </DashboardLayout>
        );
    }
}

const mapDispatchToProps = {
    fetchCompanyData,
    fetchCompanyDataEmpty,
    updateCompany,
    createCompany,
    fetchMainData,

    cleanCompany,

    removeCompanyUrl,
    clickSuggestUpdate,
    clickRequestManage,
    clickFollowCompany,

    downloadCsvCompany,
    clearCsvHandler
};

const mapStateToProps = (state) => {
    return {
        mainDataFetched: state.mainHandler.mainDataFetched,
        companyData: state.companyHandler.companyData,
        companyTeam: state.companyHandler.companyTeam,
        companyInvestments: state.companyHandler.companyInvestments,
        partnerships: state.companyHandler.partnerships,
        accelerators: state.companyHandler.accelerators,
        portfolios: state.companyHandler.portfolios,
        companyDocuments: state.companyHandler.companyDocuments,
        companyMentions: state.companyHandler.companyMentions,

        sectors: state.mainHandler.sectors,
        subSectors: state.mainHandler.subSectors,
        technologies: state.mainHandler.technologies,
        countriesArray: state.mainHandler.countries,
        subStatesArray: state.mainHandler.subStates,
        SDGs: state.mainHandler.SDGs,

        companyUpdating: state.companyHandler.companyUpdating,
        companyUpdateError: state.companyHandler.companyUpdateError,

        companyFetched: state.companyHandler.companyFetched,
        geographyFetched: state.companyHandler.geographyFetched,

        myUser: state.authHandler.myUser,

        pendingClaims: state.mainHandler.pendingClaims,
        activeClaims: state.mainHandler.activeClaims,

        companiesFollowed: state.followHandler.companiesFollowed,
        followStatusUpdating: state.followHandler.followStatusUpdating,

        requestingDownloadCsv: state.downloadCsvHandler.requestingDownloadCsv
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
