export const userProfileObj = {
    user_id: null,
    first_name: null,
    last_name: null,
    email: null,
    avatar_url: null,

    role: null,
    active: null,
    status: null,

    creation_date: null,
    last_access: null,
    last_update: null,

    user_token: null
};
