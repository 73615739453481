import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import moment from 'moment';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import { actionTypes } from '../../../helpers/actionTypes';
import StatusButton from '../../StatusButton';
import styles from '../styles';
import Checkbox from '@material-ui/core/Checkbox';
import ActionsBox from '../../ActionsBox';
import { can, DELETE_ROW, EDIT_ROW } from '../../../helpers/Roles/roleActions';

class UserRow extends Component {

    onClickNameUser = () => {
        history.push({
            pathname: '/user/' + actionTypes.EDIT + '/' + this.props.row.user_id
        });
    };

    onClickDelete = () => {
        // TODO
    };

    render() {

        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            onClickStatus,
            myUser,
            activeSelection
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={event => onClickCheckbox(event, row.idrow)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    onClick={this.onClickNameUser}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    {row.first_name}
                </TableCell>
                <TableCell>{row.last_name}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                    <StatusButton
                        //disabled={}
                        status={row.status}
                        onClick={onClickStatus ? () => onClickStatus(row) : null}
                    />
                </TableCell>
                <TableCell>{moment(row.last_access).utc().format('DD/MM/YYYY - HH:mm')}</TableCell>
                <TableCell align={'right'}>
                    <ActionsBox
                        disabled={activeSelection}
                        onEdit={can(myUser, EDIT_ROW) ? this.onClickNameUser : null}
                        // onDelete={can(myUser, DELETE_ROW) ? this.onClickDelete : null}
                        onDelete={null}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(UserRow);
