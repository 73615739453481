import { locations } from './locations';

export const getCountryByName = (countryName) => {
    // if (countryName) {
    // console.log('GET COUNTRY BY NAME', countryName);
    let country = locations.find(c => c.name === countryName);
    // console.log('return', country);
    return country;
    // }
};

export const countries = (region) => [...new Set(locations.map(re => {
    if (region === '')
        return re['name'];
    else if (region === re['sub-region'])
        return re['name'];
    else
        return '';
}))].filter(function(el) {
    return el !== '';
});


export const regions = [...new Set(locations.map(re => re['sub-region']))].filter(function(el) {
    return el !== '';
}).sort();

export const getCountryCodeByName = (countryName) => {
    let countryNameString = countryName.trim();

    let country = locations.find(c => c.name === countryNameString);
    if (country) {
        return country['country-code'];
    } else {
        country = locations.find(c => c.name === countryNameString.toLowerCase());
        if (country) {
            return country['country-code'];
        }
    }

    return null;
};
