import { extractTables, getCSVHeader, isValidHeader } from '../helpers/CSVhelper';

export const CSV_READING_START = 'csv_reading_start';
export const CSV_READING_END = 'csv_reading_end';
export const CSV_CLEAR = 'csv_clear';

export const CSV_BODY_READ = 'csv_body_read';
export const CSV_HEADER_READ = 'csv_header_read';
export const CSV_SET_TABLES = 'csv_set_tables';

export const CSV_SET_HEADER_VALIDITY = 'csv_set_header_validity';
export const CLEAR_CSV_HANDLER = 'clear_csv_handler';

const startReadingCsv = () => ({
    type: CSV_READING_START
});

const endReadingCsv = (isUploadable) => ({
    type: CSV_READING_END,
    payload: isUploadable
});

const setTables = (errors, tables) => ({
    type: CSV_SET_TABLES,
    payload: { errors, tables }
});

const readBodyCsv = (matrix) => ({
    type: CSV_BODY_READ,
    payload: matrix
});

const readHeaderCsv = (header, numTopRowsCleaned) => ({
    type: CSV_HEADER_READ,
    payload: { header, numTopRowsCleaned }
});

const setHeaderValidity = (isValidHeader) => ({
    type: CSV_SET_HEADER_VALIDITY,
    payload: isValidHeader
});

const clearCsv = () => ({
    type: CSV_CLEAR
});

export const checkHeader = (header, datapointId) => {
    return dispatch => {
        let isValid = isValidHeader(header, datapointId);
        dispatch(setHeaderValidity(isValid));
    };
};

export const loadCsv = (matrix, datapointId) => {
    // console.log("MATRIX", matrix);
    return dispatch => {
        let isUploadable = false;
        dispatch(startReadingCsv());

        let [header, numTopRowsCleaned] = getCSVHeader(matrix);

        // console.log("HEADER", header);

        numTopRowsCleaned++; // For Excel index
        dispatch(readHeaderCsv(header, numTopRowsCleaned));
        let isValid = isValidHeader(header, datapointId);
        dispatch(setHeaderValidity(isValid));
        if (isValid) {
            dispatch(readBodyCsv(matrix));
            let [datapoint_errors, tables] = extractTables(header, matrix, datapointId, numTopRowsCleaned);
            dispatch(setTables(datapoint_errors, tables));
            if (datapoint_errors.length === 0) {
                isUploadable = true;
            }
        }
        dispatch(endReadingCsv(isUploadable));
    };
};

export const clearCsvFile = () => {
    return dispatch => {
        dispatch(clearCsv());
    };
};
