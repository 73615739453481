import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../UI/ButtonExtended/ButtonExtended';

const useStyles = makeStyles(theme => ({
    button: {
        padding: '4px 4px 4px 4px',
        fontSize: '10px'
    }
}));



export default function OperatingStatusButton(props) {
    const classes = useStyles();
    // const { onClick } = props;

    const getBackgroundColor = () => {
        switch (props.status) {
            case 'y':
                return 'primary';
            case 'n':
                return 'secondary';
            case 'c':
                return 'warning';
            default:
                return 'warning';
        }
    };

    const renderStatusLabel = () => {
        let labelStatus;
        switch (props.status) {
            case 'y':
                labelStatus = 'ACTIVE';
                break;
            case 'n':
                labelStatus = 'SHUT DOWN';
                break;
            case 'c':
                labelStatus = 'UNDER REVIEW';
                break;
            default:
                labelStatus = 'UNDER REVIEW';
                break;
        }
        return (
            labelStatus
        );
    };

    return (
        <div>
            <Button
                variant="contained"
                color={getBackgroundColor()}
                disableElevation={true}
                className={classes.button}
                size={'small'}
                // onClick={onClick ? onClick : null}
            >
                {renderStatusLabel()}
            </Button>
        </div>
    );
}
