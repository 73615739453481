import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { actionTypes, isFormDisabled } from '../../helpers/actionTypes';
import * as Yup from 'yup';
import { Formik } from 'formik';
import history from '../../config/history';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import SimpleSnackbar from '../../components/Feedback/SimpleSnackbar';
import Button from '@material-ui/core/Button';
import ProfileUser from './profileUser';
import { cleanUser, createUser, fetchUserData, fetchUserDataEmpty, updateUserData } from '../../actions/usersHandler';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Spacer from '../../components/Spacer/Spacer';

class User extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actionMode: actionTypes.SHOW,
            snackVisible: false,
            snackbarVariant: '',
            dialogOpen: false,
            titleDialog: '',
            contentDialog: ''
        };

        this.props.cleanUser();
    }


    componentDidMount() {
        const actionType = this.props.match.params.actionType;
        const user_id = this.props.match.params.user_id;

        this.setState({
            ...this.state,
            actionMode: actionType
        });

        switch (actionType) {
            case actionTypes.SHOW:
                this.props.fetchUserData(user_id, this.props.myUser);
                break;
            case actionTypes.ADD:
                this.props.fetchUserDataEmpty();
                break;
            case actionTypes.EDIT:
                this.props.fetchUserData(user_id, this.props.myUser);
                break;
            default:
                break;
        }
    }

    handleClickOkDialog() {
        history.push({
            pathname: '/manageusers'
        });
    }

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (variant) => {
        this.setState({
            ...this.state,
            snackbarVariant: variant,
            snackVisible: true
        });
    };

    openDialogCreation(success) {
        if (success) {
            this.setState({
                ...this.state,
                dialogOpen: true,
                titleDialog: 'Users created with success!'
            });
        }
    }

    submitForm = (values, actions) => {

        // alert(JSON.stringify(values, null, 2));

        switch (this.state.actionMode) {
            case actionTypes.ADD:
                this.props.createUser(this.props.userData, values, this.props.myUser);
                break;
            case actionTypes.EDIT:
                this.props.updateUserData(this.props.userData, values, this.props.userData.user.user_id, this.props.myUser);
                break;
            default:
                break;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userUpdating && !this.props.userUpdating) {
            let variant = 'info';

            switch (this.state.actionMode) {
                case actionTypes.ADD:
                    this.openDialogCreation(true);
                    break;
                case actionTypes.EDIT:
                    if (!this.props.userUpdateError) {
                        variant = 'success';
                    } else {
                        variant = 'error';
                    }
                    this.openSnack(variant);
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        let {
            userData,
            userUpdating
        } = this.props;

        return (
            <DashboardLayout title="User">
                {userData
                    ?
                    <div>
                        <AppBar position="static"
                                color="inherit"
                                style={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    borderBottom: 'solid 1px #ddd'
                                }}
                        >
                            <Toolbar>
                                <div style={{ flexGrow: 1 }}/>
                                {this.state.actionMode !== actionTypes.SHOW ?
                                    <Button
                                        variant="contained"
                                        disabled={false}
                                        form="general-user-form"
                                        type="submit"
                                        value="Submit"
                                        color="primary"
                                        style={{ backgroundColor: '#45B880' }}
                                    >
                                        {this.state.actionMode === actionTypes.ADD
                                            ? 'CREATE USER'
                                            : 'SAVE USER'
                                        }
                                    </Button> : null
                                }
                            </Toolbar>
                            {userUpdating ? <LinearProgress/> : null}
                        </AppBar>
                        <Spacer />
                        <Formik
                            enableReinitialize

                            initialValues={{
                                user: {
                                    first_name: this.props.userData.user.first_name,
                                    last_name: this.props.userData.user.last_name,
                                    email: this.props.userData.user.email,
                                    role: this.props.userData.user.role,
                                    status: this.props.userData.user.status,
                                    email_confirmed: this.props.userData.user.email_confirmed,
                                }
                            }}

                            onSubmit={this.submitForm}

                            validationSchema={Yup.object().shape({
                                user: Yup.object().shape({
                                    first_name: Yup.string().nullable(),
                                    last_name: Yup.string().nullable(),
                                    email: Yup.string()
                                        .email('Invalid email')
                                        .required('Required')
                                        .nullable()
                                })
                            })}
                        >
                            {formikProps => (
                                <form id="general-user-form"
                                      onSubmit={formikProps.handleSubmit}
                                >
                                    <ProfileUser
                                        formDisabled={isFormDisabled(this.state.actionMode)}
                                        formikProps={formikProps}
                                        userData={userData}
                                    />
                                </form>
                            )}
                        </Formik>
                    </div>
                    : <LinearProgress/>
                }
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
                <AlertDialog
                    openDialog={this.state.dialogOpen}
                    title={this.state.titleDialog}
                    content={this.state.contentDialog}
                    clicked={this.handleClickOkDialog.bind(this)}
                />
            </DashboardLayout>
        );
    }
}

const mapDispatchToProps = {
    fetchUserData,
    fetchUserDataEmpty,
    updateUserData,
    createUser,
    cleanUser
};

const mapStateToProps = (state) => {
    return {
        user_id: state.usersHandler.user_id,
        userData: state.usersHandler.userData,

        userUpdating: state.usersHandler.userUpdating,
        userUpdateError: state.usersHandler.userUpdateError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
