
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './config/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { StylesProvider } from '@material-ui/core/styles';

import * as serviceWorker from './common/serviceWorker';

import App from './App';

const WebFont = require('webfontloader');


WebFont.load({
    google: {
        families: ['Titillium Web:300,400,700', 'Roboto', 'Trade Winds']
    }
});


ReactDOM.render(
    <StylesProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </StylesProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
