import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import { actionTypes } from '../../../helpers/actionTypes';
import Checkbox from '@material-ui/core/Checkbox';
import ActionsBox from '../../ActionsBox';
import { can, DELETE_ROW, EDIT_ROW } from '../../../helpers/Roles/roleActions';

class PersonaRow extends Component {

    onClickNamePersona = () => {
        history.push({
            pathname: '/persona/' + actionTypes.EDIT + '/' + this.props.row.persona_id
        });
    };

    render() {

        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            myUser,
            activeSelection,
            onClickDelete
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={event => onClickCheckbox(event, row.idrow)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    onClick={this.onClickNamePersona}
                    style={{ cursor: 'pointer' }}
                >
                    {row.name}
                </TableCell>
                <TableCell>{row.lastname}</TableCell>
                <TableCell>{row.company_name}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell align={'right'}>
                    <ActionsBox
                        disabled={activeSelection}
                        onEdit={can(myUser, EDIT_ROW) ? this.onClickNamePersona : null}
                        onDelete={can(myUser, DELETE_ROW) ? () => onClickDelete(row) : null}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(PersonaRow);
