export const investmentStageList = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 108,
        name: 'Incubator',
        value: 'Incubator'
    },
    {
        id: 109,
        name: 'Accelerator',
        value: 'Accelerator'
    },
    {
        id: 10,
        name: 'Angel Round',
        value: 'Angel Round'
    },
    {
        id: 106,
        name: 'Acquired',
        value: 'Acquired'
    },
    {
        id: 3,
        name: 'Pre-Seed',
        value: 'Pre-Seed'
    },
    {
        id: 4,
        name: 'Seed',
        value: 'Seed'
    },
    {
        id: 100,
        name: 'Pre-Series A',
        value: 'Pre-Series A'
    },
    {
        id: 5,
        name: 'Series A',
        value: 'Series A'
    },
    {
        id: 101,
        name: 'Pre-Series B',
        value: 'Pre-Series B'
    },
    {
        id: 6,
        name: 'Series B',
        value: 'Series B'
    },
    {
        id: 102,
        name: 'Pre-Series C',
        value: 'Pre-Series C'
    },
    {
        id: 7,
        name: 'Series C',
        value: 'Series C'
    },
    {
        id: 8,
        name: 'Series D',
        value: 'Series D'
    },
    {
        id: 9,
        name: 'Series E',
        value: 'Series E'
    },
    {
        id: 103,
        name: 'Series F',
        value: 'Series F'
    },
    {
        id: 2,
        name: 'IPO',
        value: 'IPO'
    },
    {
        id: 104,
        name: 'ICO',
        value: 'ICO'
    },
    {
        id: 105,
        name: 'Unspecified',
        value: 'Unspecified'
    }
];

export const investmentStageListFilter = [
    {
        id: 108,
        name: 'Incubator',
        value: 'Incubator'
    },
    {
        id: 109,
        name: 'Accelerator',
        value: 'Accelerator'
    },
    {
        id: 10,
        name: 'Angel Round',
        value: 'Angel Round'
    },
    {
        id: 106,
        name: 'Acquired',
        value: 'Acquired'
    },
    {
        id: 3,
        name: 'Pre-Seed',
        value: 'Pre-Seed'
    },
    {
        id: 4,
        name: 'Seed',
        value: 'Seed'
    },
    {
        id: 100,
        name: 'Pre-Series A',
        value: 'Pre-Series A'
    },
    {
        id: 5,
        name: 'Series A',
        value: 'Series A'
    },
    {
        id: 101,
        name: 'Pre-Series B',
        value: 'Pre-Series B'
    },
    {
        id: 6,
        name: 'Series B',
        value: 'Series B'
    },
    {
        id: 102,
        name: 'Pre-Series C',
        value: 'Pre-Series C'
    },
    {
        id: 7,
        name: 'Series C',
        value: 'Series C'
    },
    {
        id: 8,
        name: 'Series D',
        value: 'Series D'
    },
    {
        id: 9,
        name: 'Series E',
        value: 'Series E'
    },
    {
        id: 103,
        name: 'Series F',
        value: 'Series F'
    },
    {
        id: 2,
        name: 'IPO',
        value: 'IPO'
    },
    {
        id: 104,
        name: 'ICO',
        value: 'ICO'
    }];
