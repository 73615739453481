import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Dashboard as DashboardLayout } from '../../../layouts';
import { Grid } from '@material-ui/core';
import AlertDialog from '../../../components/UI/AlertDialog/AlertDialog';
import List from '@material-ui/core/List';
import CollapseElement from '../../../components/UI/CollapseElement/CollapseElement';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SnackbarContentWrapper from '../../../components/UI/SnackbarContentWrapper/SnackbarContentWrapper';
import Box from '@material-ui/core/Box';

import datapoints from '../../../data/datapoints';
import { CSVPreview, DatapointForm } from '../../../components';
import { checkHeader, clearCsvFile, loadCsv } from '../../../actions/csvHandler';
import { pushTables, clearDbHandler } from '../../../actions/dbDatapointHandler';


class DataInsertion extends Component {

    state = {
        datapoint_index: 0,
        datapoint_id: datapoints[0].datapoint_id,

        iconPushedDB: 'error',

        openDialogFinishedImport: false,

        showImportResult: false

    };

    handleClickDialog = () => {
        const toggle = { ...this.state.openDialogFinishedImport };
        this.setState({ openDialogFinishedImport: !toggle });
    };

    handle_change_datapoint = (id, index) => {
        this.setState({
            datapoint_index: index,
            datapoint_id: id
        }, () => {
            if (this.props.csv_loaded) {
                this.checkCSVHeader();
            }
        });
    };

    checkCSVHeader = () => {
        this.props.checkHeader(this.props.header, this.state.datapoint_id);
    };

    pushTables = () => {
        this.props.pushTables(
            this.props.tables,
            this.state.datapoint_id,
            this.props.numTopRowsCleaned
        );

        this.handle_delete_file();
    };

    handle_delete_file = () => {
        this.props.clearCsvFile();
    };

    componentDidUpdate(prevProps) {
        if (this.props.showImportResult !== prevProps.showImportResult && this.props.showImportResult) {
            this.setState({
                openDialogFinishedImport: true
            });
        }
    }

    componentDidMount() {
        this.props.clearDbHandler();
    }

    showImportResult = () => {
        let jsx_output = '';
        let jsx_output_2 = '';

        if (this.props.importResult.numRowsSuccess > 0) {
            jsx_output = <SnackbarContentWrapper
                variant="info"
                style={{ marginBottom: '10px' }}
                message={this.props.importResult.numRowsSuccess + ' rows inserted in the database.'}
            />;
        }
        if (this.props.importResult && this.props.importResult.numRowsError !== 0) {

            const rowsResult = this.props.importResult['rowsResult'];
            const errorsList = Object.keys(rowsResult).map(function(key) {
                return (
                    rowsResult[key]['errors'].length > 0 ?
                        <ListItem key={key} button divider>
                            <ListItemText
                                primary={'Row n' + rowsResult[key].indexRowCsv + ' - ' + rowsResult[key].errors[0][0].detail}/>
                        </ListItem>
                        : null
                );
            });
            jsx_output_2 =
                <CollapseElement variant={'error'} textColor={'textWhite'}
                                 message={this.props.importResult['numRowsError'] + ' rows failed'}>
                    <List component="nav"
                          style={{ paddingTop: '0', backgroundColor: '#fff', paddingBottom: '0' }}>
                        {errorsList}
                    </List>
                </CollapseElement>;
        }
        return (<Box>{jsx_output}{jsx_output_2}</Box>);
    };

    handleCsvLoaded = (matrix) => {
        this.props.loadCsv(matrix, this.state.datapoint_id);
    };

    render() {
        return (
            <DashboardLayout title="Dashboard">

                <Grid
                    container
                    spacing={4}
                >
                    <Grid
                        item
                        lg={6}
                        md={8}
                        xl={6}
                        xs={12}
                    >
                        <DatapointForm
                            uploadable={this.props.uploadable}
                            handle_change_datapoint={this.handle_change_datapoint}
                            datapoints={datapoints}
                            onLoadCsv={this.handleCsvLoaded}
                            csv_loaded={this.props.csv_loaded}
                            handleOnDeleteClick={this.handle_delete_file}
                            uniqueIdCsv={this.props.uniqueIdCsv}
                            handle_upload_clicked={this.pushTables}
                            writingDB={this.props.writingDB}
                            numRows={this.props.numRows}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={4}
                >
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xl={8}
                        xs={12}
                    >
                        <CSVPreview
                            numColumns={this.props.numColumns}
                            numRows={this.props.numRows}
                            csv_loaded={this.props.csv_loaded}
                            datapoint_errors={this.props.datapoint_errors}
                            readingCsv={this.props.readingCsv}
                            validHeader={this.props.validHeader}
                        />
                    </Grid>
                </Grid>

                <AlertDialog
                    openDialog={this.state.openDialogFinishedImport}
                    title={'Import Result'}
                    content={this.props.messagePushedDB}
                    clicked={this.handleClickDialog}
                />

                {this.props.showImportResult ?
                    this.showImportResult() :
                    null
                }

            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadCsv,
        clearCsvFile,
        checkHeader,
        pushTables,
        clearDbHandler
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        csvMatrix: state.csvHandler.csvMatrix,
        numRows: state.csvHandler.numRows,
        numColumns: state.csvHandler.numColumns,
        uniqueIdCsv: state.csvHandler.uniqueIdCsv,

        header: state.csvHandler.header,
        validHeader: state.csvHandler.validHeader,
        uploadable: state.csvHandler.uploadable,

        csv_loaded: state.csvHandler.csv_loaded,
        csv_checked: state.csvHandler.csv_checked,

        readingCsv: state.csvHandler.readingCsv,
        errorCsv: state.csvHandler.errorCsv,

        datapoint_errors: state.csvHandler.datapoint_errors,
        tables: state.csvHandler.tables,


        resultPushDB: state.dbDatapointHandler.resultPushDB,
        resultPushDBRowsSubmitted: state.dbDatapointHandler.resultPushDBRowsSubmitted,
        importResult: state.dbDatapointHandler.importResult,
        databaseErrorMessage: state.dbDatapointHandler.databaseErrorMessage,
        writingDB: state.dbDatapointHandler.writingDB,
        messagePushedDB: state.dbDatapointHandler.messagePushedDB,

        showImportResult: state.dbDatapointHandler.showImportResult
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataInsertion);
