const getCompanyPercentage = (companyValues) => {
    let companyPercentage = 0;
    let unit = 3;
    if (companyValues.company.name && companyValues.company.name !== '') companyPercentage += unit;
    if (companyValues.company.email && companyValues.company.email !== '') companyPercentage += unit;

    if (companyValues.company.founding_date && companyValues.company.founding_date !== '') companyPercentage += unit;
    if (companyValues.company.number_employees && companyValues.company.number_employees !== '') companyPercentage += unit;
    if (companyValues.company.contact_number && companyValues.company.contact_number !== '') companyPercentage += unit;
    if (companyValues.company.ownership_structure && companyValues.company.ownership_structure !== '') companyPercentage += unit;
    if (companyValues.company.description && companyValues.company.description !== '') companyPercentage += unit;
    if (companyValues.company.stage && companyValues.company.stage !== '') companyPercentage += unit;
    // if (companyValues.company.product_development && companyValues.company.product_development !== '') companyPercentage += unit;

    return companyPercentage;
};

const getSocialPercentage = (companyValues) => {
    let socialPercentage = 0;
    let max = 10;
    if (companyValues.company && companyValues.company.website) socialPercentage += 5;
    if (companyValues.social && companyValues.social.facebook) socialPercentage += 5;
    if (companyValues.social && companyValues.social.linkedin) socialPercentage += 5;
    if (companyValues.social && companyValues.social.twitter) socialPercentage += 5;
    if (companyValues.social && companyValues.social.instagram) socialPercentage += 5;

    if (socialPercentage >= max) {
        socialPercentage = max;
    }
    return socialPercentage;
};

const getGeographyPercentage = (companyValues) => {
    let geographyPercentage = 0;
    let unit = 3;
    if (companyValues.geography && companyValues.geography.hq_city) geographyPercentage += unit;
    if (companyValues.geography && companyValues.geography.hq_country) geographyPercentage += unit;
    if (companyValues.geography && companyValues.geography.hq_address) geographyPercentage += unit;
    if (companyValues.countries && companyValues.countries.length > 0) geographyPercentage += unit;
    return geographyPercentage;
};

const getSectorsPercentage = (companyValues) => {
    let geographyPercentage = 0;
    let unit = 3;
    if (companyValues.sectors && companyValues.sectors.length > 0) geographyPercentage += unit;
    if (companyValues.sub_sectors && companyValues.sub_sectors.length > 0) geographyPercentage += unit;
    return geographyPercentage;
};

const getTeamPercentage = (teamLength) => {
    let teamPercentage = 0;
    if (teamLength > 0) teamPercentage += 5;
    return teamPercentage;
};

const getAssessmentPercentage = (companyValues) => {
    let assessmentPercentage = 0;

    return assessmentPercentage;
};

const getDocsPercentage = (docsLength) => {
    let docPercentage = 0;
    if (docsLength > 0) docPercentage += 10;
    return docPercentage;
};


const getMentionsPercentage = (mentionsLength) => {
    let mentionsPercentage = 0;
    if (mentionsLength > 0) mentionsPercentage += 10;
    return mentionsPercentage;
};

const getFundingPercentage = (companyValues, dealsLenght) => {
    let dealsPercentage = 0;

    if (companyValues.company.raised_capital === null || companyValues.company.raised_capital === false) {
        dealsPercentage += 10;
    } else {
        if (dealsLenght > 0) dealsPercentage += 10;
    }


    return dealsPercentage;
};

const getImpactPercentage = (companyValues) => {
    let impactPercentage = 0;
    let unit = 5;
    if (companyValues.impact) {
        let max = 5;

        if (companyValues.impact && companyValues.impact.female_cofounder) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.assistive_tech) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.rural_focused) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.female_focused) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.youth_focused) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.fifty_local) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.elderly_people) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.zero_emissions) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.informal_sector) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.bop) impactPercentage += unit;
        if (companyValues.impact && companyValues.impact.employment_creation) impactPercentage += unit;

        if (impactPercentage >= max) {
            impactPercentage = 5;
        }
    }
    if (companyValues.SDGs) {
        if (companyValues.SDGs.length > 0) {
            impactPercentage += unit;
        }
    }
    return impactPercentage;
};

export const getPercentageCompletitionCompany = (companyValues,
                                                 teamLength,
                                                 docsLength,
                                                 mentionsLength,
                                                 dealsLenght) => {
    // console.log(companyValues);
    let percentage = 0;

    percentage += getCompanyPercentage(companyValues);
    percentage += getSocialPercentage(companyValues);
    percentage += getGeographyPercentage(companyValues);
    percentage += getSectorsPercentage(companyValues);
    percentage += getTeamPercentage(teamLength);

    percentage += getDocsPercentage(docsLength);
    percentage += getMentionsPercentage(mentionsLength);
    percentage += getImpactPercentage(companyValues);
    percentage += getFundingPercentage(companyValues, dealsLenght);

    if (percentage > 100) {
        percentage = 100;
    }

    // percentage += getAssessmentPercentage(companyValues);

    return percentage;
};
