import { combineReducers } from 'redux';
import csvHandler from './csvHandler';
import dbDatapointHandler from './dbDatapointHandler';
import companyHandler from './companyHandler';
import mainHandler from './mainHandler';
import peopleHandler from './peopleHandler';
import authHandler from './authHandler';
import usersHandler from './usersHandler';
import tableHandler from './tableHandler';
import chartsHandler from './chartsHandler';
import compareHandler from './compareHandler';
import contactUsHandler from './contactUsHandler';
import claimHandler from './claimHandler';
import dashboardHandler from './dashboardHandler';
import followHandler from './followHandler';
import emailHandler from './emailHandler';
import downloadCsvHandler from './downloadCsvHandler';

const rootReducer = combineReducers({
    csvHandler,
    dbDatapointHandler,
    companyHandler,
    mainHandler,
    peopleHandler,
    authHandler,
    contactUsHandler,
    usersHandler,
    tableHandler,
    chartsHandler,
    compareHandler,
    claimHandler,
    dashboardHandler,
    followHandler,
    emailHandler,
    downloadCsvHandler
});

export default rootReducer;
