import { getRandomId, getIndexColumn } from '../DBhelper';
import { parseNameLastname } from '../Parser';
import { CATEGORIES } from '../../models/company/company';
import { cloneDeep } from 'lodash';
import { impactObj } from '../../data/tableObjects';

export default () => {

    // --- Columns ----------------------------

    const personaFields = {
        name: ['name'],
        role: ['role'],
        ethnicity: ['ethnicity'],
        gender: ['gender'],
        education: ['education'],
        linkedin: ['exec linkedin'],
        contact: ['contact'],
        email: ['email']
    };

    const companyFields = {
        active: ['active'],
        name: ['company name'],
        founding_date: ['founding date'],
        description: ['description'],
        new_description: ['new description'],
        website: ['website'],
        email: ['general email'],
        contact_number: ['phone numbers'],
        operating_status: ['operating status']
    };

    const geographyFileds = {
        regions: ['regions(s)'],
        countries: ['country/ies'],
        hq: ['hq city'],
        city_id_code: ['id city'],
        address: ['address'],
        other_cities: ['other cities']
        // TODO double ?
    };

    const socialFields = {
        twitter: ['twitter'],
        instagram: ['instagram'],
        facebook: ['facebook'],
        youtube: ['youtube'],
        linkedin: ['LinkedIn']
    };

    /*    const sectorFields = {
            category: ['category'],
            first_sector: ['sector criteria']
        };*/

    const hubFields = {
        fund_provided: ['funding provided (y/n)'],
        type: ['type']
    };

    // --- Functions ----------------------------

    // ------------------------ People ----------------------------

    const getFirstNamePersonaIndex = (header) => {
        let index = getIndexColumn(personaFields.name[0], header);
        return index;
    };

    const extractPersona = (row, role_index, header) => {
        const persona = { ...require('../../data/tableObjects').personaObj };
        const social = { ...require('../../data/tableObjects').socialObj };

        persona.persona_id = getRandomId();
        social.social_id = getRandomId();

        let namePersona = '';
        let lastnamePersona = '';

        let isEmptyPersona = true;

        for (var i = role_index; i < (role_index + 8); i++) {
            let tempValue = null;
            tempValue = row[i];

            if (tempValue !== '' && tempValue !== null) {
                isEmptyPersona = false;

                switch (header[i]) {
                    case personaFields.role[0]:
                    case personaFields.role[1]:
                    case personaFields.role[2]:
                    case personaFields.role[3]:
                        persona.role = tempValue;
                        break;
                    case personaFields.name[0]:
                    case personaFields.name[1]:
                    case personaFields.name[2]:
                    case personaFields.name[3]:
                        let nameLastname = row[getIndexColumn(personaFields.name[0], header)];
                        [namePersona, lastnamePersona] = parseNameLastname(nameLastname);
                        persona.name = namePersona;
                        persona.lastname = lastnamePersona;
                        break;
                    case personaFields.ethnicity[0]:
                        persona.ethnicity = tempValue;
                        break;
                    case personaFields.gender[0]:
                        persona.gender = tempValue;
                        break;
                    case personaFields.education[0]:
                        if (tempValue) {
                            let educationFields = tempValue.split(';');
                            persona.level_education = educationFields[0].trim();
                            persona.degree = educationFields[1].trim();
                            persona.university = educationFields[2].trim();
                        }
                        break;
                    case personaFields.email[0]:
                        persona.email = tempValue;
                        break;
                    case personaFields.linkedin[0]:
                        social.linkedin = tempValue;
                        break;
                    default:
                        break;
                }
            }
        }

        if (isEmptyPersona) {
            // console.log('Empty persona');
            return [null, null];
        } else {
            return [persona, social];
        }
    };

    const extractPersonas_Socials = (row, header) => {
        const numPersonasToExtract = 2;
        const indexIncrementPersona = 8;

        let personas = [];
        let socials = [];

        let firstIndexPersonaName = getFirstNamePersonaIndex(header);
        if (firstIndexPersonaName === -1) {
            // console.log('No personas found'); // TODO
            return null;
        }

        let indexToStart = firstIndexPersonaName;

        for (var i = 0; i < numPersonasToExtract; i++) {
            let tempPersona = null;
            let tempSocial = null;

            [tempPersona, tempSocial] = extractPersona(row, indexToStart, header);

            if (tempPersona !== null) {
                if (tempSocial != null) {
                    tempPersona.social_id = tempSocial.social_id;
                    socials.push(tempSocial);
                }
                personas.push(tempPersona);
            }

            indexToStart = indexToStart + indexIncrementPersona;
        }

        return [personas, socials];
    };

    // ------------------------ Sector ----------------------------

    /*    const extractSectorName = (row, header) => {
            let sector_name = row[getIndexColumn(sectorFields.first_sector[0], header)];

            return sector_name;
        };*/

    // ------------------------ Social ----------------------------

    const extractSocial = (row, header) => {
        let social = { ...require('../../data/tableObjects').socialObj };
        social.social_id = getRandomId();
        social.twitter = row[getIndexColumn(socialFields.twitter[0], header)];
        social.facebook = row[getIndexColumn(socialFields.facebook[0], header)];
        social.linkedin = row[getIndexColumn(socialFields.linkedin[0], header)];
        return social;
    };

    // ------------------------ Hub ----------------------------

    const extractHub = (row, header) => {
        let hub = { ...require('../../data/tableObjects').hubObj };
        let stringFundProvided = row[getIndexColumn(hubFields.fund_provided[0], header)];
        let hubType = row[getIndexColumn(hubFields.type[0], header)];
        if (stringFundProvided !== '') {
            hub.fund_provided = stringFundProvided === 'Y';
        }
        hub.type = hubType;
        return hub;
    };

    // ------------------------ Geography ----------------------------

    const extractGeography = (row, header) => {
        let geography = { ...require('../../data/tableObjects').geographyObj };
        const separator = ',';

        geography.geography_id = getRandomId();

        let geography_regions = [];

        const regionsString = row[getIndexColumn(geographyFileds.regions[0], header)];
        if (regionsString && regionsString !== '') {
            const regionsSplitted = regionsString.split(separator);
            for (let i = 0; i < regionsSplitted.length; i++) {
                let geography_region = { ...require('../../data/tableObjects').geography_regionObj };
                // geography_region.geography_id = // TODO
                // geography_region.region_id = // TODO
                geography_region.name = regionsSplitted[i];
                geography_regions.push(geography_region);
            }
        }

        geography.hq_country = row[getIndexColumn(geographyFileds.countries[0], header)];
        geography.hq_city = row[getIndexColumn(geographyFileds.hq[0], header)];
        // geography.city_id_code = row[getIndexColumn(geographyFileds.city_id_code[0], header)];

        const citiesString = row[getIndexColumn(geographyFileds.other_cities[0], header)];
        if (citiesString && regionsString !== '') {
            let citiesArray = [];
            const citiesSplitted = citiesString.split(separator);
            for (let i = 0; i < citiesSplitted.length; i++) {
                citiesArray.push(citiesSplitted[i]);
            }
            // geography.other_cities = citiesArray; // TODO
        }

        geography.hq_address = row[getIndexColumn(geographyFileds.address[0], header)];

        return geography;
    };

    // ------------------------ Overview ----------------------------

    const extractCompany = (index_row, indexRowCsv, row, header, errors) => {
        const ACTIVE_TRUE = 'y';
        // const ACTIVE_FALSE = 'n';

        let company = { ...require('../../data/tableObjects').companyObj };

        company.company_id = getRandomId();
        company.impact_id = getRandomId();

        company.name = row[getIndexColumn(companyFields.name[0], header)];
        if (company.name === '') {
            errors.push('Row (' + indexRowCsv + ') - empty Overview name');
        }

        // let isActive = row[getIndexColumn(companyFields.active[0], header)];
        // let isActiveBoolean = (isActive === ACTIVE_TRUE) ? true : false; // TODO check false or null
        let isActiveBoolean = true; // TODO check false or null
        company.active = isActiveBoolean;

        company.description = row[getIndexColumn(companyFields.description[0], header)];  // TODO new description ??

        const founding_date = row[getIndexColumn(companyFields.founding_date[0], header)];
        if (founding_date && founding_date !== '') {
            const founding_date_int = parseInt(founding_date);
            // if year
            if (founding_date.length === 4 && 1900 < founding_date_int < 2100) {
                company.founding_date = founding_date + "-01-01 00:00:00"
            } else {
                errors.push('Row (' + index_row + ') - invalid Founding Date');
            }
        }

        company.website = row[getIndexColumn(companyFields.website[0], header)];
        company.contact_number = row[getIndexColumn(companyFields.contact_number[0], header)];
        company.email = row[getIndexColumn(companyFields.email[0], header)];

        company.operating_status = row[getIndexColumn(companyFields.operating_status[0], header)];

        company.category = CATEGORIES.HUB.category_name;

        return company;
    };

    // --- Main ----------------------------

    const exctractTableHub = (indexRow, indexRowCsv, row, header) => {
        let errors = [];

        let [personas_array, socials_array] = extractPersonas_Socials(row, header); // socials personas
        //console.log(JSON.stringify(personas_array, null, 2))
        //console.log(JSON.stringify(socials_array, null, 2))
        let company = extractCompany(indexRow, indexRowCsv, row, header, errors);
        //console.log(JSON.stringify(company, null, 2))
        let social = extractSocial(row, header); // social company
        socials_array.push(social);
        company.social_id = social.social_id;
        //console.log(JSON.stringify(social, null, 2))
        let geography = extractGeography(row, header);
        //console.log(JSON.stringify(geography, null, 2))
        // let sector = extractSector(row, header);
        //console.log(JSON.stringify(sector, null, 2))
        let impact = cloneDeep(impactObj);
        impact.impact_id = company.impact_id;
        let hub = extractHub(row, header);

        hub.company_id = company.company_id;

        company.geography_id = geography.geography_id;

        // -------- FOREIGN KEYS N-N ----------------------------

        let company_persona_array = [];
        for (var i = 0; i < personas_array.length; i++) {
            let company_persona = null;
            company_persona = { ...require('../../data/tableObjects').company_personaObj };
            company_persona.company_id = company.company_id;
            company_persona.persona_id = personas_array[i].persona_id;
            company_persona_array.push(company_persona);
        }

        //--------------------------------
        // aggregate tables
        let hubs_tables = { ...require('../../data/tableObjects').hubTablesObj };
        hubs_tables.indexRow = indexRow;
        hubs_tables.indexRowCsv = indexRowCsv;

        hubs_tables.company = company;
        hubs_tables.personas = personas_array;
        hubs_tables.socials = socials_array;
        hubs_tables.geography = geography;
        hubs_tables.hub = hub;
        hubs_tables.impact = impact;

        hubs_tables.company_persona = company_persona_array;

        // console.log(JSON.stringify(hubs_tables, null, 2));

        // TODO

        return [errors, hubs_tables];
    };

    return [exctractTableHub];

}
