import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import history from '../../config/history';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { disableCompanyOfActualPartnership } from '../../helpers/Validation/fieldForm';
import { BASE_URL } from '../../helpers/axiosConfig';
import { authHeader } from '../../auth/authUtils';
import PartnershipForm from '../../components/Company/PartnershipForm';
import { addCompanyToPartnerships, removeCompanyToPartnerships } from '../../actions/companyHandler';
import SearchCompanyDialog from '../../components/SearchCompanyDialog';

class PartnershipTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogAddCompanyVisible: false,
            companyQuery: '',
            companiesResult: [],
            searchingCompanies: false,
            companySelected: null
        };
    }

    getCompanies = () => {
        axios.get(BASE_URL + '/company/search', {
            params: {
                pattern: this.state.companyQuery,
                justActives: true
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateCompaniesResult(data.companies);
            });
    };

    updateCompaniesResult = (companies) => {
        let companiesResult = cloneDeep(companies);
        disableCompanyOfActualPartnership(companiesResult, this.props.partnerships);

        this.setState({
            companiesResult: companiesResult,
            searchingCompanies: false
        });
    };

    handleInputCompanyChange = (e) => {
        this.setState({
            companyQuery: e.target.value
        }, () => {
            if (this.state.companyQuery && this.state.companyQuery.length > 0) {
                this.setState({
                        searchingCompanies: true
                    }, () => this.getCompanies()
                );
                //}
            }
        });
    };

    handleClickCompany(company) {
        history.push({
            pathname: '/redirect/show/' + company.company_id
        });
    };

    handleClickRemovePartnership(company) {
        this.props.removeCompanyToPartnerships(company);
    };

    openDialogSearchCompany = () => {
        this.setState({
            dialogAddCompanyVisible: true
        });
    };

    closeDialogSearch = () => {
        this.setState({
            dialogAddCompanyVisible: false,
            companySelected: null
        });
    };

    selectCompany = (company) => {
        this.setState({
            companySelected: company
        });
    };

    addPartnership = () => {
        if (this.state.companySelected) {
            this.props.addCompanyToPartnerships(this.state.companySelected);
            this.setState({
                dialogAddCompanyVisible: false,
                companySelected: null,
                companyQuery: '',
                companiesResult: []
            });
        }
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        <PartnershipForm
                            formDisabled={this.props.formDisabled}
                            openDialogSearchCompany={this.openDialogSearchCompany.bind(this)}
                            partnerships={this.props.partnerships}
                            handleClickCompany={this.handleClickCompany.bind(this)}
                            handleClickRemovePartnership={this.handleClickRemovePartnership.bind(this)}
                            myUser={this.props.myUser}
                        />
                    </Grid>
                </Grid>
                <SearchCompanyDialog
                    open={this.state.dialogAddCompanyVisible}
                    selectCompany={this.selectCompany}
                    closeDialogSearch={this.closeDialogSearch.bind(this)}
                    companiesResult={this.state.companiesResult}
                    searchingCompanies={this.state.searchingCompanies}
                    companySelected={this.state.companySelected}
                    handleInputCompanyChange={this.handleInputCompanyChange.bind(this)}
                    addCompany={this.addPartnership.bind(this)}
                    categorySearching={'company'}
                />
            </div>
        );
    }
}

const mapDispatchToProps = {
    addCompanyToPartnerships,
    removeCompanyToPartnerships
};

const mapStateToProps = (state) => {
    return {
        partnerships: state.companyHandler.partnerships,
        fetchingPartnerships: state.companyHandler.fetchingPartnerships,
        partnershipsError: state.companyHandler.partnershipsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipTab);
