import React from 'react';
import { Grid } from '@material-ui/core';
import CompanySocialForm from '../../components/Company/CompanySocialForm';

function SocialCompanyTab(props) {

    const {
        formDisabled,
        formikProps
    } = props;

    return (
        <div>
            <fieldset disabled={formDisabled} style={{ border: 'none' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        <CompanySocialForm
                            formik={formikProps}
                        />
                    </Grid>
                </Grid>
            </fieldset>
        </div>
    );
}

export default SocialCompanyTab;
