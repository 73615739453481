export const SORTING_DEFAULT = {
    sector: 'name',
    sub_sector: 'name',
    sdg: 'name',
    deal: 'date'
};

export const findIndexCompany = (companiesPreview, company_id) => {
    let index = -1;
    let i = 0;
    for (let company of companiesPreview) {
        if (company.company_id === company_id) {
            index = i;
            break;
        }
        i++;
    }
    return index;
};

export const findIndexUser = (usersPreview, user_id) => {
    let index = -1;
    let i = 0;
    for (let user of usersPreview) {
        if (user.user_id === user_id) {
            index = i;
            break;
        }
        i++;
    }
    return index;
};

export const findIndexClaim = (claimsPreview, claim_id) => {
    let index = -1;
    let i = 0;
    for (let claim of claimsPreview) {
        if (claim.claim_id === claim_id) {
            index = i;
            break;
        }
        i++;
    }
    return index;
};


const ONE_DAY = 24 * 60 * 60 * 1000; /* ms */
const ONE_MONTH = ONE_DAY * 30;

export const isOld = (myDate, unitTime) => {
    const isOld = ((new Date) - new Date(myDate)) > unitTime;
    return isOld;
};

export const getBadgeCompanyVisibility = (lastUpdate) => {
    if (lastUpdate) {
        return !isOld(lastUpdate, ONE_MONTH * 6);
    } else {
        return true;
    }
};

export const getBadgeColor = (lastUpdate) => {
    if (lastUpdate) {
        if (isOld(lastUpdate, ONE_DAY * 25)) {
            return 'error';
        } else if (isOld(lastUpdate, ONE_DAY * 5)) {
            return 'secondary';
        } else {
            return 'primary';
        }
    } else {
        return 'primary';
    }
};
