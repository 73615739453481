export const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const getAnnualInvestment = (investments) => {
    let annualInvestment = 0;

    if(investments && investments.length > 0) {
        for(let investment of investments) {
            annualInvestment = annualInvestment + investment["amount"];
        }
    }

    annualInvestment = formatCash(annualInvestment);

    return annualInvestment;
};


export const getTotalInvestments = (investments) => {
    let annualInvestment = 0;

    if(investments && investments.length > 0) {

        if (investments && investments.length > 0) {

            for (let investment of investments) {
                // console.log("*** II", investment)
                if(investment["amount"]) {
                    annualInvestment = annualInvestment + investment["amount"];
                }
            }
        }
    }

    return annualInvestment;
};
