import { CONFIRM_EMAIL, CONFIRM_EMAIL_ERROR, CONFIRM_EMAIL_SUCCESS } from '../actions/emailHandler';

const initialState = {
    confirmingEmail: null,
    confirmEmailError: null,
    emailConfirmed: null
};


export default function followHandler(state = initialState, action) {
    switch (action.type) {

        case CONFIRM_EMAIL:
            return {
                ...state,
                confirmingEmail: true,
                confirmEmailError: null,
                emailConfirmed: null
            };

        case CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                confirmingEmail: false,
                confirmEmailError: null,
                emailConfirmed: true
            };

        case CONFIRM_EMAIL_ERROR:
            return {
                ...state,
                confirmingEmail: false,
                confirmEmailError: action.payload,
                emailConfirmed: null
            };

        default:
            return state;
    }
}
