import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import DataInsertion from './containers/Admin/DataInsertion';
import ManageCompanies from './containers/Admin/ManageCompanies/manageCompanies';
import Persona from './containers/Persona/persona';
import ManagePeople from './containers/Admin/ManagePeople/managePeople';
import ManageUsers from './containers/Admin/ManageUsers/manageUsers';
import User from './containers/User/user';
import GenericManageTable from './containers/Admin/GenericManageTable/genericManageTable';
import ConfirmEmailPage from './containers/Auth/confirmEmailPage';

import Company from './containers/Company/company';
import HomePageNew from './containers/Home/homeNew';
import { bindActionCreators } from 'redux';
import { authMySelf, signOutMySelf } from './actions/authHandler';
import { connect } from 'react-redux';
import PrivateRoute from './components/PrivateRoute';
import GenericTable from './containers/GenericTable/genericTable';
import linkedinAuthPage from './containers/Auth/linkedin';
import Overview from './containers/Overview/Overview';
import Events from './containers/Events/events';
import TermsCondition from './containers/TermsCondition/index';
import Initiatives from './containers/Initiatives/Initiatives';
import { ALL, INNER_TEAM, USERS } from './helpers/Roles/roleActions';
import Compare from './containers/Compare/compare';
import RedirectPage from './containers/Redirect/RedirectPage';
import ManageClaims from './containers/Admin/ManageClaims/manageClaims';

class Routes extends Component {

    render() {
        const {
            iAmApproved,
            myUser
        } = this.props;

        return (
            <Switch>

                <Route component={HomePageNew} exact path="/"/>

                <Route component={Overview} path="/overview"
                />

                <Route component={ConfirmEmailPage} path="/confirm-email/:email/:confirmationId"
                />

                <Route component={RedirectPage}
                       path="/redirect/:actionType/:company_id"
                />

                <Route exact path="auth/linkedin/" component={linkedinAuthPage}/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={DataInsertion} path="/datamanager"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={INNER_TEAM.concat(USERS)}
                              component={ManageCompanies} path="/managecompanies"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={ALL}
                              component={Company}
                              path="/company/:actionType/:company_id"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={ALL}
                              component={Company}
                              path="/company/:actionType"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={ManagePeople}
                              path="/managepeople"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={Persona}
                              path="/persona/:actionType/:persona_id"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={Persona}
                              path="/persona/:actionType"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin']} component={ManageUsers}
                              path="/manageusers"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin']} component={User}
                              path="/user/:actionType/:user_id"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin']} component={User}
                              path="/user/:actionType"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericManageTable}
                              tableName={'sector'}
                              path="/managesector"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericTable}
                              tableName={'sector'}
                              path="/sector/:actionType/:rowid"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericTable}
                              tableName={'sector'}
                              path="/sector/:actionType"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericManageTable}
                              tableName={'sub_sector'}
                              path="/managesub_sector"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericTable}
                              tableName={'sub_sector'}
                              path="/sub_sector/:actionType/:rowid"/>
                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}
                              component={GenericTable}
                              tableName={'sub_sector'}
                              path="/sub_sector/:actionType"/>

                {/*<PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}*/}
                {/*              component={GenericManageTable}*/}
                {/*              tableName={'deal'}*/}
                {/*              path="/manage_deals"/>*/}

                <PrivateRoute
                    component={GenericManageTable}
                    tableName={'deal'}
                    path="/deals"
                    ovverrideAuth={true}
                />


                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin']}
                              component={GenericManageTable}
                              tableName={'logs'}
                              path="/logs"/>

                <PrivateRoute authed={iAmApproved} userAuth={myUser} roles={INNER_TEAM}
                              component={ManageClaims} path="/manage_claims"/>

                {/*<PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}*/}
                {/*              component={GenericManageTable}*/}
                {/*              tableName={'sdg'}*/}
                {/*              path="/managesdg"/>*/}
                {/*<PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}*/}
                {/*              component={GenericTable}*/}
                {/*              tableName={'sdg'}*/}
                {/*              path="/sdg/:actionType/:rowid"/>*/}
                {/*<PrivateRoute authed={iAmApproved} userAuth={myUser} roles={['admin', 'collaborator']}*/}
                {/*              component={GenericTable}*/}
                {/*              tableName={'sdg'}*/}
                {/*              path="/sdg/:actionType"/>*/}

                <Route component={Compare} path="/compare"/>
                <Route component={Events} path="/events"/>
                <Route component={TermsCondition} path="/termsandconditions"/>
                <Route component={Initiatives} path="/initiatives"/>

            </Switch>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signOutMySelf,
        authMySelf
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        authenticating: state.authHandler.authenticating,
        iAmAuthenticated: state.authHandler.iAmAuthenticated,
        iAmApproved: state.authHandler.iAmApproved,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
