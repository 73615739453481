import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Clear, Delete, Description, GetApp, Image, InsertDriveFile, PictureAsPdf, Toc } from '@material-ui/icons';

import {
    Paper,
    PortletHeader
} from '../index';

import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getDocFileName } from '../../helpers/Strings/DocFileNames';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

function getIconByExtension(extension) {
    switch (extension.toLowerCase()) {
        case 'pdf':
            return <PictureAsPdf/>;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
            return <Image/>;
        case 'odt':
        case 'doc':
        case 'docm':
        case 'dotx':
            return <Description/>;
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xls':
        case 'xlst':
        case 'ods':
            return <Toc/>;
        default:
            return <InsertDriveFile/>;
    }
}

const DocumentInfo = (props) => {
    const {
        classes, className,
        formDisabled,
        canDelete, document, onClickName, onClickDelete, onClickClear, handleUploadClick, handleClickDownload,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={0}
        >
            <PortletHeader style={{ margin: '0px' }}>
                <div
                    style={{ alignContent: 'left', display: 'inline-flex', cursor: 'pointer' }}
                    onClick={handleClickDownload}
                >
                    <Icon>
                        {getIconByExtension(document.extension)}
                    </Icon>
                    <Typography variant="subtitle1" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: '4px'
                    }}>
                        {getDocFileName(document.name, 15)}
                    </Typography>
                </div>
                {/*<PortletLabel*/}
                {/*    style={!formDisabled ? { cursor: 'pointer' } : null}*/}
                {/*    onClick={() =>*/}
                {/*        !formDisabled ? handleClickDownload() : null*/}
                {/*    }*/}
                {/*    title={getDocFileName(document.name, 22)}*/}
                {/*/>*/}
                {!formDisabled ?
                    <div>
                        {document.location ?
                            <IconButton
                                onClick={handleClickDownload}
                                size={'small'}
                            >
                                <GetApp/>
                            </IconButton>
                            : null
                        }
                        {!document.location ?
                            <Button
                                color="primary"
                                variant="text"
                                onClick={handleUploadClick}
                                disabled={document.uploading}
                            >
                                Upload
                            </Button> : null
                        }
                        {
                            document.location ?
                                <IconButton
                                    onClick={onClickDelete}
                                >
                                    <Delete/>
                                </IconButton> :
                                <IconButton
                                    onClick={onClickDelete}
                                >
                                    <Clear/>
                                </IconButton>
                        }
                    </div>
                    : null}
            </PortletHeader>
            {document.uploading ? <LinearProgress/> : null}
        </Paper>

    );
};

export default withStyles(styles)(DocumentInfo);
