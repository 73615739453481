import { cloneDeep } from 'lodash';

import {
    CLEAN_COMPANY,

    COMPANIES_PREVIEW_FETCH,
    COMPANIES_PREVIEW_FETCH_SUCCESS,
    COMPANIES_PREVIEW_FETCH_ERROR,

    COMPANY_DATA_FETCH,
    COMPANY_DATA_FETCH_SUCCESS,
    COMPANY_DATA_FETCH_ERROR,

    COMPANY_SOCIAL_FETCH,
    COMPANY_SOCIAL_FETCH_SUCCESS,
    COMPANY_SOCIAL_FETCH_ERROR,

    COMPANY_GEOGRAPHY_FETCH,
    COMPANY_GEOGRAPHY_FETCH_SUCCESS,
    COMPANY_GEOGRAPHY_FETCH_ERROR,

    COMPANY_FINANCIAL_FETCH,
    COMPANY_FINANCIAL_FETCH_SUCCESS,
    COMPANY_FINANCIAL_FETCH_ERROR,

    COMPANY_TEAM_FETCH,
    COMPANY_TEAM_FETCH_SUCCESS,
    COMPANY_TEAM_FETCH_ERROR,

    COMPANY_UPDATE,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_ERROR,

    COMPANY_CREATE,
    COMPANY_CREATE_SUCCESS,
    COMPANY_CREATE_ERROR,

    UPDATE_COMPANY_STATUS,
    UPDATE_COMPANY_STATUS_SUCCESS,
    UPDATE_COMPANY_STATUS_ERROR,

    DELETE_COMPANY,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_ERROR,

    ADD_PERSONA_TO_TEAM,
    REMOVE_PERSONA_TO_TEAM,
    REMOVE_COMPANY_URL,
    PARTNERSHIPS_FETCH_SUCCESS,
    PARTNERSHIPS_FETCH_ERROR,
    PARTNERSHIPS_FETCH,
    ADD_COMPANY_TO_PARTNERSHIP,
    REMOVE_COMPANY_TO_PARTNERSHIP,
    PORTFOLIOS_FETCH,
    PORTFOLIOS_FETCH_SUCCESS,
    PORTFOLIOS_FETCH_ERROR,
    ADD_COMPANY_TO_PORTFOLIO,
    REMOVE_COMPANY_TO_PORTFOLIO,
    SIMILAR_COMPANIES_FETCH,
    SIMILAR_COMPANIES_FETCH_SUCCESS,
    SIMILAR_COMPANIES_FETCH_ERROR,
    COMPANY_DOCS_FETCH_SUCCESS,
    COMPANY_DOCS_FETCH_ERROR,
    COMPANY_DOCS_FETCH,
    ADD_FILE_TO_DOCS,
    UPDATE_FILE_TO_DOCS,
    REMOVE_FILE_TO_DOCS,
    UPLOADING_FILE,
    UPLOAD_FILE_ERROR,
    COMPANY_MENTIONS_FETCH_SUCCESS,
    COMPANY_MENTIONS_FETCH_ERROR,
    COMPANY_MENTIONS_FETCH,
    ADD_MENTION,
    REMOVE_MENTION,
    UPDATE_MENTION,
    COMPANY_INVESTMENTS_FETCH,
    COMPANY_INVESTMENTS_FETCH_SUCCESS,
    COMPANY_INVESTMENTS_FETCH_ERROR,
    ADD_INVESTMENT,
    REMOVE_INVESTMENT,
    UPDATE_INVESTMENT,
    ADD_COMPANY_TO_ACCELERATORS,
    REMOVE_COMPANY_TO_ACCELERATORS,
    ACCELERATORS_FETCH,
    ACCELERATORS_FETCH_SUCCESS, ACCELERATORS_FETCH_ERROR
} from '../actions/companyHandler';
import { findIndexCompany } from '../helpers/mainPreviews';
import { getIndexDoc, getIndexInvestment, getIndexMention } from '../helpers/UploaderDoc/docFinder';

const initialState = {
    companiesPreview: [],
    fetchingCompaniesPreview: false,
    companiesPreviewError: null,

    numCompanies: 0,
    fetchingCompaniesCount: false,
    companiesCountError: null,

    saving: false,

    companyData: null,
    company_id: null,
    fetchingCompanyData: false,
    companyDataError: null,

    companyTeam: [],
    fetchingCompanyTeam: false,
    companyTeamError: null,

    companyDocuments: [],
    fetchingCompanyDocuments: false,
    companyDocumentsError: null,

    companyMentions: [],
    fetchingCompanyMentions: false,
    companyMentionsError: null,

    partnerships: [],
    fetchingPartnerships: false,
    partnershipsError: null,

    accelerators: [],
    fetchingAccelerators: false,
    acceleratorsError: null,

    portfolios: [],
    fetchingPortfolios: false,
    portfoliosError: null,

    similarCompanies: {},
    fetchingSimilarCompanies: false,
    similarCompaniesError: null,

    companyUpdating: false,
    companyUpdateError: null,

    companyStatusUpdating: false,
    companyStatusUpdateError: null,

    companyFetched: false,
    geographyFetched: false,

    companyInvestments: [],
    fetchingCompanyInvestments: false,
    companyInvestmentsError: null
};

export default function companyHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAN_COMPANY:
            return initialState;
        case COMPANIES_PREVIEW_FETCH:
            return {
                ...state,
                companiesPreview: [],
                fetchingCompaniesPreview: true,
                companiesPreviewError: null,
                numCompanies: 0
            };
        case COMPANIES_PREVIEW_FETCH_SUCCESS:
            const companiesPreviewRows = action.payload.rows;
            const numRows = action.payload.numRows;
            for (let row of companiesPreviewRows) {
                row['idrow'] = row.company_id;
            }
            return {
                ...state,
                companiesPreview: companiesPreviewRows,
                fetchingCompaniesPreview: false,
                numCompanies: numRows
            };
        case COMPANIES_PREVIEW_FETCH_ERROR:
            return {
                ...state,
                fetchingCompaniesPreview: false,
                companiesPreviewError: action.payload,
                numCompanies: 0
            };

        case COMPANY_DATA_FETCH:
            return {
                ...state,
                companyData: null,
                fetchingCompanyData: true,
                companyDataError: null
            };
        case COMPANY_DATA_FETCH_SUCCESS:
            let newCompanyData = action.payload;
            return {
                ...state,
                companyData: newCompanyData,
                fetchingCompanyData: false,
                companyFetched: true
            };
        case COMPANY_DATA_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyData: false,
                companyDataError: action.payload
            };


        case COMPANY_GEOGRAPHY_FETCH:
            return {
                ...state,
                fetchingCompanyGeography: true,
                companyGeographyError: null
            };
        case COMPANY_GEOGRAPHY_FETCH_SUCCESS:
            let newCompanyData_geo = cloneDeep(state.companyData);
            newCompanyData_geo['geography'] = action.payload;
            return {
                ...state,
                companyData: newCompanyData_geo,
                fetchingCompanyGeography: false,
                geographyFetched: true
            };
        case COMPANY_GEOGRAPHY_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyGeography: false,
                companyGeographyError: action.payload
            };

        case COMPANY_FINANCIAL_FETCH:
            return {
                ...state,
                fetchingCompanyFinancial: true,
                companyFinancialError: null
            };
        case COMPANY_FINANCIAL_FETCH_SUCCESS:
            let newCompanyData_financial = cloneDeep(state.companyData);
            let financials = action.payload;

            for (let financial of financials) {
                newCompanyData_financial.financial_metrics[financial.year] = financial;
            }

            return {
                ...state,
                companyData: newCompanyData_financial,
                fetchingCompanyFinancial: false
            };
        case COMPANY_FINANCIAL_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyFinancial: false,
                companyFinancialError: action.payload
            };


        case COMPANY_SOCIAL_FETCH:
            return {
                ...state,
                fetchingCompanySocial: true,
                companySocialError: null
            };
        case COMPANY_SOCIAL_FETCH_SUCCESS:
            let newCompanyData_social = cloneDeep(state.companyData);
            newCompanyData_social['social'] = action.payload;
            return {
                ...state,
                companyData: newCompanyData_social,
                fetchingCompanySocial: false
            };
        case COMPANY_SOCIAL_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanySocial: false,
                companySocialError: action.payload
            };

        case COMPANY_TEAM_FETCH:
            return {
                ...state,
                companyTeam: null,
                fetchingCompanyTeam: true,
                companyTeamError: null
            };
        case COMPANY_TEAM_FETCH_SUCCESS:
            return {
                ...state,
                companyTeam: action.payload,
                fetchingCompanyTeam: false
            };
        case COMPANY_TEAM_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyTeam: false,
                companyTeamError: action.payload
            };

        case COMPANY_DOCS_FETCH:
            return {
                ...state,
                companyDocuments: null,
                fetchingCompanyDocuments: true,
                companyDocumentsError: null
            };
        case COMPANY_DOCS_FETCH_SUCCESS:
            return {
                ...state,
                companyDocuments: action.payload,
                fetchingCompanyDocuments: false
            };
        case COMPANY_DOCS_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyDocuments: false,
                companyDocumentsError: action.payload
            };

        case COMPANY_MENTIONS_FETCH:
            return {
                ...state,
                companyMentions: null,
                fetchingCompanyMentions: true,
                companyMentionsError: null
            };
        case COMPANY_MENTIONS_FETCH_SUCCESS:
            return {
                ...state,
                companyMentions: action.payload,
                fetchingCompanyMentions: false
            };
        case COMPANY_MENTIONS_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyMentions: false,
                companyMentionsError: action.payload
            };

        case ACCELERATORS_FETCH:
            return {
                ...state,
                accelerators: [],
                fetchingAccelerators: true,
                acceleratorsError: null
            };
        case ACCELERATORS_FETCH_SUCCESS:
            return {
                ...state,
                accelerators: action.payload,
                fetchingAccelerators: false
            };
        case ACCELERATORS_FETCH_ERROR:
            return {
                ...state,
                fetchingAccelerators: false,
                acceleratorsError: action.payload
            };

        case PARTNERSHIPS_FETCH:
            return {
                ...state,
                partnerships: null,
                fetchingPartnerships: true,
                partnershipsError: null
            };
        case PARTNERSHIPS_FETCH_SUCCESS:
            return {
                ...state,
                partnerships: action.payload,
                fetchingPartnerships: false
            };
        case PARTNERSHIPS_FETCH_ERROR:
            return {
                ...state,
                fetchingPartnerships: false,
                partnershipsError: action.payload
            };

        case PORTFOLIOS_FETCH:
            return {
                ...state,
                portfolios: null,
                fetchingPortfolios: true,
                portfoliosError: null
            };
        case PORTFOLIOS_FETCH_SUCCESS:
            return {
                ...state,
                portfolios: action.payload,
                fetchingPortfolios: false
            };
        case PORTFOLIOS_FETCH_ERROR:
            return {
                ...state,
                fetchingPortfolios: false,
                portfoliosError: action.payload
            };

        case SIMILAR_COMPANIES_FETCH:
            return {
                ...state,
                similarCompanies: null,
                fetchingSimilarCompanies: true,
                similarCompaniesError: null
            };
        case SIMILAR_COMPANIES_FETCH_SUCCESS:
            return {
                ...state,
                similarCompanies: action.payload,
                similarCompaniesError: false
            };
        case SIMILAR_COMPANIES_FETCH_ERROR:
            return {
                ...state,
                fetchingSimilarCompanies: false,
                similarCompaniesError: action.payload
            };

        case COMPANY_UPDATE:
            return {
                ...state,
                companyUpdating: true,
                companyUpdateError: null
            };
        case COMPANY_UPDATE_SUCCESS:
            let companyUpdated = cloneDeep(action.payload.companyUpdated);
            return {
                ...state,
                companyData: companyUpdated,
                companyUpdating: false
            };
        case COMPANY_UPDATE_ERROR:
            return {
                ...state,
                companyUpdating: false,
                companyUpdateError: true
            };

        case COMPANY_CREATE:
            return {
                ...state,
                companyUpdating: true,
                companyUpdateError: null
            };
        case COMPANY_CREATE_SUCCESS:
            return {
                ...state,
                company_id: action.payload,
                companyUpdating: false
            };
        case COMPANY_CREATE_ERROR:
            return {
                ...state,
                companyUpdating: false,
                companyUpdateError: true
            };

        case UPDATE_COMPANY_STATUS:
            return {
                ...state,
                companyStatusUpdating: true,
                companyStatusUpdateError: null
            };
        case UPDATE_COMPANY_STATUS_SUCCESS:
            let company_id = action.payload.company_id;
            const newStatus = action.payload.newStatus;
            const companiesPreviewUpdated = cloneDeep(state.companiesPreview);
            const indexCompanyInPreviews = findIndexCompany(companiesPreviewUpdated, company_id);
            if (indexCompanyInPreviews !== -1) {
                companiesPreviewUpdated[indexCompanyInPreviews].status = newStatus;
            }
            return {
                ...state,
                companyStatusUpdating: false,
                companyStatusUpdateError: null,
                companiesPreview: companiesPreviewUpdated
            };
        case UPDATE_COMPANY_STATUS_ERROR:
            return {
                ...state,
                companyStatusUpdating: false,
                companyStatusUpdateError: action.payload
            };

        case DELETE_COMPANY:
            return {
                ...state,
                companyStatusUpdating: true,
                companyStatusUpdateError: null
            };
        case DELETE_COMPANY_SUCCESS:
            // company_id = action.payload.company_id;
            return {
                ...state,
                companyStatusUpdating: false,
                companyStatusUpdateError: null
            };
        case DELETE_COMPANY_ERROR:
            return {
                ...state,
                companyStatusUpdating: false,
                companyStatusUpdateError: action.payload
            };


        case ADD_PERSONA_TO_TEAM:
            let companyTeamAdded = cloneDeep(state.companyTeam);
            companyTeamAdded.push(action.payload);
            return {
                ...state,
                companyTeam: companyTeamAdded
            };

        case ADD_FILE_TO_DOCS:
            let documentsAdded = cloneDeep(state.companyDocuments);
            documentsAdded.push(action.payload);
            return {
                ...state,
                companyDocuments: documentsAdded
            };

        case UPLOAD_FILE_ERROR:
            let doc_id_error = action.payload;
            let currentDocumentsOk = cloneDeep(state.companyDocuments);
            let indexDocError = getIndexDoc(currentDocumentsOk, doc_id_error);
            if (indexDocError !== -1) {
                currentDocumentsOk[indexDocError].uploading = false;
            }
            return {
                ...state,
                companyDocuments: currentDocumentsOk
            };

        case UPLOADING_FILE:
            let doc_id_uploading = action.payload;
            let currentDocuments = cloneDeep(state.companyDocuments);
            let indexDocUploading = getIndexDoc(currentDocuments, doc_id_uploading);
            if (indexDocUploading !== -1) {
                currentDocuments[indexDocUploading].uploading = true;
            }
            return {
                ...state,
                companyDocuments: currentDocuments
            };

        case REMOVE_FILE_TO_DOCS:
            let doc_id = action.payload;
            let documentsRemoved = cloneDeep(state.companyDocuments);
            let indexDoc = getIndexDoc(documentsRemoved, doc_id);
            if (indexDoc !== -1) {
                documentsRemoved.splice(indexDoc, 1);
            }
            return {
                ...state,
                companyDocuments: documentsRemoved
            };

        case UPDATE_FILE_TO_DOCS:
            let doc_id_updated = action.payload.doc_id;
            let location = action.payload.location;
            let key = action.payload.key;

            let currentDocumentsUpdated = cloneDeep(state.companyDocuments);
            let indexDocUploadingUpdated = getIndexDoc(currentDocumentsUpdated, doc_id_updated);

            if (indexDocUploadingUpdated !== -1) {
                currentDocumentsUpdated[indexDocUploadingUpdated].uploading = false;
                currentDocumentsUpdated[indexDocUploadingUpdated].location = location;
                currentDocumentsUpdated[indexDocUploadingUpdated].key = key;
            }

            return {
                ...state,
                companyDocuments: currentDocumentsUpdated
            };

        case ADD_MENTION:
            let newMentions = cloneDeep(state.companyMentions);
            newMentions.push(action.payload);
            return {
                ...state,
                companyMentions: newMentions
            };

        case REMOVE_MENTION:
            let mention_id = action.payload;
            let mentionsRemoved = cloneDeep(state.companyMentions);
            let indexMention = getIndexMention(mentionsRemoved, mention_id);
            if (indexMention !== -1) {
                mentionsRemoved.splice(indexMention, 1);
            }
            return {
                ...state,
                companyMentions: mentionsRemoved
            };

        case UPDATE_MENTION:
            let mention_id_updated = action.payload.mention_id;
            let currentMentions = cloneDeep(state.companyMentions);
            let indexMentionUpdated = getIndexMention(currentMentions, mention_id_updated);
            if (indexMentionUpdated !== -1) {
                currentMentions[indexMentionUpdated].url = action.payload.url;
            }
            return {
                ...state,
                companyMentions: currentMentions
            };

        case REMOVE_PERSONA_TO_TEAM:
            let personaToRemove = action.payload;
            let companyTeamRemoved = cloneDeep(state.companyTeam);
            let index = -1;
            let i = 0;
            for (let persona of companyTeamRemoved) {
                if (persona.persona_id === personaToRemove.persona_id
                    || (persona.name === personaToRemove.name && persona.lastname === personaToRemove.lastname)) {
                    index = i;
                }
                i++;
            }
            if (index !== -1) {
                companyTeamRemoved.splice(index, 1);
            }
            return {
                ...state,
                companyTeam: companyTeamRemoved
            };

        case ADD_COMPANY_TO_PARTNERSHIP:
            let partnershipsAdded = cloneDeep(state.partnerships);
            partnershipsAdded.push(action.payload);
            return {
                ...state,
                partnerships: partnershipsAdded
            };

        case REMOVE_COMPANY_TO_PARTNERSHIP:
            let companyToRemove = action.payload;
            let partnershipsRemoved = cloneDeep(state.partnerships);
            let indexCompany = -1;
            let iC = 0;
            for (let company of partnershipsRemoved) {
                if (company.company_id === companyToRemove.company_id
                    || (company.name === companyToRemove.name)) {
                    indexCompany = iC;
                }
                iC++;
            }
            if (indexCompany !== -1) {
                partnershipsRemoved.splice(indexCompany, 1);
            }
            return {
                ...state,
                partnerships: partnershipsRemoved
            };

        case ADD_COMPANY_TO_ACCELERATORS:
            let acceleratorsAdded = cloneDeep(state.accelerators);
            acceleratorsAdded.push(action.payload);
            return {
                ...state,
                accelerators: acceleratorsAdded
            };

        case REMOVE_COMPANY_TO_ACCELERATORS:
            let acceleratorToRemove = action.payload;
            let acceleratorsRemoved = cloneDeep(state.accelerators);
            let indexAccelerator = -1;
            let iCA = 0;
            for (let company of acceleratorsRemoved) {
                if (company.company_id === acceleratorToRemove.company_id
                    || (company.name === acceleratorToRemove.name)) {
                    indexAccelerator = iCA;
                }
                iCA++;
            }
            if (indexAccelerator !== -1) {
                acceleratorsRemoved.splice(indexAccelerator, 1);
            }
            return {
                ...state,
                accelerators: acceleratorsRemoved
            };

        case ADD_COMPANY_TO_PORTFOLIO:
            let portfoliosAdded = cloneDeep(state.portfolios);
            portfoliosAdded.push(action.payload);
            return {
                ...state,
                portfolios: portfoliosAdded
            };

        case REMOVE_COMPANY_TO_PORTFOLIO:
            let companyPortfolioToRemove = action.payload;
            let portfoliosRemoved = cloneDeep(state.portfolios);
            let indexCompanyPortfolio = -1;
            let iCP = 0;
            for (let company of portfoliosRemoved) {
                if (company.company_id === companyPortfolioToRemove.company_id
                    || (company.name === companyPortfolioToRemove.name)) {
                    indexCompanyPortfolio = iCP;
                }
                iCP++;
            }
            if (indexCompanyPortfolio !== -1) {
                portfoliosRemoved.splice(indexCompanyPortfolio, 1);
            }
            return {
                ...state,
                portfolios: portfoliosRemoved
            };


        case REMOVE_COMPANY_URL:
            let newCompanyDataNoUrl = cloneDeep(state.companyData);
            newCompanyDataNoUrl.company.avatar_url = null;
            return {
                ...state,
                companyData: newCompanyDataNoUrl
            };


        case COMPANY_INVESTMENTS_FETCH:
            return {
                ...state,
                companyInvestments: null,
                fetchingCompanyInvestments: true,
                companyInvestmentsError: null
            };
        case COMPANY_INVESTMENTS_FETCH_SUCCESS:
            return {
                ...state,
                companyInvestments: action.payload,
                fetchingCompanyInvestments: false
            };
        case COMPANY_INVESTMENTS_FETCH_ERROR:
            return {
                ...state,
                fetchingCompanyInvestments: false,
                companyInvestmentsError: action.payload
            };

        case ADD_INVESTMENT:
            let newInvestments = cloneDeep(state.companyInvestments);
            newInvestments.push(action.payload);
            return {
                ...state,
                companyInvestments: newInvestments
            };

        case REMOVE_INVESTMENT:
            let investment_id = action.payload;
            let investmentsRemoved = cloneDeep(state.companyInvestments);
            let indexInvestment = getIndexInvestment(investmentsRemoved, investment_id);
            if (indexInvestment !== -1) {
                investmentsRemoved.splice(indexInvestment, 1);
            }
            return {
                ...state,
                companyInvestments: investmentsRemoved
            };

        case UPDATE_INVESTMENT:
            let newInvestmentUpdated = action.payload;
            let investment_id_updated = newInvestmentUpdated.investment_id;
            let currentInvestments = cloneDeep(state.companyInvestments);
            let indexInvestmentUpdated = getIndexInvestment(currentInvestments, investment_id_updated);
            if (indexInvestmentUpdated !== -1) {
                currentInvestments[indexInvestmentUpdated] = newInvestmentUpdated;
            }
            return {
                ...state,
                companyInvestments: currentInvestments
            };
        default:
            return state;
    }
}
