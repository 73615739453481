import { SDG, SECTOR, SUBSECTOR, LOGS, DEAL, TECHNOLOGY, DEAL_FORM } from './emptyTable';
import { can, DOWNLOAD_CSV, INNER_TEAM } from '../helpers/Roles/roleActions';

const headSector = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

const headSubSector = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

const headTechnology = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

const headSDG = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

const headLogs = [
    { id: 'user', numeric: false, disablePadding: false, label: 'User', sortable: false },
    { id: 'action_type', numeric: false, disablePadding: false, label: 'Action', sortable: false },
    { id: 'subject', numeric: false, disablePadding: false, label: 'Subject', sortable: false },
    { id: 'mail', numeric: false, disablePadding: false, label: 'Email', sortable: false },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: false }
];

const headDeal = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
    { id: 'investment_stage', numeric: false, disablePadding: false, label: 'Stage', sortable: true },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Amount ($)', sortable: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true },
    { id: 'sector', numeric: false, disablePadding: false, label: 'Sector', sortable: true },
    { id: 'hq_country', numeric: false, disablePadding: false, label: 'HQ Country', sortable: true },
    { id: 'investors', numeric: false, disablePadding: false, label: 'Investors', sortable: false }
];

export const headClaim = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
    { id: 'first_name', numeric: false, disablePadding: false, label: 'User', sortable: true },
    { id: 'role_user', numeric: false, disablePadding: false, label: 'Role', sortable: true },
    { id: 'claim_status', numeric: false, disablePadding: false, label: 'Status', sortable: true },
    { id: 'user_email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
    { id: 'date_request', numeric: false, disablePadding: false, label: 'Request Date', sortable: true }
];

const headDealForm = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
    { id: 'stage', numeric: false, disablePadding: false, label: 'Stage', sortable: true },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Amount ($)', sortable: true },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true },
    { id: 'investors', numeric: false, disablePadding: false, label: 'Investors', sortable: false }
];


export const getHeaderCells = (tableName) => {
    switch (tableName) {
        case SECTOR:
            return headSector;
        case SUBSECTOR:
            return headSubSector;
        case TECHNOLOGY:
            return headTechnology;
        case SDG:
            return headSDG;
        case LOGS:
            return headLogs;
        case DEAL:
            return headDeal;
        case DEAL_FORM:
            return headDealForm;
        default:
            return null;
    }
};

export const getHeaderCellsDealsForm = () => {
    return [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: false },
        { id: 'investment_stage', numeric: false, disablePadding: false, label: 'Stage', sortable: true },
        { id: 'amount', numeric: false, disablePadding: false, label: 'Amount ($)', sortable: true },
        { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true }
    ];
};

export const getHeaderCellsCompanies = (role) => {
    if (INNER_TEAM.includes(role)) {
        return [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
            { id: 'category', numeric: false, disablePadding: false, label: 'Category', sortable: true },
            { id: 'sector_name', numeric: false, disablePadding: false, label: 'Sector', sortable: true },
            { id: 'hq_country', numeric: false, disablePadding: false, label: 'HQ Country', sortable: true },
            { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: true },
            { id: 'creator_type', numeric: false, disablePadding: false, label: 'Creator', sortable: true },
            { id: 'operating_status', numeric: false, disablePadding: false, label: 'Operating', sortable: true },
            { id: 'contacts', numeric: false, disablePadding: false, label: 'Contacts', sortable: false },
            { id: 'creation_date', numeric: false, disablePadding: false, label: 'Created', sortable: true },
            { id: 'last_update', numeric: false, disablePadding: false, label: 'Updated', sortable: true },
            { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
        ];
    } else {
        return [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: true },
            { id: 'category', numeric: false, disablePadding: false, label: 'Category', sortable: true },
            { id: 'sector_name', numeric: false, disablePadding: false, label: 'Sector', sortable: true },
            { id: 'hq_country', numeric: false, disablePadding: false, label: 'HQ Country', sortable: true },
            { id: 'operating_status', numeric: false, disablePadding: false, label: 'Operating', sortable: true },
            { id: 'contacts', numeric: false, disablePadding: false, label: 'Contacts', sortable: false },
            { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
        ];
    }
};

// export const getHeaderCellsDeals = (role) => {
//     if(can(role, DOWNLOAD_CSV)) {
//         return [
//             { id: 'name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
//             { id: 'investment_stage', numeric: false, disablePadding: false, label: 'Stage', sortable: true },
//             { id: 'amount', numeric: false, disablePadding: false, label: 'Amount ($)', sortable: true },
//             { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true },
//             { id: 'sector', numeric: false, disablePadding: false, label: 'Sector', sortable: true },
//             { id: 'investors', numeric: false, disablePadding: false, label: 'Investors', sortable: false },
//         ];
//     } else {
//         return [
//             { id: 'name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
//             { id: 'investment_stage', numeric: false, disablePadding: false, label: 'Stage', sortable: true },
//             { id: 'amount', numeric: false, disablePadding: false, label: 'Amount ($)', sortable: true },
//             { id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true },
//             { id: 'sector', numeric: false, disablePadding: false, label: 'Sector', sortable: true },
//             { id: 'investors', numeric: false, disablePadding: false, label: 'Investors', sortable: false },
//         ];
//     }
// };
