import palette from '../palette';


const raleway = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  };

export default {
  root: {
    color: palette.text.primary,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
};
