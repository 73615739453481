import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default function ConfirmDialog(props) {
    let {
        visible,
        onClose,
        onConfirm,
        title,
        content
    } = props;

    return (
        <div>
            <Dialog open={visible}
                    onClose={onClose}
            >
                <DialogTitle
                    id="alert-dialog-title">{title}
                </DialogTitle>
                <DialogContent
                    style={{ minWidth: '300px', minHeight: '60px' }}
                >
                    <Typography variant='body1'>{content}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onConfirm}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
