const uuidv4 = require('uuid/v4');

const corsProxy = 'https://cors-anywhere.herokuapp.com/';

const lambdaTableInsertion_URL = 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/db-insertions';
const lambdaRowInsertion_URL = 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/row-insertion';
const lambdaChunkInsertion_URL = 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/chunk-insertion';
const lambdaMailerRegistration_URL = 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/mailer';

const urlLambdaAPI_table = corsProxy + lambdaTableInsertion_URL;
const urlLambdaAPI_row = corsProxy + lambdaRowInsertion_URL;
const urlLambdaAPI_chunk = corsProxy + lambdaChunkInsertion_URL;

export const urlMailerRegistration = corsProxy + lambdaMailerRegistration_URL;


const getRandomId = () => {
    // return Math.floor((Math.random() * 2047483647) + 1);
    return uuidv4();
};
export { getRandomId };

const getIndexColumn = (name_column, header) => {
    return header.findIndex(function(element) {
        return element.toLowerCase() === name_column.toLowerCase();
    });
};
export { getIndexColumn };

async function insertChunkLambda(chunkObj, datapoint_id) {

    let fetchResponse = await fetch(urlLambdaAPI_chunk, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            queryId: getRandomId(),
            chunkObj: chunkObj,
            datapoint_id: datapoint_id
        })
    });

    let fetchResult = await fetchResponse.json();

    // console.log("insertRowLambda result", fetchResult);

    return fetchResult;
}

export { insertChunkLambda };

async function insertRowLambda(indexRow, indexRowCsv, rowObj, datapoint_id) {

    let fetchResponse = await fetch(urlLambdaAPI_row, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            queryId: getRandomId(),
            indexRow: indexRow,
            indexRowCsv: indexRowCsv,
            rowObj: rowObj,
            datapoint_id: datapoint_id
        })
    });

    let fetchResult = await fetchResponse.json();

    // console.log("insertRowLambda result", fetchResult);

    return fetchResult;
}

export { insertRowLambda };

async function insertTableLambda(indexRow, tableName, tableObj) {

    let fetchResponse = await fetch(urlLambdaAPI_table, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tableObj: tableObj,
            tableName: tableName,
            queryId: getRandomId(),
            indexRow: indexRow
        })
    });

    let fetchResult = await fetchResponse.json();

    // console.log(fetchResult);

    return fetchResult;
}

export { insertTableLambda };
