import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Spacer from '../Spacer/Spacer';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import AvatarNameCell from '../Table/AvatarNameCell';
import IconButton from '@material-ui/core/IconButton';
import { InfoOutlined } from '@material-ui/icons';

export default function AssessmentBoxModal(props) {

    const {
        open, handleClose, companiesResult, searchingCompanies,
        selectCompany, handleInputCompanyChange
    } = props;
    const classes = useStyles();

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 250
                }}
            >
                <Fade in={open}>
                    <Grid item xs={12} sm={8} lg={4}>
                        <Paper className={classes.paper} style={{ marginTop: 0 }}>
                            <Typography variant="h2">
                                {'Assessment Toolkit'}
                            </Typography>
                            <Spacer/>
                            <Typography variant="h6">
                                {'Our Assessment Toolkit is designed to help companies indicate their growth stage and needs and allows them to match with ecosystem builders and investors based on a given set of criteria.'}
                            </Typography>
                            <Spacer/>
                            <Autocomplete
                                id="asynchronous-demo"
                                style={{ width: 400 }}
                                getOptionDisabled={option => option.disabled}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={option => (option.name)}
                                // getOptionValue={option => option.id}
                                options={companiesResult}
                                noOptionsText={'Type to search'}
                                loading={searchingCompanies}
                                onChange={(event, value) => selectCompany(value)}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <AvatarNameCell
                                            avatarUrl={option.avatar_url}
                                            name={option.name}
                                            truncateLength={34}
                                        />
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleInputCompanyChange}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {searchingCompanies ?
                                                        <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <Spacer small/>
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton aria-label="delete" disabled color={'secondary'}>
                                    <InfoOutlined/>
                                </IconButton>
                                <Typography variant="h5">
                                    {'Request to Manage your company\'s profile to access the editing panel'}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 0.9
    }
}));
