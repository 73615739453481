import { cloneDeep } from 'lodash';

export const getPieThreshold = (data) => {
    let cloneData = cloneDeep(data);
    cloneData.sort((a, b) => (parseInt(a.count) < parseInt(b.count)) ? 1 : -1);
    let threshold = 12;

    let sum = getSumCounter(cloneData);
    if (cloneData[2]) {
        threshold = parseInt(cloneData[2].count) / sum * 100;
    } else {
        threshold = parseInt(cloneData[1].count) / sum * 100;
    }

    return threshold;
};

const getSumCounter = (data) => {
    let sum = 0;
    for (let d of data) {
        sum = sum + parseInt(d.count);
    }
    return sum;
};

export const aggregateColumns = (data, categoryField, valueField, maxColumns) => {
    let cloneData = cloneDeep(data);
    cloneData.sort((a, b) => (parseInt(a[valueField]) < parseInt(b[valueField])) ? 1 : -1);

    if (cloneData.length < 4) {
        for (let i = 0; i < 4 - cloneData.length; i++) {
            cloneData.push({ [categoryField]: '', [valueField]: 0 });
        }
    } else {
        if (cloneData.length > maxColumns) {
            let others = { [categoryField]: 'Others', [valueField]: 0 };
            for (let d in cloneData) {
                if (d > maxColumns) {
                    others[valueField] = others[valueField] + parseInt(cloneData[d][valueField]);
                }
            }
            cloneData.splice(maxColumns, cloneData.length);
            // cloneData.push(others);
        }
    }

    return cloneData;
};
