import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
    DashboardOutlined as DashboardIcon,
    Public,
    HelpOutline as HelpIcon,
    Business as BusinessIcon,
    PeopleAltOutlined as PeopleIcon,
    SupervisedUserCircle as UsersIcon,
    Category,
    CategoryOutlined,
    Eco,
    EventAvailable,
    Whatshot,
    MailOutline,
    Help,
    ListAlt,
    AttachMoney,
    LinkedIn,
    Info,
    Description,
    MonetizationOn,
    CompareArrows, StarBorder, ExpandLess, ExpandMore, Instagram, Facebook, Twitter, Event, Assignment
} from '@material-ui/icons';
import styles from './styles';
import logo from '../../../../assets/images/uk_nigeria_tech_hub_logo.png';
import { can } from '../../../../helpers/Roles/roleActions';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ContactsBox from '../../../../components/Contacts';
import ContactsBoxDash from '../../../../components/ContactsBox/contactsBoxDash';

class Sidebar extends Component {

    handleClickAdmin = () => {
        this.props.toggleAdminList();
    };

    render() {
        const {
            classes, className,
            iAmAuthenticated, iAmApproved, myUser,
            handleContactUsClick,
            handleTroubleShootingUsClick,
            handleClickUpgrade,
            handleClickUnauthRoute,
            handleClickAssessmentToolkit,
            adminListOpen
        } = this.props;
        const rootClassName = classNames(classes.root, className);

        return (
            <nav className={rootClassName}>
                <div className={classes.logoWrapper}>
                    <Link className={classes.logoLink} to="/overview">
                        <img alt="logo" className={classes.logoImage} src={logo}/>
                        {/*<Typography variant="h2">*/}
                        {/*    {'NIGERIA'}*/}
                        {/*</Typography> :*/}
                    </Link>
                </div>

                <Divider className={classes.logoDivider}/>


                {can(myUser, 'sidebarManage') ?
                    <List component="nav" disablePadding>

                        <ListItem button onClick={this.handleClickAdmin}>
                            <ListItemIcon>
                                <BusinessIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Admin"/>
                            {adminListOpen ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={adminListOpen} timeout="auto" unmountOnExit>
                            <List component="div">

                                <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                          style={{ marginLeft: '9px' }}
                                          component={NavLink}
                                          to="/managecompanies">
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <BusinessIcon/>
                                    </ListItemIcon>
                                    <ListItemText classes={{ primary: classes.listItemText }}
                                                  primary="Manage Companies"/>
                                </ListItem>

                                <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                          component={NavLink}
                                          style={{ marginLeft: '9px' }}
                                          to="/managepeople">
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <PeopleIcon/>
                                    </ListItemIcon>
                                    <ListItemText classes={{ primary: classes.listItemText }} primary="Manage People"/>
                                </ListItem>

                                {myUser.role === 'admin' ?
                                    <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                              component={NavLink}
                                              style={{ marginLeft: '9px' }}
                                              to="/manageusers">
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <UsersIcon/>
                                        </ListItemIcon>
                                        <ListItemText classes={{ primary: classes.listItemText }}
                                                      primary="Manage Users"/>
                                    </ListItem>
                                    : null
                                }

                            </List>

                        </Collapse>

                        <Divider className={classes.listDivider}/>

                    </List>


                    : null}


                <List component="div" disablePadding>

                    <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={NavLink}
                              to="/overview">
                        <ListItemIcon className={classes.listItemIcon}>
                            <Public/>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.listItemText }} primary="Home"/>
                    </ListItem>

                    {this.props.myUser && (this.props.myUser.role === 'paying_user' || this.props.myUser.role === 'user') ?
                        <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                  component={NavLink}
                                  to="/managecompanies">
                            <ListItemIcon className={classes.listItemIcon}>
                                <BusinessIcon/>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Companies"/>
                        </ListItem>
                        : this.props.myUser && this.props.myUser.role === 'admin' ?
                            null :
                            <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                      onClick={this.props.myUser ? handleClickUpgrade : handleClickUnauthRoute}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <BusinessIcon/>
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.listItemText }} primary="Companies"/>
                            </ListItem>
                    }


                    <div>
                        <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                  onClick={handleClickAssessmentToolkit}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Assessment Toolkit"/>
                        </ListItem>
                    </div>


                    {this.props.myUser ?
                        <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                  component={NavLink}
                                  to="/deals">
                            <ListItemIcon className={classes.listItemIcon}>
                                <MonetizationOn/>
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Deals"/>
                        </ListItem>
                        : null}


                    {/*<Tooltip title="Coming Soon" placement="top">*/}
                    {/*    <ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                    {/*        // component={NavLink}*/}
                    {/*        // to="/events"*/}
                    {/*    >*/}
                    {/*        <ListItemIcon className={classes.listItemIcon}>*/}
                    {/*            <EventAvailable/>*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText classes={{ primary: classes.listItemText }} primary="Events"/>*/}
                    {/*    </ListItem>*/}
                    {/*</Tooltip>*/}

                    {/*<Tooltip title="Coming Soon" placement="top">*/}
                    {/*    <ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                    {/*        // component={NavLink}*/}
                    {/*        // to="/initiatives"*/}
                    {/*    >*/}
                    {/*        <ListItemIcon className={classes.listItemIcon}>*/}
                    {/*            <Whatshot/>*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText classes={{ primary: classes.listItemText }} primary="Initiatives"/>*/}
                    {/*    </ListItem>*/}
                    {/*</Tooltip>*/}

                    <Divider className={classes.listDivider}/>

                    {!this.props.myUser || this.props.myUser && (this.props.myUser.role === 'user' || this.props.myUser.role === 'paying_user') ?
                        <div>

                            <ListItem activeClassName={classes.activeListItem} className={classes.listItem}
                                      onClick={handleContactUsClick}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <MailOutline/>
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.listItemText }} primary="Contact Us"/>
                            </ListItem>

                            {/*<ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                            {/*          onClick={() => {*/}
                            {/*              window.open('https://briterbridges.medium.com/how-to-guide-to-briter-intelligence-b9d16cdcf356', '_blank');*/}
                            {/*          }}*/}
                            {/*          to="/">*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <Help/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText classes={{ primary: classes.listItemText }} primary="How-To Guide"/>*/}
                            {/*</ListItem>*/}

                            {/*<ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                            {/*          onClick={() => {*/}
                            {/*              // window.location = 'https://calendly.com/briterbridges/30min';*/}
                            {/*              window.open('https://calendly.com/briterbridges/bi-demo', '_blank');*/}
                            {/*          }}*/}
                            {/*          to="/">*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <Event/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText classes={{ primary: classes.listItemText }} primary="Schedule a Demo"/>*/}
                            {/*</ListItem>*/}

                            {/*< ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                            {/*           onClick={() => {*/}
                            {/*               window.location = 'https://briterbridges.com/about';*/}
                            {/*           }}*/}
                            {/*           to='/'>*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <Info/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText classes={{ primary: classes.listItemText }} primary="About"/>*/}
                            {/*</ListItem>*/}

                            {/*<ListItem activeClassName={classes.activeListItem} className={classes.listItem}*/}
                            {/*          component={NavLink}*/}
                            {/*          to="/termsandconditions">*/}
                            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
                            {/*        <Description/>*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <ListItemText classes={{ primary: classes.listItemText }} primary="Terms of Service"/>*/}
                            {/*</ListItem>*/}

                            {/*<Divider className={classes.listDivider}/>*/}
                        </div>
                        : null}

                    <Divider className={classes.listDivider}/>

                    <ListItem
                    >
                        <ContactsBoxDash dataInfo={{
                            linkedin: 'https://www.linkedin.com/company/uk-nigeria-tech-hub/',
                            instagram: 'https://www.instagram.com/ukngtechhub/',
                            // facebook: 'https://www.facebook.com/briterbridges',
                            twitter: 'https://twitter.com/ukngtechhub'
                        }}
                                         noWebsite/>
                        <ListItemText classes={{ primary: classes.listItemText }}/>
                    </ListItem>

                </List>

            </nav>
        );
    }
}

Sidebar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
