import * as Yup from 'yup';

export const signInSchema = Yup.object().shape({
    email_SignIn: Yup.string()
        .email('must be a valid email')
        .required('Required')
        .nullable(),
    password_SignIn: Yup.string()
        .required('Required')
        .min(8, 'Password too short, should be at least 8 chars long')
        .nullable()
});

export const signUpSchema = Yup.object().shape({
    firstName_SignUp: Yup.string()
        .required('Required')
        .nullable(),
    lastName_SignUp: Yup.string()
        .required('Required')
        .nullable(),
    email_SignUp: Yup.string()
        .email('must be a valid email')
        .nullable(),
    password_SignUp: Yup.string()
        .required('Required')
        .min(8, 'Password too short, should be at least 8 chars long')
        .nullable()
});
