import { cloneDeep } from 'lodash';
import {
    CLEAN_TABLE,
    TABLE_COUNT_FETCH,
    TABLE_COUNT_FETCH_ERROR,
    TABLE_COUNT_FETCH_SUCCESS,
    TABLE_CREATE,
    TABLE_CREATE_ERROR,
    TABLE_CREATE_SUCCESS,
    TABLE_DATA_FETCH,
    TABLE_DATA_FETCH_ERROR,
    TABLE_DATA_FETCH_SUCCESS,
    TABLE_PREVIEW_FETCH,
    TABLE_PREVIEW_FETCH_ERROR,
    TABLE_PREVIEW_FETCH_SUCCESS,
    TABLE_UPDATE,
    TABLE_UPDATE_ERROR,
    TABLE_UPDATE_SUCCESS,

    TABLE_DELETE,
    TABLE_DELETE_SUCCESS,
    TABLE_DELETE_ERROR
} from '../actions/tableHandler';
import { getRowId } from '../models/emptyTable';

const initialState = {
    tablePreview: [],
    fetchingTablePreview: false,
    tablePreviewError: null,

    numRows: 0,
    fetchingRowsCount: false,
    rowsCountError: null,

    saving: false,

    tableData: null,
    rowid: null,
    fetchingTableData: false,
    tableDataError: null,
    tableFetched: false,

    tableUpdating: false,
    tableUpdateError: null
};

export default function tableHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAN_TABLE:
            return initialState;
        case TABLE_PREVIEW_FETCH:
            return {
                ...state,
                tablePreview: [],
                fetchingTablePreview: true,
                tablePreviewError: null
            };
        case TABLE_PREVIEW_FETCH_SUCCESS:
            const tablePreviewRows = action.payload.rows;
            const numRows = action.payload.numRows;
            const tableName = action.payload.tableName;
            for (let row of tablePreviewRows) {
                // row['idrow'] = row.company_id;
                row['idrow'] = row[getRowId(tableName)];
            }
            return {
                ...state,
                tablePreview: tablePreviewRows,
                fetchingTablePreview: false,
                numRows: numRows
            };
        case TABLE_PREVIEW_FETCH_ERROR:
            return {
                ...state,
                fetchingTablePreview: false,
                tablePreviewError: action.payload,
                numRows: 0
            };


        case TABLE_DATA_FETCH:
            return {
                ...state,
                tableData: null,
                fetchingTableData: true,
                tableDataError: null
            };
        case TABLE_DATA_FETCH_SUCCESS:
            let newTableData = action.payload;
            return {
                ...state,
                tableData: newTableData,
                fetchingTableData: false,
                tableFetched: true
            };
        case TABLE_DATA_FETCH_ERROR:
            return {
                ...state,
                fetchingTableData: false,
                tableDataError: action.payload
            };


        case TABLE_UPDATE:
            return {
                ...state,
                tableUpdating: true,
                tableUpdateError: null
            };
        case TABLE_UPDATE_SUCCESS:
            let tableUpdated = cloneDeep(action.payload.tableUpdated);
            return {
                ...state,
                tableData: tableUpdated,
                tableUpdating: false
            };
        case TABLE_UPDATE_ERROR:
            return {
                ...state,
                tableUpdating: false,
                tableUpdateError: action.payload
            };

        case TABLE_CREATE:
            return {
                ...state,
                tableUpdating: true,
                tableUpdateError: null
            };
        case TABLE_CREATE_SUCCESS:
            return {
                ...state,
                table_id: action.payload,
                tableUpdating: false
            };
        case TABLE_CREATE_ERROR:
            return {
                ...state,
                tableUpdating: false,
                tableUpdateError: action.payload
            };

        case TABLE_DELETE:
            return {
                ...state,
                tableUpdating: true,
                tableUpdateError: null
            };
        case TABLE_DELETE_SUCCESS:
            return {
                ...state,
                tableUpdating: false,
                tableUpdateError: null
            };
        case TABLE_DELETE_ERROR:
            return {
                ...state,
                tableUpdating: false,
                tableUpdateError: action.payload
            };

        default:
            return state;
    }
}
