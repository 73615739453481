import axios from 'axios';
import { companyDataEmpty, parseTeam } from '../models/company/company';
import { parseGeography } from '../models/geography';
import { parseSocial } from '../models/social';
import { cloneDeep } from 'lodash';
import { authHeader } from '../auth/authUtils';
import axiosApp, { BASE_URL } from '../helpers/axiosConfig';
import FormData from 'form-data';
import { docObj, investmentObj, mentionObj } from '../data/tableObjects';
import { getRandomId } from '../helpers/DBhelper';
import { uploadFile } from '../helpers/UploaderDoc/uploader';
import { isToIncrement } from '../helpers/Roles/claimUtils';
import { USERS } from '../helpers/Roles/roleActions';
import { enrichObjFromIds } from '../helpers/idsArrayHelper';

const qs = require('qs');
const merge = require('deepmerge');

/** -- Overview preview -- **/
export const COMPANIES_PREVIEW_FETCH = 'companies_preview_fetch';
export const COMPANIES_PREVIEW_FETCH_SUCCESS = 'companies_preview_fetch_success';
export const COMPANIES_PREVIEW_FETCH_ERROR = 'companies_preview_fetch_error';

/** -- Overview -- **/
export const COMPANY_FETCH = 'company_fetch';
export const COMPANY_FETCH_SUCCESS = 'company_fetch_success';
export const COMPANY_FETCH_ERROR = 'company_fetch_error';

export const COMPANY_DATA_FETCH = 'company_data_fetch';
export const COMPANY_DATA_FETCH_SUCCESS = 'company_data_fetch_success';
export const COMPANY_DATA_FETCH_ERROR = 'company_data_fetch_error';

export const COMPANY_SOCIAL_FETCH = 'company_social_fetch';
export const COMPANY_SOCIAL_FETCH_SUCCESS = 'company_social_fetch_success';
export const COMPANY_SOCIAL_FETCH_ERROR = 'company_social_fetch_error';

export const COMPANY_GEOGRAPHY_FETCH = 'company_geography_fetch';
export const COMPANY_GEOGRAPHY_FETCH_SUCCESS = 'company_geography_fetch_success';
export const COMPANY_GEOGRAPHY_FETCH_ERROR = 'company_geography_fetch_error';

export const COMPANY_FINANCIAL_FETCH = 'company_financial_fetch';
export const COMPANY_FINANCIAL_FETCH_SUCCESS = 'company_financial_fetch_success';
export const COMPANY_FINANCIAL_FETCH_ERROR = 'company_financial_fetch_error';

export const COMPANY_TEAM_FETCH = 'company_team_fetch';
export const COMPANY_TEAM_FETCH_SUCCESS = 'company_team_fetch_success';
export const COMPANY_TEAM_FETCH_ERROR = 'company_team_fetch_error';

export const COMPANY_DOCS_FETCH = 'company_docs_fetch';
export const COMPANY_DOCS_FETCH_SUCCESS = 'company_docs_fetch_success';
export const COMPANY_DOCS_FETCH_ERROR = 'company_docs_fetch_error';

export const COMPANY_MENTIONS_FETCH = 'company_mentions_fetch';
export const COMPANY_MENTIONS_FETCH_SUCCESS = 'company_mentions_fetch_success';
export const COMPANY_MENTIONS_FETCH_ERROR = 'company_mentions_fetch_error';

export const PARTNERSHIPS_FETCH = 'partnerships_fetch';
export const PARTNERSHIPS_FETCH_SUCCESS = 'partnerships_fetch_success';
export const PARTNERSHIPS_FETCH_ERROR = 'partnerships_fetch_error';

export const ACCELERATORS_FETCH = 'accelerators_fetch';
export const ACCELERATORS_FETCH_SUCCESS = 'accelerators_fetch_success';
export const ACCELERATORS_FETCH_ERROR = 'accelerators_fetch_error';

export const PORTFOLIOS_FETCH = 'portfolios_fetch';
export const PORTFOLIOS_FETCH_SUCCESS = 'portfolios_fetch_success';
export const PORTFOLIOS_FETCH_ERROR = 'portfolios_fetch_error';

export const SIMILAR_COMPANIES_FETCH = 'similar_companies_fetch';
export const SIMILAR_COMPANIES_FETCH_SUCCESS = 'similar_companies_fetch_success';
export const SIMILAR_COMPANIES_FETCH_ERROR = 'similar_companies_fetch_error';

export const COMPANY_UPDATE = 'company_update';
export const COMPANY_UPDATE_SUCCESS = 'company_update_success';
export const COMPANY_UPDATE_ERROR = 'company_update_error';

export const COMPANY_CREATE = 'company_create';
export const COMPANY_CREATE_SUCCESS = 'company_create_success';
export const COMPANY_CREATE_ERROR = 'company_create_error';

export const CLEAN_COMPANY = 'clean_company';

export const UPDATE_COMPANY_STATUS = 'update_company_status';
export const UPDATE_COMPANY_STATUS_SUCCESS = 'update_company_status_success';
export const UPDATE_COMPANY_STATUS_ERROR = 'update_company_status_error';

export const DELETE_COMPANY = 'delete_company';
export const DELETE_COMPANY_SUCCESS = 'delete_company_success';
export const DELETE_COMPANY_ERROR = 'delete_company_error';

export const ADD_PERSONA_TO_TEAM = 'add_persona_to_team';
export const REMOVE_PERSONA_TO_TEAM = 'remove_persona_to_team';

export const ADD_FILE_TO_DOCS = 'add_file_to_docs';
export const REMOVE_FILE_TO_DOCS = 'remove_file_to_docs';
export const UPDATE_FILE_TO_DOCS = 'update_file_to_docs';
export const UPLOAD_FILE = 'upload_file';
export const UPLOADING_FILE = 'uploading_file';
export const UPLOAD_FILE_ERROR = 'upload_file_error';

export const ADD_MENTION = 'add_mention';
export const REMOVE_MENTION = 'remove_mention';
export const UPDATE_MENTION = 'update_mention';

export const ADD_COMPANY_TO_PARTNERSHIP = 'add_company_to_partnership';
export const REMOVE_COMPANY_TO_PARTNERSHIP = 'remove_company_to_partnership';

export const ADD_COMPANY_TO_ACCELERATORS = 'add_company_to_accelerators';
export const REMOVE_COMPANY_TO_ACCELERATORS = 'remove_company_to_accelerators';

export const ADD_COMPANY_TO_PORTFOLIO = 'add_company_to_portfolio';
export const REMOVE_COMPANY_TO_PORTFOLIO = 'remove_company_to_portfolio';

export const REMOVE_COMPANY_URL = 'remove_company_url';


// ---------------------------------
export const ADD_INVESTMENT = 'add_investment';
export const REMOVE_INVESTMENT = 'remove_investment';
export const UPDATE_INVESTMENT = 'update_investment';

export const COMPANY_INVESTMENTS_FETCH = 'company_investments_fetch';
export const COMPANY_INVESTMENTS_FETCH_SUCCESS = 'company_investments_fetch_success';
export const COMPANY_INVESTMENTS_FETCH_ERROR = 'company_investments_fetch_error';
// ---------------------------------

export const cleanCompany = () => {
    return dispatch => {
        dispatch({ type: CLEAN_COMPANY });
    };
};

export const removeCompanyUrl = () => {
    return dispatch => {
        dispatch({ type: REMOVE_COMPANY_URL });
    };
};

export const fetchCompaniesPreview = (page, pageSize, sorting, filterData, myUser) => {
    let filterFields = qs.stringify(filterData);
    let params = {
        page: page,
        pageSize: pageSize,
        columnSorting: sorting.column,
        desc: sorting.desc,
        filterFields: filterFields,
        role: myUser.role
    };

    return dispatch => {
        dispatch({ type: COMPANIES_PREVIEW_FETCH });
        axios.get(BASE_URL + '/company/companies-preview/', {
            params, headers: authHeader(myUser)
        })
            .then(res => {
                dispatch({
                    type: COMPANIES_PREVIEW_FETCH_SUCCESS,
                    payload: {
                        rows: res.data.rows,
                        numRows: res.data.numRows
                    }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: COMPANIES_PREVIEW_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchCompanyData = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_DATA_FETCH });
        axios.get(BASE_URL + '/company', {
            params: {
                company_id: company_id,
                incrementCounter: isToIncrement(myUser)
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const companyData = cloneDeep(companyDataEmpty);
                companyData['company'] = res.data.companyData.company[0];

                companyData['company'] = enrichObjFromIds(companyData['company']);

                if (!companyData['company'].category) {
                    companyData['company'].category = 'startup';
                }

                if (res.data.companyData.types && res.data.companyData.types[0]) {
                    companyData['types'] = res.data.companyData.types;
                }

                if (res.data.companyData.stagesOfInterest && res.data.companyData.stagesOfInterest[0]) {
                    companyData['stagesOfInterest'] = res.data.companyData.stagesOfInterest;
                }

                if (res.data.companyData.sectors[0]) {
                    companyData['sectors'] = res.data.companyData.sectors;
                }
                if (res.data.companyData.sub_sectors[0]) {
                    companyData['sub_sectors'] = res.data.companyData.sub_sectors;
                }
                if (res.data.companyData.technologies[0]) {
                    companyData['technologies'] = res.data.companyData.technologies;
                }
                if (res.data.companyData.countries[0]) {
                    companyData['countries'] = res.data.companyData.countries;
                }
                if (res.data.companyData.sub_states[0]) {
                    companyData['sub_states'] = res.data.companyData.sub_states;
                }
                if (res.data.companyData.SDGs[0]) {
                    companyData['SDGs'] = res.data.companyData.SDGs;
                }

                if (res.data.companyData.impact[0]) {
                    companyData['impact'] = res.data.companyData.impact[0];
                }
                if (res.data.companyData.categorySpec) {
                    companyData['categorySpec'][`${companyData['company'].category.toLowerCase()}`] =
                        res.data.companyData.categorySpec[`${companyData['company'].category.toLowerCase()}`];
                }

                dispatch({
                    type: COMPANY_DATA_FETCH_SUCCESS,
                    payload: companyData
                });
                dispatch(fetchGeography(companyData.company.geography_id, myUser));
                dispatch(fetchSocial(companyData.company.social_id, myUser));
                dispatch(fetchTeam(company_id, myUser));
                dispatch(fetchInvestments(company_id, companyData.company.category, myUser));
                dispatch(fetchDocuments(company_id, myUser));
                dispatch(fetchMentions(company_id, myUser));
                dispatch(fetchPartnerships(company_id, myUser));
                dispatch(fetchAccelerators(company_id, myUser)); // TODO
                dispatch(fetchPortfolios(company_id, myUser));
                dispatch(fetchSimilarCompanies(company_id, companyData.company.geography_id, myUser));
                dispatch(fetchFinancialMetrics(company_id, myUser));
            })
            .catch(err => {
                dispatch({
                    type: COMPANY_DATA_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchCompanyDataEmpty = () => {
    return dispatch => {
        const dataEmpty = cloneDeep(companyDataEmpty);
        if (!dataEmpty['company'].category) {
            dataEmpty['company'].category = 'startup';
        }
        dispatch({
            type: COMPANY_DATA_FETCH_SUCCESS,
            payload: dataEmpty
        });
    };
};

export const fetchGeography = (geography_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_GEOGRAPHY_FETCH });
        axios.get(BASE_URL + '/geography', {
            params: {
                geography_id: geography_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const companyGeography = parseGeography(res.data);
                dispatch({
                    type: COMPANY_GEOGRAPHY_FETCH_SUCCESS,
                    payload: companyGeography
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: COMPANY_GEOGRAPHY_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchSocial = (social_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_SOCIAL_FETCH });
        axios.get(BASE_URL + '/social', {
            params: {
                social_id: social_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const companySocial = parseSocial(res.data);
                dispatch({
                    type: COMPANY_SOCIAL_FETCH_SUCCESS,
                    payload: companySocial
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: COMPANY_SOCIAL_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchFinancialMetrics = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_FINANCIAL_FETCH });
        axios.get(BASE_URL + '/company/financials', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                let financials = [];
                if (res.data && res.data.financials) {
                    financials = res.data.financials;
                }
                dispatch({
                    type: COMPANY_FINANCIAL_FETCH_SUCCESS,
                    payload: financials
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: COMPANY_FINANCIAL_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchInvestments = (company_id, category, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_INVESTMENTS_FETCH });
        axios.get(BASE_URL + '/company/investments', {
            params: {
                company_id: company_id,
                category: category
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const companyInvestments = res.data;
                let numInvestors = 0;
                for (let investment of companyInvestments) {
                    numInvestors = 0;
                    if (investment.investors) {
                        for (let investor of investment.investors) {
                            if (investor !== null) {
                                numInvestors += 1;
                            }
                        }
                    }
                    if (numInvestors === 0) {
                        investment.investors = [];
                    }
                }

                dispatch({
                    type: COMPANY_INVESTMENTS_FETCH_SUCCESS,
                    payload: companyInvestments
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: COMPANY_INVESTMENTS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchTeam = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_TEAM_FETCH });
        axios.get(BASE_URL + '/company/team', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const companyTeam = parseTeam(res.data);
                dispatch({
                    type: COMPANY_TEAM_FETCH_SUCCESS,
                    payload: companyTeam
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: COMPANY_TEAM_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchDocuments = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_DOCS_FETCH });
        axios.get(BASE_URL + '/company/documents', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const documents = res.data;
                dispatch({
                    type: COMPANY_DOCS_FETCH_SUCCESS,
                    payload: documents
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: COMPANY_DOCS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchMentions = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_MENTIONS_FETCH });
        axios.get(BASE_URL + '/company/mentions', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const mentions = res.data;
                dispatch({
                    type: COMPANY_MENTIONS_FETCH_SUCCESS,
                    payload: mentions
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: COMPANY_MENTIONS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchPartnerships = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: PARTNERSHIPS_FETCH });
        axios.get(BASE_URL + '/company/partnerships', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const partnerships = res.data;
                dispatch({
                    type: PARTNERSHIPS_FETCH_SUCCESS,
                    payload: partnerships
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: PARTNERSHIPS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchAccelerators = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: ACCELERATORS_FETCH });
        axios.get(BASE_URL + '/company/acceleration', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const accelerators = res.data;
                dispatch({
                    type: ACCELERATORS_FETCH_SUCCESS,
                    payload: accelerators
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: ACCELERATORS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchPortfolios = (company_id, myUser) => {
    return dispatch => {
        dispatch({ type: PORTFOLIOS_FETCH });
        axios.get(BASE_URL + '/company/portfolios', {
            params: {
                company_id: company_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const portfolios = res.data;
                dispatch({
                    type: PORTFOLIOS_FETCH_SUCCESS,
                    payload: portfolios
                });
            }).catch(err => {
            // console.error(err);
            dispatch({
                type: PORTFOLIOS_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchSimilarCompanies = (company_id, geography_id, myUser) => {
    return dispatch => {
        dispatch({ type: SIMILAR_COMPANIES_FETCH });
        axios.get(BASE_URL + '/company/similarcompanies', {
            params: {
                company_id: company_id,
                geography_id: geography_id
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const similarCompanies = res.data;
                dispatch({
                    type: SIMILAR_COMPANIES_FETCH_SUCCESS,
                    payload: similarCompanies
                });
            }).catch(err => {
            // console.error(err);
            dispatch({
                type: SIMILAR_COMPANIES_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const updateCompany = (companyData, selectedFile, companyValues, team, investments, partnerships, accelerators, portfolios, documents, mentions, company_id, myUser) => {
    return dispatch => {
        dispatch({ type: COMPANY_UPDATE });

        // console.log('companyData', companyData);
        // console.log('companyValues', companyValues);

        const mergedCompanyData = merge(companyData, companyValues);
        mergedCompanyData.sectors = companyValues.sectors;
        mergedCompanyData.sub_sectors = companyValues.sub_sectors;
        mergedCompanyData.technologies = companyValues.technologies;
        mergedCompanyData.countries = companyValues.countries;
        mergedCompanyData.sub_states = companyValues.sub_states;
        mergedCompanyData.SDGs = companyValues.SDGs;
        mergedCompanyData.types = companyValues.types;
        mergedCompanyData.stagesOfInterest = companyValues.stagesOfInterest;

        mergedCompanyData.key_milestones_ids = companyValues.key_milestones_ids;
        mergedCompanyData.looking_acceleration_ids = companyValues.looking_acceleration_ids;
        mergedCompanyData.ext_capital_sought_types = companyValues.ext_capital_sought_types;
        mergedCompanyData.investment_stages_ids = companyValues.investment_stages_ids;

        // console.log('companyData merged', mergedCompanyData);

        try {
            if (selectedFile) {
                uploadAvatar(selectedFile, myUser, (avatarUrl) => {
                    if (avatarUrl) {
                        mergedCompanyData.company.avatar_url = avatarUrl;
                    }
                    updateCompanyData(mergedCompanyData, team, investments, partnerships, accelerators, portfolios, documents, mentions, company_id, myUser, () => {
                        if (companyValues.geography.hq_country && companyValues.geography.hq_country.name) {
                            mergedCompanyData.geography.hq_country = companyValues.geography.hq_country.name;
                        }
                        dispatch({
                            type: COMPANY_UPDATE_SUCCESS,
                            payload: {
                                companyUpdated: mergedCompanyData
                            }
                        });
                    });
                });
            } else {
                updateCompanyData(mergedCompanyData, team, investments, partnerships, accelerators, portfolios, documents, mentions, company_id, myUser, () => {
                    if (companyValues.geography.hq_country && companyValues.geography.hq_country.name) {
                        mergedCompanyData.geography.hq_country = companyValues.geography.hq_country.name;
                    }

                    dispatch({
                        type: COMPANY_UPDATE_SUCCESS,
                        payload: {
                            companyUpdated: mergedCompanyData
                        }
                    });
                });
            }
        } catch (e) {
            dispatch({
                type: COMPANY_UPDATE_ERROR
            });
        }
    };
};

export const uploadAvatar = (selectedFile, myUser, callback) => {
    let formData = new FormData();
    formData.append('selectedFile', selectedFile);
    axios.post(BASE_URL + '/avatar/upload',
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + myUser.user_token
            }
        }
    )
        .then(res => {
            let avatarUrl = null;
            if (res.data) {
                avatarUrl = res.data.avatar_location;
            }
            return callback(avatarUrl);
        })
        .catch(err => {
            // console.log(err);
            return callback();
        });
};

export const updateCompanyData = (mergedCompanyData, team, investments, partnerships, accelerators, portfolios, documents, mentions, company_id, myUser, callback) => {
    axios.post(BASE_URL + '/company/update',
        {
            companyData: mergedCompanyData,
            team,
            investments,
            company_id,
            partnerships,
            accelerators,
            portfolios,
            documents,
            mentions
        },
        { headers: authHeader(myUser) }
    )
        .then(res => {
            return callback();
        })
        .catch(err => {
            // console.log(err);
            return callback();
        });
};

export const createCompanyData = (data, myUser, success, error) => {
    axios.put(BASE_URL + '/company/create', data, { headers: authHeader(myUser) })
        .then(res => {
            // console.log('SUCCESS CREATED');
            return success();
        })
        .catch(err => {
            // console.log(err);
            // console.log('ERROR CREATED');
            return error();
        });
};


export const createCompany = (companyData, selectedFile, companyValues, team, investments, partnerships, accelerators, portfolios, documents, mentions, myUser) => {
    const mergedCompanyData = merge(companyData, companyValues);

    return dispatch => {
        dispatch({ type: COMPANY_CREATE });
        try {
            let data = null;
            if (selectedFile) {
                uploadAvatar(selectedFile, myUser, (avatarUrl) => {
                    if (avatarUrl) {
                        mergedCompanyData.company.avatar_url = avatarUrl;
                    }
                    data = {
                        companyData: mergedCompanyData,
                        team: team,
                        investments: investments,
                        partnerships: partnerships,
                        accelerators: accelerators,
                        portfolios: portfolios,
                        documents: documents,
                        mentions: mentions,
                        user: myUser
                    };
                    createCompanyData(data, myUser, () => {
                        dispatch({
                            type: COMPANY_CREATE_SUCCESS
                        });
                        sendEmailCrowdAdded(myUser, mergedCompanyData.company);
                    }, () => {
                        dispatch({
                            type: COMPANY_CREATE_ERROR
                        });
                    });
                });
            } else {
                data = {
                    companyData: mergedCompanyData,
                    team: team,
                    investments: investments,
                    partnerships: partnerships,
                    portfolios: portfolios,
                    documents: documents,
                    mentions: mentions,
                    user: myUser
                };
                createCompanyData(data, myUser, () => {
                    dispatch({
                        type: COMPANY_CREATE_SUCCESS
                    });
                    sendEmailCrowdAdded(myUser, mergedCompanyData.company);
                }, () => {
                    dispatch({
                        type: COMPANY_CREATE_ERROR
                    });
                });
            }
        } catch (err) {
            dispatch({
                type: COMPANY_CREATE_ERROR
            });
        }
    };
};

export const sendEmailCrowdAdded = (myUser, companyData) => {

    if (USERS.includes(myUser.role)) {
        let userEmail = myUser.email;
        let subject = 'New Company added';
        let finalText = userEmail + ' has just created the ' + companyData.category + ' ' + companyData.name + '.';

        const corsProxy = 'https://cors-anywhere.herokuapp.com/';
        fetch(corsProxy + 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/contactusmailer', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email_sender: userEmail,
                subject: subject,
                text: finalText
            })
        }).then(() => {
        }).catch(
        );
    }

};

export const deleteCompany = (company_ids, myUser) => {
    return dispatch => {
        dispatch({ type: DELETE_COMPANY });
        const data = { company_ids };
        axios.put(BASE_URL + '/company/delete', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: DELETE_COMPANY_SUCCESS,
                    payload: { company_ids }
                });
            })
            .catch(err => {
                dispatch({
                    type: DELETE_COMPANY_ERROR,
                    payload: err
                });
            });
    };
};

export const updateCompanyStatus = (company_id, newStatus, myUser) => {
    return dispatch => {
        dispatch({ type: UPDATE_COMPANY_STATUS });
        const data = { company_id, newStatus };
        axios.put(BASE_URL + '/company/update-status', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: UPDATE_COMPANY_STATUS_SUCCESS,
                    payload: { company_id, newStatus }
                });
            })
            .catch(err => {
                // console.log(err);
                dispatch({
                    type: UPDATE_COMPANY_STATUS_ERROR,
                    payload: err
                });
            });
    };
};

export const addPersonaToTeam = (user) => {
    return dispatch => {
        dispatch({
            type: ADD_PERSONA_TO_TEAM,
            payload: user
        });
    };
};

export const addFileToDocuments = (file) => {
    let newDoc = cloneDeep(docObj);
    newDoc.name = file.name;
    newDoc.doc_id = getRandomId();
    newDoc.size = file.size;
    newDoc['file'] = file;
    newDoc.type = file.type;
    newDoc.extension = file.name.split('.').pop();
    return dispatch => {
        dispatch({
            type: ADD_FILE_TO_DOCS,
            payload: newDoc
        });
    };
};

// Deal ---------------------------------------------------------------------
export const addEmptyInvestment = () => {
    let newInvestment = cloneDeep(investmentObj);
    newInvestment.investment_id = getRandomId();
    return dispatch => {
        dispatch({
            type: ADD_INVESTMENT,
            payload: newInvestment
        });
    };
};

export const removeInvestment = (investment) => {
    return dispatch => {
        dispatch({
            type: REMOVE_INVESTMENT,
            payload: investment.investment_id
        });
    };
};

export const updateInvestment = (investment) => {
    return dispatch => {
        dispatch({
            type: UPDATE_INVESTMENT,
            payload: investment
        });
    };
};
// -----------------------------------------------------------------------------

// Mention ---------------------------------------------------------------------
export const addEmptyMention = () => {
    let newMention = cloneDeep(mentionObj);
    newMention.mention_id = getRandomId();
    return dispatch => {
        dispatch({
            type: ADD_MENTION,
            payload: newMention
        });
    };
};

export const removeMention = (mention) => {
    return dispatch => {
        dispatch({
            type: REMOVE_MENTION,
            payload: mention.mention_id
        });
    };
};

export const updateMention = (url, mention) => {
    return dispatch => {
        dispatch({
            type: UPDATE_MENTION,
            payload: {
                url: url,
                mention_id: mention.mention_id
            }
        });
    };
};
// -----------------------------------------------------------------------------

export const removeFileToDocuments = (doc) => {
    return dispatch => {
        dispatch({
            type: REMOVE_FILE_TO_DOCS,
            payload: doc.doc_id
        });
    };
};

export const uploadDocument = (doc, company_id, user) => {
    return dispatch => {
        dispatch({
            type: UPLOADING_FILE,
            payload: doc.doc_id
        });
        uploadFile(doc, company_id, user, (result) => {
            if (result === 'error') {
                dispatch({
                    type: UPLOAD_FILE_ERROR,
                    payload: doc.doc_id
                });
            } else {
                const data = result.data;
                dispatch({
                    type: UPDATE_FILE_TO_DOCS,
                    payload: {
                        doc_id: doc.doc_id,
                        location: data.file_location,
                        key: data.key
                    }
                });
            }
        });
    };
};

export const removePersonaToTeam = (user) => {
    return dispatch => {
        dispatch({
            type: REMOVE_PERSONA_TO_TEAM,
            payload: user
        });
    };
};

export const addCompanyToPartnerships = (company) => {
    return dispatch => {
        dispatch({
            type: ADD_COMPANY_TO_PARTNERSHIP,
            payload: company
        });
    };
};

export const removeCompanyToPartnerships = (user) => {
    return dispatch => {
        dispatch({
            type: REMOVE_COMPANY_TO_PARTNERSHIP,
            payload: user
        });
    };
};

export const addCompanyToAccelerators = (company) => {
    return dispatch => {
        dispatch({
            type: ADD_COMPANY_TO_ACCELERATORS,
            payload: company
        });
    };
};

export const removeCompanyToAccelerators = (user) => {
    return dispatch => {
        dispatch({
            type: REMOVE_COMPANY_TO_ACCELERATORS,
            payload: user
        });
    };
};

export const addCompanyToPortfolios = (company) => {
    return dispatch => {
        dispatch({
            type: ADD_COMPANY_TO_PORTFOLIO,
            payload: company
        });
    };
};

export const removeCompanyToPortfolios = (user) => {
    return dispatch => {
        dispatch({
            type: REMOVE_COMPANY_TO_PORTFOLIO,
            payload: user
        });
    };
};

