import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import { actionTypes } from '../../../helpers/actionTypes';
import styles from '../styles';
import ActionsBox from '../../ActionsBox';
import 'react-image-lightbox/style.css';
import AvatarNameCell from '../AvatarNameCell';

class SDGRow extends Component {
    onClickName = () => {
        history.push({
            pathname: '/sdg/' + actionTypes.EDIT + '/' + this.props.row.sdg_id
        });
    };

    render() {
        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            myUser,
            activeSelection,
            onClickDelete
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={event => onClickCheckbox(event, row.idrow)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={this.onClickName}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.img_url}
                        name={row.name}
                    />
                </TableCell>
                <TableCell align={'right'}>
                    <ActionsBox
                        disabled={activeSelection}
                        // onEdit={role ? this.onClickName : null}
                        // onDelete={(role && role === 'admin') ? () => onClickDelete(row) : null}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(SDGRow);
