import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import history from '../../config/history';
import { actionTypes } from '../../helpers/actionTypes';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { addPersonaToTeam, removePersonaToTeam } from '../../actions/companyHandler';
import { disablePersonasOfActualTeam } from '../../helpers/Validation/fieldForm';
import CompanyTeamForm from '../../components/Company/CompanyTeamForm';
import SearchPersonaDialog from '../../components/SearchPersonaDialog';
import { BASE_URL } from '../../helpers/axiosConfig';
import { authHeader } from '../../auth/authUtils';

class TeamCompanyTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogAddPersonaVisible: false,
            personaQuery: '',
            peopleResult: [],
            searchingPeople: false,
            personaSelected: null
        };
    }

    getPeople = () => {
        axios.get(BASE_URL + '/persona/search', {
            params: {
                pattern: this.state.personaQuery
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updatePeopleResult(data.people);
            });
    };

    updatePeopleResult = (people) => {
        let peopleResult = cloneDeep(people);
        disablePersonasOfActualTeam(peopleResult, this.props.companyTeam);

        this.setState({
            peopleResult: peopleResult,
            searchingPeople: false
        });
    };

    handleInputPersonaChange = (e) => {
        this.setState({
            personaQuery: e.target.value
        }, () => {
            if (this.state.personaQuery && this.state.personaQuery.length > 0) { // TODO increment
                //if (this.state.personaQuery.length % 2 === 0) {
                this.setState({
                        searchingPeople: true
                    }, () => this.getPeople()
                );
                //}
            }
        });
    };

    handleClickPersona(persona) {
        if(this.props.formDisabled) {
            history.push({
                pathname: '/persona/' + actionTypes.SHOW + '/' + persona.persona_id
            });
        } else {
            history.push({
                pathname: '/persona/' + actionTypes.EDIT + '/' + persona.persona_id
            });
        }

    };

    handleClickRemovePersona(persona) {
        this.props.removePersonaToTeam(persona);
    };

    openDialogSearchPersona = () => {
        this.setState({
            dialogAddPersonaVisible: true
        });
    };

    closeDialogSearchPersona = () => {
        this.setState({
            dialogAddPersonaVisible: false,
            personaSelected: null
        });
    };

    selectPersona = (persona) => {
        this.setState({
            personaSelected: persona
        });
    };

    addPersona = () => {
        if (this.state.personaSelected) {
            this.props.addPersonaToTeam(this.state.personaSelected);
            this.setState({
                dialogAddPersonaVisible: false,
                personaSelected: null,
                personaQuery: '',
                peopleResult: []
            });
        }
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        <CompanyTeamForm
                            formDisabled={this.props.formDisabled}
                            openDialogSearchPersona={this.openDialogSearchPersona.bind(this)}
                            companyTeam={this.props.companyTeam}
                            handleClickPersona={this.handleClickPersona.bind(this)}
                            handleClickRemovePersona={this.handleClickRemovePersona.bind(this)}
                            myUser={this.props.myUser}
                        />
                    </Grid>
                </Grid>
                <SearchPersonaDialog
                    open={this.state.dialogAddPersonaVisible}
                    selectPersona={this.selectPersona}
                    closeDialogSearchPersona={this.closeDialogSearchPersona.bind(this)}
                    peopleResult={this.state.peopleResult}
                    searchingPeople={this.state.searchingPeople}
                    personaSelected={this.state.personaSelected}
                    handleInputPersonaChange={this.handleInputPersonaChange.bind(this)}
                    addPersona={this.addPersona.bind(this)}
                />
            </div>
        );
    }
}

const mapDispatchToProps = {
    addPersonaToTeam,
    removePersonaToTeam
};

const mapStateToProps = (state) => {
    return {
        companyTeam: state.companyHandler.companyTeam,
        fetchingCompanyTeam: state.companyHandler.fetchingCompanyTeam,
        companyTeamError: state.companyHandler.companyTeamError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCompanyTab);
