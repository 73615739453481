import React, { Component } from 'react';
import CompanyFinancialForm from '../../components/Company/CompanyFinancialForm';
import { actionTypes } from '../../helpers/actionTypes';
import { Grid } from '@material-ui/core';
import InvestorFinancialForm from '../../components/Company/InvestorFinancialForm';
import OPSForm from '../../components/Company/OPSForm';
import Spacer from '../../components/Spacer/Spacer';
import DealsForm from '../../components/Company/DealsForm';
import { addEmptyInvestment, removeInvestment, updateInvestment } from '../../actions/companyHandler';
import { connect } from 'react-redux';
import DealsTable from '../../components/Company/DealsForm/DealsTable';

const yearList = [2020, 2019, 2018, 2017];

const dateList = [2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2013, 2012, 2011, 2010, 2009];
const quarterList = ['Q1', 'Q2', 'Q3', 'Q4'];

class FinancialCompanyTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearSelected: 2020,
            yearList: [],
            actionMode: actionTypes.EDIT,
            dateSelected: 2020
        };
    }

    setYear = (year) => {
        this.setState({
            yearSelected: year
        });
    };

    handleChangeYear = event => {
        const year = event.target.value;
        this.setYear(year);
    };

    setDate = (date) => {
        this.setState({
            dateSelected: date
        });
    };

    handleChangeDate = event => {
        const date = event.target.value;
        this.setDate(date);
    };

    render() {
        return (
            <div>
                <fieldset disabled={this.props.formDisabled} style={{ border: 'none' }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        {this.props.formikProps.values.company.category.toLowerCase() === 'investor' ?
                            <div>
                                <Grid item xs={12} lg={9}>
                                    <InvestorFinancialForm
                                        formik={this.props.formikProps}
                                        numInvestments={this.props.numInvestments}
                                        editable={this.props.formDisabled}
                                    />
                                </Grid>
                                <Spacer/>
                                <Grid item xs={12} lg={9}>
                                    {/*<DealsForm*/}
                                    {/*    formik={this.props.formikProps}*/}
                                    {/*    onAddInvestment={this.props.addEmptyInvestment}*/}
                                    {/*    handleClickRemoveInvestment={this.props.removeInvestment}*/}
                                    {/*    handleSaveInvestment={this.props.updateInvestment}*/}
                                    {/*    myUser={this.props.myUser}*/}
                                    {/*    actionMode={this.props.actionMode}*/}
                                    {/*    companyInvestments={this.props.companyInvestments}*/}
                                    {/*    formDisabled={this.props.formDisabled}*/}
                                    {/*    addCompanyToInvestors={this.props.updateInvestment}*/}
                                    {/*    isMyCompany={this.props.isMyCompany}*/}
                                    {/*    removeInvestedCompany={this.props.removeInvestedCompany}*/}
                                    {/*/>*/}
                                    <DealsTable
                                        myUser={this.props.myUser}
                                        deals={this.props.companyInvestments}
                                    />
                                </Grid>
                            </div>
                            : null
                        }
                        {this.state.yearSelected && this.props.formikProps.values.company.category.toLowerCase() === 'startup' ?
                            <Grid item xs={12} lg={9}>
                                <CompanyFinancialForm
                                    handleChangeYear={this.handleChangeYear}
                                    yearSelected={this.state.yearSelected}
                                    yearList={yearList}
                                    formik={this.props.formikProps}
                                />
                                <Spacer/>
                                <OPSForm
                                    handleChangeDate={this.handleChangeDate}
                                    dateSelected={this.state.dateSelected}
                                    dateList={dateList}
                                    quarterList={quarterList}
                                    formik={this.props.formikProps}
                                />
                            </Grid>
                            : null
                        }

                    </Grid>
                </fieldset>
            </div>
        );
    }
}

const mapDispatchToProps = {
    addEmptyInvestment,
    removeInvestment,
    updateInvestment
};

const mapStateToProps = (state) => {
    return {
        companyInvestments: state.companyHandler.companyInvestments,
        fetchingCompanyInvestments: state.companyHandler.fetchingCompanyInvestments,
        companyInvestmentsError: state.companyHandler.companyInvestmentsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialCompanyTab);
