import {
    CLEAR_NOTIFICATION_BADGE,
    COMPANIES_FOLLOWED_FETCH,
    COMPANIES_FOLLOWED_FETCH_ERROR,
    COMPANIES_FOLLOWED_FETCH_SUCCESS, UPDATE_FOLLOW_STATUS, UPDATE_FOLLOW_STATUS_ERROR, UPDATE_FOLLOW_STATUS_SUCCESS
} from '../actions/followHandler';

const initialState = {
    companiesFollowed: [],
    fetchingCompaniesFollowed: false,
    companiesFollowedError: null,

    followStatusUpdating: null,
    followStatusUpdateError: null,

    notifications: [],
    notificationBadgeNumber: null
};


export default function followHandler(state = initialState, action) {
    switch (action.type) {

        case CLEAR_NOTIFICATION_BADGE:
            return {
                ...state,
                notificationBadgeNumber: null
            };

        case COMPANIES_FOLLOWED_FETCH:
            return {
                ...state,
                companiesFollowed: [],
                fetchingCompaniesFollowed: true,
                companiesFollowedError: null
            };
        case COMPANIES_FOLLOWED_FETCH_SUCCESS:
            const companiesFollowed = action.payload.companiesFollowed;
            const notifications = action.payload.notifications;

            let badgeNumber = null;
            for (let notification of notifications) {
                if (notification['update_seen'] !== true) {
                    badgeNumber += 1;
                }
            }
            return {
                ...state,
                companiesFollowed: companiesFollowed,
                fetchingCompaniesFollowed: false,
                notifications: notifications,
                notificationBadgeNumber: badgeNumber
            };
        case COMPANIES_FOLLOWED_FETCH_ERROR:
            return {
                ...state,
                fetchingCompaniesFollowed: false,
                companiesFollowedError: action.payload
            };

        case UPDATE_FOLLOW_STATUS:
            return {
                ...state,
                followStatusUpdating: true,
                followStatusUpdateError: null
            };
        case UPDATE_FOLLOW_STATUS_SUCCESS:
            return {
                ...state,
                followStatusUpdating: false,
                followStatusUpdateError: null,
                companiesFollowed: action.payload.companiesFollowed
            };
        case UPDATE_FOLLOW_STATUS_ERROR:
            return {
                ...state,
                followStatusUpdating: false,
                followStatusUpdateError: action.payload
            };

        default:
            return state;
    }
}
