import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
   return (
    <Dialog
      open={props.openDialog}
      onClose={props.clicked}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={typeof props.fullWidth !== 'undefined' ? props.fullWidth : true}
      maxWidth={typeof props.maxWidth !== 'undefined' ? props.maxWidth : 'sm'}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.clicked} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}