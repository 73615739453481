import React from 'react';
import { Typography } from '@material-ui/core';

export const IndentedElement = ({children, number}) => {
    const indentPosition = number.split(".").length;
    const marginToIndent = (indentPosition*20)+'px';
    return (
        <div style={{marginLeft: marginToIndent}}>
            <Typography
                variant="body1"
                style={{align: 'justify', display: 'flex'}}
            >
                <div style={{marginRight: 20}}>{number}</div><div>{children}</div>
            </Typography>
        </div>
    );
};
