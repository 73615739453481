import React, { useRef } from 'react';
import FilterFieldsOverview from './FilterFieldsOverview';
import { Formik } from 'formik';
import { Dashboard as DashboardLayout } from '../../layouts';
import FilterFormFloat from './FilterFormFloat';

const FilterFormBox = (props) => {

    const formRef = useRef();

    const [filterFloatOpen, setFilterFloatOpen] = React.useState(false);

    const {
        submit,
        sectors,
        subSectors,
        SDGs,
        handleClickExport,
        selectCompany,
        myUser,
        fromCompare = false,
        fromManageCompanies,
        initFields,
        fromDeals,
        subStates
    } = props;

    const onSubmitForm = (values, actions) => {
        // console.log("SUUUU", values)
        submit(values);
    };

    const onClickFilter = () => {
        setFilterFloatOpen(true);
    };

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={initFields ? {
                        category: initFields.category ? initFields.category : null,
                        countries: initFields.countries ? initFields.countries : [],
                        sub_states: initFields.sub_states ? initFields.sub_states : [],
                        region: initFields.region ? initFields.region : null,
                        business_model: initFields.business_model ? initFields.business_model : 'all',
                        foundation_year: initFields.foundation_year ? initFields.foundation_year : null,
                        sectors: initFields.sectors ? initFields.sectors : [],
                        sub_sectors: initFields.sub_sectors ? initFields.sub_sectors : [],
                        impacts: initFields.impacts ? initFields.impacts : [],
                        stages: initFields.stages ? initFields.stages : [],
                        SDGs: initFields.SDGs ? initFields.SDGs : [],
                        date_from: null,
                        date_to: null,

                        milestoneIds: [],
                        product_development: null,
                        ext_capital_sought_types: [],
                        // own_ip: null,
                    } :
                    {
                        category: fromCompare ? fromCompare.name : null,
                        // country: null,
                        countries: [],
                        region: null,
                        business_model: 'all',
                        foundation_year: null,
                        sectors: [],
                        sub_sectors: [],
                        impacts: [],
                        stages: [],
                        SDGs: [],
                        investorTypes: [],
                        hubTypes: [],
                        businessModels: [],
                        numPortfolios: null,
                        foundingAmount: null,
                        date_from: null,
                        date_to: null,

                        milestoneIds: [],
                        product_development: null,
                        ext_capital_sought_types: [],
                        // own_ip: null,
                    }
                }
                onSubmit={onSubmitForm}
            >
                {formikProps => (
                    <form id="filter-companies-form"
                          onSubmit={formikProps.handleSubmit}
                        // onChange={formikProps.handleSubmit}
                    >
                        <FilterFieldsOverview
                            // formDisabled={isFormDisabled(this.state.actionMode)}
                            formik={formikProps}
                            sectorsArray={sectors}
                            subSectorsArray={subSectors}
                            SDGsArray={SDGs}
                            onDataChange={formikProps.handleSubmit}
                            handleClickExport={handleClickExport}
                            selectCompany={selectCompany}
                            myUser={myUser}
                            fromCompare={fromCompare}
                            fromManageCompanies={fromManageCompanies}
                            onClickFilter={onClickFilter}
                            fromDeals={fromDeals}
                            subStates={subStates}
                        />
                        <FilterFormFloat
                            open={filterFloatOpen}
                            handleClose={() => setFilterFloatOpen(false)}
                            formik={formikProps}
                            onApplyClick={formikProps.handleSubmit}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default FilterFormBox;
