export default {
    "3D":1,
    "Agriculture":2,
    "AgTech":3,
    "Artificial Intelligence":4,
    "ArtTech":5,
    "Banking":6,
    "Big Data & Analytics":7,
    "BioTech":8,
    "Blockchain":9,
    "CivicTech":10,
    "CleanTech":11,
    "Construction":12,
    "Coworking":13,
    "Crowdsourcing":14,
    "Design":15,
    "Directory":16,
    "Disability":17,
    "Drones":18,
    "E-commerce":19,
    "EdTech":20,
    "Education":21,
    "Entertainment":22,
    "Events":23,
    "Fashion":24,
    "FemTech":25,
    "FinTech":26,
    "Food & Beverage":27,
    "FoodTech":28,
    "Gig Economy":29,
    "GovTech":30,
    "Health":31,
    // "Hospitality":32,
    "Human Resources":33,
    "Hydroponics":34,
    "Incubator":35,
    "Insurance":36,
    "Internet":37,
    "IoT":38,
    "Jobs":39,
    "LegalTech":40,
    "Logistics":41,
    "Manufacturing":42,
    "Marketing":43,
    "Media":44,
    "Mobility":45,
    "Music":46,
    "Professional Services":47,
    "Publishing":48,
    "Real Estate":49,
    "Recycling":50,
    "RegTech":51,
    "Renewables":52,
    "Research":53,
    "Robotics":54,
    "Sanitation":55,
    "Security":56,
    "Social Network":57,
    "Software":58,
    "Sport":59,
    "Travel":60,
    "Utilities":61,
    "Waste Management":62,
    "Water Access":63,
    "Space Technology": "663be760-20b2-4c5f-b093-0663e73c683c",
    "Recruitment": "bf73d1fa-bb40-44c0-bced-f50daad7715d",
    "HR Tech": "175c0cd9-7b9b-4d40-bbf3-e601109b2417",
    "SAM": "adcbf96b-b76a-4af5-94d4-60eb9caf2389",
    "Sleeptech": "f794de8c-34d0-4609-94aa-8f3c3557b08a",
    "Steve": "8c8a46ed-681c-4527-a6f0-40da55fc4054",
    "Eva Tech": "55cdf2b4-0d1d-48eb-9ada-3a340ace2249",
    "Deep Tech": "d0e71f71-91a7-40b9-8f6c-c69c38bd58ea",
    "Mining Tech": "1ddde4f8-5e28-4327-ba33-cbf36076c6a5",
    "PropTech": "19beb34c-2979-4ddb-b139-270ec8b8b911",
    "Hospitality": "2586d4e5-74bc-49e7-95e9-6b8c02680167",
    "Sector Agnostic": "6802a23d-7992-46ac-b03d-ee990197a448",
    "Digital": "d9681257-d48e-404f-b362-7c63661174dd",
    "Circular Economy": "64858a80-4a4e-417b-96da-f590236f82c0",
    "Green Economy": "e76c43a0-56bd-48e2-b3e6-b97fcdad7575",
    "Wildlife": "b34b993c-6adc-40a8-a1a1-930949f78ae0",
    "Cold Chain": "d26ef00f-8008-48da-9659-0026d97a731e",

    "Domestic Farming": "c805a44c-9e08-4c37-beeb-84f730c9362c",
    "Equipment Sharing": "c5a51f6c-aa4a-4e32-9f50-3f6a070028bb",
    "Fertilisers": "e0be25ff-371e-4abd-8222-a547e4912966",
    "Credit Scoring": "9433cfc9-cacc-43fe-a8ad-465cce2d3539",
    "Farm Management": "3e2e855b-812f-437b-9a59-555ae2134558",
    "Bio Material": "2aa7e39a-d8fe-4790-a32f-bf803e29d8b1",
    "Escrow": "fc198b8d-f238-4ea9-b19c-85e59365ebec",
    "Equipment": "999f3c27-09f9-4a83-aaf7-f76b5eace0b8",
    "Virology": "42fe1c1c-eb21-4635-a4ba-b62ed2ca33aa",
    "Coronavirus": "660892d3-cc1a-4e38-a24c-63333780fdb1",
    "Advisory Services": "665c85c8-cb2b-4a44-b159-e1dc6bf1b202",
    "COVID-19": "2c4c9b3f-87ce-4d46-9cf0-f0e875c30db7",
    "Commodity Exchange": "e5443f68-48f0-4978-b324-5b651ad6913c",
    "Disease Detection": "b496e188-97dd-4629-8306-ca1b06e5c938",
    "Aeroponics": "bdbd5724-1962-4bea-9b0b-c2ac58761d85",
    "E-wallet": "0896a236-cdda-43c2-9bc2-71c9541e75df",
    "Apiculture": "df8269cc-70fd-42ff-b9d4-7cf6bc1ad587",
    "Aquaculture": "99ec5b1c-5c38-4e2f-b048-22240e83097f",
    "Commodities Marketplace": "a9402c82-4b9d-45e6-8319-a338eda1e649",
    "Consulting": "2e22af73-ef57-4912-bcb7-2a5b27a60ad0",
    "Crowdfunding": "799cd562-fe04-43cf-9dad-8cc2405426ef",
    "Inputs": "376cb8be-751d-4ff4-83a9-983161e54ce3",
    "Geolocation": "9738aec7-d726-4c6e-aa19-2768ddaedbd5",
    "Informal Sector": "1ea9740c-91b5-44fa-838c-3ea1085af84b",
    "Fish Farming": "8c52af5d-4c6c-4c05-bb82-228bd8febea1",
    "Geospatial": "202b5bd6-6a42-4b3f-893d-058d02c35b14",
    "Financial Services": "adc0d6ed-b070-4a69-ac04-92f67fa7ff42",
    "Financial Inclusion": "1ca167dc-9cc3-4830-88b1-2ea3b4017ddc",
    "Food": "5f32e2d2-7cb6-49fb-94fe-a8c5586fe008",
    "Forecasting": "174b6b01-5e44-4f6a-9b6c-12543ee5bbce",
    "Gaming": "6c171faf-55bb-43d5-b9ed-5c131f38dc8b",
    "Hardware": "508a8c72-09af-4c83-8d8d-3816c9484f23",
    "Internet of Things": "1944aaa6-6d91-4d2e-8d71-6f2edcc77d77",
    "Investments": "8305ad8c-3384-456d-b81c-1f69ddc4ac5e",
    "Irrigation": "10341cdf-862c-46fc-b916-75d874407f6f",
    "Legal": "c2e2bb13-e405-4bbf-8003-6225774918ab",
    "Livestock": "9f407f8f-9a63-4f0b-ae2f-f2d1eccf53da",
    "Loans": "857cfd35-0a7a-4b68-b4ef-88c54403915f",
    "Management": "673216c2-650e-474b-b1b7-2646a83d69f8",
    "Market Access": "ff9e173b-b5a1-4ea6-94c6-4f8677249e92",
    "Market Information": "182f740e-cf2f-4bd1-b709-f51efede2475",
    "Market Linkages": "782f1926-fad4-412f-a7e7-6840aef6f7eb",
    "Marketplace for Agricultural Inputs": "d20ba3b1-d441-4f92-a7cb-c179f1fd9fa6",
    "Marketplace for Agricultural Produce": "581db159-7de1-487f-bf14-843c21ea97ec",
    "Mobile App": "a9fd4853-a313-4847-a96c-f47563834912",
    "Monitoring": "fcd31c2f-b002-4993-a79c-e02203624acd",
    "Network": "8b81c304-b404-42fe-ba45-1a6b212b5af8",
    "Organic Waste": "b6d972c2-a24b-4fe9-a290-d1a48f7ac062",
    "Payments": "a0176f2b-ab43-41ee-99ad-9139b5fd3581",
    "Peer to Peer": "4c702d4d-59a5-4554-a85b-cfc1d1bd4480",
    "Pest Detection": "ab7174af-7daa-4f56-8865-65503b9e6b60",
    "Precision Agriculture": "931930cd-3651-4e8b-bed2-d19515fd32e6",
    "Radio": "c9f6f30f-9d16-4857-a7be-c8d95eb23721",
    "Savings": "8690f26a-f208-4200-8061-c2614ac18fc5",
    "Seeds": "2cff559b-bb51-4400-8040-606087c6c985",
    "Services": "da2e824c-9995-4434-bc4c-dbc37bd7fbb0",
    "Smart Contracts": "5acc8d3a-046f-4feb-9f88-39d63ff0032d",
    "Supply Chain Management": "18d3183d-f7b0-44db-8b88-2d10a16785e3",
    "Topography": "8de5331e-97b8-4c81-afda-4cf4e3834b7c",
    "Training": "098dadce-63ed-49c3-90ac-522547934ced",
    "Urban Agriculture": "94bbd1cb-2928-413f-994a-e66afeb0b9da",
    "Vertical Farming": "55ba9fa2-76f1-442c-b10b-ccf10f5e21fe",
    "Wallet": "36dcc29a-9196-40fb-95a1-f3af66b0eab6",
    "Contract farming": "49afb686-257a-470c-a071-417f9461e3b0",
    "Social Media": "7a73efdb-24c6-4f6b-8bfc-bbbb07ae3f61",
    "SportTech": "cf6fd711-8437-4b32-8133-a7cfa5a98f21",
    "Surveys": "2d404851-daea-44ff-bdba-95910cf7ab52",
    "Weather Forecast": "797d3fee-1273-4f7d-9dad-6819b5785484",
    "Advice": "9b557f82-667c-475d-97e1-d98d23ded861",
    "Communications": "1c3ff914-c5c4-4563-a99e-43028a855598",
    "Emergency Services": "4400a9d0-693f-430f-b7a6-4b7de04b4238",
    "Biofuels": "ab018415-5b54-46d6-ae2e-2f4a38278eda",
    "Cooking": "2ce9c144-30c7-4c07-abb5-79ce938f1bef",
    "Forestry": "d18cf3d6-cd2e-4bc5-a46b-00b2c8c7e823",
    "Gas": "87d29e17-bc75-47d5-a5c6-48d3ff07c961",
    "Heating": "d6c51b05-dcea-4f5b-97c2-b4a874072250",
    "Hydropower": "56f5d27a-fbbe-4903-905c-3b210c82dcc6",
    "Pay As You Go": "25df6c23-50b6-4964-aaf2-1bd077058e4d",
    "Solar Energy": "d2de1ba8-61d8-4859-9e46-de0b119bc26e",
    "Solar Kiosk": "797cb1c0-96c5-4a2d-937f-1825d3fc62fa",

    "Sustainable Housing": "ce007945-24a5-47ca-98f4-99d8278e69aa",
    "GIS": "5be5cd5e-c274-453f-9961-a588a635ea32",
    "Remote Sensing": "0b91ff4a-237c-4faa-87f9-b1d43d85e5bd",
    "Beauty": "d3128a30-1ce5-40a0-a578-e164570f2fad",
    "Booking": "76dba7e5-360c-439b-bd0a-e8f30e82c49b",
    "Cars": "38d8fad4-5d6e-40b1-a80f-0cc1012398f9",
    "Classifieds": "31471620-9cb2-4644-8688-c846734cb9b8",
    "Competition": "ffd6c9b0-9409-439a-97bd-e07518b0aba2",
    "Cosmetics": "d6a342af-a95e-40ed-89d5-6fa2bfd5c185",
    "Delivery": "4cf69847-714f-4313-a833-36598772185f",
    "Discounts": "c1c36697-7d1e-4977-a922-56966c237f25",
    "Drinks on Delivery": "56e8c6e4-9647-422f-9603-76e5f4db6dcc",
    "e-Pharmacy": "30ab684e-17a6-4bed-9a63-004103697869",
    "Electronics": "e7dcac38-7654-4337-a9a4-6adb9aeaa6c0",
    "Fish": "96c43213-cae3-40c7-bb6c-2ea4176e3a13",
    "Fitness": "d57dd333-2518-42ce-ab91-ed2f6b6ac442",
    "Food Delivery": "cd6cec00-ff5e-4972-bafc-513cced7d498",
    "Phones": "8193566d-9c40-49ff-975c-048be4b7fb19",
    "Photography": "1279918c-599c-4647-8c10-7cac69929855",
    "Textile": "a3669d6d-62aa-41b1-8b5f-6871a1d668f0",
    "Ticketing": "114b2332-768a-4b48-99d4-485bb122c6d5",
    "Trade": "1c3ca041-74d5-4cb8-bd84-b1017301837f",
    "Vehicle Marketplace": "b2cf6989-5acb-4a0c-a687-b529566f659f",
    "Vehicle Services": "d325140f-f021-439c-b71f-2a0647911e0e",
    "Watches": "0497dbde-347c-49a8-a985-29c34f272a5c",
    "Professional Skills Development": "7a4f24a8-d38b-49f6-b5b7-bf3c121d9e9d",
    "Revision & Examinations": "97a91537-159e-4f67-bc30-b927a1740f82",
    "STEM": "f43dfe5d-f9bf-4a8e-a4d6-c43edf9c5a3e",
    "Teacher Training": "9e134cfd-35bf-444f-9d07-49f28eca1d95",
    "Tutoring": "c8b3869c-47ec-4593-b251-07610c64e8e2",
    "Videos": "f1aacbf6-cfd1-4e56-83b7-e26a129417bf",
    "Animation": "ad3257f9-edef-4658-b8f9-342345536b58",
    "Comics": "cafe945e-412f-438f-93d0-0664f6a5f34d",
    "Remittances": "b86879ac-5fd2-445a-b7d7-770d0000c8cd",
    "Rent to Own": "ae47cbfa-737b-42b9-88a4-41591ca85454",
    "Blue Collar": "e7bdb6d4-240d-43cf-9ffc-32378d43fccb",
    "Bot": "50d4f7ca-d68c-43f4-abb0-0c49091514b7",
    "Hotels": "c2ded21c-6f8d-4bcb-897b-8fc4a38297e6",
    "Point of Sale": "944d1567-b32e-4ca8-a808-225926b3e5c9",
    "Bikes": "82447f0b-0b2a-410c-8335-0d30a4893c2a",
    "Carpooling": "5f523c3a-b06e-4082-9fce-8ab0ae516fc0",
    "Last Mile Delivery": "90408db1-4b12-4630-abb9-8acb53254f8c",
    "WASH": "9ecb046b-1176-435e-bc24-884b374daa9f"


}
