import axios from 'axios';
import { personaDataEmpty } from '../models/persona';
import { cloneDeep } from 'lodash';
import { authHeader } from '../auth/authUtils';
import { BASE_URL } from '../helpers/axiosConfig';
import FormData from 'form-data';

const merge = require('deepmerge');

/** -- People preview -- **/
export const PEOPLE_PREVIEW_FETCH = 'people_preview_fetch';
export const PEOPLE_PREVIEW_FETCH_SUCCESS = 'people_preview_fetch_success';
export const PEOPLE_PREVIEW_FETCH_ERROR = 'people_preview_fetch_error';

export const PERSONA_COUNT_FETCH = 'persona_count_fetch';
export const PERSONA_COUNT_FETCH_SUCCESS = 'persona_count_fetch_success';
export const PERSONA_COUNT_FETCH_ERROR = 'persona_count_fetch_error';

export const PERSONA_DATA_FETCH = 'persona_data_fetch';
export const PERSONA_DATA_FETCH_SUCCESS = 'persona_data_fetch_success';
export const PERSONA_DATA_FETCH_ERROR = 'persona_data_fetch_error';

export const PERSONA_UPDATE = 'persona_update';
export const PERSONA_UPDATE_SUCCESS = 'persona_update_success';
export const PERSONA_UPDATE_ERROR = 'persona_update_error';

export const PERSONA_CREATE = 'persona_create';
export const PERSONA_CREATE_SUCCESS = 'persona_create_success';
export const PERSONA_CREATE_ERROR = 'persona_create_error';

export const DELETE_PERSONA = 'delete_persona';
export const DELETE_PERSONA_SUCCESS = 'delete_persona_success';
export const DELETE_PERSONA_ERROR = 'delete_persona_error';

export const CLEAN_PERSONA = 'clean_persona';
export const REMOVE_PERSONA_URL = 'remove_persona_url';

export const cleanPersona = () => {
    return dispatch => {
        dispatch({ type: CLEAN_PERSONA });
    };
};

export const removePersonaUrl = () => {
    return dispatch => {
        dispatch({ type: REMOVE_PERSONA_URL });
    };
};

export const fetchCountPersona = (myUser) => {
    return dispatch => {
        dispatch({ type: PERSONA_COUNT_FETCH });
        axios.get(BASE_URL + '/persona/count', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: PERSONA_COUNT_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: PERSONA_COUNT_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchPeoplePreview = (page, pageSize, sorting, filter, myUser) => {
    let params = {
        page: page,
        pageSize: pageSize,
        columnSorting: sorting.column,
        desc: sorting.desc,
        filter: filter
    };

    return dispatch => {
        dispatch({ type: PEOPLE_PREVIEW_FETCH });
        axios.get(BASE_URL + '/persona/people-preview', { params, headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: PEOPLE_PREVIEW_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: PEOPLE_PREVIEW_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchPersonaDataEmpty = () => {
    return dispatch => {
        const dataEmpty = cloneDeep(personaDataEmpty);
        dispatch({
            type: PERSONA_DATA_FETCH_SUCCESS,
            payload: dataEmpty
        });
    };
};

export const fetchPersonaData = (persona_id, myUser) => {
    return dispatch => {
        dispatch({ type: PERSONA_DATA_FETCH });
        axios.get(BASE_URL + '/persona', {
            params: {
                persona_id: persona_id
            },
            headers: authHeader(myUser)
        })
            .then(res => {
                const personaData = cloneDeep(personaDataEmpty);
                personaData['persona'] = res.data.persona;
                personaData['social'] = res.data.social;

                let numCompanies = 0;
                for (let company of personaData.persona.companies) {
                    if (company !== null) {
                        numCompanies += 1;
                    }
                }
                if (numCompanies === 0) {
                    personaData.persona.companies = [];
                }

                dispatch({
                    type: PERSONA_DATA_FETCH_SUCCESS,
                    payload: personaData
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: PERSONA_DATA_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const updatePersonaData = (mergedPersonaData, myUser, callback) => {
    axios.post(BASE_URL + '/persona/update',
        {
            persona: mergedPersonaData
        },
        { headers: authHeader(myUser) }
    )
        .then(res => {
            return callback();
        })
        .catch(err => {
            return callback();
        });
};

export const updatePersona = (personaData, personaValues, myUser, isNewSocial, selectedFile) => {
    return dispatch => {
        dispatch({ type: PERSONA_UPDATE });

        const mergedPersonaData = merge(personaData, personaValues);
        try {
            if (selectedFile) {
                uploadAvatar(selectedFile, myUser, (avatarUrl) => {
                    if (avatarUrl) {
                        mergedPersonaData.persona.avatar_url = avatarUrl;
                    }
                    updatePersonaData(mergedPersonaData, myUser, () => {
                        dispatch({
                            type: PERSONA_UPDATE_SUCCESS,
                            payload: {
                                personaUpdated: mergedPersonaData
                            }
                        });
                    });
                });
            } else {
                updatePersonaData(mergedPersonaData, myUser, () => {
                    dispatch({
                        type: PERSONA_UPDATE_SUCCESS,
                        payload: {
                            personaUpdated: mergedPersonaData
                        }
                    });
                });
            }
        } catch (e) {
            dispatch({
                type: PERSONA_UPDATE_ERROR
            });
        }
    };
};

export const uploadAvatar = (selectedFile, myUser, callback) => {
    let formData = new FormData();
    formData.append('selectedFile', selectedFile);
    axios.post(BASE_URL + '/avatar/upload',
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + myUser.user_token
            }
        }
    )
        .then(res => {
            let avatarUrl = null;
            if (res.data) {
                avatarUrl = res.data.avatar_location;
            }
            return callback(avatarUrl);
        })
        .catch(err => {
            // console.log(err);
            return callback();
        });
};

export const createPersonaData = (data, myUser, success, error) => {
    axios.put(BASE_URL + '/persona/create', data, { headers: authHeader(myUser) })
        .then(res => {
            return success();
        })
        .catch(err => {
            return error();
        });
};

export const createPersona = (personaData, personaValues, myUser, selectedFile) => {
    const mergedPersonaData = merge(personaData, personaValues);
    return dispatch => {
        dispatch({ type: PERSONA_CREATE });
        try {
            let data = null;
            if (selectedFile) {
                uploadAvatar(selectedFile, myUser, (avatarUrl) => {
                    if (avatarUrl) {
                        mergedPersonaData.persona.avatar_url = avatarUrl;
                    }
                    data = {
                        persona: mergedPersonaData,
                        user: myUser
                    };
                    createPersonaData(data, myUser, () => {
                        dispatch({
                            type: PERSONA_CREATE_SUCCESS
                        });
                    }, () => {
                        dispatch({
                            type: PERSONA_CREATE_ERROR
                        });
                    });
                });
            } else {
                data = {
                    persona: mergedPersonaData,
                    user: myUser
                };
                createPersonaData(data, myUser, () => {
                    dispatch({
                        type: PERSONA_CREATE_SUCCESS
                    });
                }, () => {
                    dispatch({
                        type: PERSONA_CREATE_ERROR
                    });
                });
            }
        } catch (err) {
            dispatch({
                type: PERSONA_CREATE_ERROR
            });
        }
    };
};


export const deletePersona = (persona_ids, myUser) => {
    return dispatch => {
        dispatch({ type: DELETE_PERSONA });
        const data = { persona_ids };
        axios.put(BASE_URL + '/persona/delete', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: DELETE_PERSONA_SUCCESS,
                    payload: { persona_ids }
                });
            })
            .catch(err => {
                // console.log(err);
                dispatch({
                    type: DELETE_PERSONA_ERROR,
                    payload: err
                });
            });
    };
};
