import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { Grid } from '@material-ui/core';
import FilterFormBox from '../../components/FilterFormOverview/FilterFormBox';
import history from '../../config/history';
import { actionTypes } from '../../helpers/actionTypes';
import { bindActionCreators } from 'redux';
import { clearStats, fetchStats, fetchStatsRightColumn, fetchStatsUnAuth } from '../../actions/compareHandler';
import { setAuthbox } from '../../actions/authHandler';
import { fetchMainData } from '../../actions/mainHandler';
import { connect } from 'react-redux';
import CustomPaper from '../../components/Paper';
import Typography from '@material-ui/core/Typography';
import Spacer from '../../components/Spacer/Spacer';
import { CATEGORIES, categoriesList, getCategoryFromValue } from '../../models/company/company';
import CompanyTableFiltered from '../../components/Table/Companies/CompanyTableFiltered';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class Compare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 5,
            numTotalRows: 0,
            filterData: null,
            rightCategory: CATEGORIES.INVESTOR,
            leftCategory: CATEGORIES.STARTUP,
            // matchingNumbers: 0
        };
    }

    componentDidMount() {
        this.init();
        // window.location.reload(); // TODO
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === false) {
            this.props.clearStats();
            this.init();
        }
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === true) {
            // window.location.reload();
            this.init();
        }
    }

    init() {
        this.props.fetchMainData(this.props.myUser);
        if (this.props.myUser) {
            this.props.fetchStats(this.props.myUser, { category: this.state.leftCategory.name });
            this.props.fetchStatsRightColumn(this.props.myUser, { category: this.state.rightCategory.name });
        } else {
            this.props.fetchStatsUnAuth();
        }
    }

    onCategoryChangeRight = (event) => {
        const newCategory = getCategoryFromValue(event.target.value);
        this.setState({
            rightCategory: newCategory
        }, () => {
            this.fetchRows();
        });
    };

    onCategoryChangeLeft = (event) => {
        const newCategory = getCategoryFromValue(event.target.value);
        this.setState({
            leftCategory: newCategory
        }, () => {
            this.fetchRows();
        });
    };

    // onMatchingNumbersChange = (event) => {
    //     this.setState({
    //         matchingNumbers: event.target.value
    //     }, () => {
    //         this.fetchRows();
    //     });
    // };

    fetchRows = () => {
        if (this.props.myUser && this.props.iAmAuthenticated) {
            const filterDataLeft = { ...this.state.filterData, category: this.state.leftCategory.name };
            const filterDataRight = {
                ...this.state.filterData,
                category: this.state.rightCategory.name,
                // matchingNumbers: this.state.matchingNumbers
            };
            this.props.fetchStats(this.props.myUser, filterDataLeft);
            this.props.fetchStatsRightColumn(this.props.myUser, filterDataRight);
        } else {
            this.props.setAuthbox(true);
            this.props.setAuthbox(false);
        }
    };

    handleOnSubmitFilterForm = (filterData) => {
        this.setState({ filterData: filterData }, () => {
            this.fetchRows();
        });
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps !== this.props || nextState !== this.state) {
            return true;
        }
        return false;
    };

    onCompanyRowClicked = (company_id) => {
        if (!this.props.iAmAuthenticated) {
            this.props.setAuthbox(true);
        } else {
            history.push({
                pathname: '/company/' + actionTypes.SHOW + '/' + company_id
            });
        }
    };

    handleClickExport = () => {
        if (!this.props.iAmAuthenticated) {
            this.props.setAuthbox(true);
        }
    };

    onSelectCompany = (company) => {
        if (company) {
            this.props.fetchStatsUnAuth(company);
        } else {
            this.init();
        }
    };

    render() {
        let {
            sectors,
            subSectors,
            SDGs
        } = this.props;

        return (
            <DashboardLayout title="Match">
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <CustomPaper style={{ padding: 15 }}>
                            <FilterFormBox
                                submit={this.handleOnSubmitFilterForm}
                                sectors={sectors}
                                subSectors={subSectors}
                                SDGs={SDGs}
                                handleClickExport={this.handleClickExport}
                                selectCompany={this.onSelectCompany}
                                myUser={this.props.myUser}
                                fromCompare={CATEGORIES.STARTUP}
                            />
                        </CustomPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <CustomPaper style={{ padding: 15 }}>
                            <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h2">{this.state.leftCategory.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            labelId="category-left"
                                            id="category-left"
                                            value={this.state.leftCategory.category_name}
                                            label="Category"
                                            onChange={this.onCategoryChangeLeft}
                                        >
                                          {categoriesList.map(option => (
                                              this.state.rightCategory.category_name !== option.value ?
                                                  <MenuItem
                                                      key={option.category_id}
                                                      value={option.value}
                                                  >
                                                    {option.name}
                                                  </MenuItem>
                                                  : null
                                          ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Spacer/>
                            <Spacer divider/>
                            <Grid xs={12}>
                                <CompanyTableFiltered
                                    myUser
                                    fetching={this.props.fetchingStatsSectors}
                                    rows={this.props.companyRows}
                                    onClickRow={this.onCompanyRowClicked}
                                    fromCompare
                                    tableSide={"left"}
                                    onClickUpgrade={this.props.onClickUpgrade}
                                />
                            </Grid>
                        </CustomPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <CustomPaper style={{ padding: 15 }}>
                            <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h2">{this.state.rightCategory.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            labelId="category-right"
                                            id="category-right"
                                            value={this.state.rightCategory.category_name}
                                            label="Category"
                                            onChange={this.onCategoryChangeRight}
                                        >
                                            {categoriesList.map(option => (
                                                this.state.leftCategory.category_name !== option.value ?
                                                    <MenuItem
                                                        key={option.category_id}
                                                        value={option.value}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                    : null
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Spacer/>
                            <Spacer divider/>
                            <CompanyTableFiltered
                                myUser
                                fetching={this.props.fetchingStatsSectors}
                                rows={this.props.companyRowsRight}
                                onClickRow={this.onCompanyRowClicked}
                                fromCompare
                                tableSide={"right"}
                            />
                        </CustomPaper>
                    </Grid>
                </Grid>
            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clearStats,
        fetchStats,
        fetchStatsRightColumn,
        fetchStatsUnAuth,
        setAuthbox,
        fetchMainData
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        statsSectors: state.chartsHandler.statsSectors,
        statsSubSectors: state.chartsHandler.statsSubSectors,
        statsCountries: state.chartsHandler.statsCountries,
        statsSDGs: state.chartsHandler.statsSDGs,
        fetchingStatsSectors: state.chartsHandler.fetchingStatsSectors,
        statsGeneral: state.chartsHandler.statsGeneral,

        companyRows: state.compareHandler.companyRows,
        companyRowsRight: state.compareHandler.companyRowsRight,

        sectors: state.mainHandler.sectors,
        subSectors: state.mainHandler.subSectors,
        countriesArray: state.mainHandler.countries,
        SDGs: state.mainHandler.SDGs,

        myUser: state.authHandler.myUser,
        iAmAuthenticated: state.authHandler.iAmAuthenticated,
        iAmApproved: state.authHandler.iAmApproved,

        authBoxRequired: state.authHandler.authBoxRequired
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
