import { CLAIM_PROFILE, UPDATE_SUGGESTION } from '../models/contactForm/subjectList';
import axios from 'axios';
import { BASE_URL } from '../helpers/axiosConfig';
import { authHeader } from '../auth/authUtils';

export const SEND_EMAIL = 'SEND_EMAIL';
export const EMAIL_SENT = 'EMAIL_SENT';
export const COMPANY_CLAIMED = 'COMPANY_CLAIMED';


export const emailSent = () => {
    return dispatch => {
        dispatch({ type: EMAIL_SENT });
    };
};

export const sendEmail = (values, myUser, companyName = null, companyId = null) => {
    let finalText = '';
    let requestText = '';
    if (values.subject === UPDATE_SUGGESTION && companyName) {
        finalText = 'Company to edit: ' + companyName + '\n\n\n' + values.text;
    } else if (values.subject === CLAIM_PROFILE && companyName) {
        finalText = 'I\'m claiming this company: ' + companyName + '\n\n\n' + values.text;
        requestText = values.text;
    } else {
        finalText = values.text;
    }

    return dispatch => {
        dispatch({ type: SEND_EMAIL });

        const corsProxy = 'https://cors-anywhere.herokuapp.com/';

        fetch(corsProxy + 'https://bmww2h892j.execute-api.us-east-2.amazonaws.com/betaPhase/contactusmailer', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email_sender: values.email_sender,
                subject: values.subject,
                text: finalText
            })
        }).then(() => {
            if (values.subject === CLAIM_PROFILE && companyName) {
                claimCompany(myUser, companyId, requestText, () => {
                    dispatch({ type: EMAIL_SENT });
                    dispatch({
                        type: COMPANY_CLAIMED,
                        payload: companyId
                    });
                });
            } else {
                dispatch({ type: EMAIL_SENT });
            }
        }).catch(
            dispatch({ type: EMAIL_SENT })
        );
    };
};

const claimCompany = (user, companyId, requestText, callback) => {
    axios.post(BASE_URL + '/claim/company',
        {
            user,
            companyId,
            requestText
        },
        { headers: authHeader(user) }
    ).then(res => {
        return callback();
    })
        .catch(err => {
            return callback();
        });
};
