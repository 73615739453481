// Material helpers
import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
    palette,
    typography,
    overrides,
    zIndex: {
        appBar: 1200,
        drawer: 1100
    },
    // fontFamily: 'Trade Winds'
    fontFamily: 'Montserrat'
});

export const chartFontFamily = 'Montserrat';

export default theme;
