import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../../layouts';
import EnhancedTable from '../../../components/Table/EnhanceTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import { deletePersona, fetchCountPersona, fetchPeoplePreview } from '../../../actions/peopleHandler';
import { tableTypes } from '../../../components/Table/rowSelector';
import { SORTING } from '../../../models/persona';
import Button from '@material-ui/core/Button';
import history from '../../../config/history';
import { actionTypes, tableActionTypes } from '../../../helpers/actionTypes';
import Spacer from '../../../components/Spacer/Spacer';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/axiosConfig';
import { authHeader } from '../../../auth/authUtils';
import { cloneDeep } from 'lodash';
import ActionDialog from '../../../components/ChangeStatusDialog';
import SimpleSnackbar from '../../../components/Feedback/SimpleSnackbar';


const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
    { id: 'lastname', numeric: false, disablePadding: true, label: 'Last Name', sortable: true },
    { id: 'company_name', numeric: false, disablePadding: false, label: 'Company', sortable: true },
    { id: 'role', numeric: false, disablePadding: false, label: 'Role', sortable: true },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

class ManagePeople extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            pageSize: 25,
            numTotalRows: 0,
            sorting: {
                column: SORTING.PERSONA_PREVIEW_DEFAULT,
                desc: false
            },
            filter: null,

            dialogActionVisible: false,
            snackVisible: false,
            snackbarVariant: '',
            rowClicked: null,
            rowsSelected: null,
            activeActionType: null,
            multipleAction: false,

            searchQuery: '',
            searchResult: [],
            searching: false
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.props.fetchCountPersona(this.props.myUser);
        this.fetchPeoplePreview();
    }

    fetchPeoplePreview() {
        this.props.fetchPeoplePreview(
            this.state.page,
            this.state.pageSize,
            this.state.sorting,
            this.state.filter,
            this.props.myUser
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pageSize !== this.state.pageSize
            || prevState.sorting !== this.state.sorting
            || prevState.page !== this.state.page) {
            this.fetchPeoplePreview();
        }

        if (prevProps.numPersona !== this.props.numPersona) {
            this.setState({
                numTotalRows: this.props.numPersona
            });
        }

        if (prevProps.personaUpdating && !this.props.personaUpdateError) {
            let variant = 'info';
            let successful = false;
            if (!this.props.personaUpdateError) {
                variant = 'success';
                successful = true;
                if (this.state.activeActionType === tableActionTypes.DELETE) {
                    this.init();
                }
            } else {
                variant = 'error';
            }
            this.openSnack(successful, variant);
        }
    }

    handleAddPersona = () => {
        history.push({
            pathname: '/persona/' + actionTypes.ADD
        });
    };

    handleChangePageSize = (pageSize) => {
        this.setState({
            pageSize: pageSize,
            page: 0
        });
    };

    handleChangeSorting = (columnName, isDesc) => {
        let newSorting = {
            column: columnName,
            desc: isDesc
        };
        this.setState({
            sorting: newSorting,
            page: 0
        });
    };

    handlePageChange = (newPage) => {
        this.setState({
            page: newPage
        });
    };

    startSearch = () => {
        axios.get(BASE_URL + '/persona/active-search', {
            params: {
                pattern: this.state.searchQuery
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateSearchResult(data.results);
            });
    };

    updateSearchResult = (results) => {
        let newResults = cloneDeep(results);

        this.setState({
            searchResult: newResults,
            searching: false
        });
    };

    handleInputSearchChange = (e) => {
        this.setState({
            searchQuery: e.target.value
        }, () => {
            if (this.state.searchQuery && this.state.searchQuery.length > 0) {
                this.setState({
                        searching: true
                    }, () => this.startSearch()
                );
            }
        });
    };

    onClickDelete = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.DELETE
        }, () => this.openDialogAction());
    };

    openDialogAction = () => {
        this.setState({
            dialogActionVisible: true
        });
    };

    closeActionDialog = (force) => {
        if (!this.props.personaUpdating || force) {
            this.setState({
                dialogActionVisible: false
            });
        }
    };


    handleConfirmAction = () => {
        switch (this.state.activeActionType) {
            case tableActionTypes.DELETE:
                if (this.state.multipleAction) {
                    this.props.deletePersona(this.state.rowsSelected, this.props.myUser);
                } else {
                    this.props.deletePersona([this.state.rowClicked.persona_id], this.props.myUser);
                }
                break;
            default:
                break;
        }
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (successful, variant) => {
        this.setState({
            ...this.state,
            dialogActionVisible: false,
            snackbarVariant: variant,
            snackVisible: true,
            updateSuccess: successful,
            rowClicked: null,
            rowsSelected: null
        });
    };

    handleMultipleDelete = (rows) => {
        this.setState({
            rowsSelected: rows,
            activeActionType: tableActionTypes.DELETE,
            multipleAction: true
        }, () => this.openDialogAction());
    };

    render() {
        return (
            <DashboardLayout title="Manage People">
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    onClick={this.handleAddPersona}*/}
                {/*>*/}
                {/*    ADD PERSON*/}
                {/*</Button>*/}
                {/*<Spacer/>*/}
                {this.props.fetchingPeoplePreview ? <LinearProgress/> : null}
                {this.props.peoplePreviewError ? 'Error fetching people' : null}
                <EnhancedTable
                    rows={(this.state.searchQuery.length > 0)
                        ? this.state.searchResult
                        : this.props.peoplePreview
                    }
                    headCells={headCells}
                    tableType={tableTypes.PEOPLE}

                    page={this.state.page}
                    onChangePageSize={this.handleChangePageSize}
                    onChangeSorting={this.handleChangeSorting}
                    onPageChange={this.handlePageChange}
                    numTotalRows={this.state.numTotalRows}

                    myUser={this.props.myUser}

                    handleClickStatus={this.onClickStatus}
                    handleClickDelete={this.onClickDelete}
                    multipleDelete={this.handleMultipleDelete}
                    onUpdateSuccess={this.state.updateSuccess}

                    searching={this.state.searching}
                    handleInputSearchChange={this.handleInputSearchChange}

                    toolbarVisible
                    paginationVisible
                    checkable
                    sortable

                    handleAddEntry={this.handleAddPersona}
                    addEnabled
                    deleteEnabled
                />
                <ActionDialog
                    tableName={"people"}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogActionVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    rowClicked={this.state.rowClicked}
                    updating={this.props.personaUpdating}
                    onConfirm={() => this.handleConfirmAction()}
                    isMultiple={this.state.multipleAction}
                />
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPeoplePreview,
        fetchCountPersona,
        deletePersona
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        peoplePreview: state.peopleHandler.peoplePreview,
        fetchingPeoplePreview: state.peopleHandler.fetchingPeoplePreview,
        peoplePreviewError: state.peopleHandler.peoplePreviewError,

        personaUpdating: state.peopleHandler.personaUpdating,
        personaUpdateError: state.peopleHandler.personaUpdateError,

        numPersona: state.peopleHandler.numPersona,

        myUser: state.authHandler.myUser
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ManagePeople);
