import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ component: Component, authed, userAuth, roles, tableName, ovverrideAuth, ...rest }) {

    const roleIncludes = (roles, userAuth) => {
        if (roles && userAuth && roles.includes(userAuth.role) || ovverrideAuth) {
            return true;
        } else {
            return false;
        }

    };

    return (
        <Route
            {...rest}
            render={(props) => (roleIncludes(roles, userAuth))
                ? <Component {...props} tableName={tableName}/>
                : <Redirect to={{ pathname: '/', state: { from: props.location } }}/>
            }
        />
    );
}
