import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withStyles, Grid, Typography } from '@material-ui/core';
import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import DealRow from './DealRow';
import ConfirmDialog from '../../ConfirmDialog';
import { actionTypes } from '../../../helpers/actionTypes';
import SearchCompanyDialog from '../../SearchCompanyDialog';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/axiosConfig';
import { authHeader } from '../../../auth/authUtils';
import { cloneDeep } from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Spacer from '../../Spacer/Spacer';
import { formatCash } from '../../../helpers/Stats/investments';

const DealsForm = (props) => {
    const {
        classes, className,
        onAddInvestment, handleClickRemoveInvestment, handleSaveInvestment,
        companyInvestments, formDisabled, isMyCompany, removeInvestedCompany,
        onBlur,
        addCompanyToInvestors,
        formik,
        fromDeals,
        actionMode, myUser, ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [investmentClicked, setInvestmentClicked] = useState(null);

    const [dialogAddInvestorVisible, setDialogAddInvestorVisible] = useState(false);
    const [companyQuery, setCompanyQuery] = useState('');
    const [searchingCompanies, setSarchingCompanies] = useState(false);
    const [companiesResults, setCompaniesResults] = useState([]);
    const [companySelected, setCompanySelected] = useState(null);
    const [dealAddingInvestor, setDealAddingInvestor] = useState(null);
    const [categorySearching, setCategorySearching] = useState('investor');

    const handleClickDeleteInvestment = (deal) => {
        setConfirmDialogVisible(true);
        setInvestmentClicked(deal);
    };

    const getTotalInvestment = () => {
        let totalInvestment = 0;
        for (let investment of companyInvestments) {
            if (investment.amount && Number.isInteger(investment.amount)) {
                totalInvestment += investment.amount;
            }
        }
        return totalInvestment;
    };

    const addInvestor = () => {
        if (companySelected) {
            let newInvestment = cloneDeep(dealAddingInvestor);
            newInvestment.investors.push(companySelected);
            addCompanyToInvestors(newInvestment);
            setDialogAddInvestorVisible(false);
            setCompanySelected(null);
            setCompanyQuery('');
            setCompaniesResults([]);
        }
    };

    const selectCompany = (company) => {
        setCompanySelected(company);
    };

    useEffect(() => {
        if (companyQuery && companyQuery.length > 0) {
            setSarchingCompanies(true);
        }
    }, [companyQuery]);

    useEffect(() => {
        if (searchingCompanies === true) {
            getCompanies();
        }
    }, [searchingCompanies]);

    const updateCompaniesResult = (companies) => {
        let companiesResult = cloneDeep(companies);
        // disableCompanyOfActualPartnership(companiesResult, this.props.partnerships); // TODO
        setSarchingCompanies(false);
        setCompaniesResults(companiesResult);
    };

    const getCompanies = () => {
        axios.get(BASE_URL + '/company/search-investor', {
            params: {
                pattern: companyQuery,
                justActives: true
            },
            headers: authHeader(myUser)
        })
            .then(({ data }) => {
                updateCompaniesResult(data.companies);
            });
    };

    const handleInputCompanyChange = (e) => {
        setCompanyQuery(e.target.value);
        if (companyQuery && companyQuery.length > 0) {
            setSarchingCompanies(true);
        }
    };

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Deals"
                />
                {actionMode !== actionTypes.SHOW ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onAddInvestment}
                    >
                        ADD DEAL
                    </Button> : null}
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    {formik.values.company.category === 'startup' ?
                        <Grid
                            container
                            item
                            xs={12} sm={6} lg={4}
                            className={classes.field}
                        >
                            <FormControlLabel
                                control={

                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.company.raised_capital}
                                        onChange={
                                            () => formik.setFieldValue('company.raised_capital',
                                                !formik.values.company.raised_capital)
                                        }
                                        value={formik.values.company.raised_capital}
                                        color="primary"
                                    />

                                }
                                label="Raised Capital"
                            />
                        </Grid>
                        : null}
                </Grid>
                {getTotalInvestment() > 0 ?
                    <Grid
                        container
                        item
                        xs={12} sm={6} lg={4}
                        className={classes.field}
                    >
                        <Typography>{'Total investment: $' + formatCash(getTotalInvestment())}</Typography>
                    </Grid>
                    : null}
                <Spacer small divider/>
                {companyInvestments ?
                    companyInvestments.map((investment, index) => {
                        return (
                            <DealRow
                                investmentIndex={index}
                                investment={investment}
                                onClickDeleteDeal={() => handleClickDeleteInvestment(investment)}
                                myUser={myUser}
                                onBlur={handleSaveInvestment}
                                handleAddCompanyClick={(category) => {
                                    setCategorySearching(category);
                                    setDialogAddInvestorVisible(true);
                                    setDealAddingInvestor(investment);
                                }}
                                formDisabled={formDisabled}
                                isMyCompany={isMyCompany}
                                removeInvestedCompany={removeInvestedCompany}
                            />
                        );
                    })
                    : null
                }
                {companyInvestments && companyInvestments.length === 0 ?
                    <Typography variant="h5">
                        No available funding data
                    </Typography>
                    : null}
            </PortletContent>
            <ConfirmDialog
                visible={confirmDialogVisible}
                onClose={() => setConfirmDialogVisible(false)}
                onConfirm={
                    () => {
                        setConfirmDialogVisible(false);
                        handleClickRemoveInvestment(investmentClicked);
                    }
                }
                title={'REMOVE DEAL'}
                content={'Are you sure?'}
            />
            <SearchCompanyDialog
                open={dialogAddInvestorVisible}
                selectCompany={selectCompany}
                closeDialogSearch={() => {
                    setDialogAddInvestorVisible(false);
                    setCompanySelected(null);
                }}
                companiesResult={companiesResults}
                searchingCompanies={searchingCompanies}
                companySelected={companySelected}
                handleInputCompanyChange={handleInputCompanyChange}
                addCompany={addInvestor}
                fromDeals={fromDeals}
                categorySearching={categorySearching}
            />
        </Paper>

    );
};

export default withStyles(styles)(DealsForm);
