import React from 'react';
import styles from './styles';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { regions } from '../../data/location/locations';
import { categoriesStrings, foundingYearListFilter } from '../../models/company/company';
import Chip from '@material-ui/core/Chip';
import { impactTypes } from '../../models/typeList/impactTypes';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { BASE_URL } from '../../helpers/axiosConfig';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Image from '@material-ui/icons/Image';
import ButtonExtended from '../UI/ButtonExtended/ButtonExtended';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { filterLocationsByRegion } from '../../helpers/Geograph/locationsUtils';
import IconButton from '@material-ui/core/IconButton';
import { FilterList, InfoOutlined } from '@material-ui/icons';
import { investmentStageListFilter } from '../../models/typeList/investmentStages';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import DatePicker from '../DatePicker';

class FilterFieldsOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companyQuery: '',
            companyResult: [],
            searchingCompany: false,
            companySelected: null,
            anchorEl: false
        };
    }

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    updateCompanyResult = (companies) => {
        let companyResult = cloneDeep(companies);

        this.setState({
            companyResult: companyResult,
            searchingCompany: false
        });
    };

    getCompany = () => {
        axios.get(BASE_URL + '/company/search', {
            params: {
                pattern: this.state.companyQuery,
                justActives: true,
                justOperating: true
            }
        })
            .then(({ data }) => {
                this.updateCompanyResult(data.companies);
            });
    };

    handleInputCompanyChange = (e) => {
        this.setState({
            companyQuery: e.target.value
        }, () => {
            if (this.state.companyQuery && this.state.companyQuery.length > 0) { // TODO increment
                this.setState({
                        searchingCompany: true
                    }, () => this.getCompany()
                );
            }
        });
    };

    handleApplyClick = () => {
        this.props.onDataChange();
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    style={styles.form_container}
                    xs={12}
                    spacing={2}
                >
                    {!this.props.fromDeals && !this.props.fromCompare && !this.props.fromManageCompanies ?
                        <Grid
                            style={styles.field}
                            item
                            xs={12}
                            md={4}
                            lg={3}
                        >
                            <Autocomplete
                                id="asynchronous-search-company"
                                disabled={!this.props.myUser}
                                size={'small'}
                                style={styles.textField}
                                getOptionSelected={(option, value) => option.name === value.name}
                                getOptionLabel={option => (option.name)}
                                options={this.state.companyResult}
                                loading={this.state.searchingCompany}
                                onChange={(event, value) => this.props.selectCompany(value)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label="Search company by name"
                                        size="small"
                                        variant="outlined"
                                        onChange={this.handleInputCompanyChange.bind(this)}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.searchingCompany ?
                                                        <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        : null}
                    {!this.props.fromDeals && !this.props.fromCompare || this.props.fromManageCompanies ?
                        <Grid
                            style={styles.field}
                            item
                            xs={6}
                            md={4}
                            lg={2}
                        >
                            <Autocomplete
                                id="category-select"
                                name="category"
                                size="small"
                                style={styles.textField}
                                options={categoriesStrings}
                                autoHighlight
                                value={this.props.formik.values.category}
                                getOptionLabel={option => option}
                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('category', value);
                                    this.props.onDataChange();
                                }}
                                inputProps={{
                                    autoComplete: 'disabled'
                                }}
                                renderOption={option => (
                                    <React.Fragment>
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'disabled'
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        : null
                    }
                    {/*<Grid*/}
                    {/*    style={styles.field}*/}
                    {/*    item*/}
                    {/*    xs={6}*/}
                    {/*    md={4}*/}
                    {/*    lg={2}*/}
                    {/*>*/}
                    {/*    <Autocomplete*/}
                    {/*        id="region-select"*/}
                    {/*        name="region"*/}
                    {/*        size="small"*/}
                    {/*        style={styles.textField}*/}
                    {/*        options={regions}*/}
                    {/*        autoHighlight*/}
                    {/*        value={this.props.formik.values.region}*/}
                    {/*        getOptionLabel={option => option}*/}
                    {/*        onChange={(event, value) => {*/}
                    {/*            this.props.formik.setFieldValue('region', value);*/}
                    {/*            this.props.onDataChange();*/}
                    {/*        }}*/}
                    {/*        inputProps={{*/}
                    {/*            autoComplete: 'disabled'*/}
                    {/*        }}*/}
                    {/*        renderOption={option => (*/}
                    {/*            <React.Fragment>*/}
                    {/*                {option}*/}
                    {/*            </React.Fragment>*/}
                    {/*        )}*/}
                    {/*        renderInput={params => (*/}
                    {/*            <TextField*/}
                    {/*                {...params}*/}
                    {/*                label="Region of ops"*/}
                    {/*                variant="outlined"*/}
                    {/*                fullWidth*/}
                    {/*                inputProps={{*/}
                    {/*                    ...params.inputProps,*/}
                    {/*                    autoComplete: 'disabled'*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid
                        style={styles.field}
                        item
                        xs={6}
                        md={4}
                        lg={3}
                    >
                        <Autocomplete
                            multiple
                            id="substates-select"
                            name="sub_states"
                            size="small"
                            style={styles.textField}
                            options={this.props.subStates ? this.props.subStates : []}
                            autoHighlight
                            value={this.props.formik.values.sub_states}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('sub_states', value);
                                this.props.onDataChange();
                            }}
                            inputProps={{
                                autoComplete: 'disabled'
                            }}
                            // renderOption={option => (
                            //     <React.Fragment>
                            //         {option.name}
                            //     </React.Fragment>
                            // )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Sub States of operation"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'disabled'
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {/*{!this.props.fromDeals && !this.props.fromCompare && !this.props.fromManageCompanies ?*/}
                    {/*    <Grid*/}
                    {/*        style={styles.field}*/}
                    {/*        container*/}
                    {/*        item*/}
                    {/*        xs={6}*/}
                    {/*        md={4}*/}
                    {/*        lg={2}*/}
                    {/*    >*/}
                    {/*        <Tooltip title="Coming Soon" placement="top">*/}
                    {/*            <Box display="flex" flexDirection="row-reverse" style={{ marginRight: 0 }}>*/}
                    {/*                <ButtonExtended*/}
                    {/*                    startIcon={<Image/>}*/}
                    {/*                    variant="contained"*/}
                    {/*                    color='success'*/}
                    {/*                    style={{ 'text-transform': 'none', 'maxHeight': '40px' }}*/}
                    {/*                    // onClick={this.props.handleClickExport}*/}
                    {/*                    // onClick={this.handleClick}*/}
                    {/*                    disabled*/}
                    {/*                >*/}
                    {/*                    EXPORT*/}
                    {/*                </ButtonExtended>*/}
                    {/*                /!*<Menu*!/*/}
                    {/*                /!*    id="simple-menu"*!/*/}
                    {/*                /!*    anchorEl={this.state.anchorEl}*!/*/}
                    {/*                /!*    keepMounted*!/*/}
                    {/*                /!*    open={Boolean(this.state.anchorEl)}*!/*/}
                    {/*                /!*    onClose={this.handleClose}*!/*/}
                    {/*                /!*>*!/*/}
                    {/*                /!*    <MenuItem onClick={this.handleClose}>Export PNG</MenuItem>*!/*/}
                    {/*                /!*    <MenuItem onClick={this.handleClose}>Export PDF</MenuItem>*!/*/}
                    {/*                /!*</Menu>*!/*/}
                    {/*            </Box>*/}
                    {/*        </Tooltip>*/}
                    {/*    </Grid>*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}
                    {!this.props.fromDeals && !this.props.fromCompare && !this.props.fromManageCompanies ?
                        <Grid
                            style={styles.field}
                            item
                            xs={6}
                            md={4}
                            lg={2}
                        >
                            <Autocomplete
                                id="foundation-year-select"
                                name="foundation_year"
                                size="small"
                                style={styles.textField}
                                options={foundingYearListFilter}
                                autoHighlight
                                value={this.props.formik.values.foundation_year}
                                getOptionLabel={option => option}
                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('foundation_year', value);
                                    this.props.onDataChange();
                                }}
                                inputProps={{
                                    autoComplete: 'disabled'
                                }}
                                renderOption={option => (
                                    <React.Fragment>
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Foundation Year"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'disabled'
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        : null}
                    <Grid
                        style={styles.field}
                        item
                        xs={6}
                        md={4}
                        lg={3}
                    >
                        <Autocomplete
                            multiple
                            id="sector-tags"
                            name="sectors"
                            size="small"
                            style={styles.textField}
                            options={this.props.sectorsArray}
                            value={this.props.formik.values.sectors}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('sectors', value);
                                this.props.onDataChange();
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Sectors"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        style={styles.field}
                        item
                        xs={6}
                        md={4}
                        lg={3}
                    >
                        <Autocomplete
                            multiple
                            id="sub_sector-tags"
                            name="sub_sectors"
                            size="small"
                            style={styles.textField}
                            options={this.props.subSectorsArray}
                            value={this.props.formik.values.sub_sectors}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('sub_sectors', value);
                                this.props.onDataChange();
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Products"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        style={styles.field}
                        item
                        xs={6}
                        md={4}
                        lg={3}
                    >
                        <Autocomplete
                            multiple
                            id="impacts-tags"
                            name="impacts"
                            size="small"
                            style={styles.textField}
                            options={impactTypes}
                            value={this.props.formik.values.impacts}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('impacts', value);
                                this.props.onDataChange();
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Impact"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        style={styles.field}
                        item
                        xs={6}
                        md={4}
                        lg={3}
                    >
                        <Autocomplete
                            multiple
                            id="stages-tags"
                            name="stages"
                            size="small"
                            style={styles.textField}
                            options={investmentStageListFilter}
                            value={this.props.formik.values.stages}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                this.props.formik.setFieldValue('stages', value);
                                this.props.onDataChange();
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                    return (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Stage"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                    {this.props.fromDeals ?
                        <Grid
                            style={styles.field}
                            item
                            xs={6}
                            md={4}
                            lg={3}
                        >
                            <CurrencyTextField
                                currencySymbol="$"
                                minimumValue="0"
                                outputFormat='number'
                                digitGroupSeparator=","
                                decimalPlaces={0}

                                style={styles.textField}
                                label="Funding Amount (more than)"
                                name="foundingAmount"
                                value={this.props.formik.values.foundingAmount}
                                autoComplete='off'
                                variant="outlined"

                                onChange={(event, value) => {
                                    this.props.formik.setFieldValue('foundingAmount', value);
                                }}

                                size="small"
                                onBlur={this.props.formik.handleBlur}
                            />
                        </Grid>
                        : null}
                    {/*<Spacer small/>*/}

                    <Grid
                        container
                        item
                        spacing={2}
                    >

                        {this.props.fromDeals ?
                            <Grid
                                style={styles.field}
                                item
                                xs={6}
                                md={4}
                                lg={2}
                            >
                                <DatePicker
                                    label={'From'}
                                    selectedDate={this.props.formik.values.date_from}
                                    handleDateChange={(event, value) => {
                                        let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                                        let localISOTime = (new Date(new Date(value) - tzoffset)).toISOString();
                                        this.props.formik.setFieldValue('date_from', localISOTime);
                                    }}
                                    disableOpenOnEnter
                                    small
                                />
                            </Grid>
                            : null}

                        {this.props.fromDeals ?
                            <Grid
                                style={styles.field}
                                item
                                xs={6}
                                md={4}
                                lg={2}
                            >
                                <DatePicker
                                    label={'To'}
                                    selectedDate={this.props.formik.values.date_to}
                                    handleDateChange={(event, value) => {
                                        let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                                        let localISOTime = (new Date(new Date(value) - tzoffset)).toISOString();
                                        this.props.formik.setFieldValue('date_to', localISOTime);
                                    }}
                                    disableOpenOnEnter
                                    small
                                    // clearable
                                />
                            </Grid>
                            : null}


                        {this.props.fromDeals ?
                            <Grid item xs justify="flex-end"
                                  style={{ display: 'flex' }}
                            >
                                <ButtonExtended
                                    variant="contained"
                                    color='action'
                                    onClick={this.handleApplyClick}
                                >
                                    APPLY
                                </ButtonExtended>
                            </Grid>
                            : null}

                    </Grid>

                    {!this.props.fromDeals ?
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            {this.props.formik.values.category !== 'Investor'
                            && this.props.formik.values.category !== 'Hub'
                            && this.props.formik.values.category !== 'Startup'
                            && this.props.formik.values.category !== 'Company' ?
                                null :
                                <Typography variant="h5" color={'textSecondary'}>
                                    Advanced Filter
                                </Typography>}
                            <IconButton color="primary"
                                        aria-label="filter"
                                        component="span"
                                        onClick={this.props.onClickFilter}
                                        disabled={
                                            this.props.formik.values.category !== 'Investor'
                                            && this.props.formik.values.category !== 'Hub'
                                            && this.props.formik.values.category !== 'Startup'
                                            && this.props.formik.values.category !== 'Company'
                                        }
                            >
                                <FilterList/>
                            </IconButton>
                        </Grid>
                        : null}

                    {!this.props.fromDeals && !this.props.fromCompare && !this.props.fromManageCompanies ?
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton aria-label="delete" disabled color={'secondary'}>
                                <InfoOutlined/>
                            </IconButton>
                            <Typography variant="h5" color={'textSecondary'}>
                                Scroll down for company profile
                            </Typography>
                        </Grid>
                        : null
                    }

                </Grid>
            </div>
        );
    }
};

export default withStyles(styles)(FilterFieldsOverview);
