import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import styles from '../styles';
import TableCell from '@material-ui/core/TableCell';
import AvatarNameCell from '../AvatarNameCell';
import ContactsBox from '../../Contacts';
import DegreeBox from '../../DegreeBox/DegreeBox';

class CompanyRowCompare extends Component {

    render() {
        const {
            row,
            labelId,
            onClickRow,
            tableSide
        } = this.props;

        return (
            <TableRow
                style={{ ...styles.pointer }}
                onClick={() => onClickRow(row.company_id)}
            >
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={this.onClickNameCompany}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.avatar_url}
                        name={row.name}
                        truncateLength={30}
                    />
                </TableCell>
                {/*<TableCell style={styles.noWrapCell}>{row.sector_name}</TableCell>*/}
                <TableCell size={'small'}>
                    <ContactsBox dataInfo={row}
                                 fromCompare
                    />
                </TableCell>
                {tableSide === 'right' ?
                    <TableCell size={'small'}>
                        <DegreeBox numberOfDegree={row.degree}
                        />
                    </TableCell>
                    : null
                }
            </TableRow>
        );
    }
}

export default CompanyRowCompare;
