import React from 'react';
import { Grid } from '@material-ui/core';
import GenericProfileForm from '../../components/Generic/GenericProfileForm';
import GridField from '../../components/GridField/GridField';


function ProfileGeneric(props) {

    const {
        formDisabled,
        formikProps,
        data,
        tableName
    } = props;

    return (
        <div>
            <fieldset disabled={formDisabled} style={{ border: 'none' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={9} lg={9}>
                        <GenericProfileForm
                            formik={formikProps}
                            tableName={tableName}
                        />
                    </Grid>
                </Grid>
            </fieldset>
        </div>
    );
}

export default ProfileGeneric;
