export const parseSocial = (socialData) => {
    const social = socialData[0];
    return {
        social_id: social.social_id,
        facebook: social.facebook,
        linkedin: social.linkedin,
        twitter: social.twitter,
        instagram: social.instagram,
        last_update: social.last_update,
    };
};
