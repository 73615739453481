export default theme => ({
    root: {},
    form_container: {
        //padding: theme.spacing(2),
    },
    field: {
        //margin: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    textField: {
        width: '100%',
        maxWidth: '100%'
        //marginRight: theme.spacing(0)
    },
    switch: {
        marginLeft: theme.spacing(2)
    }
});

