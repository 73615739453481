import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import styles from '../styles';
import ConfirmDialog from '../../ConfirmDialog';
import { can, DELETE_ACCELERATOR } from '../../../helpers/Roles/roleActions';
import CompanyCard from '../../CompanyCard';

const AcceleratorList = (props) => {
    const {
        classes, className,
        myUser,
        accelerators, handleClickCompany, handleClickRemoveAccelerator, formDisabled,
        ...rest
    } = props;

    const [confirmDialogVisible, setConfirmDialogVisible] = React.useState(false);
    const [companyClicked, setCompanyClicked] = React.useState(null);

    const onClickRemoveCompany = (company) => {
        setConfirmDialogVisible(true);
        setCompanyClicked(company);
    };

    return (
        <Grid
            container
            spacing={2}
            className={classes.form_container}
        >
            <Grid container item xs={12} spacing={2}>
                {accelerators ?
                    accelerators.map(company => {
                        return (
                            <Grid item xs={12} sm={6} lg={6}>
                                <CompanyCard
                                    onClickName={() => handleClickCompany(company)}
                                    onClickDelete={() => onClickRemoveCompany(company)}
                                    companyInfo={company}
                                    canDelete={can(myUser, DELETE_ACCELERATOR) && !formDisabled}
                                />
                            </Grid>
                        );
                    })
                    : null
                }
            </Grid>
            <ConfirmDialog
                visible={confirmDialogVisible}
                onClose={() => setConfirmDialogVisible(false)}
                onConfirm={
                    () => {
                        setConfirmDialogVisible(false);
                        handleClickRemoveAccelerator(companyClicked);
                    }
                }
                title={'REMOVE ACCELERATOR'}
                content={'Are you sure?'}
            />
        </Grid>
    );
};

export default withStyles(styles)(AcceleratorList);
