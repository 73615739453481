import 'core-js';
import React, {Component} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4geodata_nigeriaLow from '@amcharts/amcharts4-geodata/nigeriaLow';
import {chartFontFamily} from '../../theme';

am4core.useTheme(am4themes_animated);
let polygonSeries;

// let dummyData = [
//     { id: 'null', value: 0 }
// ];

let dummyData = [
  // { id: 'NG-AK', value: 1 },
  // { id: 'NG-AN', value: 4 },
  // { id: 'NG-BY', value: 2 },
  // { id: 'NG-FC', value: 2 },
  // { id: 'NG-IM', value: 6 }
];

export default class SensitiveMap extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.updateData(this.props.data);
    }
  }

    shouldComponentUpdate = (nextProps, nextState) => {
      if (nextProps.data !== this.props.data) {
        return true;
      }
      return false;
    };

    updateData = (data) => {
      if (data.length > 0) {
        polygonSeries.data = data;
      } else {
        polygonSeries.data = dummyData;
      }
    };

    componentDidMount() {
      let map = am4core.create('chartdiv', am4maps.MapChart);
      map.fontFamily = chartFontFamily;
      map.projection = new am4maps.projections.Mercator();
      map.geodata = am4geodata_nigeriaLow;
      // map.geodata = am4geodata_worldLow;


      // Create map polygon series
      polygonSeries = map.series.push(new am4maps.MapPolygonSeries());

      //Set min/max fill color for each area
      polygonSeries.heatRules.push({
        property: 'fill',
        target: polygonSeries.mapPolygons.template,
        // min: map.colors.getIndex(1).brighten(1),
        min: am4core.color('#C8D7FB'),
        // max: map.colors.getIndex(1).brighten(-0.3)
        max: am4core.color('#021267'),
        // min: map.colors.getIndex(1).brighten(1),
        // max: map.colors.getIndex(1).brighten(-0.2)
        'logarithmic': true
      });

      // Make map load polygon data (state shapes and names) from GeoJSON
      polygonSeries.useGeodata = true;

      // Set up heat legend
      let heatLegend = map.createChild(am4maps.HeatLegend);
      heatLegend.series = polygonSeries;
      heatLegend.align = 'right';
      heatLegend.width = am4core.percent(25);
      heatLegend.marginRight = am4core.percent(4);
      // heatLegend.minValue = 1;
      // heatLegend.maxValue = 100;
      heatLegend.valign = 'bottom';
      // heatLegend.markerCount = 1;
      heatLegend.valueAxis.logarithmic = true

      // Set up custom heat map legend labels using axis ranges
      // let minRange = heatLegend.valueAxis.axisRanges.create();
      // minRange.value = heatLegend.minValue;
      // minRange.label.text = heatLegend.minValue.toString();
      // let maxRange = heatLegend.valueAxis.axisRanges.create();
      // maxRange.value = heatLegend.maxValue;
      // maxRange.label.text = heatLegend.maxValue.toString();

      // Blank out internal heat legend value axis labels
      heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function (labelText) {
        return '';
      });
      heatLegend.fontFamily = chartFontFamily;

      // Configure series tooltip
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonSeries.tooltip.fontFamily = chartFontFamily;
      polygonTemplate.tooltipText = '{name}: {value}';
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeWidth = 0.5;

      polygonTemplate.adapter.add('tooltipText', function (text, ev) {
        if (!ev.dataItem.dataContext.value) {
          return '{name}'; // no values
        }
        return text;
      });

      polygonTemplate.events.on('hit', function (ev) {
        // zoom to an object
        ev.target.series.chart.zoomToMapObject(ev.target);
        // get object info
        // console.log(ev.target.dataItem.dataContext.name);
      });

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create('hover');
      // hs.properties.fill = map.colors.getIndex(1).brighten(-0.5);

      const zoomLevel = 1;
      map.homeZoomLevel = zoomLevel;
      // map.homeGeoPoint = {
      //     latitude: 15,
      //     longitude: 45
      // };
      //
      map.chartContainer.wheelable = true;
      map.maxZoomLevel = 3;

      this.updateData(this.props.data);
    }

    componentWillUnmount() {
      if (this.chart) {
        this.map.dispose();
      }
    }

    render() {
      return (
        <div id="chartdiv" style={{width: '100%', height: '400px'}} />
      );
    }
}
