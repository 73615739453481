import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { getIn } from 'formik';
import Typography from '@material-ui/core/Typography';
import Spacer from '../../Spacer/Spacer';
import CompanyCard from '../../CompanyCard';
import history from '../../../config/history';

const genders = [
    {
        gender_id: 0,
        gender_name: null
    },
    {
        gender_id: 1,
        gender_name: 'Male'
    },
    {
        gender_id: 2,
        gender_name: 'Female'
    },
    {
        gender_id: 0,
        gender_name: 'Prefer not to say'
    }
];


const PersonaInfoForm = (props) => {
    const { classes, className, formik, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const handleClickCompany = (company) => {
        history.push({
            pathname: '/redirect/show/' + company.company_id
        });
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Persona Info"
                    subtitle="Manage the persona information"
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Name"
                                name="persona.name"
                                className={classes.textField}
                                value={formik.values.persona.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'persona.name')
                                    && formik.errors.persona.name
                                }
                                error={getIn(formik.errors, 'persona.name')
                                && formik.errors.persona.name
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Last Name"
                                name="persona.lastname"
                                className={classes.textField}
                                value={formik.values.persona.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'persona.lastname')
                                    && formik.errors.persona.lastname
                                }
                                error={getIn(formik.errors, 'persona.lastname')
                                && formik.errors.persona.lastname
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Role"
                                name="persona.role"
                                className={classes.textField}
                                value={formik.values.persona.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'persona.role')
                                    && formik.errors.persona.role
                                }
                                error={getIn(formik.errors, 'persona.role')
                                && formik.errors.persona.role
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Email"
                                name="persona.email"
                                className={classes.textField}
                                value={formik.values.persona.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'persona.email')
                                    && formik.errors.persona.email
                                }
                                error={getIn(formik.errors, 'persona.email')
                                && formik.errors.persona.email
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        {/*<Grid*/}
                        {/*    className={classes.field}*/}
                        {/*    container*/}
                        {/*    item*/}
                        {/*    xs={12} sm={6} lg={6}*/}
                        {/*>*/}
                        {/*    <TextField*/}
                        {/*        label="Ethnicity"*/}
                        {/*        name="persona.ethnicity"*/}
                        {/*        className={classes.textField}*/}
                        {/*        value={formik.values.persona.ethnicity}*/}
                        {/*        onChange={formik.handleChange}*/}
                        {/*        onBlur={formik.handleBlur}*/}
                        {/*        helperText={*/}
                        {/*            getIn(formik.errors, 'persona.ethnicity')*/}
                        {/*            && formik.errors.persona.ethnicity*/}
                        {/*        }*/}
                        {/*        error={getIn(formik.errors, 'persona.ethnicity')*/}
                        {/*        && formik.errors.persona.ethnicity*/}
                        {/*        }*/}
                        {/*        autoComplete='off'*/}
                        {/*        variant="outlined"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                className={classes.textField}
                                label="Gender"
                                name="persona.gender"
                                select
                                SelectProps={{ native: true }}
                                value={formik.values.persona.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                            >
                                {genders.map(option => (
                                    <option
                                        key={option.gender_id}
                                        value={option.gender_name}
                                    >
                                        {option.gender_name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="LinkedIn"
                                name="social.linkedin"
                                className={classes.textField}
                                value={formik.values.social.linkedin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        {(formik.values.persona.companies && formik.values.persona.companies.length > 0) ?
                            <Spacer divider/>
                            : null}
                        {(formik.values.persona.companies && formik.values.persona.companies.length > 0) ?
                            <div>
                                <Typography variant='h5'> Companies </Typography>
                                <Spacer/>
                            </div>
                            : null
                        }
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid container item xs={12} spacing={2}>
                                {formik.values.persona.companies && formik.values.persona.companies.length > 0 ?
                                    formik.values.persona.companies.map(company => {
                                        return (
                                            <Grid item xs={12} sm={6} lg={6}>
                                                <div onClick={() => handleClickCompany(company)}>
                                                    <CompanyCard
                                                        style={{ cursor: 'pointer' }}
                                                        companyInfo={company}
                                                    />
                                                </div>

                                            </Grid>
                                        );
                                    })
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(PersonaInfoForm);
