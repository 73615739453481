import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { getIn } from 'formik';
import { actionTypes } from '../../helpers/actionTypes';

export default function GridField(props) {
    const {
        classes, className,
        xs, sm, lg,
        container, item,
        label, formName, fieldName,
        formik,
        actionMode,
        ...rest
    } = props;

    return (
        actionMode && actionMode === actionTypes.SHOW
        && (!formik.values[formName][fieldName] || formik.values[formName][fieldName] === '') ?
            null :
            <Grid
                className={className}
                container={container}
                item={item}
                xs={xs}
                sm={sm}
                lg={lg}
            >
                <TextField
                    label={label}
                    name={formName + '.' + fieldName}
                    className={classes.textField}
                    value={formik.values[formName][fieldName]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={getIn(formik.errors, formName + '.' + fieldName)
                    && formik.errors[formName][fieldName]
                    }
                    error={getIn(formik.errors, formName + '.' + fieldName)
                    && formik.errors[formName][fieldName]}
                    autoComplete='off'

                    variant="outlined"
                    fullWidth
                />
            </Grid>
    );
};
