export const actionTypes = {
    SHOW: 'show',
    EDIT: 'edit',
    ADD: 'add'
};

export const tableActionTypes = {
    DELETE: 'delete',
    STATUS: 'status',
    DOWNLOAD: 'download'
};

export const isFormDisabled = (actionType) => {
    switch (actionType) {
        case actionTypes.SHOW:
            return true;
        case actionTypes.EDIT:
            return false;
        case actionTypes.ADD:
            return false;
        default:
            return true;
    }
};
