import axios from 'axios';
import { authHeader } from '../auth/authUtils';
import { getDataTableEmpty } from '../models/emptyTable';
import { BASE_URL } from '../helpers/axiosConfig';

const merge = require('deepmerge');

/** -- TableRows preview -- **/
export const TABLE_PREVIEW_FETCH = 'table_preview_fetch';
export const TABLE_PREVIEW_FETCH_SUCCESS = 'table_preview_fetch_success';
export const TABLE_PREVIEW_FETCH_ERROR = 'table_preview_fetch_error';

export const TABLE_COUNT_FETCH = 'table_count_fetch';
export const TABLE_COUNT_FETCH_SUCCESS = 'table_count_fetch_success';
export const TABLE_COUNT_FETCH_ERROR = 'table_count_fetch_error';

/** -- TableRows -- **/

export const TABLE_DATA_FETCH = 'table_data_fetch';
export const TABLE_DATA_FETCH_SUCCESS = 'table_data_fetch_success';
export const TABLE_DATA_FETCH_ERROR = 'table_data_fetch_error';

export const TABLE_UPDATE = 'table_update';
export const TABLE_UPDATE_SUCCESS = 'table_update_success';
export const TABLE_UPDATE_ERROR = 'table_update_error';

export const TABLE_CREATE = 'table_create';
export const TABLE_CREATE_SUCCESS = 'table_create_success';
export const TABLE_CREATE_ERROR = 'table_create_error';

export const TABLE_DELETE = 'table_delete';
export const TABLE_DELETE_SUCCESS = 'table_delete_success';
export const TABLE_DELETE_ERROR = 'table_delete_error';

export const CLEAN_TABLE = 'clean_table';

const qs = require('qs');

export const cleanTable = () => {
    return dispatch => {
        dispatch({ type: CLEAN_TABLE });
    };
};

export const fetchTablePreview = (tableName, page, pageSize, sorting, filterData, myUser) => {
    let filterFields = qs.stringify(filterData);
    let params = {
        page: page,
        pageSize: pageSize,
        columnSorting: sorting.column,
        desc: sorting.desc,
        filterFields: filterFields,
        role: myUser ? myUser.role : null
    };

    return dispatch => {
        dispatch({ type: TABLE_PREVIEW_FETCH });
        axios.get(BASE_URL + `/${tableName}/preview`, {
            params, headers: authHeader(myUser)
        })
            .then(res => {
                dispatch({
                    type: TABLE_PREVIEW_FETCH_SUCCESS,
                    payload: {
                        rows: res.data.rows,
                        numRows: res.data.numRows,
                        tableName: tableName
                    }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: TABLE_PREVIEW_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchCountTableRows = (tableName, myUser) => {
    return dispatch => {
        dispatch({ type: TABLE_COUNT_FETCH });
        axios.get(BASE_URL + `/${tableName}/count`, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: TABLE_COUNT_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: TABLE_COUNT_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchTableData = (tableName, rowid, myUser) => {
    return dispatch => {
        dispatch({ type: TABLE_DATA_FETCH });
        axios.get(BASE_URL + `/${tableName}`, {
            params: {
                rowid: rowid
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const tableData = getDataTableEmpty(tableName); // TODO fix
                const row = res.data[0];
                dispatch({
                    type: TABLE_DATA_FETCH_SUCCESS,
                    payload: row
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: TABLE_DATA_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchTableDataEmpty = (tableName) => {
    return dispatch => {
        const dataEmpty = getDataTableEmpty(tableName);
        dispatch({
            type: TABLE_DATA_FETCH_SUCCESS,
            payload: dataEmpty
        });
    };
};

export const updateRow = (tableName, rowData, rowValues, myUser) => {
    return dispatch => {
        dispatch({ type: TABLE_UPDATE });
        const mergedRowData = merge(rowData, rowValues);
        const data = { rowData: mergedRowData };
        axios.put(BASE_URL + `/${tableName}/update`, data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: TABLE_UPDATE_SUCCESS,
                    payload: { tableUpdated: mergedRowData, result: res }
                });
            })
            .catch(err => {
                //console.log(err);
                dispatch({
                    type: TABLE_UPDATE_ERROR,
                    payload: err
                });
            });
    };
};

export const createRow = (tableName, rowData, rowValues, myUser) => {
    const mergedRowData = merge(rowData, rowValues);
    let data = { rowData: mergedRowData };

    return dispatch => {
        dispatch({ type: TABLE_CREATE });
        axios.put(BASE_URL + `/${tableName}/create`, data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: TABLE_CREATE_SUCCESS
                });
            })
            .catch(err => {
                //console.log(err);
                dispatch({
                    type: TABLE_CREATE_ERROR,
                    payload: err
                });
            });
    };
};


export const downloadAllDealsFiltered = (pagination) => {
    // console.log('PAGINATION', pagination);
    return dispatch => {
        // TODO
    };
};

export const downloadDealsSelected = (rowsSelected) => {
    // console.log('ROWS', rowsSelected);
    return dispatch => {
        // TODO
    };
};

export const deleteRow = (tableName, row_ids, myUser) => {
    return dispatch => {
        dispatch({ type: TABLE_DELETE });
        const data = { row_ids };
        axios.put(BASE_URL + `/${tableName}/delete`, data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: TABLE_DELETE_SUCCESS,
                    payload: { row_ids }
                });
            })
            .catch(err => {
                // console.log(err);
                dispatch({
                    type: TABLE_DELETE_ERROR,
                    payload: err
                });
            });
    };
};
