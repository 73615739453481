import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import history from '../../config/history';
import { actionTypes } from '../../helpers/actionTypes';


class RedirectPage extends Component {

    componentDidMount() {
        const company_id = this.props.match.params.company_id;
        const actionType = this.props.match.params.actionType;
        const destinationTab = this.props.location.destinationTab;

        history.push({
            pathname: '/company/' + actionType + '/' + company_id,
            destinationTab: destinationTab
        });
    }


    render() {
        return (
            <DashboardLayout title="redirecting...">

            </DashboardLayout>
        );
    }
}

export default RedirectPage;
