import React from 'react';
import { Grid } from '@material-ui/core';
import PersonaInfoForm from '../../components/Persona/PersonaInfoForm';
import AvatarBoxForm from '../../components/Company/CompanyAvatarForm';
import Spacer from '../../components/Spacer/Spacer';
import ContactsBoxProfile from '../../components/ContactsBox/contactsBox';

function ProfilePersona(props) {

    const {
        formDisabled,
        formikProps,
        personaData,
        myUser,

        onRemoveClick,
        urlAvatarSaved,
        avatarFile,
        onAvatarLoaded
    } = props;

    return (
        <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={3}>
                        <AvatarBoxForm
                            editable={!formDisabled}
                            myUser={myUser}

                            urlAvatarSaved={urlAvatarSaved}
                            avatarFile={avatarFile}

                            onRemoveClick={onRemoveClick}
                            onAvatarLoaded={onAvatarLoaded}
                        />
                        <Spacer/>
                        <ContactsBoxProfile
                            email={personaData.email}
                            website={personaData.website}
                            socials={personaData.social}
                            myUser={myUser}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={9}>
                        <PersonaInfoForm
                            formik={formikProps}
                        />
                    </Grid>
                </Grid>
        </div>
    );
}

export default ProfilePersona;
