import React from 'react';
import ContactUsFields from './ContactUsFields';
import { Formik } from 'formik';
import { scontactUsSchema } from '../../../helpers/Validation/contactUsForm';
import { subjectList } from '../../../models/contactForm/subjectList';


export default class ContactUsForm extends React.Component {

    onSubmitForm = (values, actions) => {
        this.props.handleSubmitForm(values);
    };


    render() {
        return (
            <div>
                <Formik
                    initialValues={{
                        email_sender: this.props.myUser ? this.props.myUser.email : null,
                        subject: subjectList[this.props.indexSubject].name,
                        text: null
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={scontactUsSchema}
                    onSubmit={this.onSubmitForm}
                >
                    {formikProps => (
                        <form id="contact-us-form"
                              onSubmit={formikProps.handleSubmit}
                        >
                            <ContactUsFields
                                formik={formikProps}
                                sendingEmail={this.props.sendingEmail}
                                indexSubject={this.props.indexSubject}
                            />
                        </form>
                    )}
                </Formik>

            </div>
        );
    }
};
