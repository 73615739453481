import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Material components
import { Drawer } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar, Footer } from './components';

// Component styles
import styles from './styles';
import { Container } from '../../components';
import history from '../../config/history';
import { googleSignOut, LINKEDIN } from '../../auth/authUtils';
import { bindActionCreators } from 'redux';
import {
    authMySelf,
    setAuthbox,
    signOutMySelf,
    signUpMySelf,
    signInMySelfEmailPassword,
    cleanAuth
} from '../../actions/authHandler';
import AuthBoxModal from '../../components/Auth/AuthBox/AuthBoxModal';
import { SIGN_IN_STATE } from '../../components/Auth/AuthBox/AuthForm';
import { emailSent, sendEmail } from '../../actions/contactUsHandler';
import ContactBoxModal from '../../components/ContactUs/ContactUsBox/ContactBoxModal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { toggleAdminList } from '../../actions/dashboardHandler';
import Popover from '@material-ui/core/Popover';
import NotificationRow from '../../components/Notification/NotificationRow';
import Divider from '../../components/Divider/Divider';
import { setNotificationsSeen } from '../../actions/followHandler';
import { actionTypes } from '../../helpers/actionTypes';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import AssessmentBoxModal from '../../components/AssessmentBoxModal/AssessmenBoxModal';
import SearchWrapper from '../../components/SearchWrapper/SearchWrapper';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        const isMobile = ['xs', 'sm', 'md'].includes(props.width);

        this.state = {
            drawerOpen: !isMobile,
            authBoxOpen: false,
            contactUsBoxOpen: false,
            assessmentBoxOpen: false,
            showEmailResult: false,
            snackMailSentOpen: false,
            indexSubject: 0,
            ancorElPopover: null,
            notificationPopoverOpen: false,
            managePopoverOpen: false,
            resetting: false
        };
    }

    loginMySelf = (socialAccessToken, provider, redirectUri) => {
        if (!this.props.iAmAuthenticated) {
            this.props.authMySelf(socialAccessToken, provider, redirectUri);
        }
    };

    receiveProviderTokenLinkedin = ({ provider, token, redirectUri }) => {
        this.loginMySelf(token, LINKEDIN, redirectUri);
    };

    handleCloseDrawer = () => {
        this.setState({ drawerOpen: false });
    };

    handleToggleOpenDrawer = () => {
        this.setState(prevState => ({
            drawerOpen: !prevState.drawerOpen
        }));
    };

    closeAuthBox = () => {
        this.setState({
            authBoxOpen: false
        });
    };

    closeContactUsBox = () => {
        this.setState({
            contactUsBoxOpen: false
        });
    };

    openAuthBox = () => {
        this.setState({
            authBoxOpen: true
        });
    };

    openAssessmentBox = () => {
        this.setState({
            assessmentBoxOpen: true
        });
    };

    closeAssessmentBox = () => {
        this.setState({
            assessmentBoxOpen: false
        });
    };


    openContactUsBox = (indexSubject) => {
        this.setState({
            contactUsBoxOpen: true,
            indexSubject: indexSubject ? indexSubject : 0
        });
    };

    handleClickAssessmentToolkit = () => {
        if (!this.props.myUser) {
            this.openAuthBox();
        } else {
            this.openAssessmentBox();
        }
    };


    onSignedOut = () => {
        if (this.props.myUser) {
            switch (this.props.myUser.provider) {
                case 'google':
                    googleSignOut(() => {

                    });
                    this.props.signOutMySelf();
                    break;
                case 'linkedin':
                    window.signoutLinkedin();
                    this.props.signOutMySelf();
                    break;
                default:
                    googleSignOut(() => {
                        this.props.signOutMySelf();
                    });
                    break;
            }
        } else {
            this.goToCharts();
        }
    };

    goToCharts = () => {
        history.push({
            pathname: '/overview'
        });
    };

    componentDidMount() {
        this.props.emailSent();
    }

    handleCardClick = (subjectId) => {
        if (subjectId) {
            if (this.props.activeClaims && this.props.activeClaims.includes(subjectId)) {
                history.push({
                    pathname: '/redirect/' + actionTypes.EDIT + '/' + subjectId
                });
            } else {
                history.push({
                    pathname: '/redirect/' + actionTypes.SHOW + '/' + subjectId
                });
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === false) {
            this.goToCharts();
        }
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === true) {
            this.closeAuthBox();
        }
        if (prevProps.authBoxRequired !== this.props.authBoxRequired && this.props.authBoxRequired === true) {
            this.openAuthBox();
        }

        if (prevProps.upgradeClicked !== this.props.upgradeClicked) {
            this.openContactUsBox(2);
        }

        if (prevProps.suggestUpdateClicked !== this.props.suggestUpdateClicked) {
            this.openContactUsBox(4);
        }

        if (prevProps.requestManageClicked !== this.props.requestManageClicked) {
            this.openContactUsBox(5);
        }

        if (prevProps.sendingEmail !== this.props.sendingEmail && this.props.sendingEmail === false) {
            this.closeContactUsBox();
            this.openEmailSentSnackbar();
        }
    }

    handleLoginClick = () => {
        this.openAuthBox();
    };

    openEmailSentSnackbar = () => {
        this.setState({ snackMailSentOpen: true });
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps !== this.props || nextState !== this.state) {
            return true;
        }
        return false;
    };

    submitSign = (values, state) => {
        if (state === SIGN_IN_STATE) {
            this.props.signInMySelfEmailPassword(values.email_SignIn, values.password_SignIn);
        } else {
            this.props.signUpMySelf(values, state);
        }
    };

    handleCloseSnackbarEmailSent = () => {
        this.setState({ snackMailSentOpen: false });
    };

    handleClickNotifications = (divRef) => {
        this.setState({
            ancorElPopover: divRef,
            notificationPopoverOpen: !this.state.notificationPopoverOpen
        });

        if (this.props.notificationBadgeNumber && this.props.notificationBadgeNumber > 0) {
            this.props.setNotificationsSeen(this.props.myUser);
        }
    };

    handleClickManage = (divRef) => {
        this.setState({
            ancorElPopover: divRef,
            managePopoverOpen: !this.state.managePopoverOpen
        });

    };

    handleClosePopover = () => {
        this.setState({
            notificationPopoverOpen: false,
            managePopoverOpen: false
        });
    };


    handleResetPassword = (email) => {
        if (email && email.length > 5) {
            this.openEmailSentSnackbar();
        }
        // console.log(email)
        // this.setState({ resetting: true }, () => {
        //     setTimeout(function() {
        //         handleCloseDrawer.bind(this)
        //     }, 1500);
        // });
    };


    render() {
        const { classes, width, title, children, authenticating, myUser } = this.props;
        const { drawerOpen } = this.state;

        const isMobile = ['xs', 'sm', 'md'].includes(width);
        const shiftTopbar = drawerOpen && !isMobile;
        const shiftContent = drawerOpen && !isMobile;

        return (
            <div>
                <Fragment>
                    <Topbar
                        className={classNames(classes.topbar, {
                            [classes.topbarShift]: shiftTopbar
                        })}
                        isSidebarOpen={drawerOpen}
                        onToggleSidebar={this.handleToggleOpenDrawer}
                        title={title}
                        onSignOut={this.onSignedOut}
                        myUser={this.props.myUser}
                        iAmAuthenticated={this.props.iAmAuthenticated}
                        handleLogin={this.handleLoginClick}
                        handleClickNotifications={this.handleClickNotifications}
                        notificationBadgeNumber={this.props.notificationBadgeNumber}
                        notifications={this.props.notifications}
                        manageActive={this.props.activeClaims && this.props.activeClaims.length > 0}
                        handleClickManage={this.handleClickManage}
                    />
                    <Drawer
                        anchor="left"
                        classes={{ paper: classes.drawerPaper }}
                        onClose={this.handleCloseDrawer}
                        open={drawerOpen}
                        variant={isMobile ? 'temporary' : 'persistent'}
                    >
                        <Sidebar
                            className={classes.sidebar}
                            myUser={myUser}
                            iAmAuthenticated={this.props.iAmAuthenticated}
                            iAmApproved={this.props.iAmApproved}
                            handleContactUsClick={() => this.openContactUsBox(0)}
                            handleTroubleShootingUsClick={() => this.openContactUsBox(1)}
                            handleClickUnauthRoute={this.openAuthBox}
                            handleClickUpgrade={() => this.openContactUsBox(2)}
                            adminListOpen={this.props.adminListOpen}
                            toggleAdminList={this.props.toggleAdminList}
                            handleClickAssessmentToolkit={this.handleClickAssessmentToolkit}
                        />
                    </Drawer>
                    <main
                        style={{ paddingTop: '32px' }}
                        className={classNames(classes.content, {
                            [classes.contentShift]: shiftContent
                        })}
                    >
                        <Container onClickUpgrade={() => this.openContactUsBox(2)} {...this.props}>
                            {children}
                        </Container>
                        <Footer/>
                    </main>
                </Fragment>


                <Popover
                    id={'popover-notification'}
                    open={this.state.notificationPopoverOpen}
                    anchorEl={this.state.ancorElPopover}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    style={{ maxHeight: 400 }}
                >
                    {this.props.notifications.map(n => (
                        <div>
                            <NotificationRow
                                key={n.id}
                                notificationObj={n}
                                onCardClick={() => this.handleCardClick(n.subject_id)}
                            />
                            <Divider/>
                        </div>
                    ))}
                </Popover>

                <Popover
                    id={'popover-manage'}
                    open={this.state.managePopoverOpen}
                    anchorEl={this.state.ancorElPopover}
                    onClose={this.handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    style={{ maxHeight: 400 }}
                >
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            My Companies
                        </Typography>
                    </CardContent>

                    {this.props.myCompanies ?
                        this.props.myCompanies.map(c => (
                            <div>
                                <NotificationRow
                                    key={c.company_id}
                                    notificationObj={c}
                                    onCardClick={() => this.handleCardClick(c.company_id)}
                                    fromManage
                                />
                                <Divider/>
                            </div>
                        ))
                        : null}
                </Popover>

                <AuthBoxModal
                    open={this.state.authBoxOpen}
                    handleClose={this.closeAuthBox}
                    authenticating={authenticating}
                    iAmAuthenticated={this.props.iAmAuthenticated}
                    iAmApproved={this.props.iAmApproved}
                    receiveProviderTokenLinkedin={this.receiveProviderTokenLinkedin}
                    loginMySelf={this.loginMySelf}
                    fetching={this.props.loadingAuth || this.props.authenticating || this.state.resetting}
                    emailAlreadyExists={this.props.emailAlreadyExists}
                    submitSign={this.submitSign}
                    wrongPassword={this.props.wrongPassword}
                    emailNotExists={this.props.emailNotExists}
                    sendResetPassword={this.handleResetPassword}
                />

                <SearchWrapper>
                    <AssessmentBoxModal
                        open={this.state.assessmentBoxOpen}
                        handleClose={this.closeAssessmentBox}
                    />
                </SearchWrapper>

                <ContactBoxModal
                    open={this.state.contactUsBoxOpen}
                    handleClose={this.closeContactUsBox}
                    sendingEmail={this.props.sendingEmail}
                    sendEmail={(values) => this.props.sendEmail(values, this.props.myUser, this.props.companyNameSuggestion, this.props.companyIdSuggestion)}
                    indexSubject={this.state.indexSubject}
                    myUser={this.props.myUser}
                />
                <Snackbar open={this.state.snackMailSentOpen} autoHideDuration={6000}
                          onClose={this.handleCloseSnackbarEmailSent}>
                    <Alert onClose={this.handleCloseSnackbarEmailSent} severity="success">
                        Message Sent!
                    </Alert>
                </Snackbar>
            </div>

        );
    }
}

Dashboard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    width: PropTypes.string.isRequired
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signOutMySelf,
        signUpMySelf,
        authMySelf,
        setAuthbox,
        signInMySelfEmailPassword,
        cleanAuth,
        sendEmail,
        emailSent,
        toggleAdminList,
        setNotificationsSeen
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        iAmAuthenticated: state.authHandler.iAmAuthenticated,
        iAmApproved: state.authHandler.iAmApproved,
        authenticating: state.authHandler.authenticating,

        myUser: state.authHandler.myUser,
        loadingAuth: state.authHandler.loadingAuth,
        emailAlreadyExists: state.authHandler.emailAlreadyExists,
        wrongPassword: state.authHandler.wrongPassword,
        emailNotExists: state.authHandler.emailNotExists,

        authBoxRequired: state.authHandler.authBoxRequired,

        sendingEmail: state.contactUsHandler.sendingEmail,

        upgradeClicked: state.mainHandler.upgradeClicked,
        suggestUpdateClicked: state.mainHandler.suggestUpdateClicked,
        requestManageClicked: state.mainHandler.requestManageClicked,

        companyNameSuggestion: state.mainHandler.companyNameSuggestion,
        companyIdSuggestion: state.mainHandler.companyIdSuggestion,

        adminListOpen: state.dashboardHandler.adminListOpen,
        notifications: state.followHandler.notifications,
        notificationBadgeNumber: state.followHandler.notificationBadgeNumber,

        activeClaims: state.mainHandler.activeClaims,
        myCompanies: state.mainHandler.myCompanies
    };
};

const enhance = compose(
    withStyles(styles),
    withWidth(),
    connect(mapStateToProps, mapDispatchToProps));

export default enhance(Dashboard);
