import classNames from 'classnames';
import { Paper, PortletContent, PortletHeader, PortletLabel } from '../../index';
import { Grid, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Spacer from '../../Spacer/Spacer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import { capitalSoughtTypes } from '../../../models/typeList/extCapitalSoughtTypes';

import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AcceleratorList from '../AcceleratorList';
import { supportTypes } from '../../../models/typeList/supportTypes';
import { getAssessmentPortletHeader } from '../../../strings/genericStrings';


const SupportForm = (props) => {
    const {
        classes, className, formik, editable,
        accelerators, myUser, openDialogSearchCompany, handleClickCompany, handleClickRemoveAccelerator,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title={getAssessmentPortletHeader(formik.values.company.category)}
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    {formik.values.company.category === 'startup' ? <Grid
                            container
                            item
                            xs={12} sm={6} lg={4}
                            className={classes.field}
                        >
                            <FormControlLabel
                                control={

                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.company.fundraising}
                                        onChange={
                                            () => formik.setFieldValue('company.fundraising',
                                                !formik.values.company.fundraising)
                                        }
                                        value={formik.values.company.fundraising}
                                        color="primary"
                                    />

                                }
                                label="Fundraising"
                            />
                        </Grid>
                        : null}
                    <Spacer small/>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={6}
                    >
                        <Autocomplete
                            multiple
                            id="ecst-tags"
                            name="ext_capital_sought_types"
                            disabled={editable}
                            className={classes.textField}
                            options={capitalSoughtTypes}
                            value={formik.values.ext_capital_sought_types}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                formik.setFieldValue('ext_capital_sought_types', value);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        color="primary"
                                        style={{ backgroundColor: '#888FCA' }}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={formik.values.company.category === 'startup' ? "Type of external capital sought"
                                    : "Type of investment"}
                                    variant="outlined"
                                    // placeholder="Select multiple types"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} lg={4}
                    >
                        <CurrencyTextField
                            currencySymbol="$"
                            minimumValue="0"
                            outputFormat='number'
                            digitGroupSeparator=","
                            decimalPlaces={0}

                            label="Capital Sought"
                            name={`company.ext_capital_sought`}
                            className={classes.textField}
                            value={formik.values.company.ext_capital_sought}
                            onChange={(e, v) => {
                                e.target.value = v;
                                formik.handleChange(e);
                            }}
                            onBlur={formik.handleBlur}
                            autoComplete='off'

                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            fullWidth
                            // error={!isNumeric(formik.values.company.ext_capital_sought)}
                        />
                    </Grid>
                    <Spacer small divider/>
                    <Grid
                        container
                        item
                        xs={24} sm={12} lg={8}
                        className={classes.field}
                    >
                        <Tooltip
                            title={'e.g. incubator or accelerator'}
                            placement="top"
                        >
                            <FormControlLabel
                                control={

                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.company.past_accelerated}
                                        onChange={
                                            () => formik.setFieldValue('company.past_accelerated',
                                                !formik.values.company.past_accelerated)
                                        }
                                        value={formik.values.company.past_accelerated}
                                        color="primary"
                                    />

                                }
                                label={'Past Participation in hub support programme'}
                            />
                        </Tooltip>
                    </Grid>

                    {formik.values.company.category === 'startup'
                    && formik.values.company.past_accelerated
                    && formik.values.company.past_accelerated === true ?
                        <Grid
                            item
                            xs={12} sm={8} lg={4}
                            className={classes.field}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={openDialogSearchCompany}
                            >
                                ADD ACCELERATOR
                            </Button>
                        </Grid>
                        : null}


                    {formik.values.company.category === 'startup' && formik.values.company.past_accelerated && formik.values.company.past_accelerated === true ?
                        <Grid
                            container
                        >
                            <AcceleratorList
                                accelerators={accelerators}
                                handleClickCompany={handleClickCompany}
                                handleClickRemoveAccelerator={handleClickRemoveAccelerator}
                                myUser={myUser}
                            />
                            <Spacer small divider/>
                        </Grid>

                        : null}

                    {formik.values.company.category === 'startup' ?
                        <Grid
                            container
                            item
                            xs={24} sm={12} lg={8}
                            className={classes.field}
                        >
                            <Tooltip
                                title={'e.g. incubator or accelerator'}
                                placement="top"
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            className={classes.switch}
                                            checked={formik.values.company.looking_acceleration}
                                            onChange={
                                                () => formik.setFieldValue('company.looking_acceleration',
                                                    !formik.values.company.looking_acceleration)
                                            }
                                            value={formik.values.company.looking_acceleration}
                                            color="primary"
                                        />
                                    }
                                    label={formik.values.company.category === 'startup'
                                        ? 'Looking for support programmes'
                                        : 'Participated in support programme'}
                                />
                            </Tooltip>
                        </Grid>
                        : null}

                    {formik.values.company.looking_acceleration && formik.values.company.looking_acceleration === true ?
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={6}
                        >
                            <Autocomplete
                                multiple
                                id="accelerationTypes-tags"
                                name="looking_acceleration_ids"
                                disabled={editable}
                                className={classes.textField}
                                options={supportTypes}
                                value={formik.values.looking_acceleration_ids}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => {
                                    formik.setFieldValue('looking_acceleration_ids', value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            color="primary"
                                            style={{ backgroundColor: '#888FCA' }}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Types of support sought"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        : null}


                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(SupportForm);
