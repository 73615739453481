import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';
import { ROLES } from '../../../helpers/Roles/roleActions';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { getIn } from 'formik';
import { statusList } from '../../../models/company/company';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const UserInfoForm = (props) => {
    const { classes, className, formik, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="User Info"
                    subtitle="Manage the user information"
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Name"
                                name="user.first_name"
                                className={classes.textField}
                                value={formik.values.user.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'user.first_name')
                                    && formik.errors.user.first_name
                                }
                                error={getIn(formik.errors, 'user.first_name')
                                && formik.errors.user.first_name
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Last Name"
                                name="user.last_name"
                                className={classes.textField}
                                value={formik.values.user.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'user.last_name')
                                    && formik.errors.user.last_name
                                }
                                error={getIn(formik.errors, 'user.last_name')
                                && formik.errors.user.last_name
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Email"
                                name="user.email"
                                className={classes.textField}
                                value={formik.values.user.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'user.email')
                                    && formik.errors.user.email
                                }
                                error={getIn(formik.errors, 'user.email')
                                && formik.errors.user.email
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                className={classes.textField}
                                label="Role"
                                name="user.role"
                                select
                                SelectProps={{ native: true }}
                                value={formik.values.user.role}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                            >
                                {ROLES.map(option => (
                                    <option
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                className={classes.textField}
                                label="Status"
                                name="user.status"
                                select
                                SelectProps={{ native: true }}
                                value={formik.values.user.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                            >
                                {statusList.map(option => (
                                    <option
                                        key={option.status_id}
                                        value={option.value}
                                    >
                                        {option.status_name}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={5}
                        >
                            <FormControlLabel
                                disabled
                                control={
                                    <Switch
                                        checked={formik.values.user.email_confirmed}
                                        // onChange={
                                        //     () => formik.setFieldValue('user.email_confirmed',
                                        //         !formik.values.user.email_confirmed)
                                        // }
                                        value={formik.values.user.email_confirmed}
                                        color="primary"
                                    />
                                }
                                label="Email Confirmed"
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(UserInfoForm);
