const parseNameLastname = (nameSurnameString) => {
  let name = '';
  let concatenatedLastname = '';

  if (nameSurnameString && nameSurnameString.length > 0) {
    let splitted = nameSurnameString.split(' ');
    if (splitted.length > 0) {
      if (splitted[0] && splitted[0] !== '') name = splitted[0];
      if (splitted[1]) {
        concatenatedLastname = splitted[1];
        if (splitted[2]) {
          for (let i = 2; i < splitted.length; i++) {
            concatenatedLastname = concatenatedLastname.concat(' ', splitted[i]);
          }
        }
      }
    }
  }

  return [name, concatenatedLastname];
};

export { parseNameLastname };