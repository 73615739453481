export const GENERAL = 'General';
export const TROUBLESHOOTING = 'Troubleshooting';
export const UPGRADE = 'Upgrade';
export const PRICING = 'Pricing';
export const UPDATE_SUGGESTION = 'Update Suggestion';
export const CLAIM_PROFILE = 'Claim Profile';

export const subjectList = [
    {
        id: 0,
        name: GENERAL,
        value: GENERAL
    },
    {
        id: 1,
        name: TROUBLESHOOTING,
        value: TROUBLESHOOTING
    },
    {
        id: 2,
        name: UPGRADE,
        value: UPGRADE
    },
    {
        id: 3,
        name: PRICING,
        value: PRICING
    },
    {
        id: 4,
        name: UPDATE_SUGGESTION,
        value: UPDATE_SUGGESTION
    },
    {
        id: 5,
        name: CLAIM_PROFILE,
        value: CLAIM_PROFILE
    }
];

export const subjectListNoClaim = [
    {
        id: 0,
        name: GENERAL,
        value: GENERAL
    },
    {
        id: 1,
        name: TROUBLESHOOTING,
        value: TROUBLESHOOTING
    },
    {
        id: 2,
        name: UPGRADE,
        value: UPGRADE
    },
    {
        id: 3,
        name: PRICING,
        value: PRICING
    },
    {
        id: 4,
        name: UPDATE_SUGGESTION,
        value: UPDATE_SUGGESTION
    }
];
