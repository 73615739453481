import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    addEmptyMention,
    addFileToDocuments,
    removeFileToDocuments,
    removeMention, updateMention
} from '../../actions/companyHandler';
import CompanyMentionsForm from '../../components/Company/CompanyMentionsForm/CompanyMentionsForm';

class MentionsTab extends Component {

    uploadDocument = (doc) => {
        const company_id = this.props.companyId;
        this.props.uploadDocument(doc, company_id, this.props.myUser);
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        {this.props.myUser ?
                            <CompanyMentionsForm
                                formDisabled={this.props.formDisabled}
                                companyMentions={this.props.companyMentions}
                                myUser={this.props.myUser}
                                handleClickAdd={this.props.addEmptyMention}
                                handleClickRemove={this.props.removeMention}
                                onBlur={this.props.updateMention}
                            />
                            : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = {
    addEmptyMention,
    removeMention,
    updateMention
};

const mapStateToProps = (state) => {
    return {
        companyMentions: state.companyHandler.companyMentions,
        fetchingCompanyMentions: state.companyHandler.fetchingCompanyMentions,
        companyMentionsError: state.companyHandler.companyMentionsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MentionsTab);
