import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withRouter } from 'react-router-dom';
import styles from '../styles';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../helpers/stringUtils';
import AvatarNameCell from '../AvatarNameCell';
import EllipsisText from 'react-ellipsis-text';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import history from '../../../config/history';
import { INNER_TEAM } from '../../../helpers/Roles/roleActions';
import { actionTypes } from '../../../helpers/actionTypes';

class DealTableFormRow extends Component {

    removeDuplicates = (investors) => {
        let names = [];
        let noDuplicates = [];
        for (let investor of investors) {
            if (investor && !names.includes(investor.name)) {
                names.push(investor.name);
                noDuplicates.push(investor);
            }
        }
        return noDuplicates;
    };

    onClickCompany = (company_id) => {
        if (INNER_TEAM.includes(this.props.myUser.role)) {
            history.push({
                pathname: '/redirect/edit/' + company_id
            });
        } else {
            history.push({
                pathname: '/redirect/show/' + company_id
            });
        }
    };


    render() {
        const {
            isItemSelected,
            row,
            labelId,
            onClickRow
        } = this.props;

        return (
            <TableRow
                hover
                aria-checked={isItemSelected}
                tabIndex={-1}
            >
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={() => this.onClickCompany(row.company_id)}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.avatar_url}
                        name={row.company_name}
                        truncateLength={30}
                    />
                </TableCell>
                <TableCell>
                    {capitalizeFirstLetter(row.stage)}
                </TableCell>
                <TableCell>
                    {row.amount ? (parseInt(row.amount)).toLocaleString('en-US') : 'Undisclosed'}
                </TableCell>
                <TableCell>
                    {row.date && moment(row.date).isValid() ? moment(row.date).utc().format('DD/MM/YYYY') : null}
                </TableCell>
                <TableCell>
                    {row.investors ?
                        this.removeDuplicates(row.investors)
                            .map(investor => investor ?
                                <div onClick={() => this.onClickCompany(investor.company_id)}
                                     style={{ cursor: 'pointer' }}>
                                    <Link>
                                        <EllipsisText
                                            style={{ fontWeight: 'bold' }}
                                            tooltip
                                            text={investor.name}
                                            length={25}
                                        />
                                    </Link>
                                </div> : null)
                        : null}
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(DealTableFormRow);
