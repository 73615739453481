import React, { useRef } from 'react';
import classNames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';
import { TEAM } from '../../../helpers/Roles/roleActions';

import { Paper, PortletContent, PortletFooter } from '../../index';

import styles from './styles';
import CompanyAvatar from '../CompanyAvatar';
import Button from '@material-ui/core/Button';
import ButtonExtended from '../../UI/ButtonExtended/ButtonExtended';
import Spacer from '../../Spacer/Spacer';
import Typography from '@material-ui/core/Typography';

const AvatarBoxForm = (props) => {
    const fileInput = useRef(null);
    const {
        classes, className,
        name,
        onRemoveClick, urlAvatarSaved, avatarFile, onAvatarLoaded,
        handleSuggestUpdate, handleRequestManage, requestManageDisabled, pendingClaims, activeClaims, company_id,
        handleFollowCompany, disabledFollow, companiesFollowed,
        editable,
        myUser,
        ...rest
    } = props;

    const rootClassName = classNames(props.classes.root, props.className);

    const handleUploadClick = () => {
        fileInput.current.click();
    };

    const handleFileChange = e => {
        if (e.target.files && e.target.files.length !== 0) {
            onAvatarLoaded(e.target.files[0]);
        }
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent noPadding>
                <input
                    type="file"
                    onChange={(e) => handleFileChange(e)}
                    ref={fileInput}
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg"
                />
                {/*{console.log(pendingClaims)}*/}
                <Grid container spacing={1} alignItems="flex-start" justify="space-between" style={{ padding: '10px' }}>
                    <Grid container item xs justify="flex-start" alignItems="center">
                        <Grid container item xs={12} sm={12} lg={12} justify="center"
                              alignItems="center">
                            <Grid item xs={6} sm={6} lg={6}>
                                <CompanyAvatar
                                    urlAvatarSaved={urlAvatarSaved}
                                    avatarFile={avatarFile}
                                />
                            </Grid>
                        </Grid>
                        <Spacer xsmall/>
                        <Grid item xs={12} sm={12} lg={12}>
                            <Typography variant="subtitle1" align="center">
                                {name ? name : null}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </PortletContent>
            {myUser && editable ?
                <PortletFooter className={classes.portletFooter}>
                    <Grid container spacing={1} alignItems="center" justify="space-between">
                        <Grid item>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={handleUploadClick}
                            >
                                Upload
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                onClick={onRemoveClick}
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>
                </PortletFooter>
                : null}
            {myUser && !editable && !TEAM.includes(myUser.role) ?
                <PortletFooter className={classes.portletFooter}>
                    <Grid spacing={2}
                          alignItems="center"
                          justify="center"
                          align="center"
                    >
                        <Grid item>
                            <ButtonExtended
                                variant="contained"
                                color='action'
                                onClick={handleSuggestUpdate}
                            >
                                SUGGEST AN UPDATE
                            </ButtonExtended>
                        </Grid>
                        {activeClaims && !activeClaims.includes(company_id) ?
                            <div>
                                <Spacer/>
                                <Grid item>
                                    <ButtonExtended
                                        variant="contained"
                                        color='success'
                                        onClick={handleRequestManage}
                                        disabled={requestManageDisabled || (pendingClaims && pendingClaims.includes(company_id))}
                                    >
                                        REQUEST TO MANAGE
                                    </ButtonExtended>
                                </Grid>
                            </div>
                            : null
                        }
                        {/*<Spacer/>*/}
                        {/*<Grid item>*/}
                        {/*    <ButtonExtended*/}
                        {/*        variant="contained"*/}
                        {/*        color='info'*/}
                        {/*        onClick={handleFollowCompany}*/}
                        {/*        disabled={disabledFollow}*/}
                        {/*    >*/}
                        {/*        {(companiesFollowed && companiesFollowed.includes(company_id))*/}
                        {/*            ? 'FOLLOWING'*/}
                        {/*            : 'FOLLOW'*/}
                        {/*        }*/}
                        {/*    </ButtonExtended>*/}
                        {/*</Grid>*/}
                    </Grid>
                </PortletFooter> : null
            }
        </Paper>

    );
};

export default withStyles(styles)(AvatarBoxForm);
