import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { getIn } from 'formik';
import { TITLE_ROW } from '../../../strings/genericStrings';
import GridField from '../../GridField/GridField';


const GenericProfileForm = (props) => {
    const { classes, className, formik, tableName, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={2}
        >
            <PortletHeader>
                <PortletLabel
                    title={TITLE_ROW[tableName]}
                    // subtitle="Manage the ?? information"
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={6}
                        >
                            <TextField
                                label="Name"
                                name="name"
                                className={classes.textField}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                    getIn(formik.errors, 'name')
                                    && formik.errors.name
                                }
                                error={getIn(formik.errors, 'name')
                                && formik.errors.name
                                }
                                autoComplete='off'
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(GenericProfileForm);
