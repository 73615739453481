import axiosApp, { BASE_URL } from '../helpers/axiosConfig';
import { authHeader } from '../auth/authUtils';
import axios from 'axios';

const qs = require('qs');

export const CLAIMS_PREVIEW_FETCH = 'claims_preview_fetch';
export const CLAIMS_PREVIEW_FETCH_SUCCESS = 'claims_preview_fetch_success';
export const CLAIMS_PREVIEW_FETCH_ERROR = 'claims_preview_fetch_error';

export const UPDATE_CLAIM_STATUS = 'update_claim_status';
export const UPDATE_CLAIM_STATUS_SUCCESS = 'update_claim_status_success';
export const UPDATE_CLAIM_STATUS_ERROR = 'update_claim_status_error';

export const fetchClaimsPreview = (page, pageSize, sorting, filterData, myUser) => {
    // let filterFields = qs.stringify(filterData);

    let params = {
        page: page,
        pageSize: pageSize,
        columnSorting: sorting.column,
        desc: sorting.desc,
        // filterFields: filterFields,
        role: myUser.role
    };

    return dispatch => {
        dispatch({ type: CLAIMS_PREVIEW_FETCH });
        axiosApp.get(BASE_URL + '/claim/company-previews/', {
            params, headers: authHeader(myUser)
        })
            .then(res => {
                dispatch({
                    type: CLAIMS_PREVIEW_FETCH_SUCCESS,
                    payload: {
                        rows: res.data.rows,
                        numRows: res.data.numRows
                    }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: CLAIMS_PREVIEW_FETCH_ERROR,
                    payload: err
                });
            });
    };
};


export const updateClaimStatus = (claim_id, newStatus, myUser) => {
    return dispatch => {
        dispatch({ type: UPDATE_CLAIM_STATUS });
        const data = { claim_id, newStatus };
        axios.put(BASE_URL + '/claim/update-status', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: UPDATE_CLAIM_STATUS_SUCCESS,
                    payload: { claim_id, newStatus }
                });
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_CLAIM_STATUS_ERROR,
                    payload: err
                });
            });
    };
};
