import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import styles from './styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import SnackbarContentWrapper from '../UI/SnackbarContentWrapper/SnackbarContentWrapper';
import CollapseElement from '../UI/CollapseElement/CollapseElement';


class CSVPreview extends Component {

    render() {
        const { classes } = this.props;

        //let status;
        let errorsDatapoint = null;

        const checkErrors = () => {
            if (this.props.datapoint_errors.length === 0) {
                // status = <p style={{ color: 'green' }}>Status</p>;
            } else {
                // status = <p style={{ color: 'red' }}>Status</p>;

                errorsDatapoint = this.props.datapoint_errors.map(error => {
                    return (
                        <ListItem button divider className={classes.nested}>
                            <ListItemText primary={error}/>
                        </ListItem>

                    );
                });
            }
        };

        checkErrors();

        return (
            <Box>
                {this.props.csv_loaded ?
                    (this.props.validHeader) ?
                        (this.props.datapoint_errors.length === 0) ?
                            <SnackbarContentWrapper
                                variant="success"
                                style={{ marginBottom: '10px' }}
                                message={'All rows look great!!! :)'}
                            />
                            :
                            <CollapseElement variant={'error'} textColor={'textWhite'} message={'Error'}>
                                <List component="nav" className={classes.root}
                                      style={{ paddingTop: '0', backgroundColor: '#fff', paddingBottom: '0' }}>
                                    {errorsDatapoint}
                                </List>
                            </CollapseElement>
                        :
                        <SnackbarContentWrapper
                            variant="error"
                            style={{ marginBottom: '10px' }}
                            message={'Invalid Datapoint'}
                        />
                    : null
                }
            </Box>

        );
    }
}

CSVPreview.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CSVPreview);
