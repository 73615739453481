import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getIn } from 'formik';
import LinearProgress from '@material-ui/core/LinearProgress';
import { subjectList, subjectListNoClaim } from '../../../models/contactForm/subjectList';

export default function ContactUsFields(props) {

    const { formik, sendingEmail, indexSubject } = props;

    const classes = useStyles();

    return (
        <div className={classes.form}>
            <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email_sender"
                label="Your Email"
                autoComplete="email"
                autoFocus
                disabled={sendingEmail || indexSubject === 5}

                name="email_sender"
                value={formik.values.email_sender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

                helperText={
                    getIn(formik.errors, 'email_sender')
                    && formik.errors.email_sender
                }
                error={getIn(formik.errors, 'email_sender')
                && formik.errors.email_sender
                }
            />
            <TextField
                className={classes.textField}
                label="Subject"
                name="subject"
                select
                SelectProps={{ native: true }}
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={indexSubject === 5 || indexSubject === 4}

                variant="outlined"
                fullWidth
            >
                {indexSubject === 5 ? subjectList.map(option => (
                        <option
                            key={option.id}
                            value={option.value}
                        >
                            {option.name}
                        </option>
                    )) :
                    subjectListNoClaim.map(option => (
                        <option
                            key={option.id}
                            value={option.value}
                        >
                            {option.name}
                        </option>
                    ))
                }
            </TextField>
            <TextField
                label="Your Message"
                name="text"
                disabled={sendingEmail}
                className={classes.textField}
                value={formik.values.text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                    getIn(formik.errors, 'text')
                    && formik.errors.text
                }
                error={getIn(formik.errors, 'text')
                && formik.errors.text
                }
                margin="dense"
                variant="outlined"
                multiline
                rows="5"
                fullWidth
            />

            {sendingEmail ?
                <LinearProgress/>
                : null
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                form="contact-us-form"
                value="Submit"
                disabled={sendingEmail}
            >
                Submit
            </Button>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
