import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import {
    Facebook,
    Instagram,
    Twitter,
    LinkedIn,
    Email,
    Language
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export default function ContactsBoxDash(props) {

    const { className, dataInfo, ...rest } = props;

    const classes = useStyles();

    return (
        <div>
            {dataInfo ?
                <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '25px' }}>
                    {props.dataInfo.linkedin ?
                        <IconButton disabled={!props.dataInfo.linkedin} href={props.dataInfo.linkedin}
                                    target="_blank">
                            <LinkedIn/>
                        </IconButton>
                        : null
                    }
                    {props.dataInfo.twitter ?
                        <IconButton disabled={!props.dataInfo.twitter} href={props.dataInfo.twitter}
                                    target="_blank">
                            <Twitter/>
                        </IconButton>
                        : null
                    }
                    {/*{props.dataInfo.facebook ?*/}
                    {/*    <IconButton disabled={!props.dataInfo.facebook} href={props.dataInfo.facebook}*/}
                    {/*                target="_blank">*/}
                    {/*        <Facebook/>*/}
                    {/*    </IconButton>*/}
                    {/*    : null*/}
                    {/*}*/}
                    {props.dataInfo.instagram ?
                        <IconButton disabled={!props.dataInfo.instagram} href={props.dataInfo.instagram}
                                    target="_blank">
                            <Instagram/>
                        </IconButton>
                        : null
                    }
                </div>

                : null
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({}));
