import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { getTableRow } from './rowSelector';
import Grid from '@material-ui/core/Grid';
import SearchField from '../SearchAsync';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import GetApp from '@material-ui/icons/GetApp';


function EnhancedTableHead(props) {
    const {
        classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
        checkable, sortable
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {checkable ?
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                    : null}
                {props.headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={sortable && orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                            disabled={!headCell.sortable}
                            style={{ fontWeight: 'bold' }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    title: {
        flex: '1 1 100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    searchField: {
        marginRight: theme.spacing(1),
        minWidth: '250px',
        backgroundColor: 'white'
    }
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const {
        numSelected, onDelete, handleClickDownloadSelected, handleClickDownloadAll, requestingDownloadCsv, handleAddEntry, addEnabled, deleteEnabled, downloadEnabled,
        searching, handleInputSearchChange, searchVisible,
        toolbarVisible
    } = props;

    return (
        <div>
            {toolbarVisible ?
                <div>
                    <Toolbar
                        className={clsx(classes.root, {
                            [classes.highlight]: numSelected > 0
                        })}
                    >
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            <Grid item xs={12} lg={9}>
                                {numSelected > 0 ? (
                                        <div style={{ display: 'inline-flex', flexDirection: 'row' }}>
                                            <Typography className={classes.title} color="inherit" variant="subtitle1">
                                                {numSelected} selected
                                            </Typography>
                                            {deleteEnabled ?
                                                <Tooltip title="Delete">
                                                    <IconButton aria-label="delete" onClick={onDelete}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                : null}
                                            {downloadEnabled ?
                                                <Tooltip title="Download CSV">
                                                    <IconButton aria-label="delete" onClick={handleClickDownloadSelected}>
                                                        <GetApp/>
                                                    </IconButton>
                                                </Tooltip> : null
                                            }

                                        </div>
                                    ) :

                                    <div>
                                        {
                                            addEnabled &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon/>}
                                                onClick={handleAddEntry}
                                            >
                                                ADD
                                            </Button>
                                        }
                                        {
                                            downloadEnabled &&
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                startIcon={<GetApp/>}
                                                onClick={handleClickDownloadAll}
                                                style={{ marginLeft: '5px' }}
                                                disabled={requestingDownloadCsv === true}
                                            >
                                                DOWNLOAD
                                            </Button>
                                        }
                                    </div>


                                }
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                {numSelected === 0 && searchVisible ?
                                    <SearchField
                                        searching={searching}
                                        handleInputSearchChange={handleInputSearchChange}
                                    />
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Toolbar>
                </div>
                : null
            }
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {},
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

export default function EnhancedTable(props) {
    const {
        rows,
        headCells,
        tableType,
        numTotalRows,
        page,
        onChangePageSize,
        onChangeSorting,
        onPageChange,

        fromCompare,
        tableSide,

        changeStatus,

        pagination,

        myUser,

        handleClickStatus,
        handleClickDelete,
        multipleDelete,
        onUpdateSuccess,

        searchVisible,
        searching,
        optionsResult,
        selectOption,
        handleInputSearchChange,

        toolbarVisible,
        paginationVisible,
        checkable,
        sortable,

        onClickRow,
        handleAddEntry,
        addEnabled,
        deleteEnabled,
        downloadEnabled,

        onClickInvestor,

        handleClickMultipleDownload,
        handleClickDownloadAll,
        requestingDownloadCsv
    } = props;

    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState(headCells[0].id);
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        if (onUpdateSuccess === true) {
            setSelected([]);
        }
    }, [onUpdateSuccess]);

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        onChangeSorting(property, isDesc);
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.idrow);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClickCheckbox = (event, idrow) => {
        const selectedIndex = selected.indexOf(idrow);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idrow);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = event => {
        onChangePageSize(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    const emptyRows = 0;

    const handleOnDelete = () => {
        if (multipleDelete) {
            multipleDelete(selected);
        }
    };

    const handleOnDownload = () => {
        if (handleClickMultipleDownload) {
            handleClickMultipleDownload(selected);
        }
    };

    const tableSizeXS = tableType === 'sector' || tableType === 'sub_sector' || tableType === 'sdg' ? 12 : 12;
    const tableSizeLG = tableType === 'sector' || tableType === 'sub_sector' || tableType === 'sdg' ? 6 : 12;

    return (
        <Grid className={classes.root} container xs>
            <Grid item xs={tableSizeXS} lg={tableSizeLG}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        onDelete={handleOnDelete}
                        searchVisible={searchVisible}
                        searching={searching}
                        optionsResult={optionsResult}
                        selectOption={selectOption}
                        handleInputSearchChange={handleInputSearchChange}
                        toolbarVisible={toolbarVisible}
                        handleAddEntry={handleAddEntry}
                        addEnabled={addEnabled}
                        deleteEnabled={deleteEnabled}

                        downloadEnabled={downloadEnabled}
                        handleClickDownloadSelected={handleOnDownload}
                        handleClickDownloadAll={handleClickDownloadAll}
                        requestingDownloadCsv={requestingDownloadCsv}
                    />
                    {paginationVisible ?
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={numTotalRows}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            onChangeSorting={handleRequestSort}
                        />
                        : null}
                    <TableContainer>
                        {rows && rows.length > 0 ?
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    headCells={headCells}
                                    checkable={checkable}
                                    sortable={sortable}
                                    fromCompare={fromCompare}
                                />
                                <TableBody>
                                    {rows
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.idrow);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                getTableRow(
                                                    tableType,
                                                    handleClickCheckbox,
                                                    isItemSelected,
                                                    row,
                                                    labelId,
                                                    handleClickStatus,
                                                    handleClickDelete,
                                                    myUser,
                                                    selected.length > 0,
                                                    onClickRow,
                                                    fromCompare,
                                                    tableSide,
                                                    changeStatus,
                                                    pagination,
                                                    onClickInvestor
                                                )
                                            );

                                        })}
                                    {/*{emptyRows > 0 && (*/}
                                    {/*    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>*/}
                                    {/*        <TableCell colSpan={6}/>*/}
                                    {/*    </TableRow>*/}
                                    {/*)}*/}
                                </TableBody>
                            </Table>
                            : null}
                    </TableContainer>
                    {paginationVisible ?
                        <TablePagination
                            rowsPerPageOptions={[25, 50]}
                            component="div"
                            count={numTotalRows}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            onChangeSorting={handleRequestSort}
                        />
                        : null}
                </Paper>
            </Grid>
        </Grid>
    );
}
