import React, { useRef } from 'react';
import classNames from 'classnames';
import { withStyles, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import UserInfo from '../../Team/UserInfo';
import ConfirmDialog from '../../ConfirmDialog';
import { ADD_PERSON_TEAM, can, DELETE_DOCUMENT, DELETE_PERSON_TEAM } from '../../../helpers/Roles/roleActions';
import DocumentInfo from '../../Document/DocumentInfo';
import ButtonExtended from '../../UI/ButtonExtended/ButtonExtended';
import Add from '@material-ui/icons/Add';
import Spacer from '../../Spacer/Spacer';
import MentionInfo from '../../Mention/MentionInfo';


const CompanyMentionsForm = (props) => {

    const {
        classes, className,
        formDisabled,
        myUser,
        companyMentions,
        handleClickAdd,
        handleClickRemove,
        onBlur,
        ...rest
    } = props;


    return (
        <div>
            {!formDisabled ?
                <ButtonExtended
                    startIcon={<Add/>}
                    variant="contained"
                    color='success'
                    style={{ 'text-transform': 'none' }}
                    onClick={handleClickAdd}
                >
                    ADD
                </ButtonExtended>
                : null
            }
            <Spacer/>
            <Grid
                container
                spacing={2}
            >
                <Grid container item xs={12} spacing={2}>
                    {companyMentions ?
                        companyMentions.map(mention => {
                            return (
                                <Grid item xs={12} sm={6} lg={6}>
                                    <MentionInfo
                                        onClickDelete={() => handleClickRemove(mention)}
                                        mention={mention}
                                        canDelete={can(myUser, DELETE_DOCUMENT) && !formDisabled}
                                        formDisabled={formDisabled}
                                        onBlur={(url) => onBlur(url, mention)}
                                    />
                                </Grid>
                            );
                        })
                        : null
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(CompanyMentionsForm);
