import classNames from 'classnames';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import React from 'react';
import {
    Business,
    Category,
    CategoryOutlined,
    MonetizationOn
} from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const RowStat = (props) => {
    const { classes, className, title, stat, type, ...rest } = props;
    const componentStyle = useStyles();
    const rootClassName = classNames(props.classes.root, props.className);

    const getStatIcon = () => {
        switch (type) {
            case 'company':
                return (
                    <Business/>
                );
            case 'sector':
                return (
                    <Category/>
                );
            case 'product':
                return (
                    <CategoryOutlined/>
                );
            case 'dollar':
            default:
                return (
                    <MonetizationOn/>
                );

        }
    };

    return (
        <Grid container spacing={1} alignItems="stretch" justify="space-between" direction='column'>
            <Grid item>
                <Typography variant="h5">
                    {title}
                </Typography>
            </Grid>
            <Grid container item spacing={1} alignItems="center" justify="space-between" direction='row'>
                <Grid item>
                    <Typography variant="h1" className={componentStyle.title}>
                        {stat ? stat : 0}
                    </Typography>
                </Grid>
                <Grid item>
                    {getStatIcon()}
                </Grid>
            </Grid>
        </Grid>
    );

};

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 800,
        color: '#FBD97C',
        [theme.breakpoints.down('md')]: {
            fontSize: '28px',
            lineHeight: '32px'
        },
    },
}));

export default withStyles(styles)(RowStat);
