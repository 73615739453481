import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Container } from '@material-ui/core';

// Component styles
const styles = theme => {
  return {
    root: {
    }
  };
};

const CustomContainer = props => {
  const { classes, className, children, onClickUpgrade, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Container
      {...rest}
      className={rootClassName}
      onClickUpgrade={onClickUpgrade}
    >
      {children}
    </Container>
  );
};

CustomContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  margin: PropTypes.string
};

export default withStyles(styles)(CustomContainer);
