import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import DealsForm from '../../components/Company/DealsForm';
import { connect } from 'react-redux';
import {
    addEmptyInvestment, removeInvestment, updateInvestment
} from '../../actions/companyHandler';

class DealsCompanyTab extends Component {

    render() {
        return (
            <div>
                <fieldset disabled={this.props.formDisabled} style={{ border: 'none' }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} lg={11}>
                            <DealsForm
                                formik={this.props.formikProps}
                                onAddInvestment={this.props.addEmptyInvestment}
                                handleClickRemoveInvestment={this.props.removeInvestment}
                                handleSaveInvestment={this.props.updateInvestment}
                                myUser={this.props.myUser}
                                actionMode={this.props.actionMode}
                                companyInvestments={this.props.companyInvestments}
                                formDisabled={this.props.formDisabled}
                                addCompanyToInvestors={this.props.updateInvestment}
                                isMyCompany={this.props.isMyCompany}
                                fromDeals={true}
                            />
                        </Grid>
                    </Grid>
                </fieldset>
            </div>
        );
    }
}

const mapDispatchToProps = {
    addEmptyInvestment,
    removeInvestment,
    updateInvestment
};

const mapStateToProps = (state) => {
    return {
        companyInvestments: state.companyHandler.companyInvestments,
        fetchingCompanyInvestments: state.companyHandler.fetchingCompanyInvestments,
        companyInvestmentsError: state.companyHandler.companyInvestmentsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsCompanyTab);
