export const getDummyBars = (num, xName, yName, empty) => {
    let dataBars = [];

    for (let i = 0; i < num; i++) {
        dataBars.push({
            [xName]: ' ' + i,
            [yName]: empty ? 0 : Math.floor(Math.random() * (20 - 3)) + 3
        });
    }
    return dataBars;
};
