import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import { actionTypes } from '../../../helpers/actionTypes';
import styles from '../styles';
import ActionsBox from '../../ActionsBox';
import 'react-image-lightbox/style.css';
import AvatarNameCell from '../AvatarNameCell';
import ContactsBox from '../../Contacts';
import moment from 'moment';

class LogRow extends Component {
    onClickNameSubject = () => {
        history.push({
            pathname: '/company/' + actionTypes.EDIT + '/' + this.props.row.subject_id
        });
    };

    render() {
        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            myUser,
            activeSelection,
            onClickDelete
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell }}
                >
                    {row.first_name} {row.last_name}
                </TableCell>
                <TableCell>
                    {row.action_type}
                </TableCell>
                <TableCell
                    onClick={this.onClickNameSubject}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    {row.subject_name}
                </TableCell>
                <TableCell>
                    <ContactsBox dataInfo={row} user/>
                </TableCell>
                <TableCell>
                    {moment(row.date_log).utc().format('DD/MM/YYYY - HH:mm')}
                </TableCell>
            </TableRow>
        );
    }
}

export default withRouter(LogRow);
