import axios from 'axios';
import { cloneDeep } from 'lodash';
import { userDataEmpty } from '../models/admin/user';
import { authHeader } from '../auth/authUtils';
import { BASE_URL } from '../helpers/axiosConfig';

const merge = require('deepmerge');

export const USERS_PREVIEW_FETCH = 'users_preview_fetch';
export const USERS_PREVIEW_FETCH_SUCCESS = 'users_preview_fetch_success';
export const USERS_PREVIEW_FETCH_ERROR = 'users_preview_fetch_error';

export const USERS_COUNT_FETCH = 'users_count_fetch';
export const USERS_COUNT_FETCH_SUCCES = 'users_count_fetch_success';
export const USERS_COUNT_FETCH_ERROR = 'users_count_fetch_error';

export const UPDATE_USER_STATUS = 'update_user_status';
export const UPDATE_USER_STATUS_SUCCESS = 'update_user_status_success';
export const UPDATE_USER_STATUS_ERROR = 'update_user_status_error';

export const USER_DATA_FETCH = 'user_data_fetch';
export const USER_DATA_FETCH_SUCCESS = 'user_data_fetch_success';
export const USER_DATA_FETCH_ERROR = 'user_data_fetch_error';

export const USER_UPDATE = 'user_update';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_ERROR = 'user_update_error';

export const USER_CREATE = 'user_create';
export const USER_CREATE_SUCCESS = 'user_create_success';
export const USER_CREATE_ERROR = 'user_create_error';

export const CLEAN_USER = 'clean_user';

export const cleanUser = () => {
    return dispatch => {
        dispatch({ type: CLEAN_USER });
    };
};

export const fetchCountUsers = (myUser) => {
    return dispatch => {
        dispatch({ type: USERS_COUNT_FETCH });
        axios.get(BASE_URL + '/users/count', { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: USERS_COUNT_FETCH_SUCCES,
                    payload: res.data.count
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: USERS_COUNT_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchUsersPreview = (page, pageSize, sorting, filter, myUser) => {
    let params = {
        page: page,
        pageSize: pageSize,
        columnSorting: sorting.column,
        desc: sorting.desc,
        filter: filter
    };

    return dispatch => {
        dispatch({ type: USERS_PREVIEW_FETCH });
        axios.get(BASE_URL + '/users/preview', {
            params,
            headers: authHeader(myUser)
        })
            .then(res => {
                dispatch({
                    type: USERS_PREVIEW_FETCH_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: USERS_PREVIEW_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const fetchUserDataEmpty = () => {
    return dispatch => {
        const dataEmpty = cloneDeep(userDataEmpty);
        dispatch({
            type: USER_DATA_FETCH_SUCCESS,
            payload: dataEmpty
        });
    };
};

export const fetchUserData = (user_id, myUser) => {
    return dispatch => {
        dispatch({ type: USER_DATA_FETCH });
        axios.get(BASE_URL + '/user', {
            params: {
                user_id: user_id
            },
            headers: authHeader(myUser)
        })
            .then(res => {
                const userData = cloneDeep(userDataEmpty);
                userData['user'] = res.data.userData.user[0];
                dispatch({
                    type: USER_DATA_FETCH_SUCCESS,
                    payload: userData
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: USER_DATA_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const updateUserData = (userData, userValues, user_id, myUser) => {
    return dispatch => {
        dispatch({ type: USER_UPDATE });
        const mergedUserData = merge(userData, userValues);
        const data = { userData: mergedUserData, user_id };
        axios.put(BASE_URL + '/user/update', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: USER_UPDATE_SUCCESS,
                    payload: { userUpdated: mergedUserData }
                });
            })
            .catch(err => {
                dispatch({
                    type: USER_UPDATE_ERROR,
                    payload: err
                });
            });
    };
};

export const createUser = (userData, userValues, myUser) => {
    const mergedUserData = merge(userData, userValues);
    let data = { userData: mergedUserData };

    return dispatch => {
        dispatch({ type: USER_CREATE });
        axios.put(BASE_URL + '/user/create', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: USER_CREATE_SUCCESS
                });
            })
            .catch(err => {
                //console.log(err);
                dispatch({
                    type: USER_CREATE_ERROR,
                    payload: err
                });
            });
    };
};


export const updateUserStatus = (user_id, newStatus, myUser) => {
    return dispatch => {
        dispatch({ type: UPDATE_USER_STATUS });
        const data = { user_id, newStatus };
        axios.put(BASE_URL + '/user/update-status', data, { headers: authHeader(myUser) })
            .then(res => {
                dispatch({
                    type: UPDATE_USER_STATUS_SUCCESS,
                    payload: { user_id, newStatus }
                });
            })
            .catch(err => {
                //console.log(err);
                dispatch({
                    type: UPDATE_USER_STATUS_ERROR,
                    payload: err
                });
            });
    };
};
