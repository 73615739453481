export const businessModelList = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 1,
        name: 'B2B',
        value: 'B2B'
    },
    {
        id: 2,
        name: 'B2C',
        value: 'B2C'
    },
    {
        id: 3,
        name: 'B2B2C',
        value: 'B2B2C'
    },
    {
        id: 4,
        name: 'C2B',
        value: 'C2B'
    },
    {
        id: 5,
        name: 'C2C',
        value: 'C2C'
    }
];

export const businessModelListFilter = [
    {
        id: 1,
        name: 'B2B',
        value: 'B2B'
    },
    {
        id: 2,
        name: 'B2C',
        value: 'B2C'
    },
    {
        id: 3,
        name: 'B2BG',
        value: 'B2G'
    },
    {
        id: 4,
        name: 'C2B',
        value: 'C2B'
    },
    {
        id: 5,
        name: 'C2C',
        value: 'C2C'
    }
];
