import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { getIn } from 'formik';
import LinearProgress from '@material-ui/core/LinearProgress';
import Spacer from '../../Spacer/Spacer';
import { Typography } from '@material-ui/core';


export default function SignUpForm(props) {

    const { formik, changeState, fetching, emailAlreadyExists } = props;

    const classes = useStyles();

    const [termsChecked, setTermsChecked] = React.useState(false);

    const handleChange = (event) => {
        setTermsChecked(event.target.termsChecked);
    };


    return (
        <div className={classes.form}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        autoComplete="fname"
                        variant="outlined"
                        //required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus

                        name="firstName_SignUp"
                        value={formik.values.firstName_SignUp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                        helperText={
                            getIn(formik.errors, 'firstName_SignUp')
                            && formik.errors.firstName_SignUp
                        }
                        error={getIn(formik.errors, 'firstName_SignUp')
                        && formik.errors.firstName_SignUp
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="outlined"
                        //required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        autoComplete="lname"

                        name="lastName_SignUp"
                        value={formik.values.lastName_SignUp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                        helperText={
                            getIn(formik.errors, 'lastName_SignUp')
                            && formik.errors.lastName_SignUp
                        }
                        error={getIn(formik.errors, 'lastName_SignUp')
                        && formik.errors.lastName_SignUp
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        //required
                        fullWidth
                        id="email"
                        label="Email Address"
                        autoComplete="email"

                        name="email_SignUp"
                        value={formik.values.email_SignUp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                        helperText={
                            getIn(formik.errors, 'email_SignUp')
                            && formik.errors.email_SignUp || emailAlreadyExists && 'Email already in use'
                        }
                        error={(getIn(formik.errors, 'email_SignUp')
                            && formik.errors.email_SignUp) || emailAlreadyExists
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        //required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"

                        name="password_SignUp"
                        value={formik.values.password_SignUp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}

                        helperText={
                            getIn(formik.errors, 'password_SignUp')
                            && formik.errors.password_SignUp
                        }
                        error={getIn(formik.errors, 'password_SignUp')
                        && formik.errors.password_SignUp
                        }
                    />
                </Grid>
            </Grid>
            {fetching ?
                <LinearProgress/>
                : null
            }
            <Spacer/>
            {/*<Checkbox*/}
            {/*    checked={termsChecked}*/}
            {/*    onChange={handleChange}*/}
            {/*    inputProps={{ 'aria-label': 'primary checkbox' }}*/}
            {/*/>*/}
            <Typography variant="h6">
                {'By clicking Sign Up, you agree to our '}
                <a href="https://docs.google.com/document/d/1Me7XTvu3GGHB4djvf3v1XFWrEpL2j5rNOK7CRoESWu0/edit?usp=sharing"
                   target="_blank"
                >
                    Terms
                </a>
            </Typography>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                form="sign-form"
                value="Submit"
                // disabled={fetching}
            >
                Sign Up
            </Button>
            <Grid container justify="flex-end">
                <Grid item>
                    <Link onClick={changeState} variant="body2">
                        Already have an account? Sign in
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
