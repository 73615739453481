import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isNumeric } from '../../../helpers/Validation/fieldForm';

const FinancialYearsForm = (props) => {
    const { classes, className, formik, yearSelected, yearList, handleChangeYear, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Financial year"
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <TextField
                                className={classes.textField}
                                label="Year"
                                name={`financial_metrics[${yearSelected}].year`}
                                select
                                SelectProps={{ native: true }}
                                value={formik.values.financial_metrics[yearSelected].year}
                                onChange={handleChangeYear}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                fullWidth
                            >
                                {yearList.map(option => (
                                    <option
                                        key={option}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <CurrencyTextField
                                currencySymbol="$"
                                minimumValue="0"
                                outputFormat='number'
                                digitGroupSeparator=","
                                decimalPlaces={0}

                                label="Annual revenue"
                                name={`financial_metrics[${yearSelected}].annual_revenue`}
                                className={classes.textField}
                                value={formik.values.financial_metrics[yearSelected].annual_revenue}
                                onChange={(e, v) => {
                                    e.target.value = v;
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                autoComplete='off'

                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                fullWidth
                                error={!isNumeric(formik.values.financial_metrics[yearSelected].annual_revenue)}
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <TextField
                                label="YoY Growth"
                                name={`financial_metrics[${yearSelected}].yoy_growth`}
                                className={classes.textField}
                                value={formik.values.financial_metrics[yearSelected].yoy_growth}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='off'

                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>
                                }}
                                fullWidth
                                error={!isNumeric(formik.values.financial_metrics[yearSelected].yoy_growth)}
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <TextField
                                label="MoM Growth"
                                name={`financial_metrics[${yearSelected}].mom_growth`}
                                className={classes.textField}
                                value={formik.values.financial_metrics[yearSelected].mom_growth}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='off'

                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>
                                }}
                                fullWidth
                                error={!isNumeric(formik.values.financial_metrics[yearSelected].mom_growth)}
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <TextField
                                label="CAC"
                                name={`financial_metrics[${yearSelected}].cac`}
                                className={classes.textField}
                                value={formik.values.financial_metrics[yearSelected].cac}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='off'

                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                fullWidth
                                error={!isNumeric(formik.values.financial_metrics[yearSelected].cac)}
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={4}
                        >
                            <TextField
                                label="EBITDA"
                                name={`financial_metrics[${yearSelected}].ebitda`}
                                className={classes.textField}
                                value={formik.values.financial_metrics[yearSelected].ebitda}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete='off'

                                variant="outlined"
                                fullWidth
                                error={!isNumeric(formik.values.financial_metrics[yearSelected].ebitda)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(FinancialYearsForm);
