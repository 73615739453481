import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { IconButton, Toolbar, Typography, withStyles } from '@material-ui/core';

import {
    AccountCircle,
    Business as BusinessIcon,
    FormatIndentDecrease as FormatIndentDecreaseIcon,
    Menu as MenuIcon,
    Notifications
} from '@material-ui/icons';
// Component styles
import styles from './styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

const Topbar = (props) => {

    const divRef = React.useRef();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLoginClicked = () => {
        props.handleLogin();
    };

    const handleClickNotifications = () => {
        props.handleClickNotifications(divRef.current);
    };

    const onClickManage = () => {
        props.handleClickManage(divRef.current);
    };

    return (
        <Fragment>
            <div className={classNames(props.classes.root, props.className)}>
                <Toolbar className={props.classes.toolbar}>
                    <Grid container
                          xs
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                    >
                        <Grid
                            item
                            style={{ display: 'flex' }}
                            alignItems="center"
                        >
                            <IconButton
                                className={props.classes.menuButton}
                                onClick={props.onToggleSidebar}
                                variant="text"
                            >
                                {props.isSidebarOpen ? <FormatIndentDecreaseIcon/> : <MenuIcon/>}
                            </IconButton>
                            <Typography
                                className={props.classes.title}
                                variant="h4"
                            >
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {props.myUser && props.iAmAuthenticated ?
                                <div>
                                    {/*<Badge*/}
                                    {/*    badgeContent={props.notificationBadgeNumber ? props.notificationBadgeNumber : 0}*/}
                                    {/*    color="error"*/}
                                    {/*    invisible={props.notificationBadgeNumber === null ||*/}
                                    {/*    props.notificationBadgeNumber === 0}*/}
                                    {/*    max={10}*/}
                                    {/*    overlap="circle"*/}
                                    {/*>*/}
                                    {/*    <IconButton*/}
                                    {/*        onClick={handleClickNotifications}*/}
                                    {/*        variant="text"*/}
                                    {/*        ref={divRef}*/}
                                    {/*        disabled={props.notifications === null || props.notifications.length === 0}*/}
                                    {/*    >*/}
                                    {/*        <Notifications/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Badge>*/}
                                    {props.manageActive ?
                                        <IconButton
                                            onClick={onClickManage}
                                            variant="text"
                                            ref={divRef}
                                            // style={{ 'marginRight': 15 }}
                                        >
                                            <BusinessIcon/>
                                        </IconButton>
                                        : null}
                                    <IconButton
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        variant="text"
                                        style={{ 'marginRight': 15 }}
                                    >
                                        <AccountCircle/>
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                    >
                                        <MenuItem onClick={props.onSignOut}>Logout</MenuItem>
                                    </Menu>
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ 'margin': 15 }}
                                        onClick={handleLoginClicked}
                                    >
                                        LOGIN
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </div>
        </Fragment>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool,
    onToggleSidebar: PropTypes.func,
    onSignOut: PropTypes.func,
    title: PropTypes.string
};

Topbar.defaultProps = {
    onToggleSidebar: () => {
    }
};

export default compose(
    withRouter,
    withStyles(styles)
)(Topbar);
