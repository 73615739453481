import { getRandomId, getIndexColumn } from '../DBhelper';
import sectors from '../../data/ids/sectors';
import { parseNameLastname } from '../Parser';
import { CATEGORIES } from '../../models/company/company';
import { cloneDeep } from 'lodash';
import { impactObj } from '../../data/tableObjects';

export default () => {

    // --- Columns ----------------------------

    const companyFields = {
        active: ['active?'],
        name: ['company name'],
        description: ['description'],
        website: ['website'],
        email: ['generic email'],
        contact_number: ['telephone'],
        operating_Status: ['operating status']
    };

    const geographyFields = {
        regions: ['hq region'],
        country: ['hq country'],
        hq_city: ['hq city'],
        address: ['address']
    };

    const investorFields = {
        fund_size: ['fund size/ investments'],
        ticket_fund_size_min: ['ticket size'],
        n_investments: ['n° of investments'],
        n_exits: ['n° of exits'],
        type: ['investor type']
    };

    /*    const investor_typeFields = {
            type: ['investor type']
        };*/

    const personaFields = {
        gender: ['gender'],
        role: ['role'],
        name: ['name'],
        ethnicity: ['white, black, asian, middle eastern'],
        education: ['education (level; degree; univeristy)'],
        email: ['contact email']
    };

    const socialFields = {
        linkedin: ['linkedin']
    };

    const sectorFields = {
        sector: ['sector']
    };


    // --- Functions ----------------------------

    // ------------------------ Sector ----------------------------

    const extractSectorName = (row, header) => {
        let sector_name = row[getIndexColumn(sectorFields.sector[0], header)];
        return sector_name;
    };

    // ------------------------ SectorCompany ----------------------------

    const getSectorCompany = (sector_name, errors) => {
        let sector_company = { ...require('../../data/tableObjects').sector_companyObj };

        if (sectors[sector_name]) {
            sector_company.sector_id = sectors[sector_name];
            return sector_company;
        } else {
            return null;
        }
    };

    // ------------------------ Social ----------------------------

    const extractSocial = (row, header) => {
        let social = { ...require('../../data/tableObjects').socialObj };
        social.social_id = getRandomId();
        social.linkedin = parseInt(row[getIndexColumn(socialFields.linkedin[0], header)]);
        return social;
    };

    // ------------------------ Investor ----------------------------

    const extractInvestor = (row, header) => {
        let investor = { ...require('../../data/tableObjects').investorObj };

        investor.fund_size = parseInt(row[getIndexColumn(investorFields.fund_size[0], header)]);
        investor.number_investment = parseInt(row[getIndexColumn(investorFields.n_investments[0], header)]);
        investor.number_exits = parseInt(row[getIndexColumn(investorFields.n_exits[0], header)]);
        investor.ticket_fund_size_min = parseInt(row[getIndexColumn(investorFields.ticket_fund_size[0], header)]);
        investor.type = row[getIndexColumn(investorFields.type[0], header)];

        return investor;
    };

    // ------------------------ InvestorType ----------------------------

    /*  const extractInvestorType = (row, header) => {
        let investor_type = { ...require('../../data/tableObjects').investor_typeObj };

        // investor.company_id
        investor_type.type = row[getIndexColumn(investor_typeFields.type[0], header)];

        return investor_type;
      };*/

    // ------------------------ Overview ----------------------------

    const extractCompany = (row, header) => {
        const ACTIVE_TRUE = 'y';
        // const ACTIVE_FALSE = 'n';

        let company = { ...require('../../data/tableObjects').companyObj };

        company.company_id = getRandomId();
        company.impact_id = getRandomId();
        company.name = row[getIndexColumn(companyFields.name[0], header)];

        // let isActive = row[getIndexColumn(companyFields.active[0], header)];
        // let isActiveBoolean = (isActive === ACTIVE_TRUE) ? true : false; // TODO check false or null
        let isActiveBoolean = true; // TODO check false or null
        company.active = isActiveBoolean;

        company.description = row[getIndexColumn(companyFields.description[0], header)];  // TODO new description ??

        // company.founding_date = row[getIndexColumn(companyFields.founding_date[0], header)];
        company.website = row[getIndexColumn(companyFields.website[0], header)];
        company.contact_number = row[getIndexColumn(companyFields.contact_number[0], header)];
        company.operating_status = row[getIndexColumn(companyFields.operating_Status[0], header)];
        company.email = row[getIndexColumn(companyFields.email[0], header)];
        // company.number_employees
        // company.ownership_structure
        // company.social_id
        // company.last_update

        company.category = CATEGORIES.INVESTOR.category_name;

        return company;
    };

    // ------------------------ Geography ----------------------------

    const extractGeography = (row, header) => {
        let geography = { ...require('../../data/tableObjects').geographyObj };
        const separator = ',';

        geography.geography_id = getRandomId();

        let geography_regions = [];

        const regionsString = row[getIndexColumn(geographyFields.regions[0], header)];
        if (regionsString && regionsString !== '') {
            const regionsSplitted = regionsString.split(separator);
            for (var i = 0; i < regionsSplitted.length; i++) {
                let geography_region = { ...require('../../data/tableObjects').geography_regionObj };
                geography_region.name = regionsSplitted[i];
                geography_regions.push(geography_region);
            }
        }

        geography.hq_country = row[getIndexColumn(geographyFields.country[0], header)];
        geography.hq_city = row[getIndexColumn(geographyFields.hq_city[0], header)];
        geography.hq_address = row[getIndexColumn(geographyFields.address[0], header)];

        return geography;
    };

    // ------------------------ Overview ----------------------------

    const extractPersona = (row, header) => {
        const persona = { ...require('../../data/tableObjects').personaObj };

        persona.persona_id = getRandomId();

        let namePersona = '';
        let lastnamePersona = '';
        let nameLastname = row[getIndexColumn(personaFields.name[0], header)];
        if (nameLastname) {
            [namePersona, lastnamePersona] = parseNameLastname(nameLastname);
            persona.name = namePersona;
            persona.lastname = lastnamePersona;
        }

        persona.role = row[getIndexColumn(personaFields.role[0], header)];
        persona.ethnicity = row[getIndexColumn(personaFields.ethnicity[0], header)];

        let education = row[getIndexColumn(personaFields.education[0], header)];
        if (education && education !== '') {
            let educationFields = education.split(';');
            if (educationFields[0]) persona.level_education = educationFields[0].trim();
            if (educationFields[1]) persona.degree = educationFields[1].trim();
            if (educationFields[2]) persona.university = educationFields[2].trim();
        }

        persona.email = row[getIndexColumn(personaFields.email[0], header)];

        return persona;
    };


    // --- Main ----------------------------

    const exctractTableInvestor = (indexRow, indexRowCsv, row, header) => {
        let errors = [];

        let persona = extractPersona(row, header);
        let personas_array = [];
        personas_array.push(persona);
        //console.log(JSON.stringify(persona, null, 2));
        let company = extractCompany(row, header);
        //console.log(JSON.stringify(company, null, 2));
        let geography = extractGeography(row, header);
        //console.log(JSON.stringify(geography, null, 2));
        let investor = extractInvestor(row, header);
        //console.log(JSON.stringify(investor, null, 2));
        let socials_array = [];
        let social = extractSocial(row, header);
        socials_array.push(social);
        //console.log(JSON.stringify(social, null, 2));
        let impact = cloneDeep(impactObj);
        impact.impact_id = company.impact_id;

        // let investor_type = extractInvestorType(row, header);
        //console.log(JSON.stringify(investor, null, 2));

        investor.company_id = company.company_id;

        company.geography_id = geography.geography_id;

        let sector_name = extractSectorName(row, header);
        let sector_company = getSectorCompany(sector_name, errors);
        if (sector_company) sector_company.company_id = company.company_id;

        // -------- FOREIGN KEYS N-N ----------------------------

        let company_persona_array = [];
        for (var i = 0; i < personas_array.length; i++) {
            let company_persona = null;
            company_persona = { ...require('../../data/tableObjects').company_personaObj };
            company_persona.company_id = company.company_id;
            company_persona.persona_id = personas_array[i].persona_id;
            company_persona_array.push(company_persona);
        }

        //let company_investor = {...require('../../data/tableObjects').company_investorObj };
        //company_investor.company_id = company.company_id;
        //--------------------------------

        // aggregate tables
        let investors_tables = { ...require('../../data/tableObjects').investorsTablesObj };
        investors_tables.indexRow = indexRow;
        investors_tables.indexRowCsv = indexRowCsv;

        investors_tables.company = company;
        investors_tables.personas = personas_array;
        investors_tables.geography = geography;
        investors_tables.investor = investor;
        investors_tables.socials = socials_array;
        investors_tables.impact = impact;

        investors_tables.company_persona = company_persona_array;

        if (sector_company) investors_tables.sector_company = sector_company;

        return [errors, investors_tables];
    };

    return [exctractTableInvestor];

}
