const styles = {
    noWrapCell: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px'
    },
    pointer: {
        cursor: 'pointer'
    }
};

export default styles;
