import 'core-js';
import React from 'react';
import {
    Paper,
    PortletContent
} from '../../index';
import classNames from 'classnames';
import PieChart from '../PieChart';
import { Typography, withStyles } from '@material-ui/core';
import styles from '../styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import TrendChart from '../TrendChart';

const TrendChartBox = (props) => {
    const {
        classes, className,
        title, idChart, data, fetching,
        maxY,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent>
                <Typography>
                    {title}
                </Typography>
                <TrendChart
                    chartData={data}
                    idChart={idChart}
                    maxY={maxY}
                />
            </PortletContent>
            {fetching ? <LinearProgress/> : null}
        </Paper>
    );
};

export default withStyles(styles)(TrendChartBox);
