import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SIGN_IN_STATE } from './AuthForm';

export default function ForgotPasswordForm(props) {

    const [email, setEmail] = React.useState('');
    const [linkSent, setLinkSent] = React.useState(false);

    const { changeState, fetching, emailNotExists } = props;

    const classes = useStyles();

    return (
        <div className={classes.form}>
            <TextField
                variant="outlined"
                margin="normal"
                //required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus

                name="email_SignIn"
                value={email}
                onChange={e => setEmail(e.target.value)}
                // error={getIn(formik.errors, 'email_SignIn')
                // && formik.errors.email_SignIn || emailNotExists && 'Email not registered'
                // }
            />
            {fetching ?
                <LinearProgress/>
                : null
            }
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={email && email.length < 5}
                onClick={() => props.sendResetPassword(email)}
            >
                Send recovery link
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link onClick={() => changeState(SIGN_IN_STATE)} variant="body2">
                        Return to login
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
