import { milestoneTypes } from '../models/typeList/milestoneTypes';
import { supportTypes } from '../models/typeList/supportTypes';
import { capitalSoughtTypes } from '../models/typeList/extCapitalSoughtTypes';
import { investmentStageListFilter } from '../models/typeList/investmentStages';

const fieldKeysIds = ['key_milestones_ids', 'looking_acceleration_ids', 'ext_capital_sought_types', 'investment_stages_ids'];
// const fieldKeysIds = ['key_milestones_ids'];

const getArray = (nameArray) => {
    switch (nameArray) {
        case 'key_milestones_ids':
            return milestoneTypes;
        case 'looking_acceleration_ids':
            return supportTypes;
        case 'ext_capital_sought_types':
            return capitalSoughtTypes;
        case 'investment_stages_ids':
            return investmentStageListFilter;
    }
};

const getObjs = (intArray, key) => {
    let objArray = [];

    let originalArray = getArray(key);
    for (let obj of originalArray) {
        if (intArray.includes(obj.id)) {
            objArray.push(obj);
        }
    }

    return objArray;
};


export const enrichObjFromIds = (companyData) => {
    for (let nameArray of fieldKeysIds) {
        if (nameArray in companyData
            && companyData[nameArray]
            && companyData[nameArray].length > 0) {
            companyData[nameArray] = getObjs(companyData[nameArray], nameArray);
        }
    }

    return companyData;
};
