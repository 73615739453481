import axios from 'axios';
import { BASE_URL } from '../helpers/axiosConfig';
import { getRandomId, urlMailerRegistration } from '../helpers/DBhelper';
import { authHeader } from '../auth/authUtils';

export const AUTH_USER = 'auth_user';
export const AUTH_USER_SUCCESS = 'auth_user_success';
export const AUTH_USER_ERROR = 'auth_user_error';
export const AUTH_WRONG_PASSWORD = 'auth_wrong_password';
export const AUTH_EMAIL_ALREADY_EXISTS = 'auth_email_already_exists';

export const SIGN_OUT = 'sign_out';
export const CLEAN_AUTH = 'clean_auth';
export const SET_AUTHBOX = 'set_authbox';

export const SIGN_UP = 'sign_up';
export const SIGN_UP_SUCCESS = 'sign_up_success';
export const SIGN_UP_EMAIL_IN_USE = 'sign_up_email_in_use';
export const SIGN_UP_ERROR = 'sign_up_error';

export const setAuthbox = (isRequired) => {
    return dispatch => {
        dispatch({
            type: SET_AUTHBOX,
            payload: isRequired
        });
    };
};

export const cleanAuth = () => {
    return dispatch => {
        dispatch({ type: CLEAN_AUTH });
    };
};

export const sendRegistrationEmail = (email) => {
    return dispatch => {
        fetch(urlMailerRegistration, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        }).then(r => {
        });
    };
};

export const signUpMySelf = (values, state) => {
    const data = { values, state };
    return dispatch => {
        dispatch({ type: SIGN_UP });
        const httpClient = axios.create();
        httpClient.defaults.timeout = 3500;
        httpClient.post(BASE_URL + '/auth/sign-up', data, {headers: authHeader(null)})
            .then(res => {
                const result = res.data;
                if (result.emailAlreadyInUse !== null && result.emailAlreadyInUse === true) {
                    dispatch({
                        type: SIGN_UP_EMAIL_IN_USE
                    });
                } else {
                    if (res.data.user) {
                        dispatch(sendRegistrationEmail(values.email_SignUp.toLowerCase()));
                        dispatch({
                            type: AUTH_USER_SUCCESS,
                            payload: res.data.user
                        });
                    } else {
                        dispatch({ type: SIGN_UP_ERROR });
                    }
                }
            })
            .catch(err => {
                dispatch({ type: SIGN_UP_ERROR });
                console.error(err);
            });
    };
};

export const signInMySelfEmailPassword = (email, password) => {
    const login = { email, password, provider: 'mail&password' };
    const data = { login };
    return dispatch => {
        dispatch({ type: AUTH_USER });
        const httpClient = axios.create();
        httpClient.defaults.timeout = 2500;
        httpClient.post(BASE_URL + '/auth/sign-in', data, { headers: authHeader(null) })
            .then(res => {
                if (res.data.passwordWrong !== null && res.data.passwordWrong === true) {
                    dispatch({
                        type: AUTH_WRONG_PASSWORD
                    });
                } else {
                    if (res.data.emailExists !== null && res.data.emailExists === false) {
                        dispatch({
                            type: AUTH_EMAIL_ALREADY_EXISTS
                        });
                    } else {
                        const user = res.data.user;
                        dispatch({
                            type: AUTH_USER_SUCCESS,
                            payload: user
                        });
                    }
                }
            })
            .catch(err => {
                dispatch({ type: AUTH_USER_ERROR });
                console.error(err);
            });
    };
};

export const authMySelf = (socialAccessToken, provider, redirectUri) => {
    const login = { socialAccessToken, provider, redirectUri };
    const data = { login };
    return dispatch => {
        dispatch({ type: AUTH_USER });
        axios.post(BASE_URL + '/auth/sign-in', data, { headers: authHeader(null) })
            .then(res => {
                const user = res.data.user;
                dispatch({
                    type: AUTH_USER_SUCCESS,
                    payload: user
                });
            })
            .catch(err => {
                dispatch({ type: AUTH_USER_ERROR });
                console.error(err);
            });
    };
};

export const signOutMySelf = () => {
    return dispatch => {
        dispatch({ type: SIGN_OUT });
    };
};
