import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import { Delete, Edit } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

export default function ActionsBox(props) {

    const { classes, className, onEdit, onDelete, disabled, ...rest } = props;

    return (
        <Grid container xs>
            {onEdit ?
                <Grid item xs>
                    <IconButton
                        disabled={disabled}
                        onClick={onEdit}
                    >
                        <Edit/>
                    </IconButton>
                </Grid>
                : null
            }
            {onDelete ?
                <Grid item xs>
                    <IconButton
                        disabled={disabled}
                        onClick={onDelete}
                    >
                        <Delete/>
                    </IconButton>
                </Grid>
                : null}
        </Grid>
    );
}
