import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import styles from '../styles';
import { capitalizeFirstLetter, getCategory } from '../../../helpers/stringUtils';
import AvatarNameCell from '../AvatarNameCell';
import ContactsBox from '../../Contacts';

class CompanyRowLimited extends Component {

    render() {
        const {
            row,
            labelId,
            onClickRow,
        } = this.props;

        return (
            <TableRow
                style={{ ...styles.pointer }}
                onClick={() => onClickRow(row.company_id)}
                hover
            >
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.avatar_url}
                        name={row.name}
                        truncateLength={25}
                    />
                </TableCell>
                <TableCell style={styles.noWrapCell}>{getCategory(row.category)}</TableCell>
                <TableCell style={styles.noWrapCell}>{row.sector_name}</TableCell>
                <TableCell style={styles.noWrapCell}>{row.hq_country}</TableCell>
                <TableCell style={styles.noWrapCell}>{row.hq_city}</TableCell>
                <TableCell size={'small'}>
                    <ContactsBox dataInfo={row} noMail/>
                </TableCell>
            </TableRow>
        );
    }
}

export default CompanyRowLimited;

