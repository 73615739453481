import React from 'react'
import PropTypes from 'prop-types'
import {Divider} from '@material-ui/core';

const Spacer = ({children, divider, horizontal, xxsmall = null, xsmall = null, small = null, medium = null, big = null, xbig = null}) => {
    let size = horizontal? styles.SpacerDefaultHorizontal : styles.SpacerDefault;
    if (xxsmall) {
        size = horizontal? styles.SpacerXXSHorizontal : styles.SpacerXXS
    } else if (xsmall) {
        size = horizontal? styles.SpacerXSHorizontal : styles.SpacerXS
    } else if (small) {
        size = horizontal? styles.SpacerSHorizontal : styles.SpacerS
    } else if (medium) {
        size = horizontal? styles.SpacerMHorizontal : styles.SpacerM
    } else if (big) {
        size = horizontal? styles.SpacerLHorizontal : styles.SpacerL
    } else if (xbig) {
        size = horizontal? styles.SpacerXLHorizontal : styles.SpacerXL
    }

    const layout = styles.Spacer;
    const spacerStyle = {...size, ...layout};

    return (
        <div style={spacerStyle}>
            {divider?
                <Divider/>
                :
                null
            }
            {children}
        </div>
    )
};

const styles = {
    Spacer:{
        width: '100%'
    },
    SpacerDefault: {
        marginTop: 20,
        marginBottom: 20,
    },
    SpacerXXS: {
        marginTop: 2.5,
        marginBottom: 2.5,
    },
    SpacerXS: {
        marginTop: 5,
        marginBottom: 5,
    },
    SpacerS: {
        marginTop: 10,
        marginBottom: 10,
    },
    SpacerM: {
        marginTop: 20,
        marginBottom: 20,
    },
    SpacerL: {
        marginTop: 30,
        marginBottom: 30,
    },
    SpacerXL: {
        marginTop: 40,
        marginBottom: 40,
    },
    SpacerDefaultHorizontal: {
        marginLeft: 20,
        marginRight: 20,
    },
    SpacerXXSHorizontal: {
        marginLeft: 2.5,
        marginRight: 2.5,
    },
    SpacerXSHorizontal: {
        marginLeft: 5,
        marginRight: 5,
    },
    SpacerSHorizontal: {
        marginLeft: 10,
        marginRight: 10,
    },
    SpacerMHorizontal: {
        marginLeft: 20,
        marginRight: 20,
    },
    SpacerLHorizontal: {
        marginLeft: 30,
        marginRight: 30,
    },
    SpacerXLHorizontal: {
        marginLeft: 40,
        marginRight: 40,
    }
};

export default Spacer

Spacer.propTypes = {
    big: PropTypes.bool,
    children: PropTypes.object,
    medium: PropTypes.bool,
    small: PropTypes.bool,
    xbig: PropTypes.bool,
    xsmall: PropTypes.bool
};
