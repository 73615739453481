import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { getIn } from 'formik';
import { investmentStageList, investmentStageListFilter } from '../../../models/typeList/investmentStages';
import { actionTypes } from '../../../helpers/actionTypes';


const CompanyInfoForm = (props) => {
    const { classes, className, formik, actionMode, sectorsArray, subSectorsArray, technologiesArray, category, editable, myUser, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title={category === 'startup' ? 'Industry Specifics' : 'Criteria'}
                    subtitle={actionMode === actionTypes.EDIT ? "Manage the company information" : null }
                />
            </PortletHeader>
            <PortletContent>
                <fieldset disabled={!editable} style={{ border: 'none' }}>
                    <Grid
                        container
                        className={classes.form_container}
                    >
                        <Grid
                            container
                            item
                            xs={12} lg={9}
                        >
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    multiple
                                    id="sector-tags"
                                    name="sectors"
                                    disabled={!editable}
                                    className={classes.textField}
                                    options={sectorsArray}
                                    value={formik.values.sectors}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => {
                                        formik.setFieldValue('sectors', value);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            return (
                                                <Chip
                                                    color="primary"
                                                    style={{ backgroundColor: '#888FCA' }}
                                                    label={option.name}
                                                    disabled={formik.values.sectors.length === 1}
                                                    {...getTagProps({ index })}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={formik.values.company.category === 'startup' ? 'Sectors' : 'Sectors of Interest'}
                                            variant="outlined"
                                            placeholder="Select a Multiple Sector"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    multiple
                                    disabled={!editable}
                                    id="subsector-tags"
                                    name="sub_sectors"
                                    className={classes.textField}
                                    options={subSectorsArray}
                                    value={formik.values.sub_sectors}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => formik.setFieldValue('sub_sectors', value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                color="primary"
                                                style={{ backgroundColor: '#888FCA' }}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={formik.values.company.category === 'startup' ? 'Subsectors' : 'Subsectors of Interest'}
                                            variant="outlined"
                                            placeholder="Select a Multiple Sub Sector"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    multiple
                                    disabled={!editable}
                                    id="technologies-tags"
                                    name="technologies"
                                    className={classes.textField}
                                    options={technologiesArray}
                                    value={formik.values.technologies}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => formik.setFieldValue('technologies', value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                color="primary"
                                                style={{ backgroundColor: '#888FCA' }}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={formik.values.company.category === 'startup' ? 'Technologies' : 'Technologies of Interest'}
                                            variant="outlined"
                                            placeholder="Select a Multiple Technology"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {/*{formik.values.company.category.toLowerCase() === 'investor' ?*/}
                            {/*    <Grid*/}
                            {/*        className={classes.field}*/}
                            {/*        container*/}
                            {/*        item*/}
                            {/*        xs={12}*/}
                            {/*    >*/}
                            {/*        <Autocomplete*/}
                            {/*            multiple*/}
                            {/*            disabled={!editable}*/}
                            {/*            id="stage-criteria-tags"*/}
                            {/*            name="stage_criteria"*/}
                            {/*            className={classes.textField}*/}
                            {/*            options={investmentStageListFilter}*/}
                            {/*            value={formik.values.stage_criteria}*/}
                            {/*            getOptionLabel={option => option.name}*/}
                            {/*            onChange={(event, value) => formik.setFieldValue('stage_criteria', value)}*/}
                            {/*            renderTags={(value, getTagProps) =>*/}
                            {/*                value.map((option, index) => (*/}
                            {/*                    <Chip*/}
                            {/*                        label={option.name}*/}
                            {/*                        {...getTagProps({ index })}*/}
                            {/*                    />*/}
                            {/*                ))*/}
                            {/*            }*/}
                            {/*            renderInput={params => (*/}
                            {/*                <TextField*/}
                            {/*                    {...params}*/}
                            {/*                    label={'Stages criteria'}*/}
                            {/*                    variant="outlined"*/}
                            {/*                    placeholder="Select multiple Stages"*/}
                            {/*                    fullWidth*/}
                            {/*                />*/}
                            {/*            )}*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*    : null}*/}

                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12}
                            >
                                <TextField
                                    label="Description"
                                    name="company.description"
                                    // disabled={!editable}
                                    className={classes.textField}
                                    value={formik.values.company.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={
                                        getIn(formik.errors, 'company.description')
                                        && formik.errors.company.description
                                    }
                                    error={getIn(formik.errors, 'company.description')
                                    && formik.errors.company.description
                                    }
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    rows="4"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </fieldset>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(CompanyInfoForm);
