import { ORIGIN_PLATFORM } from '../helpers/axiosConfig';

export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const LINKEDIN = 'linkedin';

export const googleSignOut = (callback) => {
    try {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(
                auth2.disconnect().then(callback())
            );
        }
    } catch (e) {
        console.log(e);
    }
};

export const authHeader = (myUser) => {
    if (myUser) {
        return {
            Authorization: 'Bearer ' + myUser.user_token,
            origin_platform: ORIGIN_PLATFORM,
            From: ORIGIN_PLATFORM
        };
    } else {
        return {
            Authorization: 'Bearer ',
            origin_platform: ORIGIN_PLATFORM,
            From: ORIGIN_PLATFORM
        };
    }
};
