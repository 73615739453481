
import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import Routes from './Routes';
import history from './config/history';

export default function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <Routes/>
                </Router>
            </ThemeProvider>
        </div>
    );
}
