import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import {
    Paper,
    PortletHeader,
    PortletLabel
} from '../../index';

import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import { getNameLastname } from '../../../models/persona';

const UserInfo = (props) => {
    const { classes, className, canDelete, userInfo, onClickName, onClickDelete, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={0}
        >
            <PortletHeader>
                <PortletLabel
                    style={{ cursor: 'pointer' }}
                    onClick={onClickName}
                    title={getNameLastname(userInfo)}
                />
                {canDelete ?
                    <IconButton
                        onClick={onClickDelete}
                    >
                        <Delete/>
                    </IconButton>
                    : null}
            </PortletHeader>
        </Paper>

    );
};

export default withStyles(styles)(UserInfo);
