import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import AvatarNameCell from '../Table/AvatarNameCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { millisecondsToStr } from '../../helpers/DateUtils/dateUtils';

const useStyles = makeStyles({
    root: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
});

export default function NotificationRow(props) {
    const { notificationObj, onCardClick, ...rest } = props;
    const classes = useStyles();


    const getUpdateDate = () => {
        let now = new Date().getTime();
        let lastUpdate = new Date(notificationObj.last_update).getTime();
        // lastUpdate = lastUpdate + 3600000;
        let millisecsAgo = now - lastUpdate;

        return millisecondsToStr(millisecsAgo);
    };

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={onCardClick}>
                <CardContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <AvatarNameCell
                                avatarUrl={notificationObj.avatar_url}
                            />
                        </Grid>
                        <Grid item style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',}}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {notificationObj.name}
                            </Typography>
                            {!props.fromManage ?
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        has been updated
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {getUpdateDate()}
                                    </Typography>
                                </div>
                                : null
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
