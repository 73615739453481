import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
// import ClearIcon from "@material-ui/icons/Clear";
// const TextFieldComponent = (props) => {
//     return <TextField {...props} disabled={true}/>;
// };

const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true}/>;
};

export default function DatePicker(props) {

    const { selectedDate, handleDateChange, label, disabled, small } = props;

    const dateFormatter = str => {
        return str;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                autoOk
                fullWidth
                disabled={disabled}
                variant="inline"
                inputVariant="outlined"
                id="date-picker-dialog"
                label={label}
                size={small ? 'small': null}
                // format="MM/dd/yyyy"
                format="yyyy-MM-dd"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date'
                }}
                TextFieldComponent={TextFieldComponent}
                // TextFieldComponent={TextFieldComponent}
                rifmFormatter={dateFormatter}
                // clearable
                // InputProps={{
                //     endAdornment: (
                //         <IconButton onClick={() => handleDateChange(null)}>
                //             <ClearIcon />
                //         </IconButton>
                //     )
                // }}
                // InputAdornmentProps={{
                //     position: "start"
                // }}
            />
        </MuiPickersUtilsProvider>
    );
}
