import React from 'react';
import { LINKEDIN_RIDERECT, LINKEDIN_STATE, LINKEDIN_URL } from '../../auth/linkedinUtils';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ButtonExtended from '../UI/ButtonExtended/ButtonExtended';


export default class LinkedInSignIn extends React.Component {

    signInWithLinkedin = () => {
        this.popup = window.open(LINKEDIN_URL, '_blank', 'width=600,height=600');
        window.addEventListener('message', this.receiveLinkedInMessage);
    };

    receiveLinkedInMessage = ({ origin, data: { state, code, error, ...rest } }) => {
        if (origin !== window.location.origin || state !== LINKEDIN_STATE) return;
        if (code) {
            this.props.receiveProviderToken({ provider: 'linkedin', token: code, redirectUri: LINKEDIN_RIDERECT });
        } else if (error && !['user_cancelled_login', 'user_cancelled_authorize'].includes(error)) {
            this.props.failToReceiveProviderToken({ provider: 'linkedin', error: { error, ...rest } });
        }
        this.popup.close();
    };

    // componentWillUnmount() {
    //     window.removeEventListener('message', this.receiveLinkedInMessage);
    //     this.popup && this.popup.close()
    // }

    render() {
        return (
            <div>
                <ButtonExtended
                    onClick={this.signInWithLinkedin}
                    startIcon={<LinkedInIcon />}
                    variant="contained"
                    color='info'
                    style={{ 'text-transform': 'none'}}
                >
                    Sign in with LinkedIn
                </ButtonExtended>
            </div>
        );
    }
}
