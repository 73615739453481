import { addCompanyToPartnerships, removeCompanyToPartnerships } from '../../actions/companyHandler';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import history from '../../config/history';
import axios from 'axios';
import { BASE_URL } from '../../helpers/axiosConfig';
import { authHeader } from '../../auth/authUtils';
import { cloneDeep } from 'lodash';
import { ASSESSMENT_TOOLKIT, can, EDIT_ROW } from '../../helpers/Roles/roleActions';
import { isMyCompany } from '../../helpers/Roles/claimUtils';

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
    return {
        myUser: state.authHandler.myUser,
        activeClaims: state.mainHandler.activeClaims
    };
};

class SearchWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            companyQuery: '',
            companiesResult: [],
            searchingCompanies: false,
            companySelected: null
        };
    }

    updateCompaniesResult = (companies) => {
        let companiesResult = cloneDeep(companies);

        this.setState({
            companiesResult: companiesResult,
            searchingCompanies: false
        });
    };

    getCompanies = () => {
        axios.get(BASE_URL + '/company/search', {
            params: {
                pattern: this.state.companyQuery,
                justActives: true
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateCompaniesResult(data.companies);
            });
    };

    handleInputCompanyChange = (e) => {
        this.setState({
            companyQuery: e.target.value
        }, () => {
            if (this.state.companyQuery && this.state.companyQuery.length > 0) {
                this.setState({
                        searchingCompanies: true
                    }, () => this.getCompanies()
                );
            }
        });
    };

    selectCompany = (company) => {
        this.setState({
            companySelected: company
        });

        if ((this.props.myUser && can(this.props.myUser, ASSESSMENT_TOOLKIT)) || (isMyCompany(this.props.activeClaims, company.company_id))) {
            history.push({
                pathname: '/redirect/edit/' + company.company_id,
                destinationTab: 'assessment'
            });
        } else {
            history.push({
                pathname: '/redirect/show/' + company.company_id,
                destinationTab: 'assessment'
            });
        }


    };

    render() {

        const children = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                index,
                companiesResult: this.state.companiesResult,
                searchingCompanies: this.state.searchingCompanies,
                selectCompany: this.selectCompany.bind(this),
                handleInputCompanyChange: this.handleInputCompanyChange.bind(this)
            });
        });

        return (
            <div>
                return (
                <div>
                    {children}
                </div>
            </div>

        );
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(SearchWrapper);
