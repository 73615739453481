import React, { useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ButtonExtended from '../UI/ButtonExtended/ButtonExtended';
import FilterFormFloatFields from './FilterFormFloatFields';
import Spacer from '../Spacer/Spacer';

const FilterFormFloat = (props) => {

    const {
        open,
        handleClose,
        formik,
        onApplyClick
    } = props;

    const classes = useStyles();

    const onClearClick = () => {
        formik.setFieldValue('investorTypes', []);
        formik.setFieldValue('hubTypes', []);
        formik.setFieldValue('businessModels', []);

        formik.setFieldValue('milestoneIds', []);
        formik.setFieldValue('product_development', null);
        formik.setFieldValue('ext_capital_sought_types', []);
        // formik.setFieldValue('own_ip', null);
        // formik.setFieldValue('numPortfolios', 0); // TODO null not clear

        // TODO
    };

    const handleApplyClick = () => {
        handleClose();
        onApplyClick();
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 250
                }}
            >
                <Fade in={open}>
                    <Grid container xs={10} sm={8} lg={4}>
                        <Paper className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid item xs>
                                <Typography
                                    className={classes.title}
                                    variant="h4"
                                    style={{ textAlign: 'center' }}
                                >
                                    Filter: {formik.values.category}
                                </Typography>
                                <Spacer/>
                                <div>
                                    <FilterFormFloatFields
                                        category={formik.values.category}
                                        formik={formik}
                                    />
                                    <Spacer small/>
                                    <Grid container spacing={2}>
                                        {/*{(formik.values.investorTypes && formik.values.investorTypes.length > 0) ||*/}
                                        {/*(formik.values.hubTypes && formik.values.hubTypes.length > 0) ||*/}
                                        {/*(formik.values.businessModels && formik.values.businessModels.length > 0)*/}
                                        {/*    ?*/}
                                        <Grid item xs>
                                            <ButtonExtended
                                                variant="outlined"
                                                color='action'
                                                onClick={onClearClick}
                                            >
                                                Clear
                                            </ButtonExtended>
                                        </Grid>
                                        {/*: null }*/}
                                        <Grid item xs justify="flex-end"
                                              style={{ display: 'flex' }}
                                        >
                                            <ButtonExtended
                                                variant="contained"
                                                color='action'
                                                onClick={handleApplyClick}
                                            >
                                                APPLY
                                            </ButtonExtended>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Spacer/>
                            </Grid>
                        </Paper>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
};

export default FilterFormFloat;

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
