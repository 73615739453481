import React from 'react';
import { Grid } from '@material-ui/core';
import CompanySocialForm from '../../components/Company/CompanySocialForm';
import CompanyImpactForm from '../../components/Company/CompanyImpactForm';

function ImpactCompanyTab(props) {

    const {
        formDisabled,
        formikProps,
        SDGsArray,
        editable
    } = props;

    return (
        <div>
            <fieldset disabled={formDisabled} style={{ border: 'none' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        <CompanyImpactForm
                            editable={formDisabled}
                            formik={formikProps}
                            SDGsArray={SDGsArray}
                        />
                    </Grid>
                </Grid>
            </fieldset>
        </div>
    );
}

export default ImpactCompanyTab;
