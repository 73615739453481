import * as Yup from 'yup';

export const scontactUsSchema = Yup.object().shape({
    email_sender: Yup.string()
        .email('Must be a valid email')
        .required('Required')
        .nullable(),
    subject: Yup.string()
        .required('Required')
        .min(1, 'Required')
        .nullable(),
    text: Yup.string()
        .required('Required')
        .min(4, 'Message too short')
        .nullable()
});
