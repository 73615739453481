export const impactTypes = [
    {
        name: 'Assistive Tech',
        value: 'assistive_tech'
    },
    {
        name: 'Bottom of The Pyramid',
        value: 'bop'
    },
    {
        name: 'Elderly People',
        value: 'elderly_people'
    },
    {
        name: 'Employment Creation',
        value: 'employment_creation'
    },
    {
        name: 'Female Co-Founder',
        value: 'female_cofounder'
    },
    {
        name: 'Female Focused',
        value: 'female_focused'
    },
    {
        name: 'Informal Sector',
        value: 'informal_sector'
    },
    {
        name: 'Rural Focused',
        value: 'rural_focused'
    },
    {
        name: 'Youth Focused',
        value: 'youth_focused'
    },
    {
        name: 'Zero Emissions',
        value: 'zero_emissions'
    },
    {
        name: '>50% Local Co-Founders',
        value: 'fifty_local'
    },
];
