import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TextField, withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { Paper, PortletHeader } from '../index';

import styles from './styles';
import IconButton from '@material-ui/core/IconButton';

const MentionInfo = (props) => {
    const {
        classes, className,
        formDisabled,
        canDelete, mention, onClickName, onClickDelete, onClickClear,
        onBlur,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const [url, setUrl] = useState(mention.url);

    useEffect(() => {
        setUrl(mention.url);
    }, [mention]);

    const handleOnBlur = () => {
        onBlur(url);
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={0}
            style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 20, overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
                {formDisabled ?
                    <a
                        href={url}
                        target={'_blank'}
                        style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >{url}</a>
                    : <div style={{flexDirection: 'row', display: 'flex'}}>
                        <TextField
                            className={classes.textField}
                            value={url ? url : ''}
                            onChange={e => setUrl(e.target.value)}
                            onBlur={() => handleOnBlur()}
                            autoComplete='off'
                            variant="outlined"
                            placeholder={'https://'}
                            fullWidth
                        />
                        <IconButton
                            onClick={onClickDelete}
                        >
                            <Delete/>
                        </IconButton>
                    </div>
                }
        </Paper>

    );
};

export default withStyles(styles)(MentionInfo);
