import {
    USERS_PREVIEW_FETCH,
    USERS_PREVIEW_FETCH_ERROR,
    USERS_PREVIEW_FETCH_SUCCESS,

    USERS_COUNT_FETCH,
    USERS_COUNT_FETCH_SUCCES,
    USERS_COUNT_FETCH_ERROR,

    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_USER_STATUS_ERROR,

    USER_DATA_FETCH,
    USER_DATA_FETCH_SUCCESS,
    USER_DATA_FETCH_ERROR,

    USER_UPDATE,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERROR,


    CLEAN_USER, USER_CREATE, USER_CREATE_SUCCESS, USER_CREATE_ERROR
} from '../actions/usersHandler';
import { cloneDeep } from 'lodash';
import { findIndexUser } from '../helpers/mainPreviews';

const initialState = {
    usersPreview: [],
    fetchingUsersPreview: false,
    usersPreviewError: null,

    numUsers: 0,
    fetchingUsersCount: false,
    usersCountError: null,

    userStatusUpdating: false,
    userStatusUpdateError: null,

    userData: null,
    user_id: null,
    fetchingUserData: false,
    userDataError: null,

    userUpdating: false,
    userUpdateError: null
};

export default function usersHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAN_USER:
            return initialState;
        case USERS_PREVIEW_FETCH:
            return {
                ...state,
                usersPreview: [],
                fetchingUsersPreview: true,
                usersPreviewError: null
            };
        case USERS_PREVIEW_FETCH_SUCCESS:
            const usersPreviewRows = action.payload;
            for (let row of usersPreviewRows) {
                row['idrow'] = row.user_id;
            }
            return {
                ...state,
                usersPreview: usersPreviewRows,
                fetchingUsersPreview: false
            };
        case USERS_PREVIEW_FETCH_ERROR:
            return {
                ...state,
                fetchingUsersPreview: false,
                usersPreviewError: action.payload
            };

        case USERS_COUNT_FETCH:
            return {
                ...state,
                numUsers: 0,
                fetchingUsersCount: true,
                usersCountError: null
            };
        case USERS_COUNT_FETCH_SUCCES:
            return {
                ...state,
                numUsers: action.payload,
                fetchingUsersCount: false
            };
        case USERS_COUNT_FETCH_ERROR:
            return {
                ...state,
                fetchingUsersCount: false,
                usersCountError: action.payload
            };

        case UPDATE_USER_STATUS:
            return {
                ...state,
                userStatusUpdating: true,
                userStatusUpdateError: null
            };
        case UPDATE_USER_STATUS_SUCCESS:
            const user_id = action.payload.user_id;
            const newStatus = action.payload.newStatus;
            const usersPreviewUpdated = cloneDeep(state.usersPreview);
            const indexUserInPreviews = findIndexUser(usersPreviewUpdated, user_id);
            if (indexUserInPreviews !== -1) {
                usersPreviewUpdated[indexUserInPreviews].status = newStatus;
            }
            return {
                ...state,
                userStatusUpdating: false,
                userStatusUpdateError: null,
                usersPreview: usersPreviewUpdated
            };
        case UPDATE_USER_STATUS_ERROR:
            return {
                ...state,
                userStatusUpdating: false,
                userStatusUpdateError: action.payload
            };


        case USER_DATA_FETCH:
            return {
                ...state,
                userData: null,
                fetchingUserData: true,
                userDataError: null
            };
        case USER_DATA_FETCH_SUCCESS:
            let newUserData = action.payload;
            return {
                ...state,
                userData: newUserData,
                fetchingUserData: false
            };
        case USER_DATA_FETCH_ERROR:
            return {
                ...state,
                fetchingUserData: false,
                userDataError: action.payload
            };


        case USER_UPDATE:
            return {
                ...state,
                userUpdating: true,
                userUpdateError: null
            };
        case USER_UPDATE_SUCCESS:
            //let userUpdated = cloneDeep(action.payload.userUpdated); // TODO
            return {
                ...state,
                //userData: userUpdated,
                userUpdating: false
            };
        case USER_UPDATE_ERROR:
            return {
                ...state,
                userUpdating: false,
                userUpdateError: action.payload
            };


        case USER_CREATE:
            return {
                ...state,
                userUpdating: true,
                userUpdateError: null
            };
        case USER_CREATE_SUCCESS:
            return {
                ...state,
                user_id: action.payload,
                userUpdating: false
            };
        case USER_CREATE_ERROR:
            return {
                ...state,
                userUpdating: false,
                userUpdateError: action.payload
            };

        default:
            return state;
    }
}
