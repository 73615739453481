export const hubTypeList = [
    // {
    //     type_id: 0,
    //     name: null,
    //     value: null
    // },
    {
        type_id: '33',
        name: 'Accelerator',
        value: 'accelerator'
    },
    {
        type_id: '11',
        name: 'Coworking',
        value: 'coworking'
    },
    {
        type_id: '44',
        name: 'Hackathon',
        value: 'hackathon'
    },
    {
        type_id: '22',
        name: 'Incubator',
        value: 'incubator'
    },
    {
        type_id: '55',
        name: 'Innovation Hub',
        value: 'innovation hub'
    },
    {
        type_id: '99',
        name: 'Makerspace',
        value: 'makerspace'
    },
    {
        type_id: '88',
        name: 'Network',
        value: 'network'
    },
    {
        type_id: '66',
        name: 'Technology Park',
        value: 'technology park'
    },
    {
        type_id: '77',
        name: 'Venture Builder',
        value: 'venture builder'
    }
];







