import React, { Component } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from '../../components';

// Component styles
import styles from './styles';

class TableDefinition extends Component {

  handleChange = e => {
    this.setState({
      state: e.target.value
    });
  };

  handleExpansionChange = panel => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
  };


  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const columns = this.props.table["columns"].map(igKey => {
        let strong = null;
        (igKey["required"]) ? strong = <strong>{igKey["name"]}</strong> : strong=igKey["name"];
        return (
                <li key={igKey}>
                    <span>{strong} &nbsp;-&nbsp; {igKey["var_type"]}</span>
                </li> );
    });

    // const [expanded, setExpanded] = React.useState(false);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle=""
            title="Table Definition"
          />
        </PortletHeader>

        <PortletContent noPadding>

            <ExpansionPanel /*expanded={expanded === 'panel1'}*/ onChange={this.handleExpansionChange('panel1')}>

                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                    <Typography className={classes.heading}>{this.props.table["table_name"]} Columns</Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                    <ul>
                        {columns}
                    </ul>
                </ExpansionPanelDetails>

            </ExpansionPanel>

        </PortletContent>

      </Portlet>

    );
  }
}

TableDefinition.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableDefinition);
