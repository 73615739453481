import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import UserInfo from '../../Team/UserInfo';
import ConfirmDialog from '../../ConfirmDialog';
import { ADD_PERSON_TEAM, can, DELETE_PERSON_TEAM } from '../../../helpers/Roles/roleActions';


const CompanyTeamForm = (props) => {
    const {
        classes, className,
        myUser,
        openDialogSearchPersona, companyTeam, handleClickPersona, handleClickRemovePersona, formDisabled,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const [confirmDialogVisible, setConfirmDialogVisible] = React.useState(false);
    const [personaClicked, setPersonaClicked] = React.useState(null);

    const onClickRemovePersona = (persona) => {
        setConfirmDialogVisible(true);
        setPersonaClicked(persona);
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="The Team"
                    // subtitle="add or remove person from the people's table"
                />
                {can(myUser, ADD_PERSON_TEAM) && !formDisabled ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openDialogSearchPersona}
                    >
                        ADD PERSON
                    </Button>
                    : null
                }
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid container item xs={12} spacing={2}>
                        {companyTeam ?
                            companyTeam.map(persona => {

                                return (
                                    persona.name && persona.name !== "" ?
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <UserInfo
                                                onClickName={() => handleClickPersona(persona)}
                                                onClickDelete={() => onClickRemovePersona(persona)}
                                                userInfo={persona}
                                                canDelete={can(myUser, DELETE_PERSON_TEAM) && !formDisabled}
                                                formDisabled={formDisabled}
                                            />
                                        </Grid>
                                        : null
                                );
                            })
                            : null
                        }
                    </Grid>
                </Grid>
            </PortletContent>
            <ConfirmDialog
                visible={confirmDialogVisible}
                onClose={() => setConfirmDialogVisible(false)}
                onConfirm={
                    () => {
                        setConfirmDialogVisible(false);
                        handleClickRemovePersona(personaClicked);
                    }
                }
                title={'REMOVE PERSON'}
                content={'Are you sure?'}
            />
        </Paper>

    );
};

export default withStyles(styles)(CompanyTeamForm);
