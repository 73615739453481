import 'core-js';
import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import kelly from '@amcharts/amcharts4/themes/kelly';
import * as am4plugins_sliceGrouper from '@amcharts/amcharts4/plugins/sliceGrouper';
import { getPieThreshold } from '../../helpers/Stats/aggregator';
import { chartFontFamily } from '../../theme';
import { cloneDeep } from 'lodash';

// am4core.useTheme(am4themes_material);
am4core.useTheme(kelly);
// am4core.useTheme(am4themes_animated);


let grouper;

let dummyData = [{
    'sector': 'Dummy',
    'disabled': true,
    'count': 1000,
    'color': am4core.color('#dadada'),
    'opacity': 0.3,
    'strokeDasharray': '4,4',
    'tooltip': ''
}];

export default class PieChart extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let newData = cloneDeep(this.props.chartData);
            this.updateData(newData);
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.chartData !== this.props.chartData) {
            return true;
        }
        return false;
    };

    updateData = (data) => {
        if (data && data.length > 0) {
            if (data.length > 5) {
                grouper.threshold = getPieThreshold(data);
            }
            this.chart.data = data;
        } else {
            this.chart.data = dummyData;
            this.setDummyPie();
        }
    };

    setDummyPie = () => {
        let slice = this.pieSeries.slices.template;
        slice.propertyFields.fill = 'color';
        slice.propertyFields.fillOpacity = 'opacity';
        slice.propertyFields.stroke = 'color';
        slice.propertyFields.strokeDasharray = 'strokeDasharray';
        slice.propertyFields.tooltipText = 'tooltip';

        this.pieSeries.labels.template.propertyFields.disabled = 'disabled';
        this.pieSeries.ticks.template.propertyFields.disabled = 'disabled';

        this.pieSeries.dataFields.hiddenInLegend = 'disabled';
    };


    componentDidMount() {
        const {
            idChart,
            chartData,
            categoryField,
            valueField,
            legend,
            legendAlignment
        } = this.props;

        let chart = am4core.create(idChart, am4charts.PieChart);
        chart.fontFamily = chartFontFamily;

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.category = categoryField;
        pieSeries.dataFields.value = valueField;

        // pieSeries.colors.list = [
        //     am4core.color("#ff5252"),
        //     am4core.color("#536dfe"),
        //     am4core.color("#69f0ae"),
        //     am4core.color("#ffd740"),
        //     am4core.color("#e040fb"),
        //     am4core.color("#40c4ff"),
        //     am4core.color("#eeff41"),
        //     am4core.color("#757575"),
        //     am4core.color("#ff4081")
        // ];

        grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.groupName = 'Other';
        grouper.clickBehavior = 'break';

        // Let's cut a hole in our Pie chart the size of 30% the radius
        chart.innerRadius = am4core.percent(42);

        // Put a thick white border around each Slice
        // pieSeries.slices.template.stroke = am4core.color('#fff');
        // pieSeries.slices.template.strokeWidth = 2;
        // pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template
            // change the cursor on hover to make it apparent the object can be interacted with
            .cursorOverStyle = [
            {
                'property': 'cursor',
                'value': 'pointer'
            }
        ];

        // pieSeries.alignLabels = true;
        // pieSeries.labels.template.bent = true;
        // pieSeries.labels.template.radius = 3;
        // pieSeries.labels.template.padding(0, 0, 0, 0);

        // chart.startAngle = 180;
        // chart.endAngle = 360;

        // pieSeries.ticks.template.disabled = true;

        // Create a base filter effect (as if it's not there) for the hover to return to
        let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
        shadow.opacity = 0;

        // Create hover state
        let hoverState = pieSeries.slices.template.states.getKey('hover');

        // Slightly shift the shadow and make it more prominent on hover
        let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;

        pieSeries.slices.template.fontFamily = chartFontFamily;
        pieSeries.tooltip.fontFamily = chartFontFamily;

        if (legend) {
            chart.legend = new am4charts.Legend();
            chart.legend.position = legendAlignment;
            chart.legend.fontSize = 14;
            // pieSeries.slices.template.tooltipText = '';
            pieSeries.labels.template.disabled = true;
            // pieSeries.ticks.template.disabled = true;
            //chart.legend.maxHeight = 100;
            chart.legend.maxWidth = 270;
        }

        this.chart = chart;
        this.pieSeries = pieSeries;

        this.updateData(chartData);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={this.props.idChart} style={{ width: '100%', height: '230px' }}></div>
        );
    }
}
