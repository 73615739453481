import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { getIn } from 'formik';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FORGOT_PASSWORD_STATE, SIGN_UP_STATE } from './AuthForm';

export default function SignInForm(props) {

    const { formik, changeState, fetching, wrongPassword, emailNotExists } = props;

    const classes = useStyles();

    return (
        <div className={classes.form}>
            <TextField
                variant="outlined"
                margin="normal"
                //required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus

                name="email_SignIn"
                value={formik.values.email_SignIn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

                helperText={
                    getIn(formik.errors, 'email_SignIn')
                    && formik.errors.email_SignIn || emailNotExists && 'Email not registered'
                }
                error={getIn(formik.errors, 'email_SignIn')
                && formik.errors.email_SignIn || emailNotExists && 'Email not registered'
                }
            />
            <TextField
                variant="outlined"
                margin="normal"
                //required
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"

                name="password_SignIn"
                value={formik.values.password_SignIn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

                helperText={
                    getIn(formik.errors, 'password_SignIn')
                    && formik.errors.password_SignIn || wrongPassword && 'Password Incorrect'
                }
                error={getIn(formik.errors, 'password_SignIn')
                && formik.errors.password_SignIn || wrongPassword && 'Password Incorrect'
                }
            />
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
            {/*    label="Remember me"*/}
            {/*/>*/}
            {fetching ?
                <LinearProgress/>
                : null
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                form="sign-form"
                value="Submit"
                disabled={fetching}
            >
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link onClick={() => changeState(FORGOT_PASSWORD_STATE)} variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    <Link onClick={() => changeState(SIGN_UP_STATE)} variant="body2">
                        {'Don\'t have an account? Sign Up'}
                    </Link>
                    {/*<Link onClick={changeState} variant="body2">*/}
                    {/*    {'Can\'t login?'}*/}
                    {/*</Link>*/}
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
