import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { Typography } from '@material-ui/core';

class Events extends Component {
    render() {
        return (
            <DashboardLayout title="Events">
                <Typography
                    variant="h4"
                >
                   COMING SOON
                </Typography>
            </DashboardLayout>
        );
    }
}

export default Events;
