import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../UI/ButtonExtended/ButtonExtended';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    button: {
        padding: '4px 4px 4px 4px',
        fontSize: '10px'
    }
}));


export default function ClaimStatusButton(props) {
    const {
        status,
        changeStatus,
        ...rest
    } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickAction = (action) => {
        handleClose();
        changeStatus(action);
    };

    const getBackgroundColor = () => {
        switch (props.status) {
            case 'accepted':
                return 'primary';
            case 'pending':
                return 'warning';
            case 'declined':
                return 'secondary';
            default:
                return 'secondary';
        }
    };

    const renderStatusLabel = () => {
        let labelStatus;
        switch (props.status) {
            case 'accepted':
                labelStatus = 'ACCEPTED';
                break;
            case 'pending':
                labelStatus = 'PENDING';
                break;
            case 'declined':
                labelStatus = 'DECLINED';
                break;
            default:
                labelStatus = 'PENDING';
                break;
        }
        return (
            labelStatus
        );
    };

    return (
        <div>
            <Button
                variant="contained"
                color={getBackgroundColor()}
                disableElevation={true}
                className={classes.button}
                size={'small'}
                onClick={handleClickMenu}
            >
                {renderStatusLabel()}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {status !== 'pending' ?
                    <MenuItem onClick={() => handleClickAction('pending')}>Pending</MenuItem> : null}
                {status !== 'accepted' ? <MenuItem onClick={() => handleClickAction('accepted')}>Accept</MenuItem> : null}
                {status !== 'declined' ? <MenuItem onClick={() => handleClickAction('declined')}>Decline</MenuItem> : null}
            </Menu>
        </div>
    );
}
