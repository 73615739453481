import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../ConfirmDialog';
import {
    ADD_PORTFOLIO,
    can,
    DELETE_PORTFOLIO
} from '../../../helpers/Roles/roleActions';
import CompanyCard from '../../CompanyCard';


const PortfolioForm = (props) => {
    const {
        classes, className,
        myUser,
        openDialogSearchCompany, portfolios, handleClickCompany, handleClickRemovePortfolio, formDisabled,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const [confirmDialogVisible, setConfirmDialogVisible] = React.useState(false);
    const [companyClicked, setCompanyClicked] = React.useState(null);

    const onClickRemoveCompany = (company) => {
        setConfirmDialogVisible(true);
        setCompanyClicked(company);
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Portfolio"
                />
                {can(myUser, ADD_PORTFOLIO) && !formDisabled ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={openDialogSearchCompany}
                    >
                        ADD COMPANY
                    </Button>
                    : null
                }
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid container item xs={12} spacing={2}>
                        {portfolios ?
                            portfolios.map(company => {
                                return (
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <CompanyCard
                                            onClickName={() => handleClickCompany(company)}
                                            onClickDelete={() => onClickRemoveCompany(company)}
                                            companyInfo={company}
                                            canDelete={can(myUser, DELETE_PORTFOLIO) && !formDisabled}
                                        />
                                    </Grid>
                                );
                            })
                            : null
                        }
                    </Grid>
                </Grid>
            </PortletContent>
            <ConfirmDialog
                visible={confirmDialogVisible}
                onClose={() => setConfirmDialogVisible(false)}
                onConfirm={
                    () => {
                        setConfirmDialogVisible(false);
                        handleClickRemovePortfolio(companyClicked);
                    }
                }
                title={'REMOVE PORTFOLIO'}
                content={'Are you sure?'}
            />
        </Paper>

    );
};

export default withStyles(styles)(PortfolioForm);
