import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { Typography } from '@material-ui/core';
import Spacer from '../../components/Spacer/Spacer';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../components/Paper';
import BodyTerms from './bodyTerms.js'

class TermsCondition extends Component {
    render() {
        return (
            <DashboardLayout title="Terms & Conditions">
                <Grid
                    container
                    spacing={2}
                    xs
                >
                    <Grid item xs={12} sm={10} lg={8}>
                        <CustomPaper style={{ minHeight: 200 }}>
                            <div
                                style={{
                                    padding: '20px'
                                }}
                            >
                                <BodyTerms/>
                            </div>
                        </CustomPaper>
                    </Grid>
                </Grid>
            </DashboardLayout>
        );
    }
}

export default TermsCondition;
