import React from 'react';

import styles from './styles';
import { withStyles } from '@material-ui/core';

const CompanyAvatar = (props) => {
    const { urlAvatarSaved, avatarFile, classes } = props;

    return (
        <div>
            <img
                className={classes.media}
                src={avatarFile ? URL.createObjectURL(avatarFile) :
                    (urlAvatarSaved ? urlAvatarSaved : require('../../../assets/images/logo-thumb.png'))}
                alt="new"
            />
        </div>

    );
};

export default withStyles(styles)(CompanyAvatar);
