import React, { Component } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import history from '../../../config/history';
import { withRouter } from 'react-router-dom';
import ContactsBox from '../../Contacts';
import { actionTypes } from '../../../helpers/actionTypes';
import styles from '../styles';
import AvatarNameCell from '../AvatarNameCell';
import {INNER_TEAM } from '../../../helpers/Roles/roleActions';
import moment from 'moment';
import ClaimStatusButton from './ClaimStatusButton';

class ClaimRow extends Component {
    onClickNameCompany = () => {
        if (INNER_TEAM.includes(this.props.myUser.role)) {
            history.push({
                pathname: '/company/' + actionTypes.EDIT + '/' + this.props.row.company_id
            });
        } else {
            history.push({
                pathname: '/company/' + actionTypes.SHOW + '/' + this.props.row.company_id
            });
        }
    };

    render() {
        const {
            onClickCheckbox,
            isItemSelected,
            row,
            labelId,
            onClickStatus,
            myUser,
            activeSelection,
            onClickDelete,
            changeStatus
        } = this.props;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                {/*{INNER_TEAM.includes(myUser.role) ?*/}
                {/*    <TableCell padding="checkbox">*/}
                {/*        <Checkbox*/}
                {/*            onClick={event => onClickCheckbox(event, row.idrow)}*/}
                {/*            checked={isItemSelected}*/}
                {/*            inputProps={{ 'aria-labelledby': labelId }}*/}
                {/*        />*/}
                {/*    </TableCell>*/}
                {/*    : null*/}
                {/*}*/}
                <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    onClick={this.onClickNameCompany}
                    className={styles.noWrapCell}
                    style={{ ...styles.noWrapCell, ...styles.pointer }}
                >
                    <AvatarNameCell
                        avatarUrl={row.avatar_url}
                        name={row.name}
                        truncateLength={14}
                    />
                </TableCell>
                <TableCell style={styles.noWrapCell}>
                    {row.first_name + ' ' + row.last_name}
                </TableCell>
                <TableCell size={'small'}>
                    {row.role_user}
                </TableCell>
                <TableCell size={'small'}>
                    <ClaimStatusButton
                        status={row.claim_status}
                        changeStatus={(newStatus) => changeStatus(row.claim_id, newStatus)}
                        // onClick={onClickStatus ? () => onClickStatus(row) : null}
                    />
                </TableCell>
                <TableCell size={'small'}>
                    <ContactsBox dataInfo={row} userEmail={row.user_email} justMail/>
                </TableCell>
                <TableCell size={'small'}>
                    {moment(row.date_request).utc().format('DD/MM/YYYY - HH:mm')}
                </TableCell>
                {/*<TableCell align={'right'}>*/}
                {/*    <ActionsBox*/}
                {/*        disabled={activeSelection}*/}
                {/*        onEdit={can(myUser, EDIT_ROW) ? this.onClickNameCompany : null}*/}
                {/*        onDelete={can(myUser, EDIT_ROW) ? () => onClickDelete(row) : null}*/}
                {/*    />*/}
                {/*</TableCell>*/}
            </TableRow>
        );
    }
}

export default withRouter(ClaimRow);
