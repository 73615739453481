export const investmentTypeList = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 4,
        name: 'Acquisition',
        value: 'Acquisition'
    },
    {
        id: 5,
        name: 'Award or Prize',
        value: 'Award or Prize'
    },
    {
        id: 7,
        name: 'Convertible',
        value: 'Convertible'
    },
    {
        id: 8,
        name: 'Crowdfunding',
        value: 'Crowdfunding'
    },
    {
        id: 9,
        name: 'Debt',
        value: 'Debt'
    },
    {
        id: 3,
        name: 'Debt Financing',
        value: 'Debt Financing'
    },
    {
        id: 14,
        name: 'Equity',
        value: 'Equity'
    },
    {
        id: 10,
        name: 'Grant',
        value: 'Grant'
    },
    {
        id: 6,
        name: 'Loan',
        value: 'Loan'
    },
    {
        id: 11,
        name: 'Merger',
        value: 'Merger'
    },
    {
        id: 12,
        name: 'Non-equity Assistance',
        value: 'Non-Equity Assistance'
    },
    {
        id: 15,
        name: 'SAFE',
        value: 'SAFE'
    },
    {
        id: 15,
        name: 'Trade Finance',
        value: 'Trade Finance'
    },
    {
        id: 13,
        name: 'Other',
        value: 'Other'
    }
];
