export function capitalizeFirstLetter(string) {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return '';
    }
}

export function getCategory(category) {
    let lowerCategory = category.toLowerCase();
    if (lowerCategory === 'startup') {
        return 'Company';
    } else {
        if(lowerCategory === 'ngo_charity') {
            lowerCategory = "NGO or Foundation";
            return lowerCategory;
        } else {
            return capitalizeFirstLetter(lowerCategory);
        }
    }
}
