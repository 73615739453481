import React from 'react';
import Button from '../UI/ButtonExtended/ButtonExtended';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    button: {
        padding: '4px 4px 4px 4px',
        fontSize: '10px',
    }
}));

export default function StatusButton(props) {
    const classes = useStyles();
    const { onClick } = props;

    const renderStatusLabel = () => {
        let labelStatus;
        let buttonStyle = {};
        switch (props.status) {
            case true:
                labelStatus = 'ACTIVE';
                buttonStyle = { backgroundColor: 'red' };
                break;
            case false:
                labelStatus = 'REVIEW';
                break;
            default:
                labelStatus = 'REVIEW';
                break;
        }
        return (
            labelStatus
        );
    };

    return (
        <div>
            <Button
                variant="contained"
                color={renderStatusLabel() === 'ACTIVE' ? 'success' : 'warning'}
                disableElevation={true}
                className={classes.button}
                size={'small'}
                onClick={onClick ? onClick : null}
            >
                {renderStatusLabel()}
            </Button>
        </div>
    );
}
