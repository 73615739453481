import axiosApp, { BASE_URL } from '../helpers/axiosConfig';
import { authHeader } from '../auth/authUtils';
import axios from 'axios';

const qs = require('qs');

export const COMPANIES_FOLLOWED_FETCH = 'companies_followed_fetch';
export const COMPANIES_FOLLOWED_FETCH_SUCCESS = 'companies_followed_fetch_success';
export const COMPANIES_FOLLOWED_FETCH_ERROR = 'companies_followed_fetch_error';

export const UPDATE_FOLLOW_STATUS = 'update_follow_status';
export const UPDATE_FOLLOW_STATUS_SUCCESS = 'update_follow_status_success';
export const UPDATE_FOLLOW_STATUS_ERROR = 'update_follow_status_error';

export const CLEAR_NOTIFICATION_BADGE = 'clear_notification_badge';

export const fetchFollows = (myUser) => {
    let params = {
        user_id: myUser.user_id
    };

    return dispatch => {
        dispatch({ type: COMPANIES_FOLLOWED_FETCH });
        axiosApp.get(BASE_URL + '/follow/get-companies-followed/', {
            params, headers: authHeader(myUser)
        })
            .then(res => {
                dispatch({
                    type: COMPANIES_FOLLOWED_FETCH_SUCCESS,
                    payload: {
                        companiesFollowed: res.data.companiesFollowed,
                        notifications: res.data.notifications
                    }
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: COMPANIES_FOLLOWED_FETCH_ERROR,
                    payload: err
                });
            });
    };
};

export const setNotificationsSeen = (myUser) => {
    return dispatch => {
        dispatch({ type: CLEAR_NOTIFICATION_BADGE });
        const data = { user_id: myUser.user_id };
        // axios.put(BASE_URL + '/follow/set-seen',
        axiosApp.put(BASE_URL + '/follow/set-seen',
            data,
            { headers: authHeader(myUser) }
        )
            .then(res => {
            })
            .catch(err => {
            });
    };
};


export const clickFollowCompany = (company_id, followingCompany, myUser) => {
    let newStatus = true;
    if (followingCompany && followingCompany.includes(company_id)) {
        newStatus = false;
    }
    return dispatch => {
        dispatch({ type: UPDATE_FOLLOW_STATUS });
        const data = { company_id, followingCompany, newStatus, user_id: myUser.user_id };
        axiosApp.put(BASE_URL + '/follow/update-status',
            data,
            { headers: authHeader(myUser) }
        )
            .then(res => {
                let followingCompanyUpdated = followingCompany;
                if (newStatus === true) {
                    followingCompanyUpdated.push(company_id);
                } else {
                    followingCompanyUpdated = followingCompanyUpdated.filter(e => e !== company_id);
                }
                dispatch({
                    type: UPDATE_FOLLOW_STATUS_SUCCESS,
                    payload: { companiesFollowed: followingCompanyUpdated }
                });
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_FOLLOW_STATUS_ERROR,
                    payload: err
                });
            });
    };
};
