import {
    SEND_EMAIL,
    EMAIL_SENT
} from '../actions/contactUsHandler';

const initialState = {
    sendingEmail: false
};

export default function contactUsHandler(state = initialState, action) {
    switch (action.type) {
        case SEND_EMAIL:
            return {
                ...state,
                sendingEmail: true,
            };
        case EMAIL_SENT:
            return {
                ...state,
                sendingEmail: false,
            };
        default:
            return state;
    }
}
