import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import CompanyDocsForm from '../../components/Company/CompanyDocsForm/CompanyDocsForm';
import { connect } from 'react-redux';
import { addFileToDocuments, removeFileToDocuments, uploadDocument } from '../../actions/companyHandler';
import axios from 'axios';
import { BASE_URL } from '../../helpers/axiosConfig';
import { authHeader } from '../../auth/authUtils';

class UploadFileTab extends Component {

    uploadDocument = (doc) => {
        const company_id = this.props.companyId;
        this.props.uploadDocument(doc, company_id, this.props.myUser);
    };

    downloadDoc = (doc) => {
        axios.get(BASE_URL + '/file/get_signed_url', {
            params: {
                key: doc.key
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                if (data.urlFile) {
                    const link = document.createElement('a');
                    link.href = data.urlFile;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
            }).catch(err => {
            console.error(err);
        });
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={9}>
                        {this.props.myUser && this.props.myUser.role !== 'user' ?
                            <CompanyDocsForm
                                formDisabled={this.props.formDisabled}
                                companyDocuments={this.props.companyDocuments}
                                myUser={this.props.myUser}
                                addFileToDocuments={this.props.addFileToDocuments}
                                handleClickRemoveDoc={this.props.removeFileToDocuments}
                                handleClickDownload={this.downloadDoc}
                                handleUploadDocClick={this.uploadDocument}
                            />
                            : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = {
    addFileToDocuments,
    removeFileToDocuments,
    uploadDocument
};

const mapStateToProps = (state) => {
    return {
        companyDocuments: state.companyHandler.companyDocuments,
        fetchingCompanyDocuments: state.companyHandler.fetchingCompanyDocuments,
        companyDocumentsError: state.companyHandler.companyDocumentsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileTab);
