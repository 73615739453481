export const stagesOfInterestListFilter = [
    {
        stage_id: "108",
        name: 'Incubator',
        value: 'Incubator'
    },
    {
        stage_id: "109",
        name: 'Accelerator',
        value: 'Accelerator'
    },
    {
        stage_id: "10",
        name: 'Angel Round',
        value: 'Angel Round'
    },
    {
        stage_id: "3",
        name: 'Pre-Seed',
        value: 'Pre-Seed'
    },
    {
        stage_id: "4",
        name: 'Seed',
        value: 'Seed'
    },
    {
        stage_id: "100",
        name: 'Pre-Series A',
        value: 'Pre-Series A'
    },
    {
        stage_id: "5",
        name: 'Series A',
        value: 'Series A'
    },
    {
        stage_id: "101",
        name: 'Pre-Series B',
        value: 'Pre-Series B'
    },
    {
        stage_id: "6",
        name: 'Series B',
        value: 'Series B'
    },
    {
        stage_id: "102",
        name: 'Pre-Series C',
        value: 'Pre-Series C'
    },
    {
        stage_id: "7",
        name: 'Series C',
        value: 'Series C'
    },
    {
        stage_id: "8",
        name: 'Series D',
        value: 'Series D'
    },
    {
        stage_id: "9",
        name: 'Series E',
        value: 'Series E'
    },
    {
        stage_id: "103",
        name: 'Series F',
        value: 'Series F'
    },
    {
        stage_id: "2",
        name: 'IPO',
        value: 'IPO'
    },
    {
        stage_id: "104",
        name: 'ICO',
        value: 'ICO'
    }];
