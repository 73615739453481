import { withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';

const buttonSuccess = (theme) => {
    return {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.success.main
            }
        }
    };
};
const buttonInfo = (theme) => {
    return {
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.info.main
            }
        }
    };
};

const buttonAction = (theme) => {
    return {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.secondary.main
            }
        }
    };
};

const buttonWarning = (theme) => {
    return {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.warning.main
            }
        }
    };
};

const buttonNeutral = (theme) => {
    return {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
            '@media (hover: none)': {
                backgroundColor: theme.palette.neutral.main
            }
        }
    };
};

const Button = withStyles((theme) => {
    return {
        root: props =>
            props.color === 'success' && props.variant === 'contained'
                ? buttonSuccess(theme)
                : props.color === 'warning' && props.variant === 'contained'
                ? buttonWarning(theme)
                : props.color === 'info' && props.variant === 'contained'
                    ? buttonInfo(theme)
                    : props.color === 'action' && props.variant === 'contained'
                        ? buttonAction(theme)
                        : props.color === 'neutral' && props.variant === 'contained'
                            ? buttonNeutral(theme)
                            : {}
    };
})(MuiButton);

export default Button;
