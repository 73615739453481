import axiosApp, { BASE_URL } from '../helpers/axiosConfig';
import axios from 'axios';
import { authHeader } from '../auth/authUtils';

export const DOWNLOAD_CSV_REQUEST = 'download_csv_request';
export const DOWNLOAD_CSV_SUCCESS = 'download_csv_success';
export const DOWNLOAD_CSV_ERROR = 'companies_followed_fetch_error';

export const CLEAR_CSV_HANLDER = 'clear_csv_hanlder';

const qs = require('qs');

const FileDownload = require('js-file-download');

export const clearCsvHandler = () => {
    return dispatch => {
        dispatch({ type: CLEAR_CSV_HANLDER });
    };
};

export const downloadCsvCompany = ({ myUser = null, type = null, pagination = null, ids_selected = null }) => {
    // console.log('myUser', myUser);
    // console.log('type', type);
    // console.log('pagination', pagination);
    // console.log('ids_selected', ids_selected);

    return dispatch => {
        dispatch({ type: DOWNLOAD_CSV_REQUEST });
        let paginationQs = null;
        if (pagination) {
            paginationQs = qs.stringify(pagination);
        }

        axios.get(BASE_URL + '/company/download', {
            params: {
                pagination: paginationQs,
                type: type,
                ids_selected: ids_selected
            }, headers: authHeader(myUser),
            responseType: 'blob'
        })
            .then(res => {
                // console.log(res.data);
                // FileDownload(res.data, 'report.csv');
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'companies.csv'); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch({
                    type: DOWNLOAD_CSV_SUCCESS
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: DOWNLOAD_CSV_ERROR,
                    payload: err
                });
            });
    };
};

export const downloadCsvDeals = ({ myUser = null, type = null, pagination = null, ids_selected = null }) => {
    // console.log('myUser', myUser);
    // console.log('type', type);
    // console.log('pagination', pagination);
    // console.log('ids_selected', ids_selected);

    return dispatch => {
        dispatch({ type: DOWNLOAD_CSV_REQUEST });
        let paginationQs = null;
        if (pagination) {
            paginationQs = qs.stringify(pagination);
        }

        axios.get(BASE_URL + '/deal/download', {
            params: {
                pagination: paginationQs,
                type: type,
                ids_selected: ids_selected
            }, headers: authHeader(myUser),
            responseType: 'blob'
        })
            .then(res => {
                // console.log(res.data);
                // FileDownload(res.data, 'report.csv');
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'deals.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                dispatch({
                    type: DOWNLOAD_CSV_SUCCESS
                });
            })
            .catch(err => {
                console.error(err);
                dispatch({
                    type: DOWNLOAD_CSV_ERROR,
                    payload: err
                });
            });
    };
};
