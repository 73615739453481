import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid, Divider } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../ConfirmDialog';
import {
    ADD_PORTFOLIO,
    can,
    DELETE_PORTFOLIO
} from '../../../helpers/Roles/roleActions';
import CompanyCard from '../../CompanyCard';
import Spacer from '../../Spacer/Spacer';
import Typography from '@material-ui/core/Typography';


const MarketBox = (props) => {
    const {
        classes, className,
        similarCompanies,
        handleClickCompany,
        ...rest
    } = props;

    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <div>
            <Paper
                {...rest}
                className={rootClassName}
                elevation={1}
            >
                <PortletContent>
                    {similarCompanies && similarCompanies.similarCompanies && similarCompanies.similarCompanies.internationals ?
                        <div>
                            <Typography variant="h5">Local competition landscape</Typography>
                            <Spacer/>
                            <Divider/>
                            <Spacer/>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid container item xs={11} spacing={2}>
                                    {similarCompanies && similarCompanies.similarCompanies && similarCompanies.similarCompanies.sameCountry ?
                                        similarCompanies.similarCompanies.sameCountry.map(company => {
                                            return (
                                                <Grid item xs={12} sm={6} lg={4}>
                                                    <CompanyCard
                                                        onClickName={() => handleClickCompany(company)}
                                                        companyInfo={company}
                                                    />
                                                </Grid>
                                            );
                                        })
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        : null}
                    {similarCompanies && similarCompanies.similarCompanies && similarCompanies.similarCompanies.internationals ?
                        <div>
                            <Spacer/>
                            <Typography variant="h5">International competition landscape</Typography>
                            <Spacer/>
                            <Divider/>
                            <Spacer/>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid container item xs={11} spacing={2}>
                                    {similarCompanies && similarCompanies.similarCompanies && similarCompanies.similarCompanies.internationals ?
                                        similarCompanies.similarCompanies.internationals.map(company => {
                                            return (
                                                <Grid item xs={12} sm={6} lg={4}>
                                                    <CompanyCard
                                                        onClickName={() => handleClickCompany(company)}
                                                        companyInfo={company}
                                                    />
                                                </Grid>
                                            );
                                        })
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </div> : null}
                </PortletContent>
            </Paper>
        </div>

    );
};

export default withStyles(styles)(MarketBox);
