import axios from 'axios';
import { BASE_URL } from '../helpers/axiosConfig';
import { authHeader } from '../auth/authUtils';

export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_SUCCESS = 'confirm_email_success';
export const CONFIRM_EMAIL_ERROR = 'confirm_email_error';

export const confirmEmail = (email, confirmationId) => {
    return dispatch => {
        dispatch({ type: CONFIRM_EMAIL });
        axios.post(BASE_URL + '/auth/confirm-email',
            {
                email,
                confirmationId
            },
            { headers: authHeader() }
        ).then(res => {
            dispatch({ type: CONFIRM_EMAIL_SUCCESS });
        })
            .catch(err => {
                dispatch({
                    type: CONFIRM_EMAIL_ERROR,
                    payload: err
                });
            });
    };
};


export const confirmEmailToSuccess = () => {
    return dispatch => {
        dispatch({ type: CONFIRM_EMAIL });
        dispatch({
            type: CONFIRM_EMAIL_SUCCESS,
        });
    }
};

export const confirmEmailToError = () => {
    return dispatch => {
        // dispatch({ type: CONFIRM_EMAIL });
        dispatch({
            type: CONFIRM_EMAIL_ERROR,
            payload: 'errooreee'
        });
    }
};
