import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import ContactUsForm from './ContactUsForm';

export default function ContactBoxModal(props) {

    const {
        open,
        handleClose,
        sendingEmail,
        sendEmail,
        indexSubject,
        myUser
    } = props;
    const classes = useStyles();

    const getTitleBox = () => {
        switch (indexSubject) {
            case 2:
                return (
                    <>Upgrade to continue<br/>Contact our team!</>
                );
            case 3:
                return (
                    <>Need more information? <br/> Contact our team!</>
                );
            case 4:
                return (
                    <>Suggest an update</>
                );
            case 5:
                return (
                    <>Claim this Company Profile</>
                );
            default:
                return (
                    <>Need more information? <br/> Contact our team!</>
                );
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                onBackdropClick={() => console.log('')}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 250
                }}
            >
                <Fade in={open}>
                    <Grid item xs={11} sm={8} lg={4}>
                        <Paper className={classes.paper} style={{ marginTop: 0 }}>
                            <div className={classes.logoWrapper}>
                                <Typography
                                    className={classes.title}
                                    variant="h4"
                                    style={{ textAlign: 'center' }}
                                >
                                    {getTitleBox()}
                                </Typography>
                            </div>
                            <ContactUsForm
                                myUser={myUser}
                                indexSubject={indexSubject}
                                sendingEmail={sendingEmail}
                                handleSubmitForm={sendEmail}
                            />
                        </Paper>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        position: 'absolute',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4, 6, 6, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));
