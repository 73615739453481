// --- Objects ----------------------------

export const geographyObj = {
    geography_id: null,
    hq_city: null,
    hq_country: null,
    hq_address: null,
    last_update: null
};

export const regionObj = {
    region_id: null,
    name: null
};

export const cityObj = {
    city_id: null,
    name: null
};

export const impactObj = {
    impact_id: null,
    female_cofounder: null,
    assistive_tech: null,
    rural_focused: null,
    female_focused: null,
    youth_focused: null,
    fifty_local: null,
    elderly_people: null,
    zero_emissions: null,
    informal_sector: null,
    bop: null,
    employment_creation: null,

    last_update: null
};

export const socialObj = {
    social_id: null,
    facebook: null,
    linkedin: null,
    twitter: null,
    instagram: null,
    last_update: null
};

export const geography_countryObj = {
    geography_id: null,
    country_code: null,
    last_update: null
};

export const geography_cityObj = {
    geography_id: null,
    city_id: null,
    last_update: null
};

export const ethnicityObj = {
    ethnicity_id: null,
    name: null
};

export const investmentDealsObj = {
    investor: null,
    amount: null,
    stage: null,
    type: null,
    date: null,
    deal_source: null,
    last_update: null
};

export const startupObj = {
    company_id: null,
    business_model: null
};

export const hubObj = {
    company_id: null,
    fund_provided: null,
    type: null
};

export const ngo_charityObj = {
    company_id: null,
    organization_type: null
};

export const investorObj = {
    company_id: null,
    type: null,
    fund_size: null,
    ticket_fund_size_min: null,
    ticket_fund_size_max: null,
    actively_investing: null,
    post_revenue_only: null,
    lead_rounds: null,
    number_exits: null,
    number_investment: null
};

export const companyObj = {
    company_id: null,
    geography_id: null,
    impact_id: null,
    name: null,
    active: true,
    status: null,
    category: null,
    description: null,
    founding_date: null,
    website: null,
    avatar_url: null,
    contact_number: null,
    email: null,
    number_employees: null,
    ownership_structure: null,
    social_id: null,
    last_update: null,
    operating_status: null,
    incorporation_number: null,
    reference: null,
    fundraising: null,
    stage: null,
    legal_registration: null,
    has_users: null,
    own_ip: null,
    generating_revenue: null,
    raised_capital: null,
    ext_capital_sought_types: [],
    investment_stages_ids: [],
    ext_capital_sought: null,
    past_accelerated: null,
    looking_acceleration: null,
    looking_acceleration_ids: [],
    key_milestones_ids: [],
    product_development: null,
    product_development_last_update: null
};

export const financialMetricObj = {
    financial_metric_id: null,
    year: null,
    annual_revenue: null,
    yoy_growth: null,
    mom_growth: null,
    cac: null,
    ebitda: null
};

export const company_financialObj = {
    company_id: null,
    financial_id: null,
    last_update: null
};

export const stageObj = {
    stage_id: null,
    name: null
};

export const projectObj = {
    project_id: null,
    name: null,
    description: null
};

export const company_investorObj = {
    investment_id: null,
    company_id: null
};

export const project_ngo_charityObj = {
    project_id: null,
    ngo_charity_id: null,
    last_update: null
};

export const ticket_typeObj = {
    country_id: null,
    name: null
};

export const ethnicity_impactObj = {
    ethnicity_id: null,
    impact_id: null,
    last_update: null
};

export const sectorObj = {
    sector_id: null,
    name: null,
    active: true
};

export const sub_sectorObj = {
    sub_sector_id: null,
    name: null,
    active: true
};

export const technologyObj = {
    technology_id: null,
    name: null,
    active: true
};

export const sub_sector_companyObj = {
    sub_sector_id: null,
    company_id: null,
    last_update: null
};

export const operationalMetricObj = {
    operational_metric_id: null,
    date: null,
    users: null,
    media_mention: null,
    social_followers: null
};

export const docObj = {
    doc_id: null,
    name: null,
    extension: null,
    pending: null,
    location: null,
    key: null,
    size: null,
    uploading: null,
    type: null
};

export const mentionObj = {
    mention_id: null,
    company_id: null,
    url: null,
    last_update: null
};


export const personaObj = {
    persona_id: null,
    name: null,
    active: true,
    lastname: null,
    role: null,
    ethnicity: null,
    gender: null,
    level_education: null,
    degree: null,
    university: null,
    email: null,
    social_id: null,
    last_update: null,
    avatar_url: null,
    companies: []
};

export const sdgObj = {
    sdg_id: null,
    name: null,
    img_url: null
};

export const logObj = {
    log_id: null,
    name: null
};

export const dealObj = {
    investment_id: null,
    name: null,
    stage: null,
    type: null,
    amount: null,
    date: null,
    investors: []
};

export const investmentObj = {
    investment_id: null,
    name: null,
    stage: null,
    type: null,
    pre_money_valuation: null,
    equity_sold_percentage: null,
    amount: null,
    date: null,
    investors: [],
    company_id: null
};

export const claimObj = {
    claim_id: null,
    first_name: null,
    last_name: null,
    email: null,
    status: null,
    date_quest: null,
    user_role: null
};


export const sector_companyObj = {
    sector_id: null,
    company_id: null,
    last_update: null
};

export const investor_typeObj = {
    investor_type_id: null,
    type: null
};

export const geography_regionObj = {
    geography_id: null,
    region_id: null,
    last_update: null
};

export const stage_companyObj = {
    stage_id: null,
    company_id: null,
    last_update: null
};


export const ticket_type_investorObj = {
    ticket_type_id: null,
    investor_id: null,
    last_update: null
};

export const company_metricObj = {
    company_id: null,
    operational_metric_id: null,
    last_update: null
};

export const operational_metricObj = {
    operational_metric_id: null,
    date: null,
    users: null,
    media_mention: null,
    social_followers: null,
    user_growth: null
};

export const sdg_companyObj = {
    sdg_id: null,
    company_id: null,
    last_update: null
};

export const company_personaObj = {
    company_id: null,
    persona_id: null,
    last_update: null
};

export const investor_type_investorObj = {
    investor_type_id: null,
    investor_id: null,
    last_update: null
};

// --------------- Datapoints Tables Objects ---------------------------

export const startupTablesObj = {
    indexRow: null,
    indexRowCsv: null,
    company: null,
    personas: [],
    socials: [],
    financial_metric: null,
    operational_metric: null,
    geography: null,
    startup: null,

    company_persona: [],
    company_financial: null,
    company_metric: null,
    sector_company: null,
    sub_sector_company: [],
    impact: null
};

export const hubTablesObj = {
    indexRow: null,
    indexRowCsv: null,
    company: null,
    personas: [],
    socials: [],
    geography: null,
    hub: null,

    company_persona: [],
    impact: null
};

export const investorsTablesObj = {
    indexRow: null,
    indexRowCsv: null,
    company: null,
    personas: [],
    geography: null,
    investor: null,
    socials: [],

    company_persona: [],
    sector_company: null,
    impact: null
};
