import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid, TextField } from '@material-ui/core';
import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Spacer from '../../Spacer/Spacer';
import { hasUsersList } from '../../../models/company/company';

const OPSForm = (props) => {
    const {
        classes, className, formik,
        dateSelected, dateList, quarterList, handleChangeDate,

        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    // console.log(formik.values);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Operational Metrics"
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={6}
                    >
                        <TextField
                            className={classes.textField}
                            label="Users"
                            name="company.has_users"
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.company.has_users}
                            onChange={
                                () => formik.setFieldValue('company.has_users',
                                    !formik.values.company.has_users)
                            }
                            onBlur={formik.handleBlur}

                            variant="outlined"
                            fullWidth
                        >
                            {hasUsersList.map(option => (
                                <option
                                    key={option.id}
                                    value={option.value}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {(formik.values.company.has_users === true) ?
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Spacer small divider/>
                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} lg={4}
                                >
                                    <TextField
                                        className={classes.textField}
                                        label="Year"
                                        name={`financial_metrics[${dateSelected}].year`}
                                        select
                                        SelectProps={{ native: true }}
                                        value={formik.values.financial_metrics && formik.values.financial_metrics[dateSelected]
                                            ? formik.values.financial_metrics[dateSelected].year : null}
                                        onChange={handleChangeDate}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {dateList.map(option => (
                                            <option
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={4} lg={2}
                                >
                                    <TextField
                                        className={classes.textField}
                                        label="Quarter"
                                        // name={`financial_metrics[${dateSelected}].year`}
                                        select
                                        SelectProps={{ native: true }}
                                        // value={formik.values.financial_metrics[dateSelected].year}
                                        // onChange={handleChangeDate}
                                        onBlur={formik.handleBlur}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        {quarterList.map(option => (
                                            <option
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                            </Grid>

                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} lg={4}
                                >
                                    <TextField
                                        label="Total Users"
                                        //name={`financial_metrics[${dateSelected}].annual_revenue`}
                                        className={classes.textField}
                                        //value={formik.values.financial_metrics[dateSelected].annual_revenue}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete='off'

                                        variant="outlined"
                                        fullWidth
                                        // error={!isNumeric(formik.values.financial_metrics[dateSelected].annual_revenue)}
                                    />
                                </Grid>
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} lg={4}
                                >
                                    <TextField
                                        label="Active Monthly Users"
                                        //name={`financial_metrics[${dateSelected}].annual_revenue`}
                                        className={classes.textField}
                                        //value={formik.values.financial_metrics[dateSelected].annual_revenue}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete='off'

                                        variant="outlined"
                                        fullWidth
                                        // error={!isNumeric(formik.values.financial_metrics[dateSelected].annual_revenue)}
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                        : null}
                </Grid>
            </PortletContent>
        </Paper>
    );
};

export default withStyles(styles)(OPSForm);
