import 'core-js';
import React from 'react';
import {
    Paper,
    PortletContent
} from '../../index';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import styles from '../styles';
import ColumnChart from '../ColumnChart';
import LinearProgress from '@material-ui/core/LinearProgress';


const ColumnChartBox = (props) => {
    const {
        classes, className, categoryField, valueField, title, data, idChart,
        fetching,
        innerColor,
        maxColumns,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);
    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletContent>
                <Typography>
                    {title}
                </Typography>
                <ColumnChart
                    idChart={idChart}
                    chartData={data}
                    categoryField={categoryField}
                    valueField={valueField}
                    innerColor={innerColor}
                    maxColumns={maxColumns}
                />
            </PortletContent>
            {fetching ? <LinearProgress/> : null}
        </Paper>

    );

};

export default withStyles(styles)(ColumnChartBox);
