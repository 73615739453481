import React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
//import Icon from '@material-ui/core/Icon';
import { Grid, Typography } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    textWhite: {
        color: '#ffffff'
    },
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
}));


const CollapseElement = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const { variant } = props;
    const Icon = variantIcon[variant];

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Slide direction="left" in={true}>
            <Paper className={clsx(classes[variant])}>
                <Grid container alignItems="center" justify="space-between" style={{ padding: '5px 20px' }}>
                    <Grid item xl={11}>
                        <div id="" className={classes.message}>
                            <Icon className={clsx(classes.icon, classes.iconVariant, classes[props.textColor])}/>
                            <Typography className={classes[props.textColor]}
                                        variant={'body2'}>{props.message}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={clsx(classes.expand, classes[props.textColor], {
                                [classes.expandOpen]: expanded
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box>
                        {props.children}
                    </Box>
                </Collapse>
            </Paper>
        </Slide>
    );
};

export default CollapseElement;
