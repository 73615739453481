import { cloneDeep } from 'lodash';
import { userProfileObj } from '../../data/admin/tableObjects';

export const SORTING = {
    USERS_PREVIEW_DEFAULT: 'last_access'
};

export const userDataEmpty = {
    user: cloneDeep(userProfileObj)
};

export const getNameLastname = (userInfo) => {
    let nameLastname = '';
    if (userInfo) {
        if (userInfo.name) {
            nameLastname = nameLastname.concat(userInfo.first_name);
        }
        if (userInfo.lastname) {
            nameLastname = nameLastname.concat(' ' + userInfo.last_name);
        }
    }
    return nameLastname;
};
