export const investorTypeList = [
    // {
    //     type_id: 0,
    //     name: null,
    //     value: null
    // },
    {
        type_id: '10',
        name: 'Angel',
        value: 'Angel'
    },
    {
        type_id: '20',
        name: 'Bank',
        value: 'Bank'
    },
    {
        type_id: '30',
        name: 'Corporate Venture Arm',
        value: 'Corporate Venture Arm'
    },
    {
        type_id: '150',
        name: 'Debt Providers',
        value: 'Debt Providers'
    },
    {
        type_id: '40',
        name: 'Development Finance',
        value: 'Development Finance'
    },
    {
        type_id: '50',
        name: 'Donor',
        value: 'Donor'
    },
    // {
    //     type_id: '60',
    //     name: 'Fund',
    //     value: 'Fund'
    // },
    {
        type_id: '120',
        name: 'Family Office',
        value: 'Family Office'
    },
    {
        type_id: '70',
        name: 'Government',
        value: 'Government'
    },
    {
        type_id: '80',
        name: 'Impact',
        value: 'Impact'
    },
    {
        type_id: '90',
        name: 'Private Equity',
        value: 'Private Equity'
    },
    {
        type_id: '130',
        name: 'Seed Fund',
        value: 'Seed Fund'
    },
    {
        type_id: '100',
        name: 'Venture Capital',
        value: 'Venture Capital'
    },
    // {
    //     type_id: '140',
    //     name: 'Other',
    //     value: 'Other'
    // }

];


export const investorTypeListFilter = [
    {
        type_id: '10',
        name: 'Angel',
        value: 'Angel'
    },
    {
        type_id: '20',
        name: 'Bank',
        value: 'Bank'
    },
    {
        type_id: '30',
        name: 'Corporate Venture Arm',
        value: 'Corporate Venture Arm'
    },
    {
        type_id: '150',
        name: 'Debt Providers',
        value: 'Debt Providers'
    },
    {
        type_id: '40',
        name: 'Development Finance',
        value: 'Development Finance'
    },
    {
        type_id: '50',
        name: 'Donor',
        value: 'Donor'
    },
    {
        type_id: '120',
        name: 'Family Office',
        value: 'Family Office'
    },
    // {
    //     type_id: '60',
    //     name: 'Fund',
    //     value: 'Fund'
    // },
    {
        type_id: '70',
        name: 'Government',
        value: 'Government'
    },
    {
        type_id: '80',
        name: 'Impact',
        value: 'Impact'
    },
    {
        type_id: '90',
        name: 'Private Equity',
        value: 'Private Equity'
    },
    {
        type_id: '130',
        name: 'Seed Fund',
        value: 'Seed Fund'
    },
    {
        type_id: '100',
        name: 'Venture Capital',
        value: 'Venture Capital'
    },
    // {
    //     type_id: '140',
    //     name: 'Other',
    //     value: 'Other'
    // }
];
