import 'core-js';
import React from 'react';
import {
    Paper,
    PortletContent
} from '../index';
import classNames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import Spacer from '../Spacer/Spacer';
import RowStat from './RowStat';


const GeneralStastBox = (props) => {
    const { classes, className, generalStats, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            style={{ height: '388px', paddingBottom: 0 }}
            // style={{ height: '457px', paddingBottom: 0 }}
            elevation={1}
        >
            <PortletContent style={{ height: '100%' }}>
                <Grid container
                      spacing={1}
                      direction="column"
                      justify="space-between"
                      alignItems="stretch">
                    <Grid item>
                        <RowStat
                            title={'Operating Companies'}
                            stat={generalStats.numCompanies}
                            type={'company'}
                        />
                        <Spacer small divider/>
                    </Grid>
                    <Grid item>
                        <RowStat
                            title={'Sectors'}
                            stat={generalStats.numSectors}
                            type={'sector'}
                        />
                        <Spacer small divider/>
                    </Grid>
                    <Grid item>
                        <RowStat
                            title={'Products'}
                            stat={generalStats.numSubSectors}
                            type={'product'}
                        />
                        <Spacer small divider/>
                    </Grid>
                    <Grid item>
                        <RowStat
                            title={'Funding since January'}
                            stat={'$' + (generalStats && generalStats.annualInvestment !== undefined ? generalStats.annualInvestment : 0)}
                            type={'dollar'}
                        />
                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );

};

export default withStyles(styles)(GeneralStastBox);
