import 'core-js';
import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { chartFontFamily } from '../../theme';
import { getDummyTrends } from '../../helpers/Charts/dummyTrends';
import { formatCash } from '../../helpers/Stats/investments';
import { cloneDeep } from 'lodash';

export default class TrendChart extends Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.chartData !== this.props.chartData) {
            return true;
        }
        return false;
    };

    updateData = (data) => {
        if (data && data.length > 0) {
            this.chart.data = data;
        } else {
            this.chart.data = this.dummyData;
        }
        // this.chart.data = getDummyTrends();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let newData = cloneDeep(this.props.chartData);
            this.updateData(newData);
        }
    }


    componentDidMount() {
        const {
            idChart,
            chartData
        } = this.props;

        let chart = am4core.create(idChart, am4charts.XYChart);
        chart.fontFamily = chartFontFamily;
        // chart.height = 500

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'right';

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0.1;
        // dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
        dateAxis.renderer.minGridDistance = 40;
        // dateAxis.baseInterval = {
        //     'timeUnit': 'month',
        //     'count': 1
        // };
        dateAxis.renderer.grid.template.location = 40;
        dateAxis.dateFormats.setKey('month', 'MMM'); // useless, this is the default
        dateAxis.periodChangeDateFormats.setKey('month', 'MMM');

        const yAxis = chart.yAxes.push(new am4charts.ValueAxis());

        yAxis.renderer.labels.template.adapter.add('text', function(text) {
            if (text) {
                let int_amount = text.replace(/,/g, '');
                let format = formatCash(int_amount);
                return format;
            }
        });

        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = '2021';
        series1.dataFields.dateX = 'date';
        series1.strokeWidth = 3;
        series1.fillOpacity = 0.05;
        series1.name = '2021';
        series1.tooltip.fontFamily = chartFontFamily;

        let bullet1 = series1.bullets.push(new am4charts.CircleBullet());
        bullet1.circle.strokeWidth = 2;
        bullet1.circle.radius = 3;
        bullet1.tooltipText = '${valueY}';
        bullet1.fontFamily = chartFontFamily;

        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = '2020';
        series2.dataFields.dateX = 'date';
        series2.strokeWidth = 3;
        series2.fillOpacity = 0.05;
        series2.name = '2020';
        series2.tooltip.fontFamily = chartFontFamily;

        let bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.strokeWidth = 2;
        bullet2.circle.radius = 3;
        bullet2.tooltipText = '${valueY}';
        bullet2.fontFamily = chartFontFamily;

        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = '2019';
        series3.dataFields.dateX = 'date';
        series3.strokeWidth = 3;
        series3.fillOpacity = 0.05;
        series3.name = '2019';
        series3.tooltip.fontFamily = chartFontFamily;

        let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
        bullet3.circle.strokeWidth = 2;
        bullet3.circle.radius = 3;
        bullet3.tooltipText = '${valueY}';

        let series4 = chart.series.push(new am4charts.LineSeries());
        series4.dataFields.valueY = '2018';
        series4.dataFields.dateX = 'date';
        series4.strokeWidth = 3;
        series4.fillOpacity = 0.05;
        series4.name = '2018';
        series4.tooltip.fontFamily = chartFontFamily;

        let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
        bullet4.circle.strokeWidth = 2;
        bullet4.circle.radius = 3;
        bullet4.tooltipText = '${valueY}';

        let series5 = chart.series.push(new am4charts.LineSeries());
        series5.dataFields.valueY = '2017';
        series5.dataFields.dateX = 'date';
        series5.strokeWidth = 3;
        series5.fillOpacity = 0.05;
        series5.name = '2017';
        series5.tooltip.fontFamily = chartFontFamily;

        let bullet5 = series5.bullets.push(new am4charts.CircleBullet());
        bullet5.circle.strokeWidth = 2;
        bullet5.circle.radius = 3;
        bullet5.tooltipText = '${valueY}';

        this.chart = chart;

        this.updateData(chartData);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id={this.props.idChart} style={{ width: '100%', height: '330px' }}></div>
        );
    }
}
