import { DB_END_WRITING, DB_START_WRITING, DB_TABLES_PUSHED, DB_CLEAR_HANDLER } from '../actions/dbDatapointHandler';

const initialState = {
    resultPushDB: false,
    resultPushDBRowsSubmitted: false,
    importResult: {},
    databaseErrorMessage: null,
    writingDB: false,

    showImportResult: false
};

function dbDatapointHandler(state = initialState, action) {
    switch (action.type) {
        case DB_CLEAR_HANDLER:
            return initialState;
        case DB_START_WRITING:
            return {
                ...state,
                resultPushDB: false,
                resultPushDBRowsSubmitted: false,
                importResult: {},
                databaseErrorMessage: null,
                writingDB: true,
                messagePushedDB: 'Something went wrong :(',

                showImportResult: false
            };
        case DB_END_WRITING:
            return {
                ...state,
                writingDB: false
            };
        case DB_TABLES_PUSHED:
            return {
                ...state,
                messagePushedDB: action.payload.messagePushedDB,
                resultPushDB: action.payload.resultPushDB,
                importResult: action.payload.importResult,

                showImportResult: true
            };
        default:
            return state;
    }
}

export default dbDatapointHandler;
