import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../../layouts';
import EnhancedTable from '../../../components/Table/EnhanceTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { tableActionTypes } from '../../../helpers/actionTypes';
import { tableTypes } from '../../../components/Table/rowSelector';
import { SORTING } from '../../../models/company/company';
import SimpleSnackbar from '../../../components/Feedback/SimpleSnackbar';
import ActionDialog from '../../../components/ChangeStatusDialog';
import { cloneDeep } from 'lodash';
import { headClaim } from '../../../models/headerCells';
import { fetchClaimsPreview, updateClaimStatus } from '../../../actions/claimHandler';

const qs = require('qs');

class ManageClaims extends Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            pageSize: 25,
            // numTotalRows: 0,
            sorting: {
                column: SORTING.CLAIM_PREVIEW_DEFAULT,
                desc: true
            },

            dialogActionVisible: false,
            snackVisible: false,
            snackbarVariant: '',
            rowClicked: null,
            rowsSelected: null,
            activeActionType: null,
            multipleAction: false,

            searchQuery: '',
            searchResult: [],
            searching: false,
            updateSuccess: false,
            filterFields: null
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.fetchDealsPreview();
    }

    fetchDealsPreview() {
        this.props.fetchClaimsPreview(
            this.state.page,
            this.state.pageSize,
            this.state.sorting,
            this.state.filterFields,
            this.props.myUser
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pageSize !== this.state.pageSize
            || prevState.sorting !== this.state.sorting
            || prevState.page !== this.state.page
            || prevState.filterFields !== this.state.filterFields) {
            this.fetchDealsPreview();
        }

        if (prevProps.claimStatusUpdating && !this.props.claimStatusUpdating) {
            let variant = 'info';
            let successful = false;
            if (!this.props.claimStatusUpdateError) {
                variant = 'success';
                successful = true;
                if (this.state.activeActionType === tableActionTypes.DELETE) {
                    this.init();
                }
            } else {
                variant = 'error';
            }
            this.openSnack(successful, variant);
        }
    }

    handleChangePageSize = (pageSize) => {
        this.setState({
            pageSize: pageSize,
            page: this.state.page
        });
    };

    handleChangeSorting = (columnName, isDesc) => {
        let newSorting = {
            column: columnName,
            desc: isDesc
        };
        this.setState({
            sorting: newSorting,
            page: this.state.page
        });
    };

    handlePageChange = (newPage) => {
        this.setState({
            page: newPage
        });
    };


    onClickDelete = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.DELETE
        }, () => this.openDialogAction());
    };

    openDialogAction = () => {
        this.setState({
            dialogActionVisible: true
        });
    };

    closeActionDialog = (force) => {
        if (!this.props.claimStatusUpdating || force) {
            this.setState({
                dialogActionVisible: false
            });
        }
    };


    handleConfirmAction = () => {
        switch (this.state.activeActionType) {
            // case tableActionTypes.DELETE:
            //     if (this.state.multipleAction) {
            //         this.props.deleteCompany(this.state.rowsSelected, this.props.myUser);
            //     } else {
            //         this.props.deleteCompany([this.state.rowClicked.company_id], this.props.myUser);
            //     }
            //     this.init();
            //     break;
            case tableActionTypes.STATUS:
                this.props.updateClaimStatus(this.state.rowClicked.claim_id, !this.state.rowClicked.status, this.props.myUser);
                break;
        }
    };

    changeStatus = (claim_id, newStatus) => {
        this.props.updateClaimStatus(claim_id, newStatus, this.props.myUser);
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (successful, variant) => {
        this.setState({
            ...this.state,
            dialogActionVisible: false,
            snackbarVariant: variant,
            snackVisible: true,
            updateSuccess: successful,
            rowClicked: null,
            rowsSelected: null
        });
    };

    handleMultipleDelete = (rows) => {
        this.setState({
            rowsSelected: rows,
            activeActionType: tableActionTypes.DELETE,
            multipleAction: true
        }, () => this.openDialogAction());
    };

    // startSearch = () => {
    //     let filterFields = qs.stringify(this.state.filterFields);
    //     axios.get(BASE_URL + '/company/active-search', {
    //         params: {
    //             pattern: this.state.searchQuery,
    //             role: this.props.myUser.role,
    //             page: this.state.page,
    //             pageSize: this.state.pageSize,
    //             columnSorting: this.state.sorting.column,
    //             desc: this.state.sorting.desc,
    //             filterFields: filterFields
    //         },
    //         headers: authHeader(this.props.myUser)
    //     })
    //         .then(({ data }) => {
    //             this.updateSearchResult(data.results);
    //         });
    // };

    updateSearchResult = (results) => {
        let newResults = cloneDeep(results);

        this.setState({
            searchResult: newResults,
            searching: false
        });
    };

    // handleInputSearchChange = (e) => {
    //     this.setState({
    //         searchQuery: e.target.value
    //     }, () => {
    //         if (this.state.searchQuery && this.state.searchQuery.length > 0) {
    //             this.setState({
    //                     searching: true
    //                 }, () => this.startSearch()
    //             );
    //         }
    //     });
    // };

    handleOnSubmitFilterForm = (filterFields) => {
        this.setState({
            filterFields: filterFields,
            page: 0
        });
    };

    render() {
        return (
            <DashboardLayout title={'Manage Claims'}>
                {/*<FilterFormBoxTable*/}
                {/*    submit={this.handleFilterChange}*/}
                {/*/>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <CustomPaper style={{ padding: 15 }}>*/}
                {/*        <FilterFormBox*/}
                {/*            submit={this.handleOnSubmitFilterForm}*/}
                {/*            sectors={this.props.sectors}*/}
                {/*            subSectors={this.props.subSectors}*/}
                {/*            handleClickExport={this.handleClickExport}*/}
                {/*            myUser={this.props.myUser}*/}
                {/*            fromManageCompanies*/}
                {/*            fromCompare={false}*/}
                {/*        />*/}
                {/*    </CustomPaper>*/}
                {/*</Grid>*/}
                {/*<Spacer/>*/}
                {this.props.fetchingClaimsPreview ? <LinearProgress/> : null}
                <EnhancedTable
                    rows={(this.state.searchQuery.length > 0)
                        ? this.state.searchResult
                        : this.props.claimsPreview
                    }
                    headCells={headClaim}
                    tableType={tableTypes.CLAIM}

                    page={this.state.page}
                    onChangePageSize={this.handleChangePageSize}
                    onChangeSorting={this.handleChangeSorting}
                    onPageChange={this.handlePageChange}
                    numTotalRows={this.props.numClaims}

                    myUser={this.props.myUser ? this.props.myUser : 'user'}

                    changeStatus={this.changeStatus}
                    // handleClickStatus={this.onClickStatus}
                    // handleClickDelete={this.onClickDelete}
                    // multipleDelete={this.handleMultipleDelete}
                    onUpdateSuccess={this.state.updateSuccess}

                    // searching={this.state.searching}
                    // handleInputSearchChange={this.handleInputSearchChange}

                    // toolbarVisible
                    paginationVisible
                    // checkable={INNER_TEAM.includes(this.props.myUser.role)}
                    sortable

                    // handleAddEntry={this.handleAddCompany}
                    addEnabled
                />
                <ActionDialog
                    tableName={'claim'}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogActionVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    rowClicked={this.state.rowClicked}
                    updating={this.props.claimStatusUpdating}
                    onConfirm={() => this.handleConfirmAction()}
                    isMultiple={this.state.multipleAction}
                />
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchClaimsPreview,
        updateClaimStatus
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        claimsPreview: state.claimHandler.claimsPreview,
        fetchingClaimsPreview: state.claimHandler.fetchingClaimsPreview,
        numClaims: state.claimHandler.numClaims,

        claimStatusUpdating: state.claimHandler.claimStatusUpdating,
        claimStatusUpdateError: state.claimHandler.claimStatusUpdateError,

        myUser: state.authHandler.myUser
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageClaims);
