import {

    MAIN_DATA_FETCH,
    MAIN_DATA_FETCH_SUCCESS,
    MAIN_DATA_FETCH_ERROR,

    SECTORS_FETCH,
    SECTORS_FETCH_SUCCESS,
    SECTORS_FETCH_ERROR,

    SUBSECTORS_FETCH,
    SUBSECTORS_FETCH_SUCCESS,
    SUBSECTORS_FETCH_ERROR,

    COUNTRIES_FETCH,
    COUNTRIES_FETCH_SUCCESS,
    COUNTRIES_FETCH_ERROR,

    SDGs_FETCH,
    SDGs_FETCH_SUCCESS,
    SDGs_FETCH_ERROR,
    CLICK_UPGRADE,
    CLICK_SUGGEST_UPDATE,
    CLICK_REQUEST_MANAGE,
    UPDATE_MY_CLAIMS,
    TECHNOLOGIES_FETCH,
    TECHNOLOGIES_FETCH_SUCCESS,
    TECHNOLOGIES_FETCH_ERROR,
    SUB_STATES_FETCH,
    SUB_STATES_FETCH_SUCCESS,
    SUB_STATES_FETCH_ERROR

} from '../actions/mainHandler';
import { COMPANY_CLAIMED } from '../actions/contactUsHandler';
import { cloneDeep } from 'lodash';
import { companyDataEmpty } from '../models/company/company';

const initialState = {

    mainDataFetched: false,

    sectors: [],
    subSectors: [],
    technologies: [],
    countries: [],
    subStates: [],
    SDGs: [],

    fetchingSectors: false,
    fetchingSubSectors: false,
    fetchingTechnologies: false,
    fetchingCountries: false,
    fetchingSubStates: false,
    fetchingSDGs: false,

    errorSectorsFetch: null,
    errorSubSectorsFetch: null,
    errorTechnologiesFetch: null,
    errorCountriesFetch: null,
    errorSubStatesFetch: null,
    errorSDGsFetch: null,

    upgradeClicked: 0,
    suggestUpdateClicked: 0,
    requestManageClicked: 0,
    companyNameSuggestion: null,
    companyIdSuggestion: null,

    pendingClaims: [],
    activeClaims: [],
    myCompanies: []
};

export default function mainHandler(state = initialState, action) {
    switch (action.type) {
        case CLICK_UPGRADE:
            let incrementUpgrade = state.upgradeClicked + 1;
            return {
                ...state,
                upgradeClicked: incrementUpgrade
            };

        case UPDATE_MY_CLAIMS:
            let claims = action.payload;
            return {
                ...state,
                pendingClaims: claims.pendingClaims,
                activeClaims: claims.activeClaims,
                myCompanies: claims.myCompanies
            };

        case COMPANY_CLAIMED:
            let company_id = action.payload;
            let newPendingClaims = cloneDeep(state.pendingClaims);
            newPendingClaims.push(company_id);
            return {
                ...state,
                pendingClaims: newPendingClaims
            };

        case CLICK_SUGGEST_UPDATE:
            let incrementSuggestUpdate = state.suggestUpdateClicked + 1;
            return {
                ...state,
                suggestUpdateClicked: incrementSuggestUpdate,
                companyNameSuggestion: action.companyNameSuggestion
            };

        case CLICK_REQUEST_MANAGE:
            let incrementReqestManage = state.requestManageClicked + 1;
            return {
                ...state,
                requestManageClicked: incrementReqestManage,
                companyNameSuggestion: action.payload.companyName,
                companyIdSuggestion: action.payload.companyId
            };

        case MAIN_DATA_FETCH:
            return {
                ...state,
                mainDataFetched: false
            };
        case MAIN_DATA_FETCH_SUCCESS:
            return {
                ...state,
                mainDataFetched: true
            };
        case MAIN_DATA_FETCH_ERROR:
            return {
                ...state
                // TODO error
            };
        case SECTORS_FETCH:
            return {
                ...state,
                sectors: [],
                fetchingSectors: true,
                errorSectorsFetch: null
            };
        case SECTORS_FETCH_SUCCESS:
            return {
                ...state,
                sectors: action.payload,
                fetchingSectors: false
            };
        case SECTORS_FETCH_ERROR:
            return {
                ...state,
                fetchingSectors: false,
                errorSectorsFetch: action.payload
            };

        case SUBSECTORS_FETCH:
            return {
                ...state,
                subSectors: [],
                fetchingSubSectors: true,
                errorSubSectorsFetch: null
            };
        case SUBSECTORS_FETCH_SUCCESS:
            return {
                ...state,
                subSectors: action.payload,
                fetchingSubSectors: false
            };
        case SUBSECTORS_FETCH_ERROR:
            return {
                ...state,
                fetchingSubSectors: false,
                errorSubSectorsFetch: action.payload
            };

        case TECHNOLOGIES_FETCH:
            return {
                ...state,
                technologies: [],
                fetchingTechnologies: true,
                errorTechnologiesFetch: null
            };
        case TECHNOLOGIES_FETCH_SUCCESS:
            return {
                ...state,
                technologies: action.payload,
                fetchingTechnologies: false
            };
        case TECHNOLOGIES_FETCH_ERROR:
            return {
                ...state,
                fetchingTechnologies: false,
                errorTechnologiesFetch: action.payload
            };

        case COUNTRIES_FETCH:
            return {
                ...state,
                countries: [],
                fetchingCountries: true,
                errorCountriesFetch: null
            };
        case COUNTRIES_FETCH_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                fetchingCountries: false
            };
        case COUNTRIES_FETCH_ERROR:
            return {
                ...state,
                fetchingCountries: false,
                errorCountriesFetch: action.payload
            };

        case SUB_STATES_FETCH:
            return {
                ...state,
                subStates: [],
                fetchingSubStates: true,
                errorSubStatesFetch: null
            };
        case SUB_STATES_FETCH_SUCCESS:
            return {
                ...state,
                subStates: action.payload,
                fetchingSubStates: false
            };
        case SUB_STATES_FETCH_ERROR:
            return {
                ...state,
                fetchingSubStates: false,
                errorSubStatesFetch: action.payload
            };

        case SDGs_FETCH:
            return {
                ...state,
                SDGs: [],
                fetchingSDGs: true,
                errorSDGsFetch: null
            };
        case SDGs_FETCH_SUCCESS:
            return {
                ...state,
                SDGs: action.payload,
                fetchingSDGs: false
            };
        case SDGs_FETCH_ERROR:
            return {
                ...state,
                fetchingSDGs: false,
                errorSDGsFetch: action.payload
            };
        default:
            return state;
    }
}
