import React, { useRef } from 'react';
import classNames from 'classnames';
import { withStyles, Grid } from '@material-ui/core';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import Button from '@material-ui/core/Button';
import UserInfo from '../../Team/UserInfo';
import ConfirmDialog from '../../ConfirmDialog';
import { ADD_PERSON_TEAM, can, DELETE_DOCUMENT, DELETE_PERSON_TEAM } from '../../../helpers/Roles/roleActions';
import DocumentInfo from '../../Document/DocumentInfo';
import ButtonExtended from '../../UI/ButtonExtended/ButtonExtended';
import Add from '@material-ui/icons/Add';
import Spacer from '../../Spacer/Spacer';


const CompanyDocsForm = (props) => {

    const fileInput = useRef(null);

    const {
        classes, className,
        formDisabled,
        myUser,
        companyDocuments,
        openDialogUploadDocument,
        addFileToDocuments,
        handleClickRemoveDoc,
        handleUploadDocClick,
        handleClickDownload,
        ...rest
    } = props;

    const handleUploadClick = () => {
        fileInput.current.click();
    };

    const handleFileChange = e => {
        if (e.target.files && e.target.files.length !== 0) {
            addFileToDocuments(e.target.files[0]);
        }
    };

    const onClickRemoveDocument = (document) => {
        handleClickRemoveDoc(document);
    };

    return (
        <div>
            <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                ref={fileInput}
                style={{ display: 'none' }}
                // accept="image/png, image/jpeg"
            />
            {!formDisabled ?
                <ButtonExtended
                    startIcon={<Add/>}
                    variant="contained"
                    color='success'
                    style={{ 'text-transform': 'none' }}
                    onClick={handleUploadClick.bind(this)}
                >
                    ADD
                </ButtonExtended>
                : null
            }
            <Spacer/>
            <Grid
                container
                spacing={2}
            >
                <Grid container item xs={12} spacing={2}>
                    {companyDocuments ?
                        companyDocuments.map(document => {
                            return (
                                document.name && document.name !== '' ?
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <DocumentInfo
                                            onClickDelete={() => onClickRemoveDocument(document)}
                                            document={document}
                                            canDelete={can(myUser, DELETE_DOCUMENT) && !formDisabled}
                                            formDisabled={formDisabled}
                                            handleUploadClick={() => handleUploadDocClick(document)}
                                            handleClickDownload={() => handleClickDownload(document)}
                                        />
                                    </Grid>
                                    : null
                            );
                        })
                        : null
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(CompanyDocsForm);
