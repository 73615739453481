import classNames from 'classnames';
import { Paper, PortletContent, PortletHeader, PortletLabel } from '../../index';
import { Grid, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { milestoneTypes, productDevelopmentStages } from '../../../models/typeList/milestoneTypes';
import Spacer from '../../Spacer/Spacer';
import DatePicker from '../../DatePicker';
import { getMilestonePortletHeader } from '../../../strings/genericStrings';


const MilestoneForm = (props) => {
    const { classes, className, formik, editable, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title={getMilestonePortletHeader(formik.values.company.category)}
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={6}
                    >
                        <TextField
                            className={classes.textField}
                            label="Product Development"
                            name="company.product_development"
                            select
                            SelectProps={{ native: true }}
                            value={formik.values.company.product_development}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                            variant="outlined"
                            fullWidth
                        >
                            {productDevelopmentStages.map(option => (
                                <option
                                    key={option.id}
                                    value={option.value}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    {formik.values.company.category === 'startup' ?
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} sm={6} lg={4}
                        >
                            <DatePicker
                                label={'Last Update'}
                                selectedDate={formik.values.company.product_development_last_update}
                                handleDateChange={(event, value) => {
                                    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                                    let localISOTime = (new Date(new Date(value) - tzoffset)).toISOString();
                                    formik.setFieldValue('company.product_development_last_update', localISOTime);
                                }}
                                disableOpenOnEnter
                            />
                        </Grid>
                        : null}


                    <Spacer small divider/>

                    <Grid
                        container
                        item
                        xs={12} sm={6} lg={4}
                        className={classes.field}
                    >
                        <Tooltip title="(Any Patent, Copyright, Trademark, etc)" placement="top">
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.company.own_ip}
                                        onChange={
                                            () => formik.setFieldValue('company.own_ip',
                                                !formik.values.company.own_ip)
                                        }
                                        value={formik.values.company.own_ip}
                                        color="primary"
                                    />
                                }
                                label="Owns IP and or Patents"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12} sm={6} lg={4}
                        className={classes.field}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    className={classes.switch}
                                    checked={formik.values.company.generating_revenue}
                                    onChange={
                                        () => formik.setFieldValue('company.generating_revenue',
                                            !formik.values.company.generating_revenue)
                                    }
                                    value={formik.values.company.generating_revenue}
                                    color="primary"
                                />
                            }
                            label="Generation Revenues"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12}
                    >
                        <Autocomplete
                            multiple
                            id="milestones-tags"
                            name="key_milestones_ids"
                            disabled={editable}
                            className={classes.textField}
                            options={milestoneTypes}
                            value={formik.values.key_milestones_ids}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                // console.log(value);
                                formik.setFieldValue('key_milestones_ids', value);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        color="primary"
                                        style={{ backgroundColor: '#888FCA' }}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Key Milestones"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>


                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(MilestoneForm);
