import { cloneDeep } from 'lodash';
import { claimObj, dealObj, logObj, sdgObj, sectorObj, sub_sectorObj, technologyObj } from '../data/tableObjects';

export const SECTOR = 'sector';
export const SUBSECTOR = 'sub_sector';
export const TECHNOLOGY = 'technology';
export const SDG = 'sdg';
export const LOGS = 'logs';
export const DEAL = 'deal';
export const DEAL_FORM = 'deal_form';
export const CLAIM = 'claim';

export const getDataTableEmpty = (tableName) => {
    switch (tableName) {
        case SECTOR:
            return cloneDeep(sectorObj);
        case SUBSECTOR:
            return cloneDeep(sub_sectorObj);
        case TECHNOLOGY:
            return cloneDeep(technologyObj);
        case SDG:
            return cloneDeep(sdgObj);
        case LOGS:
            return cloneDeep(logObj);
        case DEAL:
        case DEAL_FORM:
            return cloneDeep(dealObj);
        case CLAIM:
            return cloneDeep(claimObj);
        default:
            return null;
    }
};

export const setRowIds = (tableName, rows) => {
    const idrow = getRowId(tableName);
    for (let row of rows) {
        row['idrow'] = row[idrow];
    }
};

export const getRowId = (tableName) => {
    // console.log(tableName);
    switch (tableName) {
        case SECTOR:
            return 'sector_id';
        case SUBSECTOR:
            return 'sub_sector_id';
        case SDG:
            return 'sdg_id';
        case LOGS:
            return 'id';
        case DEAL:
        case DEAL_FORM:
            return 'investment_id';
        default:
            return null;
    }
};
