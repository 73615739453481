import React from 'react';
import { Paper, PortletContent } from '../../index';
import { tableTypes } from '../rowSelector';
import EnhancedTable from '../EnhanceTable';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import Spacer from '../../Spacer/Spacer';
import ButtonExtended from '../../UI/ButtonExtended/ButtonExtended';


class CompanyTableFiltered extends React.Component {

    render() {
        const {
            classes, className,
            myUser, rows, fetching,

            page,
            handleChangePageSize,
            handlePageChange,
            numTotalRows,

            fromCompare,
            tableSide,

            onClickRow,
            onClickUpgrade,
            ...rest
        } = this.props;

        const headCellsNormal = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: false },
            { id: 'category', numeric: false, disablePadding: false, label: 'Category', sortable: false },
            { id: 'sector_name', numeric: false, disablePadding: false, label: 'Sector', sortable: false },
            { id: 'hq_country', numeric: false, disablePadding: false, label: 'HQ Country', sortable: false },
            { id: 'hq_city', numeric: false, disablePadding: false, label: 'HQ City', sortable: false },
            { id: 'contacts', numeric: false, disablePadding: false, label: 'Contacts', sortable: false }
        ];
        const headCellsFromCompareLeft = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: false },
            { id: 'contacts', numeric: false, disablePadding: false, label: 'Contacts', sortable: false }
        ];

        const headCellsFromCompareRight = [
            { id: 'name', numeric: false, disablePadding: false, label: 'Name', sortable: false },
            { id: 'contacts', numeric: false, disablePadding: false, label: 'Contacts', sortable: false },
            { id: 'degree', numeric: false, disablePadding: false, label: 'Matching', sortable: false }
        ];

        const headCells = this.props.fromCompare ? tableSide === 'left' ? headCellsFromCompareLeft : headCellsFromCompareRight : headCellsNormal;

        return (
            <Paper>
                {/*<PortletHeader>*/}
                {/*  <PortletLabel*/}
                {/*    title="Companies"*/}
                {/*  />*/}
                {/*</PortletHeader>*/}
                <PortletContent noPadding>
                    <Grid container
                          alignItems="center"
                          justify="space-between"
                          direction="column"
                    >
                        <EnhancedTable
                            rows={rows ? rows : []}
                            headCells={headCells}
                            tableType={fromCompare ? tableTypes.COMPARE : tableTypes.COMPANIES_CHARTS}
                            fromCompare={fromCompare}

                            myUser={myUser}

                            tableSide={tableSide}

                            onClickRow={onClickRow}
                        />
                        {/*{!(myUser) || (myUser && myUser.role === 'user') && (rows && rows.length > 0) && !fromCompare ?*/}
                        {/*    <div>*/}
                        {/*        <ButtonExtended*/}
                        {/*            variant="contained"*/}
                        {/*            color="action"*/}
                        {/*            style={{ 'flex': 1 }}*/}
                        {/*            startIcon={<AddIcon/>}*/}
                        {/*            onClick={onClickUpgrade}*/}
                        {/*        >*/}
                        {/*            UPGRADE TO SEE MORE DATA*/}
                        {/*        </ButtonExtended>*/}
                        {/*        < Spacer/>*/}
                        {/*    </div>*/}
                        {/*    : null*/}
                        {/*}*/}
                    </Grid>
                </PortletContent>
            </Paper>
        );
    }
}

export default CompanyTableFiltered;
