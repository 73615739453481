import axios from 'axios';
import { BASE_URL } from '../helpers/axiosConfig';
import { authHeader } from '../auth/authUtils';
import { getAnnualInvestment } from '../helpers/Stats/investments';
import { sortRowsByMatching } from '../helpers/compareSorting';

const qs = require('qs');

export const STATS_COUNT_FETCH = 'stats_count_fetch';
export const STATS_COUNT_FETCH_SUCCESS = 'stats_count_fetch_success';
export const RIGHT_COLUMN_FETCH_SUCCESS = 'right_column_fetch_success';
export const STATS_COUNT_FETCH_ERROR = 'stats_count_fetch_error';

export const CLEAR_STATS = 'clear_stats';


export const fetchStatsUnAuth = (company) => {
    let apiUrl = '/stats/company_stats_sub_state';
    let company_id = '';
    if (company) {
        apiUrl = '/stats/company_stats_sub_state';
        company_id = company.company_id;
    }

    let params = {
        company_id: company_id
    };

    return dispatch => {
        dispatch({ type: STATS_COUNT_FETCH });
        axios.get(BASE_URL + apiUrl, { params, headers: authHeader(null) })
            .then(res => {
                const resultData = res.data;
                const countSectors = resultData.sectors;
                const countSubSectors = resultData.sub_sectors;
                const countSDGs = resultData.SDGs;

                const numCompanies = resultData.numCompanies;
                const numSectors = resultData.numSectors;
                const numSubSectors = resultData.numSubSectors;

                let countCountries = resultData.countries;

                let companyRows = resultData.companyRows;

                let annualInvestment = getAnnualInvestment(resultData.investments);

                dispatch({
                    type: STATS_COUNT_FETCH_SUCCESS,
                    payload: {
                        countSectors,
                        countSubSectors,
                        countCountries,
                        countSDGs,
                        numCompanies,
                        numSectors,
                        numSubSectors,
                        companyRows,
                        annualInvestment
                    }
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: STATS_COUNT_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const clearStats = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_STATS
        });
    };
};

export const fetchStats = (myUser, filterData) => {
    return dispatch => {
        dispatch({ type: STATS_COUNT_FETCH });
        let filter = qs.stringify(filterData);
        axios.get(BASE_URL + '/stats/company_stats', {
            params: {
                filter
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const resultData = res.data;
                const countSectors = resultData.sectors;
                const countSubSectors = resultData.sub_sectors;
                const countSDGs = resultData.SDGs;

                const numCompanies = resultData.numCompanies;
                const numSectors = resultData.numSectors;
                const numSubSectors = resultData.numSubSectors;

                let countCountries = resultData.countries;

                let companyRows = resultData.companyRows;

                let annualInvestment = getAnnualInvestment(resultData.investments);

                dispatch({
                    type: STATS_COUNT_FETCH_SUCCESS,
                    payload: {
                        countSectors,
                        countSubSectors,
                        countCountries,
                        countSDGs,
                        numCompanies,
                        numSectors,
                        numSubSectors,
                        companyRows,
                        annualInvestment
                    }
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: STATS_COUNT_FETCH_ERROR,
                payload: err
            });
        });
    };
};

export const fetchStatsRightColumn = (myUser, filterData) => {
    return dispatch => {
        dispatch({ type: STATS_COUNT_FETCH });
        let filter = qs.stringify(filterData);
        axios.get(BASE_URL + '/stats/company_stats_right', {
            params: {
                filter
            }, headers: authHeader(myUser)
        })
            .then(res => {
                const resultData = res.data;
                let companyRows = resultData.companyRows;
                companyRows = sortRowsByMatching(companyRows, filterData);
                dispatch({
                    type: RIGHT_COLUMN_FETCH_SUCCESS,
                    payload: {
                        companyRows
                    }
                });
            }).catch(err => {
            console.error(err);
            dispatch({
                type: STATS_COUNT_FETCH_ERROR,
                payload: err
            });
        });
    };
};
