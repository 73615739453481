import {
    CLAIMS_PREVIEW_FETCH,
    CLAIMS_PREVIEW_FETCH_ERROR,
    CLAIMS_PREVIEW_FETCH_SUCCESS, UPDATE_CLAIM_STATUS, UPDATE_CLAIM_STATUS_ERROR, UPDATE_CLAIM_STATUS_SUCCESS
} from '../actions/claimHandler';
import { cloneDeep } from 'lodash';
import { findIndexClaim } from '../helpers/mainPreviews';

const initialState = {
    claimsPreview: [],
    fetchingClaimsPreview: false,
    claimsPreviewError: null,

    numClaims: 0,

    claimStatusUpdating: null,
    claimStatusUpdateError: null
};


export default function claimHandler(state = initialState, action) {
    switch (action.type) {

        case CLAIMS_PREVIEW_FETCH:
            return {
                ...state,
                claimsPreview: [],
                fetchingClaimsPreview: true,
                claimsPreviewError: null,
                counterRows: 0
            };
        case CLAIMS_PREVIEW_FETCH_SUCCESS:
            const claimsPreviewRows = action.payload.rows;
            const numRows = action.payload.numRows;
            for (let row of claimsPreviewRows) {
                row['idrow'] = row.claim_id;
            }
            return {
                ...state,
                claimsPreview: claimsPreviewRows,
                fetchingClaimsPreview: false,
                numClaims: numRows
            };
        case CLAIMS_PREVIEW_FETCH_ERROR:
            return {
                ...state,
                fetchingClaimsPreview: false,
                claimsPreviewError: action.payload,
                counterRows: 0
            };

        case UPDATE_CLAIM_STATUS:
            return {
                ...state,
                claimStatusUpdating: true,
                claimStatusUpdateError: null
            };
        case UPDATE_CLAIM_STATUS_SUCCESS:
            let claim_id = action.payload.claim_id;
            const newStatus = action.payload.newStatus;
            const claimsPreviewUpdated = cloneDeep(state.claimsPreview);
            const indexClaimInPreviews = findIndexClaim(claimsPreviewUpdated, claim_id);
            if (indexClaimInPreviews !== -1) {
                claimsPreviewUpdated[indexClaimInPreviews].claim_status = newStatus;
            }
            return {
                ...state,
                claimStatusUpdating: false,
                claimStatusUpdateError: null,
                claimsPreview: claimsPreviewUpdated
            };
        case UPDATE_CLAIM_STATUS_ERROR:
            return {
                ...state,
                claimStatusUpdating: false,
                claimStatusUpdateError: action.payload
            };

        default:
            return state;
    }
}
