import {

    AUTH_USER,
    AUTH_USER_SUCCESS,
    AUTH_USER_ERROR,

    SIGN_OUT,

    CLEAN_AUTH,
    SET_AUTHBOX,

    SIGN_UP,
    SIGN_UP_SUCCESS,
    SIGN_UP_EMAIL_IN_USE,
    SIGN_UP_ERROR, AUTH_WRONG_PASSWORD,
    AUTH_EMAIL_ALREADY_EXISTS
} from '../actions/authHandler';

const initialState = {
    myUser: null,

    authenticating: null,
    iAmAuthenticated: false,
    iAmApproved: false,

    authBoxRequired: false,

    loadingAuth: null,
    emailAlreadyExists: false,
    wrongPassword: false,
    emailNotExists: false
};

export default function authHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAN_AUTH:
            return initialState;
        case SET_AUTHBOX:
            return {
                authBoxRequired: action.payload,
                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: false,
                emailAlreadyExists: false,
                emailNotExists: false,
            };
        case AUTH_USER:
            return {
                authenticating: true,
                myUser: null,
                iAmAuthenticated: false,
                iAmApproved: false,
                emailAlreadyExists: false,
                wrongPassword: false,
                emailNotExists: false
            };
        case AUTH_USER_SUCCESS:
            const user = action.payload;
            return {
                authenticating: false,
                myUser: user,
                iAmAuthenticated: true,
                iAmApproved: user.status,
                authBoxRequired: false,
                emailAlreadyExists: false,
                wrongPassword: false
            };
        case AUTH_USER_ERROR:
            return {
                authenticating: false,
                myUser: null,
                wrongPassword: false
            };
        case SIGN_OUT:
            return {
                myUser: null,

                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false
            };

        case SIGN_UP:
            return {
                myUser: null,
                authenticating: true,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: true,
                emailAlreadyExists: false,
                emailNotExists: false,
            };
        case SIGN_UP_EMAIL_IN_USE:
            return {
                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: false,
                emailAlreadyExists: true
            };
        case SIGN_UP_ERROR:
            return {
                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: false,
                emailAlreadyExists: false,
                emailNotExists: false
            };

        case AUTH_WRONG_PASSWORD:
            return {
                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: false,
                wrongPassword: true,
                emailNotExists: false
            };
        case AUTH_EMAIL_ALREADY_EXISTS:
            return {
                authenticating: false,
                iAmAuthenticated: false,
                iAmApproved: false,
                loadingAuth: false,
                wrongPassword: false,
                emailNotExists: true
            };
        default:
            return state;
    }
}
