function checkFoundationYear(companyFoundationYear, filterFoundationYear) {
    let degree = 0;
    if (filterFoundationYear && companyFoundationYear) {
        if (companyFoundationYear.substring(0, 4) === filterFoundationYear.toString()) {
            degree += 1;
        }
    }
    return degree;
}

function checkSectors(companySector, filterSector) {
    let degree = 0;
    if (companySector && filterSector && filterSector.length > 0) {

        const sectorsFilter = filterSector.map(function(el) {
            return el.name;
        });

        if (sectorsFilter.includes(companySector)) {
            degree += 1;
        }
    }
    return degree;
}

function checkSubSectors(companySubSector, filterSubSector) {
    let degree = 0;
    if (companySubSector && filterSubSector && filterSubSector.length > 0) {

        const subSectorsFilter = filterSubSector.map(function(el) {
            return el.name;
        });

        if (subSectorsFilter.includes(companySubSector)) {
            degree += 1;
        }
    }
    return degree;
}

function checkCountry(companyCountry, filterCountry) {
    let degree = 0;
    if (companyCountry && filterCountry && filterCountry.name) {
        if (companyCountry === filterCountry.name) {
            degree += 1;
        }
    }
    return degree;
}

function checkMatchingDegree(companyRows, filterData) {
    for (let row of companyRows) {
        let degree = 0;
        if (filterData.region) {
            degree += 1;
        }
        degree += checkFoundationYear(row.founding_date, filterData.foundation_year);
        degree += checkSectors(row.sector_name, filterData.sectors);
        degree += checkSubSectors(row.sub_sector_name, filterData.sub_sectors);
        degree += checkCountry(row.hq_country, filterData.country);
        row['degree'] = degree;
    }
    return companyRows;
}

export const sortRowsByMatching = (companyRows, filterData) => {

    if (filterData.region || filterData.country || filterData.foundation_year
        || (filterData.sectors && filterData.sectors.length > 0) || (filterData.sub_sectors && filterData.sub_sectors.length > 0)
        || (filterData.impacts && filterData.impacts.length > 0)
    ) {
        checkMatchingDegree(companyRows, filterData);
        companyRows.sort(function(first, second) {
            return second.degree - first.degree;
        });
    }

    return companyRows;
};
