import axios from 'axios';
import { BASE_URL } from '../axiosConfig';
import { authHeader } from '../../auth/authUtils';

export function isNumeric(str) {
    return /^\d+$/.test(str);
}

export const disablePersonasOfActualTeam = (peopleFetched, actualTeam) => {
    if (peopleFetched && actualTeam && actualTeam.length > 0 && peopleFetched.length > 0)
        for (let actualPerson of actualTeam) {
            for (let personaFetched of peopleFetched) {
                if (actualPerson.persona_id === personaFetched.persona_id
                    || actualPerson.name === personaFetched.name && actualPerson.lastname === personaFetched.lastname
                ) {
                    personaFetched['disabled'] = true;
                }
            }
        }
};

export const disableCompanyOfActualPartnership = (companiesFetched, actualPartnerships) => {
    if (companiesFetched && actualPartnerships && actualPartnerships.length > 0 && companiesFetched.length > 0)
        for (let actualCompany of actualPartnerships) {
            for (let companyFetched of companiesFetched) {
                if (actualCompany.company_id === companiesFetched.company_id
                    || actualCompany.name === companyFetched.name
                ) {
                    companyFetched['disabled'] = true;
                }
            }
        }
};

export const disableCompanyOfActualPortfolios = (companiesFetched, actualPortfolios) => {
    if (companiesFetched && actualPortfolios && actualPortfolios.length > 0 && companiesFetched.length > 0)
        for (let actualCompany of actualPortfolios) {
            for (let companyFetched of companiesFetched) {
                if (actualCompany.company_id === companiesFetched.company_id
                    || actualCompany.name === companyFetched.name
                ) {
                    companyFetched['disabled'] = true;
                }
            }
        }
};

export const checkAvailabilityCompanyName = (name, company_id, myUser, callback) => {
    axios.get(BASE_URL + '/company/check-availability-name', {
        params: {
            name: name,
            company_id: company_id
        },
        headers: authHeader(myUser)
    })
        .then(res => {
            return callback(res.data);
        })
        .catch(err => {
            return callback(false);
        });
};
