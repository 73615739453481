import React from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

export const useSearchFieldStyles = makeStyles(theme => ({
  searchField: {
    marginRight: theme.spacing(1),
    minWidth: '250px',
    backgroundColor: 'white'
  }
}));

export default function SearchField(props) {
  const classes = useSearchFieldStyles();

  const { searching, handleInputSearchChange } = props;

  return (
    <TextField
        fullWidth
      name="search"
      placeholder="Search"
      className={classes.searchField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon/>
          </InputAdornment>
        ),
        endAdornment: (
          <React.Fragment>
            {searching ?
              <InputAdornment position="end">
                <CircularProgress size={20}/>
              </InputAdornment>
              : null}
          </React.Fragment>
        )
      }}
      size={'medium'}
      margin={'dense'}
      variant="outlined"
      onChange={handleInputSearchChange}
    />
  );
}
