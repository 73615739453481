import { insertChunkLambda } from '../DBhelper';

const CHUNK_ROWS = 100;

const checkRowResult = (resultPushDatapoint, resultChunckLambda) => {
    // console.log("checkRowResult resultPushDatapoint", resultPushDatapoint);
    // console.log("checkRowResult resultChunckLambda", resultChunckLambda);
    // console.log("checkRowResult resultChunckLambda.resultChunk.rowsResult", resultChunckLambda.resultChunk.rowsResult);
    if (resultChunckLambda.resultChunk.code !== 200) {
        resultPushDatapoint.code = 500;
    }

    resultPushDatapoint.rowsResult = Object.assign(resultPushDatapoint.rowsResult, resultPushDatapoint.rowsResult, resultChunckLambda.resultChunk.rowsResult);
    resultPushDatapoint.numRowsError = resultPushDatapoint.numRowsError + resultChunckLambda.resultChunk.numRowsError;
    resultPushDatapoint.numRowsSuccess = resultPushDatapoint.numRowsSuccess + resultChunckLambda.resultChunk.numRowsSuccess;
};

async function pushChunkRowObj(chunkRows, resultPushDatapoint, datapoint_id) {
    let resultChunckLambda = null;
    resultChunckLambda = await insertChunkLambda(chunkRows, datapoint_id);
    await resultChunckLambda;
    // console.log("+++ resultRowLambda", resultChunckLambda);
    checkRowResult(resultPushDatapoint, resultChunckLambda);
}

function makeChunk(chunkRows, resultPushDatapoint, datapoint_id) {
    return new Promise((resolve) => {
        resolve(pushChunkRowObj(chunkRows, resultPushDatapoint, datapoint_id));
    });
}

async function process(arrayOfPromises) {
    console.time(`datapoint push`);
    //let responses = await Promise.all(arrayOfPromises);
    await Promise.all(arrayOfPromises);
    console.timeEnd(`datapoint push`);
}

async function handlerRows(resultPushDatapoint, headerLength, datapoint, datapoint_id) {
    let chunksRows = [];

    let i, j, tempChunkArray, chunk = CHUNK_ROWS;
    for (i = 0, j = datapoint.length; i < j; i += chunk) {
        tempChunkArray = datapoint.slice(i, i + chunk);
        chunksRows.push(tempChunkArray);
    }


    let subArrayOfPromises = [];
    for (let c = 0; c < chunksRows.length; c++) {
        subArrayOfPromises.push(makeChunk(chunksRows[c], resultPushDatapoint, datapoint_id));
    }
    await process(subArrayOfPromises);
}

async function insertDatapointChunks(datapoint, datapoint_id, headerLength) {
    // console.log('insertDatapoint');

    let resultPushDatapoint = {
        dbError: false,
        code: 200,
        numRowsError: 0,
        numRowsSuccess: 0,
        rowsResult: {}
    };

    await handlerRows(resultPushDatapoint, headerLength, datapoint, datapoint_id);

    // console.log('resultPush', resultPushDatapoint);
    return resultPushDatapoint;
}

export { insertDatapointChunks };
