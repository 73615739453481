import React from 'react';
import { Grid } from '@material-ui/core';
import UserInfoForm from '../../components/User/UserInfoForm';
import GridField from '../../components/GridField/GridField';

function ProfileUser(props) {

    const {
        formDisabled,
        formikProps
    } = props;

    return (
        <div>
            <fieldset disabled={formDisabled} style={{ border: 'none' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={9} lg={9}>
                        <UserInfoForm
                            formik={formikProps}
                        />
                    </Grid>
                </Grid>
            </fieldset>
        </div>
    );
}

export default ProfileUser;
