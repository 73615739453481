import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5)
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleSnackbar(props) {
    const classes = useStyles();

    let { open, handleClose, variant } = props;

    let getMessage = (variant) => {
        if (variant === 'success') {
            return 'Success!';
        } else {
            return 'Something went wrong';
        }
    };

    let getColor = (variant) => {
        if (variant === 'success') {
            return 'success';
        }else if (variant === 'info'){
            return 'info';
        }else if(variant === 'warning'){
            return 'warning';
        }else {
            return 'error';
        }
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={open}
                variant={'success'}
                autoHideDuration={4000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                ]}
            >
                <Alert
                    onClose={handleClose}
                    severity={getColor(variant)}
                    style={{minWidth: '300px'}}
                >
                    {getMessage(variant)}
                </Alert>
            </Snackbar>
        </div>
    );
}
