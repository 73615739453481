import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Divider, Typography } from '@material-ui/core';
import Copyright from '../../../../components/Copyright/Copyright';

// Component styles
const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },

    company: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    },

    logoImage: {
        width: '18px'
    }
});

class Footer extends Component {
    render() {
        const { classes, className } = this.props;

        const rootClassName = classNames(classes.root, className);

        return (
            <div className={rootClassName}>
                {/*<Divider />*/}
                {/*<Copyright/>*/}
            </div>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
