import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import history from '../../config/history';
import MarketBox from '../../components/Company/MarketBox';

class MarketTab extends Component {

    handleClickCompany(company) {
        history.push({
            pathname: '/redirect/show/' + company.company_id
        });
    };

    render() {
        return (
            <div>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={11}>
                        <MarketBox
                            similarCompanies={this.props.similarCompanies}
                            handleClickCompany={this.handleClickCompany.bind(this)}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
    return {
        similarCompanies: state.companyHandler.similarCompanies,
        fetchingSimilarCompanies: state.companyHandler.fetchingSimilarCompanies,
        similarCompaniesError: state.companyHandler.similarCompaniesError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketTab);
