import React, { useEffect } from 'react';

const GOOGLE_BUTTON_ID = 'google-sign-in-button';

export default function GoogleSignIn(props) {

    useEffect(() => {
        try {
            window.gapi.signin2.render(
                GOOGLE_BUTTON_ID,
                {
                    width: 'auto',
                    height: '40px',
                    longtitle: true,
                    theme: 'light',
                    onsuccess: onSuccess
                }
            );
        } catch (e) {
            //console.log(e);
        }
    }, []);

    const onSuccess = (googleUser) => {
        // const profile = googleUser.getBasicProfile();
        const auth = googleUser.getAuthResponse();
        const socialAccessToken = auth.id_token;

        props.onSuccess(socialAccessToken);
    };


    return (
        <div>
            <div id={GOOGLE_BUTTON_ID}/>
        </div>

    );
}
