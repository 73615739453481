export const supportTypes = [
    {
        id: 1,
        name: 'Cash Funding',
        value: 1
    },
    {
        id: 2,
        name: 'In-kind support',
        value: 2
    },
    {
        id: 4,
        name: 'Mentorship',
        value: 4
    },
    {
        id: 3,
        name: 'Networking',
        value: 3
    }

];
