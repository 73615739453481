import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid, TextField } from '@material-ui/core';
import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { getIn } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { investmentStageListFilter } from '../../../models/typeList/investmentStages';

const InvestorFinancialForm = (props) => {
    const { classes, className, formik, numInvestments, editable, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Fund Details"
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        container
                        item
                        xs={12}
                    >
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <CurrencyTextField
                                currencySymbol="$"
                                minimumValue="0"
                                outputFormat='number'
                                digitGroupSeparator=","
                                decimalPlaces={0}

                                label="Fund Size"
                                name="categorySpec.investor.fund_size"
                                className={classes.textField}
                                value={formik.values.categorySpec.investor.fund_size}
                                onChange={(e, v) => {
                                    e.target.value = v;
                                    formik.handleChange(e);
                                }}
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                                onBlur={formik.handleBlur}
                                helperText={getIn(formik.errors, 'categorySpec.investor.fund_size')
                                && formik.errors.categorySpec.investor.fund_size
                                }
                                error={getIn(formik.errors, 'categorySpec.investor.fund_size')
                                && formik.errors.categorySpec.investor.fund_size
                                }
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <TextField
                                label="Number Exits"
                                name="categorySpec.investor.number_exits"
                                className={classes.textField}
                                value={formik.values.categorySpec.investor.number_exits}
                                onChange={formik.handleChange}
                                autoComplete='off'
                                variant="outlined"
                                type="number"
                                fullWidth
                                onBlur={formik.handleBlur}
                                helperText={getIn(formik.errors, 'categorySpec.investor.number_exits')
                                && formik.errors.categorySpec.investor.number_exits
                                }
                                error={getIn(formik.errors, 'categorySpec.investor.number_exits')
                                && formik.errors.categorySpec.investor.number_exits
                                }
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <CurrencyTextField
                                currencySymbol="$"
                                minimumValue="0"
                                outputFormat='number'
                                digitGroupSeparator=","
                                decimalPlaces={0}

                                label="Min Ticket Size"
                                name="categorySpec.investor.ticket_fund_size_min"
                                className={classes.textField}
                                value={formik.values.categorySpec.investor.ticket_fund_size_min}
                                onChange={(e, v) => {
                                    e.target.value = v;
                                    formik.handleChange(e);
                                }}
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                                onBlur={formik.handleBlur}
                                helperText={getIn(formik.errors, 'categorySpec.investor.ticket_fund_size_min')
                                && formik.errors.categorySpec.investor.ticket_fund_size_min
                                }
                                error={getIn(formik.errors, 'categorySpec.investor.ticket_fund_size_min')
                                && formik.errors.categorySpec.investor.ticket_fund_size_min
                                }

                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <CurrencyTextField
                                currencySymbol="$"
                                minimumValue="0"
                                outputFormat='number'
                                digitGroupSeparator=","
                                decimalPlaces={0}

                                label="Max Ticket Size"
                                name="categorySpec.investor.ticket_fund_size_max"
                                className={classes.textField}
                                value={formik.values.categorySpec.investor.ticket_fund_size_max}
                                onChange={(e, v) => {
                                    e.target.value = v;
                                    formik.handleChange(e);
                                }}
                                autoComplete='off'
                                variant="outlined"
                                fullWidth
                                onBlur={formik.handleBlur}
                                helperText={getIn(formik.errors, 'categorySpec.investor.ticket_fund_size_max')
                                && formik.errors.categorySpec.investor.ticket_fund_size_max
                                }
                                error={getIn(formik.errors, 'categorySpec.investor.ticket_fund_size_max')
                                && formik.errors.categorySpec.investor.ticket_fund_size_max
                                }

                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                            />
                        </Grid>
                        {numInvestments ?
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} lg={6}
                            >
                                <TextField
                                    label="Number Investments"
                                    name="categorySpec.investor.number_investment"
                                    className={classes.textField}
                                    disabled
                                    // value={formik.values.categorySpec.investor.number_investment}
                                    value={numInvestments}
                                    onChange={formik.handleChange}
                                    autoComplete='off'
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    onBlur={formik.handleBlur}
                                    helperText={getIn(formik.errors, 'categorySpec.investor.number_investment')
                                    && formik.errors.categorySpec.investor.number_investment
                                    }
                                    error={getIn(formik.errors, 'categorySpec.investor.number_investment')
                                    && formik.errors.categorySpec.investor.number_investment
                                    }
                                />
                            </Grid>
                            : null}

                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.categorySpec.investor.actively_investing}
                                        onChange={
                                            () => formik.setFieldValue('categorySpec.investor.actively_investing',
                                                !formik.values.categorySpec.investor.actively_investing)
                                        }
                                        value={formik.values.categorySpec.investor.actively_investing}
                                        color="primary"
                                    />
                                }
                                label="Actively Investing"
                            />
                        </Grid>

                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.categorySpec.investor.post_revenue_only}
                                        onChange={
                                            () => formik.setFieldValue('categorySpec.investor.post_revenue_only',
                                                !formik.values.categorySpec.investor.post_revenue_only)
                                        }
                                        value={formik.values.categorySpec.investor.post_revenue_only}
                                        color="primary"
                                    />
                                }
                                label="Post-revenue only"
                            />
                        </Grid>

                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12} lg={6}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        className={classes.switch}
                                        checked={formik.values.categorySpec.investor.lead_rounds}
                                        onChange={
                                            () => formik.setFieldValue('categorySpec.investor.lead_rounds',
                                                !formik.values.categorySpec.investor.lead_rounds)
                                        }
                                        value={formik.values.categorySpec.investor.lead_rounds}
                                        color="primary"
                                    />
                                }
                                label="Lead Rounds"
                            />
                        </Grid>


                        <Grid
                            className={classes.field}
                            container
                            item
                            xs={12}
                        >
                            <Autocomplete
                                multiple
                                id="isi-tags"
                                name="investment_stages_ids"
                                disabled={editable}
                                className={classes.textField}
                                options={investmentStageListFilter}
                                value={formik.values.investment_stages_ids}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => {
                                    formik.setFieldValue('investment_stages_ids', value);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            color="primary"
                                            style={{ backgroundColor: '#888FCA' }}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={"Stages of investment"}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(InvestorFinancialForm);
