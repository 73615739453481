import classNames from 'classnames';
import { Paper, PortletContent, PortletHeader, PortletLabel } from '../../index';
import { Grid, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Spacer from '../../Spacer/Spacer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';


const SwitchStyled = withStyles({
    switchBase: {
        color: '#FFFFFF',
        '&$checked': {
            color: '#FCD97F'
        },
        '&$checked + $track': {
            backgroundColor: '#FCD97F'
        }
    },
    checked: {},
    track: {}
})(Switch);

const CompanyImpactForm = (props) => {
    const { classes, className, formik, SDGsArray, editable, ...rest } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (

        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Impact Metrics"
                    subtitle=""
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.female_cofounder}
                                    onChange={
                                        () => formik.setFieldValue('impact.female_cofounder',
                                            !formik.values.impact.female_cofounder)
                                    }
                                    value={formik.values.impact.female_cofounder}
                                    color="primary"
                                />
                            }
                            label="Female Co/Founders"
                        />
                    </Grid>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.assistive_tech}
                                    onChange={
                                        () => formik.setFieldValue('impact.assistive_tech',
                                            !formik.values.impact.assistive_tech)
                                    }
                                    value={formik.values.impact.assistive_tech}
                                    color="primary"
                                />
                            }
                            label="Assistive Tech"
                        />
                    </Grid>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.rural_focused}
                                    onChange={
                                        () => formik.setFieldValue('impact.rural_focused',
                                            !formik.values.impact.rural_focused)
                                    }
                                    value={formik.values.impact.rural_focused}
                                    color="primary"
                                />
                            }
                            label="Rural focused"
                        />
                    </Grid>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.female_focused}
                                    onChange={
                                        () => formik.setFieldValue('impact.female_focused',
                                            !formik.values.impact.female_focused)
                                    }
                                    value={formik.values.impact.female_focused}
                                    color="primary"
                                />
                            }
                            label="Women Focused / FemTech"
                        />
                    </Grid>
                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.youth_focused}
                                    onChange={
                                        () => formik.setFieldValue('impact.youth_focused',
                                            !formik.values.impact.youth_focused)
                                    }
                                    value={formik.values.impact.youth_focused}
                                    color="primary"
                                />
                            }
                            label="Youth Focused"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.fifty_local}
                                    onChange={
                                        () => formik.setFieldValue('impact.fifty_local',
                                            !formik.values.impact.fifty_local)
                                    }
                                    value={formik.values.impact.fifty_local}
                                    color="primary"
                                />
                            }
                            label=">50% Local Co-Founders"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.elderly_people}
                                    onChange={
                                        () => formik.setFieldValue('impact.elderly_people',
                                            !formik.values.impact.elderly_people)
                                    }
                                    value={formik.values.impact.elderly_people}
                                    color="primary"
                                />
                            }
                            label="Elderly People"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.zero_emissions}
                                    onChange={
                                        () => formik.setFieldValue('impact.zero_emissions',
                                            !formik.values.impact.zero_emissions)
                                    }
                                    value={formik.values.impact.zero_emissions}
                                    color="primary"
                                />
                            }
                            label="Zero Emissions"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.informal_sector}
                                    onChange={
                                        () => formik.setFieldValue('impact.informal_sector',
                                            !formik.values.impact.informal_sector)
                                    }
                                    value={formik.values.impact.informal_sector}
                                    color="primary"
                                />
                            }
                            label="Informal Sector"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.bop}
                                    onChange={
                                        () => formik.setFieldValue('impact.bop',
                                            !formik.values.impact.bop)
                                    }
                                    value={formik.values.impact.bop}
                                    color="primary"
                                />
                            }
                            label="Bottom of the Pyramid"
                        />
                    </Grid>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={12} sm={6} lg={5}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.impact.employment_creation}
                                    onChange={
                                        () => formik.setFieldValue('impact.employment_creation',
                                            !formik.values.impact.employment_creation)
                                    }
                                    value={formik.values.impact.employment_creation}
                                    color="primary"
                                />
                            }
                            label="Employment Creation"
                        />
                    </Grid>

                    <Spacer small divider/>

                    <Grid
                        className={classes.field}
                        container
                        item
                        xs={9}
                    >
                        <Autocomplete
                            multiple
                            id="SDGs-tags"
                            name="SDGs"
                            disabled={editable}
                            className={classes.textField}
                            options={SDGsArray}
                            value={formik.values.SDGs}
                            getOptionLabel={option => option.name}
                            onChange={(event, value) => {
                                formik.setFieldValue('SDGs', value);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        color="primary"
                                        style={{backgroundColor:'#888FCA'}}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="SDGs"
                                    variant="outlined"
                                    placeholder="Select multiple SDG"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>


                </Grid>
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(CompanyImpactForm);
