import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export default function DegreeBox(props) {

    const { classes, className, numberOfDegree, ...rest } = props;

    return (
        <div style={{ justifyContent: 'center' }}>
            {numberOfDegree > 0 ? < FiberManualRecordIcon style={{ fill: '#FCD97F' }}/> : null}
            {numberOfDegree > 1 ? < FiberManualRecordIcon style={{ fill: '#FCD97F' }}/> : null}
            {numberOfDegree > 2 ? < FiberManualRecordIcon style={{ fill: '#FCD97F' }}/> : null}
        </div>
    );
}
