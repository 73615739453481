import React from 'react';
import CompanyRow from './Companies/CompanyRow';
import PersonaRow from './People/PersonaRow';
import UserRow from './Users/UserRow';
import SectorRow from './Sectors/SectorRow';
import SubSectorRow from './SubSectors/SubSectorRow';
import SDGRow from './SDGs/SDGRow';
import CompanyRowLimited from './Companies/CompanyRowLimited';
import LogRow from './Logs/LogRow';
import DealTableRow from './Deal/DealTableRow';
import CompanyRowCompare from './Companies/CompanyRowCompare';
import ClaimRow from './Claim/ClaimRow';
import DealTableFormRow from './Deal/DealTableFormRow';

export const tableTypes = {
    COMPANIES: 'companies',
    COMPANIES_CHARTS: 'companies_charts',
    COMPARE: 'compare',
    PEOPLE: 'people',
    USERS: 'users',
    SECTORS: 'sector',
    SUBSECTORS: 'sub_sector',
    TECHNOLOGIES: 'technologies',
    SDG: 'sdg',
    LOGS: 'logs',
    DEAL: 'deal',
    DEAL_ROW: 'deal_row',
    CLAIM: 'claim'
};

export const getTableRow = (tableType,
                            handleClickCheckbox,
                            isItemSelected,
                            row,
                            labelId,
                            handleClickStatus,
                            handleClickDelete,
                            myUser,
                            activeSelection,
                            onClickRow,
                            fromCompare,
                            tableSide,
                            changeStatus,
                            pagination,
                            onClickInvestor) => {

    switch (tableType) {
        case tableTypes.COMPANIES:
            return (
                <CompanyRow
                    row={row}
                    key={row.company_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    onClickStatus={handleClickStatus ? handleClickStatus : null}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                    myUser={myUser}
                    activeSelection={activeSelection}
                    pagination={pagination}

                    onClickRow={onClickRow}
                />
            );
        case tableTypes.COMPANIES_CHARTS:
            return (
                <CompanyRowLimited
                    row={row}
                    key={row.company_id}
                    limited

                    labelId={labelId}
                    onClickRow={onClickRow}
                />
            );
        case tableTypes.COMPARE:
            return (
                <CompanyRowCompare
                    row={row}
                    key={row.company_id}
                    limited

                    tableSide={tableSide}

                    labelId={labelId}
                    onClickRow={onClickRow}
                />
            );
        case tableTypes.PEOPLE:
            return (
                <PersonaRow
                    row={row}
                    key={row.persona_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                    myUser={myUser}
                    activeSelection={activeSelection}
                />
            );
        case tableTypes.USERS:
            return (
                <UserRow
                    row={row}
                    key={row.user_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    onClickStatus={handleClickStatus ? handleClickStatus : null}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                    myUser={myUser}
                    activeSelection={activeSelection}
                />
            );
        case tableTypes.SECTORS:
            return (
                <SectorRow
                    row={row}
                    key={row.sector_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    myUser={myUser}
                    activeSelection={activeSelection}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                />
            );
        case tableTypes.SUBSECTORS:
            return (
                <SubSectorRow
                    row={row}
                    key={row.sub_sector_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    myUser={myUser}
                    activeSelection={activeSelection}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                />
            );
        // case tableTypes.TECHNOLOGIES:
        //     return (
        //     // TODO
        //     );
        case tableTypes.DEAL:
            return (
                <DealTableRow
                    row={row}
                    key={row.investment_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}

                    onClickRow={onClickRow}
                    onClickInvestor={onClickInvestor}

                    labelId={labelId}
                    myUser={myUser}
                />
            );
        case tableTypes.DEAL_ROW:
            return (
                <DealTableFormRow
                    row={row}
                    key={row.investment_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}

                    onClickRow={onClickRow}
                    onClickInvestor={onClickInvestor}

                    labelId={labelId}
                    myUser={myUser}
                />
            );
        case tableTypes.SDG:
            return (
                <SDGRow
                    row={row}
                    key={row.sdg_id}

                    onClickCheckbox={handleClickCheckbox}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    myUser={myUser}
                    activeSelection={activeSelection}
                    onClickDelete={handleClickDelete ? handleClickDelete : null}
                />
            );
        case tableTypes.LOGS:
            return (
                <LogRow
                    row={row}
                    key={row.log_id}

                    //onClickCheckbox={handleClickCheckbox}
                    //isItemSelected={isItemSelected}
                    labelId={labelId}
                    myUser={myUser}
                    //activeSelection={activeSelection}
                    //onClickDelete={handleClickDelete ? handleClickDelete : null}
                />
            );

        case tableTypes.CLAIM:
            return (
                <ClaimRow
                    row={row}
                    key={row.claim_id}

                    // onClickCheckbox={handleClickCheckbox}
                    // isItemSelected={isItemSelected}
                    labelId={labelId}
                    onClickStatus={handleClickStatus ? handleClickStatus : null}
                    // onClickDelete={handleClickDelete ? handleClickDelete : null}
                    myUser={myUser}
                    // activeSelection={activeSelection}
                    changeStatus={changeStatus}
                />
            );
        default:
            return null;
    }
};
