export default [
    {
        datapoint_id: 'startups',
        datapoint_name: 'Startups'
    },
    {
        datapoint_id: 'hubs',
        datapoint_name: 'Hubs'
    },
    {
        datapoint_id: 'investors',
        datapoint_name: 'Investors'
    }
];
