import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Grid, withStyles } from '@material-ui/core';

import { Typography, Button, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent,
    PortletFooter
} from '../../components';

import styles from './styles';

class DatapointForm extends Component {
    state = {
        datapoint_id: this.props.datapoints[0]
    };

    handle_change_datapoint = event => {
        const selectedIndex = event.target.options.selectedIndex;
        const event_datapoint_id = event.target.options[selectedIndex].getAttribute('datapoint_id');
        this.setState({
            datapoint_id: event_datapoint_id
        });

        this.props.handle_change_datapoint(event_datapoint_id, selectedIndex);
    };

    render() {
        const { classes, className, ...rest } = this.props;

        const rootClassName = classNames(this.props.classes.root, this.props.className);

        let label = <Typography variant="h6" style={{ marginBottom: '5px' }}>Select CSV</Typography>;

        return (
            <Paper
                {...rest}
                className={rootClassName}
                elevation={2}
            >
                <PortletHeader>
                    <PortletLabel
                        subtitle=""
                        title="Insert CSV"
                    />
                </PortletHeader>
                <PortletContent noPadding>
                    <Grid>
                        <form
                            autoComplete="off"
                            noValidate
                        >
                            <div className={classes.field}>
                                <TextField
                                    className={classes.textField}
                                    label="Datapoint Name"
                                    margin="dense"
                                    onChange={this.handle_change_datapoint}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={this.state.datapointName}
                                    variant="outlined">
                                    {this.props.datapoints.map(option => (
                                        <option
                                            key={option.datapoint_id}
                                            value={option.datapoint_name}
                                            datapoint_id={option.datapoint_id}
                                        >
                                            {option.datapoint_name}
                                        </option>
                                    ))}
                                </TextField>

                            </div>

                            <Grid
                                xs container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-end"
                                className="form-group"
                                style={{ marginBottom: '20px' }}>
                                <Grid key={this.props.uniqueIdCsv} item xs className={classes.field}
                                      style={{ marginTop: '0px', marginBottom: '0px', display: 'flex' }}>
                                    <CSVReader
                                        cssClass="csv-reader-input"
                                        label={label}
                                        onFileLoaded={this.props.onLoadCsv}
                                        onError={this.handleDarkSideForce}
                                        inputId={this.props.uniqueIdCsv}
                                        inputStyle={{ color: 'grey' }}
                                    />
                                </Grid>
                                {this.props.csv_loaded
                                    ? <Grid xs item>
                                        <DeleteIcon
                                            style={({ marginLeft: 50 })}
                                            onClick={this.props.handleOnDeleteClick}
                                        />
                                    </Grid>
                                    : null
                                }
                            </Grid>

                        </form>
                    </Grid>
                </PortletContent>
                <PortletFooter className={classes.portletFooter}>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>
                            <Typography variant="h6">
                                Total Rows: {this.props.numRows}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!this.props.uploadable || this.props.writingDB}
                                onClick={this.props.handle_upload_clicked}
                            >
                                SUBMIT TO DATABASE
                            </Button>
                        </Grid>
                    </Grid>
                </PortletFooter>

                {this.props.writingDB
                    ? <LinearProgress/>
                    : null
                }
            </Paper>

        );
    }
}

DatapointForm.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DatapointForm);
