import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgressBarExtended from '../UI/LinearProgressBarExtended/LinearProgressBarExtended';


function LinearProgressWithLabel(props) {
    const { percentage } = props;

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1} style={{ marginLeft: 20, marginRight: 20 }}>
                {/*<LinearProgress variant="determinate" {...props}/>*/}
                <LinearProgressBarExtended color={getBackgroundColor(percentage)} variant="determinate" {...props}
                                           style={{ backgroundColor: '#eeeeee' }}
                />
            </Box>
            <Box>
                <Typography noWrap variant="body2" color="textSecondary">{`${Math.round(
                    props.value
                )}% Profile Completed`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired
};

const useStyles = makeStyles({
    root: {
        width: '400px'
    }
});

export const getBackgroundColor = (percentage) => {
    if (percentage >= 0 && percentage < 30) {
        return 'danger';
    }
    if (percentage >= 30 && percentage < 70) {
        return 'warning';
    }
    if (percentage >= 70) {
        return 'completed';
    }
};

export default function LinearWithValueLabel(props) {
    const { completed } = props;

    const classes = useStyles();
    // const [progress, setProgress] = React.useState(10);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    return (
        <div className={classes.root}>
            <LinearProgressWithLabel value={completed} percentage={completed}/>
        </div>
    );
}
