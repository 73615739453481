import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { actionTypes, isFormDisabled } from '../../helpers/actionTypes';
import * as Yup from 'yup';
import { Formik } from 'formik';
import history from '../../config/history';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import SimpleSnackbar from '../../components/Feedback/SimpleSnackbar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import {
    cleanTable,
    createRow,
    fetchTableData,
    fetchTableDataEmpty,
    updateRow
} from '../../actions/tableHandler';
import ProfileGeneric from './profileGeneric';
import Spacer from '../../components/Spacer/Spacer';
import { TITLE_ROW } from '../../strings/genericStrings';

class GenericTable extends Component {

    constructor(props) {
        super(props);

        let tableName = this.props.tableName;

        this.state = {
            actionMode: actionTypes.SHOW,
            snackVisible: false,
            snackbarVariant: '',
            dialogOpen: false,
            titleDialog: '',
            contentDialog: '',
            tableName: tableName
        };

        this.props.cleanTable();
    }


    componentDidMount() {
        const actionType = this.props.match.params.actionType;
        const rowid = this.props.match.params.rowid;

        this.setState({
            ...this.state,
            actionMode: actionType
        });

        switch (actionType) {
            case actionTypes.SHOW:
                this.props.fetchTableData(this.state.tableName, rowid, this.props.myUser);
                break;
            case actionTypes.ADD:
                this.props.fetchTableDataEmpty(this.state.tableName);
                break;
            case actionTypes.EDIT:
                this.props.fetchTableData(this.state.tableName, rowid, this.props.myUser);
                break;
            default:
                break;
        }
    }

    handleClickOkDialog() {
        history.push({
            pathname: `/manage${this.state.tableName}`
        });
    }

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (variant) => {
        this.setState({
            ...this.state,
            snackbarVariant: variant,
            snackVisible: true
        });
    };

    openDialogCreation(success) {
        if (success) {
            this.setState({
                ...this.state,
                dialogOpen: true,
                titleDialog: 'Created with success!'
            });
        }
    }

    submitForm = (values, actions) => {
        switch (this.state.actionMode) {
            case actionTypes.ADD:
                this.props.createRow(
                    this.state.tableName,
                    this.props.tableData,
                    values,
                    this.props.myUser
                );
                break;
            case actionTypes.EDIT:
                this.props.updateRow(
                    this.state.tableName,
                    this.props.tableData,
                    values,
                    this.props.myUser
                );
                break;
            default:
                break;
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tableUpdating && !this.props.tableUpdating) {
            let variant = 'info';

            switch (this.state.actionMode) {
                case actionTypes.ADD:
                    this.openDialogCreation(true);
                    break;
                case actionTypes.EDIT:
                    if (!this.props.tableUpdateError) {
                        variant = 'success';
                    } else {
                        variant = 'error';
                    }
                    this.openSnack(variant);
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        let {
            tableData,
            tableUpdating
        } = this.props;

        return (
            <DashboardLayout title={TITLE_ROW[this.state.tableName]}>
                {tableData
                    ?
                    <div>
                        <AppBar position="static"
                                color="inherit"
                                style={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    borderBottom: 'solid 1px #ddd'
                                }}
                        >
                            <Toolbar>
                                <div style={{ flexGrow: 1 }}/>
                                {this.state.actionMode !== actionTypes.SHOW ?
                                    <Button
                                        variant="contained"
                                        disabled={false}
                                        form="general-table-form"
                                        type="submit"
                                        value="Submit"
                                        color="primary"
                                        style={{ backgroundColor: '#45B880' }}
                                    >
                                        {this.state.actionMode === actionTypes.ADD
                                            ? 'CREATE'
                                            : 'SAVE '
                                        }
                                    </Button> : null
                                }
                            </Toolbar>
                            {tableUpdating ? <LinearProgress/> : null}
                        </AppBar>
                        <Spacer/>
                        <Formik
                            enableReinitialize

                            initialValues={{
                                name: this.props.tableData.name
                            }}

                            onSubmit={this.submitForm}

                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .required('Required')
                            })}
                        >
                            {formikProps => (
                                <form id="general-table-form"
                                      onSubmit={formikProps.handleSubmit}
                                >
                                    <ProfileGeneric
                                        formDisabled={isFormDisabled(this.state.actionMode)}
                                        formikProps={formikProps}
                                        data={tableData}
                                        tableName={this.state.tableName}
                                    />
                                </form>
                            )}
                        </Formik>
                    </div>
                    : <LinearProgress/>
                }
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
                <AlertDialog
                    openDialog={this.state.dialogOpen}
                    title={this.state.titleDialog}
                    content={this.state.contentDialog}
                    clicked={this.handleClickOkDialog.bind(this)}
                />
            </DashboardLayout>
        );
    }
}

const mapDispatchToProps = {
    fetchTableData,
    fetchTableDataEmpty,
    updateRow,
    createRow,
    cleanTable,
};

const mapStateToProps = (state) => {
    return {
        table_id: state.tableHandler.table_id,
        tableData: state.tableHandler.tableData,

        tableUpdating: state.tableHandler.tableUpdating,
        tableUpdateError: state.tableHandler.tableUpdateError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
