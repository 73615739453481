import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../../layouts';
import history from '../../../config/history';
import { actionTypes, tableActionTypes } from '../../../helpers/actionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCountUsers, fetchUsersPreview, updateUserStatus } from '../../../actions/usersHandler';
import LinearProgress from '@material-ui/core/LinearProgress';
import EnhancedTable from '../../../components/Table/EnhanceTable';
import { tableTypes } from '../../../components/Table/rowSelector';
import { SORTING } from '../../../models/admin/user';
import ActionDialog from '../../../components/ChangeStatusDialog';
import SimpleSnackbar from '../../../components/Feedback/SimpleSnackbar';
import Button from '../../../components/UI/ButtonExtended/ButtonExtended';
import Spacer from '../../../components/Spacer/Spacer';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/axiosConfig';
import { authHeader } from '../../../auth/authUtils';
import { cloneDeep } from 'lodash';

const headCells = [
    { id: 'first_name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
    { id: 'last_name', numeric: false, disablePadding: false, label: 'Last Name', sortable: true },
    { id: 'role', numeric: false, disablePadding: false, label: 'Role', sortable: true },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', sortable: false },
    { id: 'last_access', numeric: false, disablePadding: false, label: 'Last Access', sortable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', sortable: false }
];

class ManageUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            pageSize: 25,
            numTotalRows: 0,
            sorting: {
                column: SORTING.USERS_PREVIEW_DEFAULT,
                desc: true
            },
            filter: null,
            dialogActionVisible: false,
            snackVisible: false,
            snackbarVariant: '',
            rowClicked: null,
            rowsSelected: null,
            activeActionType: null,

            searchQuery: '',
            searchResult: [],
            searching: false
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.props.fetchCountUsers(this.props.myUser);
        this.fetchUsersPreview();
    }

    fetchUsersPreview() {
        this.props.fetchUsersPreview(
            this.state.page,
            this.state.pageSize,
            this.state.sorting,
            this.state.filter,
            this.props.myUser
        );
    };

    handleChangePageSize = (pageSize) => {
        this.setState({
            pageSize: pageSize,
            page: 0
        });
    };

    handleChangeSorting = (columnName, isDesc) => {
        let newSorting = {
            column: columnName,
            desc: isDesc
        };
        this.setState({
            sorting: newSorting,
            page: 0
        });
    };

    handlePageChange = (newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleAddUser = () => {
        history.push({
            pathname: '/user/' + actionTypes.ADD
        });
    };

    onClickStatus = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.STATUS
        }, () => this.openDialogAction());
    };

    onClickDelete = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.DELETE
        }, () => this.openDialogAction());
    };

    openDialogAction = () => {
        this.setState({
            dialogActionVisible: true
        });
    };

    closeActionDialog = (force) => {
        if (!this.props.companyStatusUpdating || force) {
            this.setState({
                dialogActionVisible: false
            });
        }
    };


    handleConfirmAction = (row) => {
        switch (this.state.activeActionType) {
            case tableActionTypes.DELETE:
                // TODO
                // this.props.deleteUser(row.user_id, this.props.myUser);
                break;
            case tableActionTypes.STATUS:
                this.props.updateUserStatus(row.user_id, !row.status, this.props.myUser);
                break;
            default:
                break
        }
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (variant) => {
        this.setState({
            ...this.state,
            dialogActionVisible: false,
            snackbarVariant: variant,
            snackVisible: true
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pageSize !== this.state.pageSize
            || prevState.sorting !== this.state.sorting
            || prevState.page !== this.state.page) {
            this.fetchUsersPreview();
        }

        if (prevProps.numUsers !== this.props.numUsers) {
            this.setState({
                numTotalRows: this.props.numUsers
            });
        }

        if (prevProps.userStatusUpdating && !this.props.userStatusUpdating) {
            let variant = 'info';
            if (!this.props.userStatusUpdateError) {
                variant = 'success';
                if (this.state.activeActionType === tableActionTypes.DELETE) {
                    this.init();
                }
            } else {
                variant = 'error';
            }
            this.openSnack(variant);
        }
    }

    startSearch = () => {
        axios.get(BASE_URL + '/user/active-search', {
            params: {
                pattern: this.state.searchQuery
            },
            headers: authHeader(this.props.myUser)
        })
          .then(({ data }) => {
              this.updateSearchResult(data.results);
          });
    };

    updateSearchResult = (results) => {
        let newResults = cloneDeep(results);

        this.setState({
            searchResult: newResults,
            searching: false
        });
    };

    handleInputSearchChange = (e) => {
        this.setState({
            searchQuery: e.target.value
        }, () => {
            if (this.state.searchQuery && this.state.searchQuery.length > 0) {
                this.setState({
                      searching: true
                  }, () => this.startSearch()
                );
            }
        });
    };

    render() {
        return (
            <DashboardLayout title="Manage Users">
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    onClick={this.handleAddUser}*/}
                {/*>*/}
                {/*    ADD USER*/}
                {/*</Button>*/}
                {/*<Spacer/>*/}
                {this.props.fetchingUsersPreview ? <LinearProgress/> : null}
                {this.props.usersPreviewError ? 'Error fetching users' : null}
                <EnhancedTable
                    rows={(this.state.searchQuery.length > 0)
                      ? this.state.searchResult
                      : this.props.usersPreview
                    }
                    headCells={headCells}
                    tableType={tableTypes.USERS}

                    page={this.state.page}
                    onChangePageSize={this.handleChangePageSize}
                    onChangeSorting={this.handleChangeSorting}
                    onPageChange={this.handlePageChange}
                    numTotalRows={this.state.numTotalRows}

                    handleClickStatus={this.onClickStatus}
                    handleClickDelete={this.onClickDelete}

                    myUser={this.props.myUser}

                    searching={this.state.searching}
                    handleInputSearchChange={this.handleInputSearchChange}

                    toolbarVisible
                    paginationVisible
                    checkable
                    sortable

                    handleAddEntry={this.handleAddUser}
                    addEnabled
                    deleteEnabled
                />
                <ActionDialog
                    tableName={"user"}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogActionVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    rowClicked={this.state.rowClicked}
                    updating={this.props.userStatusUpdating}
                    onConfirm={() => this.handleConfirmAction(this.state.rowClicked)}
                />
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
            </DashboardLayout>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUsersPreview,
        fetchCountUsers,
        updateUserStatus
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        usersPreview: state.usersHandler.usersPreview,
        fetchingUsersPreview: state.usersHandler.fetchingUsersPreview,
        usersPreviewError: state.usersHandler.usersPreviewError,

        numUsers: state.usersHandler.numUsers,

        userStatusUpdating: state.usersHandler.userStatusUpdating,
        userStatusUpdateError: state.usersHandler.userStatusUpdating,

        myUser: state.authHandler.myUser
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
