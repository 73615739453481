import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default function SearchCompanyDialog(props) {

    const {
        classes, className,
        open, companiesResult, searchingCompanies, companySelected,
        selectCompany, handleInputCompanyChange, closeDialogSearch, addCompany,
        fromDeals, categorySearching,
        ...rest
    } = props;

    return (
        <div>
            <Dialog open={open}
                    onClose={closeDialogSearch}
            >
                <DialogTitle
                    id="alert-dialog-title">
                    {(categorySearching && categorySearching === 'investor') ? 'FIND INVESTOR' : ' FIND COMPANY'}
                </DialogTitle>
                <DialogContent>

                    <Autocomplete
                        id="asynchronous-demo"
                        style={{ width: 400 }}
                        getOptionDisabled={option => option.disabled}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={option => (option.name)}
                        // getOptionValue={option => option.id}
                        options={companiesResult}
                        loading={searchingCompanies}
                        onChange={(event, value) => selectCompany(value)}
                        noOptionsText={fromDeals ? 'Add investor first if not found' : 'Add company first if not found'}
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                onChange={handleInputCompanyChange}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {searchingCompanies ?
                                                <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={closeDialogSearch}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!companySelected}
                        onClick={addCompany}
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
