import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Spacer from '../../components/Spacer/Spacer';
import MilestoneForm from '../../components/Company/MilestonesForm';
import SupportForm from '../../components/Company/SupportForm';
import {
    addCompanyToAccelerators,
    removeCompanyToAccelerators
} from '../../actions/companyHandler';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../helpers/axiosConfig';
import { authHeader } from '../../auth/authUtils';
import { cloneDeep } from 'lodash';
import { disableCompanyOfActualPartnership } from '../../helpers/Validation/fieldForm';
import history from '../../config/history';
import SearchCompanyDialog from '../../components/SearchCompanyDialog';

class AssessmentTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogAddCompanyVisible: false,
            companyQuery: '',
            companiesResult: [],
            searchingCompanies: false,
            companySelected: null
        };
    }

    getCompanies = () => {
        axios.get(BASE_URL + '/company/search', {
            params: {
                pattern: this.state.companyQuery,
                justActives: true
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateCompaniesResult(data.companies);
            });
    };

    updateCompaniesResult = (companies) => {
        let companiesResult = cloneDeep(companies);
        disableCompanyOfActualPartnership(companiesResult, this.props.accelerators);

        this.setState({
            companiesResult: companiesResult,
            searchingCompanies: false
        });
    };

    handleInputCompanyChange = (e) => {
        this.setState({
            companyQuery: e.target.value
        }, () => {
            if (this.state.companyQuery && this.state.companyQuery.length > 0) {
                this.setState({
                        searchingCompanies: true
                    }, () => this.getCompanies()
                );
                //}
            }
        });
    };

    handleClickCompany(company) {
        history.push({
            pathname: '/redirect/show/' + company.company_id
        });
    };

    handleClickRemoveAccelerator(company) {
        this.props.removeCompanyToAccelerators(company);
    };

    openDialogSearchCompany = () => {
        this.setState({
            dialogAddCompanyVisible: true
        });
    };

    closeDialogSearch = () => {
        this.setState({
            dialogAddCompanyVisible: false,
            companySelected: null
        });
    };

    selectCompany = (company) => {
        this.setState({
            companySelected: company
        });
    };

    addAccelerator = () => {
        if (this.state.companySelected) {
            this.props.addCompanyToAccelerators(this.state.companySelected);
            this.setState({
                dialogAddCompanyVisible: false,
                companySelected: null,
                companyQuery: '',
                companiesResult: []
            });
        }
    };


    render() {
        return (
            <div>
                <fieldset disabled={this.props.formDisabled} style={{ border: 'none' }}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} lg={9}>
                            <MilestoneForm
                                editable={this.props.formDisabled}
                                formik={this.props.formikProps}
                            />
                            <Spacer/>
                            <SupportForm
                                editable={this.props.formDisabled}
                                formik={this.props.formikProps}
                                accelerators={this.props.accelerators}
                                myUser={this.props.myUser}
                                openDialogSearchCompany={this.openDialogSearchCompany.bind(this)}
                                handleClickCompany={this.handleClickCompany.bind(this)}
                                handleClickRemoveAccelerator={this.handleClickRemoveAccelerator.bind(this)}
                            />
                        </Grid>
                    </Grid>
                </fieldset>
                <SearchCompanyDialog
                    open={this.state.dialogAddCompanyVisible}
                    selectCompany={this.selectCompany}
                    closeDialogSearch={this.closeDialogSearch.bind(this)}
                    companiesResult={this.state.companiesResult}
                    searchingCompanies={this.state.searchingCompanies}
                    companySelected={this.state.companySelected}
                    handleInputCompanyChange={this.handleInputCompanyChange.bind(this)}
                    addCompany={this.addAccelerator.bind(this)}
                    categorySearching={'company'}
                />
            </div>
        );
    }
}

const mapDispatchToProps = {
    addCompanyToAccelerators,
    removeCompanyToAccelerators
};

const mapStateToProps = (state) => {
    return {
        accelerators: state.companyHandler.accelerators,
        fetchingAccelerators: state.companyHandler.fetchingAccelerators,
        acceleratorsError: state.companyHandler.acceleratorsError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentTab);
