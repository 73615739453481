import { cloneDeep } from 'lodash';
import { personaObj, socialObj } from '../data/tableObjects';

export const SORTING = {
    PERSONA_PREVIEW_DEFAULT: 'name'
};

export const parsePersonaInfo = (personaInfos) => {
    const personaInfo = personaInfos[0];
    return {
        persona_id: personaInfo.persona_id,
        name: personaInfo.name,
        lastname: personaInfo.lastname,
        role: personaInfo.role,
        ethnicity: personaInfo.ethnicity,
        gender: personaInfo.gender,
        level_education: personaInfo.level_education,
        degree: personaInfo.degree,
        university: personaInfo.university,
        email: personaInfo.email,
        social_id: personaInfo.social_id,
        last_update: personaInfo.last_update
    };
};

export const personaDataEmpty = {
    persona: cloneDeep(personaObj),
    social: cloneDeep(socialObj)
};

export const getNameLastname = (userInfo) => {
    let nameLastname = '';
    if (userInfo) {
        if (userInfo.name) {
            nameLastname = nameLastname.concat(userInfo.name);
        }
        if (userInfo.lastname) {
            nameLastname = nameLastname.concat(' ' + userInfo.lastname);
        }
    }
    return nameLastname;
};
