import {
    CSV_READING_START,
    CSV_READING_END,
    CSV_CLEAR,
    CSV_SET_TABLES,
    CSV_BODY_READ,
    CSV_HEADER_READ,
    CSV_SET_HEADER_VALIDITY,
    CLEAR_CSV_HANDLER
} from '../actions/csvHandler';

const initialState = {
    csvMatrix: null,
    numRows: null,
    numColumns: null,
    uniqueIdCsv: Date.now().toString(),

    header: null,
    validHeader: false,
    uploadable: null,

    csv_loaded: null,
    csv_checked: false,

    readingCsv: false,
    errorCsv: false,

    datapoint_errors: [],
    tables: []
};

function csvHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAR_CSV_HANDLER:
            return initialState;
        case CSV_CLEAR:
            return {
                ...state,
                csv_loaded: false,
                csvMatrix: null,
                numColumns: 0,
                numRows: 0,
                readingCsv: false,
                uploadable: false,
                uniqueIdCsv: Date.now().toString()
            };
        case
        CSV_READING_START:
            return {
                ...state,
                readingCsv: true,
                errorCsv: false
            };
        case
        CSV_READING_END:
            return {
                ...state,
                readingCsv: false,
                uploadable: action.payload
            };
        case
        CSV_BODY_READ: {
            const matrix = action.payload;
            const numColumns = matrix[0].length;
            const numRows = matrix.length;

            return {
                ...state,
                csvMatrix: matrix,

                numRows: numRows,
                numColumns: numColumns,

                csv_loaded: true
            };
        }
        case
        CSV_HEADER_READ: {
            return {
                ...state,
                header: action.payload.header,
                numTopRowsCleaned: action.payload.numTopRowsCleaned
            };
        }
        case
        CSV_SET_HEADER_VALIDITY: {
            return {
                ...state,
                uploadable: action.payload,
                validHeader: action.payload
            };
        }
        case
        CSV_SET_TABLES: {
            return {
                ...state,
                tables: action.payload.tables,
                datapoint_errors: action.payload.errors
            };
        }
        default:
            return state;
    }
}

export default csvHandler;
