import {
    CLEAR_STATS,
    STATS_COUNT_FETCH,
    STATS_COUNT_FETCH_ERROR,
    STATS_COUNT_FETCH_SUCCESS
} from '../actions/chartsHandler';
import { SET_AUTHBOX } from '../actions/authHandler';
import { getDummyTrends } from '../helpers/Charts/dummyTrends';

const initialState = {
    fetchingStatsSectors: null,
    statsSectorsError: null,
    statsSectors: [],
    statsSubSectors: [],
    statsCountries: [],
    statsGeneral: {},
    aggregatedTrends: getDummyTrends()
};

export default function chartsHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAR_STATS:
            return initialState;
        case STATS_COUNT_FETCH:
            return {
                ...state,
                fetchingStatsSectors: true,
                statsSectorsError: null
            };
        case STATS_COUNT_FETCH_SUCCESS:
            const generalStats = {};
            generalStats['numSectors'] = action.payload.numSectors;
            generalStats['numSubSectors'] = action.payload.numSubSectors;

            generalStats['numCompanies'] = action.payload.numCompanies;
            // generalStats['numSectors'] = action.payload.countSectors.length;
            // generalStats['numSubSectors'] = action.payload.countSubSectors.length;
            generalStats['annualInvestment'] = action.payload.annualInvestment;

            return {
                ...state,
                fetchingStatsSectors: false,
                statsSectors: action.payload.countSectors,
                statsSubSectors: action.payload.countSubSectors,
                statsCountries: action.payload.countCountries,
                statsSDGs: action.payload.countSDGs,
                statsGeneral: generalStats,
                companyRows: action.payload.companyRows,
                aggregatedTrends: action.payload.aggregatedTrends
            };
        case STATS_COUNT_FETCH_ERROR:
            return {
                ...state,
                fetchingStatsSectors: false,
                statsSectorsError: action.payload
            };
        default:
            return state;
    }
}
