import Startup_TableExtractor from './Extractors/Startup_TableExtractor';
import Hub_TableExtractor from './Extractors/Hub_TableExtractor';
import Investor_TableExtractor from './Extractors/Investor_TableExtractor';


const isRowEmpty = (row) => {
    let isFullEmpty = true;
    for (let column = 0; column < row.length; column++) {
        if (row[column] !== '') {
            isFullEmpty = false;
            break;
        }
    }
    return isFullEmpty;
};

const removeEmptyRow = (matrix, index_row) => {
    // console.log('Row ' + index_row + ' empty. DELETED');
    matrix.splice(index_row, 1);
};

/*const checkDuplicatesColumns = (columns) => {
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
    let duplicateColumns = findDuplicates(columns);
    // console.log('Duplicate Columns: ' + duplicateColumns.length);
};*/

export const getCSVHeader = (matrix) => {
    let numRowsCleaned = 0;
    if (isRowEmpty(matrix[0])) {
        removeEmptyRow(matrix, 0);
        numRowsCleaned++;
    }
    let header_row = matrix[0];

    let header = [];
    for (let c = 0; c < header_row.length; c++) {
        header.push(header_row[c].toLowerCase());
    }

    removeEmptyRow(matrix, 0);
    numRowsCleaned++;

    // checkDuplicatesColumns(header);

    return [header, numRowsCleaned];
};

export const cleanCSV = (matrix) => {
    for (let i = 0; i < matrix.length; i++) {
        if (isRowEmpty(matrix[i])) {
            removeEmptyRow(matrix, i);
        }
    }
};

export const isValidHeader = (header, datapointId) => {
    let isValidHeader = true;
    switch (datapointId) {
        case 'startups':
            if (header.indexOf('hq') === -1) {
                isValidHeader = false;
            }
            break;
        case 'hubs':
            isValidHeader = true;
            break;
        case 'investors':
            isValidHeader = true;
            break;
        default:
            break;
    }
    return isValidHeader;
};

const [exctractTableStartup] = Startup_TableExtractor();
const [extractTableHub] = Hub_TableExtractor();
const [extractTableInvestor] = Investor_TableExtractor();

export const extractTables = (header, matrix, datapointId, numTopRowsCleaned) => {
    let errors = [];
    let generalTables = [];

    let indexCsv = 0;

    switch (datapointId) {
        case 'startups':
            for (let i = 0; i < matrix.length; i++) {
                if (matrix[i].length > 1) {
                    indexCsv = i + numTopRowsCleaned;
                    const [currErrors, tablesExtracted] = exctractTableStartup(
                        i,
                        indexCsv,
                        matrix[i],
                        header,
                        errors
                    );
                    errors.push.apply(errors, currErrors);
                    generalTables.push(tablesExtracted);
                }
            }
            break;
        case 'hubs':
            for (let i = 0; i < matrix.length; i++) {
                indexCsv = i + numTopRowsCleaned;
                const [currErrors, tablesExtracted] = extractTableHub(
                    i,
                    indexCsv,
                    matrix[i],
                    header,
                    errors
                );
                errors.push.apply(errors, currErrors);
                generalTables.push(tablesExtracted);
            }
            break;
        case 'investors':
            for (let i = 0; i < matrix.length; i++) {
                indexCsv = i + numTopRowsCleaned;
                const [currErrors, tablesExtracted] = extractTableInvestor(
                    i,
                    indexCsv,
                    matrix[i],
                    header,
                    errors
                );
                errors.push.apply(errors, currErrors);
                generalTables.push(tablesExtracted);
            }
            break;
        default:
            break;
    }

    return [errors, generalTables];
};
