import axios from 'axios';

// export const BASE_URL = 'https://briter-back-development.vtjzhrgqaj.us-east-2.elasticbeanstalk.com/v1';
// export const BASE_URL = 'https://briter-back-release.vtjzhrgqaj.us-east-2.elasticbeanstalk.com/v1';
export const BASE_URL = 'https://api-v1.intelligence.briterbridges.com/v1';
// export const BASE_URL = 'http://localhost:5000/v1/';

// export const ORIGIN_PLATFORM = 'briter';
export const ORIGIN_PLATFORM = 'nigeria';

const axiosApp = axios.create({
    baseURL: BASE_URL,
    crossDomain: true,
    timeout: 6500
});

export default axiosApp;
