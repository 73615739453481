var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

// str.substring(0,3);

const lastday = function(y, m) {
    return new Date(y, m + 1, 0);
};

export const getDummyTrends = () => {
    let dummyData = [];
    let max = 15000000;
    let min = 200000;

    for (let m = 0; m <= 11; m++) {
        let entry = {
            'date': lastday(2020, m),
            '2020': Math.random() * (max - min) + min,
            '2019': Math.random() * (max - min) + min,
            '2018': Math.random() * (max - min) + min
        };
        dummyData.push(entry);
    }

    return dummyData;
};
