import React from 'react';
import classNames from 'classnames';
import { withStyles, TextField, Grid } from '@material-ui/core';
import { getIn } from 'formik';
import Switch from '@material-ui/core/Switch';
import { years } from '../../../models/fundingDate';

import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import { locations } from '../../../data/location/locations';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { categoriesList, statusList, ownershipList } from '../../../models/company/company';
import { businessModelList } from '../../../models/typeList/businessModels';
import { numberEmployeesTypes } from '../../../models/typeList/numberEmployeesTypes';
import { legalRegistrationList } from '../../../models/company/company';
import Spacer from '../../Spacer/Spacer';
import Chip from '@material-ui/core/Chip';
import { investorTypeList } from '../../../models/typeList/investorTypes';
import { hubTypeList } from '../../../models/typeList/hubTypes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { operatingStatusList } from '../../../models/typeList/operatingStatusTypes';
import { investmentStageList } from '../../../models/typeList/investmentStages';
import { stagesOfInterestListFilter } from '../../../models/typeList/stageOfInterests';
import { TEAM } from '../../../helpers/Roles/roleActions';
import GridField from '../../GridField/GridField';
import { actionTypes } from '../../../helpers/actionTypes';
import Tooltip from '@material-ui/core/Tooltip';
import { GetApp } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { getTotalInvestments } from '../../../helpers/Stats/investments';


const CompanyProfileForm = (props) => {
    const {
        classes, className,
        formik, countriesArray, checkCompanyName, nameCompanyAvailable, editable, myUser, actionMode,
        visitCounter, downloadEnabled, handleClickDownloadCsv, requestingDownloadCsv, companyInvestments, subStatesArray,
        ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Basic Profile"
                    subtitle={actionMode === actionTypes.EDIT ? 'The information can be edited from your profile page' : null}
                />
                {
                    downloadEnabled &&
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<GetApp/>}
                        onClick={handleClickDownloadCsv}
                        style={{ marginLeft: '5px' }}
                        disabled={actionMode === actionTypes.ADD || requestingDownloadCsv === true}
                    >
                        CSV
                    </Button>}

                {/*{visitCounter ?*/}
                {/*    <Typography>*/}
                {/*        {visitCounter} visits*/}
                {/*    </Typography>*/}
                {/*    : null}*/}
            </PortletHeader>
            <PortletContent>
                <fieldset disabled={!editable} style={{ border: 'none' }}>
                    <Grid
                        container
                        className={classes.form_container}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} sm={6} lg={6}
                            >
                                <TextField
                                    label="Company Name"
                                    name="company.name"
                                    className={classes.textField}
                                    value={formik.values.company.name}
                                    // disabled={!editable}
                                    onChange={formik.handleChange}
                                    onBlur={(event) => {
                                        formik.handleBlur(event);
                                        checkCompanyName(event.target.value);
                                    }
                                    }
                                    helperText={getIn(formik.errors, 'company.name')
                                    && formik.errors.company.name || !nameCompanyAvailable && 'Name already in use'
                                    }
                                    error={getIn(formik.errors, 'company.name')
                                    && formik.errors.company.name || !nameCompanyAvailable
                                    }

                                    autoComplete='off'
                                    inputProps={{
                                        autoComplete: 'disabled'
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            {myUser && TEAM.includes(myUser.role) ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={6} lg={6}
                                >
                                    <TextField
                                        className={classes.textField}
                                        label="Status"
                                        name="company.status"
                                        select
                                        // disabled={!editable}
                                        SelectProps={{ native: true }}
                                        value={formik.values.company.status}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                        variant="outlined"
                                        fullWidth
                                    >
                                        {statusList.map(option => (
                                            <option
                                                key={option.status_id}
                                                value={option.value}
                                            >
                                                {option.status_name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                : null
                            }

                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} sm={6} lg={6}
                            >
                                <TextField
                                    className={classes.textField}
                                    label="Category"
                                    name="company.category"
                                    select
                                    SelectProps={{ native: true }}
                                    value={formik.values.company.category}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                    variant="outlined"
                                    fullWidth
                                >
                                    {categoriesList.map(option => (
                                        <option
                                            key={option.category_id}
                                            value={option.value}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} sm={6} lg={6}
                            >
                                <TextField
                                    className={classes.textField}
                                    label="Operating Status"
                                    name="company.operating_status"
                                    select
                                    SelectProps={{ native: true }}
                                    value={formik.values.company.operating_status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                    variant="outlined"
                                    fullWidth
                                >
                                    {operatingStatusList.map(option => (
                                        <option
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.name}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>

                            {formik.values.company.category.toLowerCase() === 'startup' ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={6} lg={6}
                                >
                                    <TextField
                                        className={classes.textField}
                                        label="Ownership Structure"
                                        name="company.ownership_structure"
                                        select
                                        SelectProps={{ native: true }}
                                        value={formik.values.company.ownership_structure}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                        variant="outlined"
                                        fullWidth
                                    >
                                        {ownershipList.map(option => (
                                            <option
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                // <GridField
                                //     className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                //     formik={formik}
                                //     label={'Ownership Structure'} formName={'company'} fieldName={'ownership_structure'}
                                //     actionMode={actionMode}
                                // />
                                : null
                            }

                            {actionMode && actionMode === actionTypes.SHOW
                            && (!formik.values.company.founding_date || formik.values.company.founding_date === '') ?
                                null :
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={6} lg={6}
                                >
                                    {/*<DatePicker*/}
                                    {/*    label={'Founding Date'}*/}
                                    {/*    name="company.founding_date"*/}
                                    {/*    selectedDate={formik.values.company.founding_date}*/}
                                    {/*    handleDateChange={(event, value) => {*/}
                                    {/*        formik.setFieldValue('company.founding_date', new Date(value).toISOString());*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <TextField
                                        className={classes.textField}
                                        label="Founding Year"
                                        name="company.founding_date"
                                        select
                                        SelectProps={{ native: true }}
                                        value={formik.values.company.founding_date ? formik.values.company.founding_date.substring(0, 4) : null}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                        variant="outlined"
                                        fullWidth
                                    >
                                        {years.map((year, index) => (
                                            <option
                                                key={`year${index}`}
                                                value={year}
                                            >
                                                {year}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                            }

                            {formik.values.company.category.toLowerCase() === 'startup' ?
                                // <GridField
                                //     className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                //     formik={formik}
                                //     label={'Number Employees'} formName={'company'} fieldName={'number_employees'}
                                //     actionMode={actionMode}
                                // />
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={6} lg={6}
                                >
                                    <TextField
                                        className={classes.textField}
                                        label="Number Employees"
                                        name="company.number_employees"
                                        select
                                        SelectProps={{ native: true }}
                                        value={formik.values.company.number_employees}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                        variant="outlined"
                                        fullWidth
                                    >
                                        {numberEmployeesTypes.map(option => (
                                            <option
                                                key={option.id}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                : null
                            }

                            {formik.values.company.category.toLowerCase() === 'startup' ?

                                actionMode && actionMode === actionTypes.SHOW
                                && (!formik.values.categorySpec.startup.business_model || formik.values.categorySpec.startup.business_model === '') ?
                                    null :
                                    <Grid
                                        className={classes.field}
                                        container
                                        item
                                        xs={12} sm={6} lg={6}
                                    >
                                        <TextField
                                            className={classes.textField}
                                            label="Business Model"
                                            name="categorySpec.startup.business_model"
                                            select
                                            SelectProps={{ native: true }}
                                            value={formik.values.categorySpec.startup.business_model}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}

                                            variant="outlined"
                                            fullWidth
                                        >
                                            {businessModelList.map(option => (
                                                <option
                                                    key={option.id}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                : null
                            }

                            {formik.values.company.category.toLowerCase() === 'startup' ?

                                actionMode && actionMode === actionTypes.SHOW
                                && (!formik.values.company.stage || formik.values.company.stage === '') ?
                                    null :
                                    <Grid
                                        className={classes.field}
                                        container
                                        item
                                        xs={12} sm={6} lg={6}
                                    >
                                        <TextField
                                            className={classes.textField}
                                            label="Stage"
                                            name="company.stage"
                                            select
                                            SelectProps={{ native: true }}
                                            value={formik.values.company.stage}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}

                                            variant="outlined"
                                            fullWidth
                                        >
                                            {investmentStageList.map(option => (
                                                <option
                                                    key={option.id}
                                                    value={option.value}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                : null
                            }


                            {formik.values.company.category.toLowerCase() === 'startup' ?
                                <GridField
                                    className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                    formik={formik}
                                    label={'Incorporation Number'} formName={'company'}
                                    fieldName={'incorporation_number'}
                                    actionMode={actionMode}
                                />
                                : null
                            }

                            {formik.values.company.category.toLowerCase() === 'startup' ?
                                actionMode && actionMode === actionTypes.SHOW
                                && (!formik.values.company.legal_registration || formik.values.company.legal_registration === '') ?
                                    null :
                                    <Grid
                                        className={classes.field}
                                        container
                                        item
                                        xs={12} sm={6} lg={6}
                                    >
                                        <Tooltip
                                            title={'Add documentation in Docs tab'}
                                            placement="top"
                                        >
                                            <TextField
                                                className={classes.textField}
                                                label="Legal Registration"
                                                name="company.legal_registration"
                                                select
                                                SelectProps={{ native: true }}
                                                value={formik.values.company.legal_registration}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                variant="outlined"
                                                fullWidth
                                            >
                                                {legalRegistrationList.map(option => (
                                                    <option
                                                        key={option.id}
                                                        value={option.value}
                                                    >
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Tooltip>
                                    </Grid>
                                : null
                            }


                            {formik.values.company.category.toLowerCase() === 'startup'
                            && myUser && TEAM.includes(myUser.role) ?
                                <GridField
                                    className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                    formik={formik}
                                    label={'Reference'} formName={'company'} fieldName={'reference'}
                                    actionMode={actionMode}
                                />
                                : null
                            }

                            {/*{formik.values.company.category.toLowerCase() === 'investor' ?*/}
                            {/*    <Grid*/}
                            {/*        className={classes.field}*/}
                            {/*        container*/}
                            {/*        item*/}
                            {/*        xs={12} sm={6} lg={6}*/}
                            {/*    >*/}
                            {/*        <TextField*/}
                            {/*            className={classes.textField}*/}
                            {/*            label="Type"*/}
                            {/*            name="categorySpec.investor.type"*/}
                            {/*            select*/}
                            {/*            // disabled={!editable}*/}
                            {/*            SelectProps={{ native: true }}*/}
                            {/*            value={formik.values.categorySpec.investor.type}*/}
                            {/*            onChange={formik.handleChange}*/}
                            {/*            onBlur={formik.handleBlur}*/}

                            {/*            variant="outlined"*/}
                            {/*            fullWidth*/}
                            {/*        >*/}
                            {/*            {investorTypeList.map(option => (*/}
                            {/*                <option*/}
                            {/*                    key={option.id}*/}
                            {/*                    value={option.value}*/}
                            {/*                >*/}
                            {/*                    {option.name}*/}
                            {/*                </option>*/}
                            {/*            ))}*/}
                            {/*        </TextField>*/}
                            {/*    </Grid>*/}
                            {/*    : null*/}
                            {/*}*/}

                            {formik.values.company.category.toLowerCase() === 'investor' ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={6}
                                >
                                    <Autocomplete
                                        multiple
                                        id="investor-type-tags"
                                        name="types"
                                        disabled={!editable}
                                        className={classes.textField}
                                        options={investorTypeList}
                                        value={formik.values.types}
                                        getOptionLabel={option => option.name}
                                        onChange={(event, value) => {
                                            formik.setFieldValue('types', value);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        color="primary"
                                                        style={{ backgroundColor: '#888FCA' }}
                                                        label={investorTypeList.find(x => x.type_id === option.type_id)?.value}
                                                        disabled={formik.values.types.length === 1}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={'Type'}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                : null
                            }

                            {formik.values.company.category.toLowerCase() === 'investor' ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={6}
                                >
                                    <Autocomplete
                                        multiple
                                        id="investor-stages-tags"
                                        name="stagesOfInterest"
                                        disabled={!editable}
                                        className={classes.textField}
                                        options={stagesOfInterestListFilter}
                                        value={formik.values.stagesOfInterest}
                                        getOptionLabel={option => option.name}
                                        onChange={(event, value) => {
                                            formik.setFieldValue('stagesOfInterest', value);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        color="primary"
                                                        style={{ backgroundColor: '#888FCA' }}
                                                        label={stagesOfInterestListFilter.find(x => x.stage_id === option.stage_id)?.value}
                                                        disabled={formik.values.stagesOfInterest.length === 1}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={'Stages of Interest'}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                : null
                            }

                            {(formik.values.company.category.toLowerCase() === 'investor' && companyInvestments && companyInvestments.length > 0 ) ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={6}
                                >
                                    <CurrencyTextField
                                        disabled={true}
                                        currencySymbol="$"
                                        minimumValue="0"
                                        outputFormat='number'
                                        digitGroupSeparator=","
                                        decimalPlaces={0}
                                        label="Total Cumulative of Investment"
                                        className={classes.textField}
                                        value={getTotalInvestments(companyInvestments)}
                                        autoComplete='off'
                                        variant="outlined"
                                        fullWidth
                                    />


                                </Grid>
                                : null
                            }



                            {/*{formik.values.company.category.toLowerCase() === 'hub' ?*/}
                            {/*    <Grid*/}
                            {/*        className={classes.field}*/}
                            {/*        container*/}
                            {/*        item*/}
                            {/*        xs={12} sm={6} lg={6}*/}
                            {/*    >*/}
                            {/*        <TextField*/}
                            {/*            className={classes.textField}*/}
                            {/*            label="Type"*/}
                            {/*            name="categorySpec.hub.type"*/}
                            {/*            select*/}
                            {/*            // disabled={!editable}*/}
                            {/*            SelectProps={{ native: true }}*/}
                            {/*            value={(formik.values.categorySpec.hub && formik.values.categorySpec.hub.type) ? formik.values.categorySpec.hub.type.toLowerCase() : null}*/}
                            {/*            onChange={formik.handleChange}*/}
                            {/*            onBlur={formik.handleBlur}*/}

                            {/*            variant="outlined"*/}
                            {/*            fullWidth*/}
                            {/*        >*/}
                            {/*            {hubTypeList.map(option => (*/}
                            {/*                <option*/}
                            {/*                    key={option.id}*/}
                            {/*                    value={option.value}*/}
                            {/*                >*/}
                            {/*                    {option.name}*/}
                            {/*                </option>*/}
                            {/*            ))}*/}
                            {/*        </TextField>*/}
                            {/*    </Grid>*/}
                            {/*    : null*/}
                            {/*}*/}

                            {formik.values.company.category.toLowerCase() === 'hub' ?
                                <Grid
                                    container
                                    item
                                    xs={12} sm={6} lg={4}
                                    className={classes.field}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                className={classes.switch}
                                                checked={formik.values.categorySpec.hub.fund_provided}
                                                onChange={
                                                    () => formik.setFieldValue('categorySpec.hub.fund_provided',
                                                        !formik.values.categorySpec.hub.fund_provided)
                                                }
                                                value={formik.values.categorySpec.hub.fund_provided}
                                                color="primary"
                                            />
                                        }
                                        label="Provides Funding"
                                    />
                                </Grid>
                                : null
                            }

                            {formik.values.company.category.toLowerCase() === 'hub' ?
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={9}
                                >
                                    <Autocomplete
                                        multiple
                                        id="hub-type-tags"
                                        name="types"
                                        disabled={!editable}
                                        className={classes.textField}
                                        options={hubTypeList}
                                        value={formik.values.types}
                                        getOptionLabel={option => option.name}
                                        onChange={(event, value) => {
                                            formik.setFieldValue('types', value);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        color="primary"
                                                        style={{ backgroundColor: '#888FCA' }}
                                                        label={option.name}
                                                        disabled={formik.values.types.length === 1}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={'Type'}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                : null
                            }

                            {/*{formik.values.company.category.toLowerCase() === 'ngo_charity' ?*/}
                            {/*    <Grid*/}
                            {/*        className={classes.field}*/}
                            {/*        container*/}
                            {/*        item*/}
                            {/*        xs={12} lg={6}*/}
                            {/*    >*/}
                            {/*        <TextField*/}
                            {/*            className={classes.textField}*/}
                            {/*            label="Type"*/}
                            {/*            name="categorySpec.ngo.organization_type"*/}
                            {/*            select*/}
                            {/*            // disabled={!editable}*/}
                            {/*            SelectProps={{ native: true }}*/}
                            {/*            value={formik.values.categorySpec.ngo.organization_type}*/}
                            {/*            onChange={formik.handleChange}*/}
                            {/*            onBlur={formik.handleBlur}*/}

                            {/*            variant="outlined"*/}
                            {/*            fullWidth*/}
                            {/*        >*/}
                            {/*            {ngoTypeList.map(option => (*/}
                            {/*                <option*/}
                            {/*                    key={option.id}*/}
                            {/*                    value={option.value}*/}
                            {/*                >*/}
                            {/*                    {option.name}*/}
                            {/*                </option>*/}
                            {/*            ))}*/}
                            {/*        </TextField>*/}
                            {/*    </Grid>*/}
                            {/*    : null*/}
                            {/*}*/}

                        </Grid>

                        <Spacer small divider/>

                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <GridField
                                className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                formik={formik}
                                label={'Email'} formName={'company'} fieldName={'email'}
                                actionMode={actionMode}
                            />
                            <GridField
                                className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                formik={formik}
                                label={'Phone Number'} formName={'company'} fieldName={'contact_number'}
                                actionMode={actionMode}
                            />

                            {actionMode && actionMode === actionTypes.SHOW
                            && (!formik.values.geography.hq_country || formik.values.geography.hq_country === '') ?
                                null :
                                <Grid
                                    className={classes.field}
                                    container
                                    item
                                    xs={12} sm={6} lg={6}
                                >
                                    <Autocomplete
                                        id="country-select"
                                        name="geography.hq_country"
                                        className={classes.textField}
                                        options={locations}
                                        autoHighlight
                                        value={formik.values.geography.hq_country}
                                        getOptionLabel={option => option.name}
                                        onChange={(event, value) => {
                                            formik.setFieldValue('geography.hq_country', value);
                                        }}
                                        renderOption={option => (
                                            <React.Fragment>
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="HQ Country"
                                                variant="outlined"

                                                fullWidth
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'disabled'
                                                }}
                                                placeholder={'Choose a country'}
                                            />
                                        )}
                                    />
                                </Grid>
                            }

                            {actionMode && actionMode === actionTypes.SHOW
                            && (!formik.values.geography.hq_city || formik.values.geography.hq_city === '') ?
                                null :
                                <GridField
                                    className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                    formik={formik}
                                    label={'HQ City'} formName={'geography'} fieldName={'hq_city'}
                                    actionMode={actionMode}
                                />
                            }

                            {actionMode && actionMode === actionTypes.SHOW
                            && (!formik.values.geography.hq_address || formik.values.geography.hq_address === '') ?
                                null :
                                <GridField
                                    className={classes.field} classes={classes} container item xs={12} sm={6} lg={6}
                                    formik={formik}
                                    label={'HQ Address'} formName={'geography'} fieldName={'hq_address'}
                                    actionMode={actionMode}
                                />
                            }

                            {(formik.values.company.email || formik.values.company.contact_number || formik.values.geography.hq_country || formik.values.geography.hq_city || formik.values.geography.hq_address)
                            || actionMode !== actionTypes.SHOW
                                ?
                                <Spacer small divider/>
                                : null}

                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} lg={9}
                            >
                                <Autocomplete
                                    multiple
                                    disabled={!editable}
                                    id="countries-tags"
                                    name="countries"
                                    autoComplete='off'
                                    className={classes.textField}
                                    options={countriesArray}
                                    value={formik.values.countries}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => {
                                        formik.setFieldValue('countries', value);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                color="primary"
                                                style={{ backgroundColor: '#888FCA' }}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={formik.values.company.category === 'startup' ? 'Active Countries' : 'Countries of Interest'}
                                            variant="outlined"
                                            placeholder="Select multiple Countries"
                                            autoComplete='off'
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                className={classes.field}
                                container
                                item
                                xs={12} lg={9}
                            >
                                <Autocomplete
                                    multiple
                                    disabled={!editable}
                                    id="sub_states-tags"
                                    name="sub_states"
                                    autoComplete='off'
                                    className={classes.textField}
                                    options={subStatesArray}
                                    value={formik.values.sub_states}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => {
                                        formik.setFieldValue('sub_states', value);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                color="primary"
                                                style={{ backgroundColor: '#888FCA' }}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={formik.values.company.category === 'startup' ? 'Active SubStates' : 'SubStates of Interest'}
                                            variant="outlined"
                                            placeholder="Select multiple SubStates"
                                            autoComplete='off'
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </fieldset>
            </PortletContent>

        </Paper>

    );
};

export default withStyles(styles)(CompanyProfileForm);
