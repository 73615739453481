import { USERS } from './roleActions';

export const isMyCompany = (activeClaims, company_id) => {
    return !!activeClaims.includes(company_id);
};

export const isToIncrement = (myUser) => {
    if (myUser && myUser.role) {
        return USERS.includes(myUser.role);
    } else {
        return false;
    }
};
