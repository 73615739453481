import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../../layouts';
import EnhancedTable from '../../../components/Table/EnhanceTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    deleteCompany,
    fetchCompaniesPreview,
    updateCompanyStatus
} from '../../../actions/companyHandler';
import history from '../../../config/history';
import { actionTypes, tableActionTypes } from '../../../helpers/actionTypes';
import { tableTypes } from '../../../components/Table/rowSelector';
import { SORTING } from '../../../models/company/company';
import Spacer from '../../../components/Spacer/Spacer';
import SimpleSnackbar from '../../../components/Feedback/SimpleSnackbar';
import ActionDialog from '../../../components/ChangeStatusDialog';
import axios from 'axios';
import { BASE_URL } from '../../../helpers/axiosConfig';
import { authHeader } from '../../../auth/authUtils';
import { cloneDeep } from 'lodash';
import { can, DOWNLOAD_CSV, INNER_TEAM } from '../../../helpers/Roles/roleActions';
import { getHeaderCellsCompanies } from '../../../models/headerCells';
import CustomPaper from '../../../components/Paper';
import FilterFormBox from '../../../components/FilterFormOverview/FilterFormBox';
import { Grid } from '@material-ui/core';
import {
    clearCsvHandler,
    downloadCsvCompany
} from '../../../actions/downloadCsvHandler';

const qs = require('qs');

class ManageCompanies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                page: 0,
                pageSize: 25,
                sorting: {
                    column: SORTING.COMPANY_PREVIEW_DEFAULT,
                    desc: false
                },
                filterFields: null
            },
            dialogActionVisible: false,
            snackVisible: false,
            snackbarVariant: '',
            rowClicked: null,
            rowsSelected: null,
            activeActionType: null,
            multipleAction: false,

            searchQuery: '',
            searchResult: [],
            searching: false,
            updateSuccess: false,

            downloadType: null
        };
    }

    componentDidMount() {
        const pagination = this.props.location.pagination;
        if (pagination) {
            this.setState({
                pagination: pagination
            }, () => this.init());
        } else {
            this.init();
        }
    }

    init() {
        this.props.clearCsvHandler();
        this.fetchCompaniesPreview();
    }

    fetchCompaniesPreview() {
        this.props.fetchCompaniesPreview(
            this.state.pagination.page,
            this.state.pagination.pageSize,
            this.state.pagination.sorting,
            this.state.pagination.filterFields,
            this.props.myUser
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pagination.pageSize !== this.state.pagination.pageSize
            || prevState.pagination.sorting !== this.state.pagination.sorting
            || prevState.pagination.page !== this.state.pagination.page
            || prevState.pagination.filterFields !== this.state.pagination.filterFields) {
            this.fetchCompaniesPreview();
        }

        if (prevProps.companyStatusUpdating && !this.props.companyStatusUpdating) {
            let variant = 'info';
            let successful = false;
            if (!this.props.companyStatusUpdateError) {
                variant = 'success';
                successful = true;
                if (this.state.activeActionType === tableActionTypes.DELETE) {
                    this.init();
                }
            } else {
                variant = 'error';
            }
            this.openSnack(successful, variant);
        }
    }

    onCompanyRowClicked = (company_id) => {
        if (INNER_TEAM.includes(this.props.myUser.role) ||
            (this.props.activeClaims && this.props.activeClaims.includes(company_id))) {
            history.push({
                pathname: '/company/' + actionTypes.EDIT + '/' + company_id,
                pagination: this.state.pagination,
                origin: 'manage'
            });
        } else {
            history.push({
                pathname: '/company/' + actionTypes.SHOW + '/' + company_id,
                pagination: this.state.pagination,
                origin: 'manage'
            });
        }
    };

    handleAddCompany = () => {
        history.push({
            pathname: '/company/' + actionTypes.ADD
        });
    };

    handleChangePageSize = (pageSize) => {
        const pagination = { ...this.state.pagination };
        pagination.pageSize = pageSize;
        pagination.page = this.state.pagination.page;
        this.setState({
            pagination: pagination
        });
    };

    handleChangeSorting = (columnName, isDesc) => {
        const pagination = { ...this.state.pagination };
        pagination.page = this.state.pagination.page;
        pagination.sorting = {
            column: columnName,
            desc: isDesc
        };
        this.setState({
            pagination: pagination
        });
    };

    handlePageChange = (newPage) => {
        const pagination = { ...this.state.pagination };
        pagination.page = newPage;
        this.setState({
            pagination: pagination
        });
    };

    onClickStatus = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.STATUS
        }, () => this.openDialogAction());
    };

    onClickDelete = (row) => {
        this.setState({
            rowClicked: row,
            activeActionType: tableActionTypes.DELETE
        }, () => this.openDialogAction());
    };

    openDialogAction = () => {
        this.setState({
            dialogActionVisible: true
        });
    };

    closeActionDialog = (force) => {
        if (!this.props.companyStatusUpdating || force) {
            this.setState({
                dialogActionVisible: false
            });
        }
    };


    handleConfirmAction = () => {
        switch (this.state.activeActionType) {
            case tableActionTypes.DELETE:
                if (this.state.multipleAction) {
                    this.props.deleteCompany(this.state.rowsSelected, this.props.myUser);
                } else {
                    this.props.deleteCompany([this.state.rowClicked.company_id], this.props.myUser);
                }
                this.init();
                break;
            case tableActionTypes.STATUS:
                this.props.updateCompanyStatus(this.state.rowClicked.company_id, !this.state.rowClicked.status, this.props.myUser);
                break;
            case tableActionTypes.DOWNLOAD:
                if (this.state.downloadType === 'all') {
                    this.props.downloadCsvCompany({
                        myUser: this.props.myUser,
                        pagination: this.state.pagination,
                        type: 'all'
                    });
                } else {
                    // let ids_selected = [];
                    // for (let row of this.state.rowsSelected) {
                    //     ids_selected.push(row.company_id);
                    // }
                    this.props.downloadCsvCompany({
                            myUser: this.props.myUser,
                            pagination: this.state.pagination,
                            type: 'selected',
                            ids_selected: this.state.rowsSelected
                        }
                    );
                }
                this.closeActionDialog();
                break;
        }
    };

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (successful, variant) => {
        this.setState({
            ...this.state,
            dialogActionVisible: false,
            snackbarVariant: variant,
            snackVisible: true,
            updateSuccess: successful,
            rowClicked: null,
            rowsSelected: null
        });
    };

    handleMultipleDelete = (rows) => {
        this.setState({
            rowsSelected: rows,
            activeActionType: tableActionTypes.DELETE,
            multipleAction: true
        }, () => this.openDialogAction());
    };

    handleClickDownloadAll = () => {
        this.setState({
            downloadType: 'all',
            activeActionType: tableActionTypes.DOWNLOAD
        }, () => this.openDialogAction());
    };

    handleClickMultipleDownload = (rows) => {
        this.setState({
            downloadType: 'multiple',
            rowsSelected: rows,
            activeActionType: tableActionTypes.DOWNLOAD
        }, () => this.openDialogAction());
    };

    startSearch = () => {
        let filterFields = qs.stringify(this.state.pagination.filterFields);
        axios.get(BASE_URL + '/company/active-search', {
            params: {
                pattern: this.state.searchQuery,
                role: this.props.myUser.role,
                page: this.state.pagination.page,
                pageSize: this.state.pagination.pageSize,
                columnSorting: this.state.pagination.sorting.column,
                desc: this.state.pagination.sorting.desc,
                filterFields: filterFields
            },
            headers: authHeader(this.props.myUser)
        })
            .then(({ data }) => {
                this.updateSearchResult(data.results);
            });
    };

    updateSearchResult = (results) => {
        let newResults = cloneDeep(results);
        for (let row of newResults) {
            row['idrow'] = row.company_id;
        }
        this.setState({
            searchResult: newResults,
            searching: false
        });
    };

    handleInputSearchChange = (e) => {
        this.setState({
            searchQuery: e.target.value
        }, () => {
            if (this.state.searchQuery && this.state.searchQuery.length > 0) {
                this.setState({
                        searching: true
                    }, () => this.startSearch()
                );
            }
        });
    };

    handleOnSubmitFilterForm = (filterFields) => {
        const pagination = { ...this.state.pagination };
        pagination.filterFields = filterFields;
        pagination.page = 0;
        this.setState({
            pagination: pagination
        });
    };

    render() {
        return (
            <DashboardLayout title={this.props.myUser.role === 'admin' ? 'Manage Companies' : 'Companies'}>
                <Grid item xs={12}>
                    <CustomPaper style={{ padding: 15 }}>
                        <FilterFormBox
                            submit={this.handleOnSubmitFilterForm}
                            sectors={this.props.sectors}
                            subSectors={this.props.subSectors}
                            myUser={this.props.myUser}
                            fromManageCompanies
                            fromCompare={false}
                            initFields={this.state.pagination.filterFields}
                            subStates={this.props.subStates}
                        />
                    </CustomPaper>
                </Grid>
                <Spacer/>
                {this.props.fetchingCompaniesPreview ? <LinearProgress/> : null}
                <EnhancedTable
                    rows={(this.state.searchQuery.length > 0)
                        ? this.state.searchResult
                        : this.props.companiesPreview
                    }
                    headCells={getHeaderCellsCompanies(this.props.myUser.role)}
                    tableType={tableTypes.COMPANIES}

                    page={this.state.pagination.page}
                    onChangePageSize={this.handleChangePageSize}
                    onChangeSorting={this.handleChangeSorting}
                    onPageChange={this.handlePageChange}
                    numTotalRows={this.props.numCompanies}

                    myUser={this.props.myUser ? this.props.myUser : 'user'}

                    handleClickStatus={this.onClickStatus}
                    handleClickDelete={this.onClickDelete}
                    multipleDelete={this.handleMultipleDelete}

                    onUpdateSuccess={this.state.updateSuccess}

                    searching={this.state.searching}
                    handleInputSearchChange={this.handleInputSearchChange}

                    onClickRow={this.onCompanyRowClicked}

                    toolbarVisible
                    paginationVisible
                    checkable={INNER_TEAM.includes(this.props.myUser.role)}
                    sortable

                    handleAddEntry={this.handleAddCompany}
                    addEnabled
                    deleteEnabled

                    pagination={this.state.pagination}

                    downloadEnabled={can(this.props.myUser, DOWNLOAD_CSV)}
                    requestingDownloadCsv={this.props.requestingDownloadCsv}

                    handleClickMultipleDownload={this.handleClickMultipleDownload}
                    handleClickDownloadAll={this.handleClickDownloadAll}
                    searchVisible={false}
                />
                <ActionDialog
                    tableName={'company'}
                    actionType={this.state.activeActionType}
                    visible={this.state.dialogActionVisible}
                    onClose={this.closeActionDialog.bind(this)}
                    rowClicked={this.state.rowClicked}
                    updating={this.props.companyStatusUpdating}
                    onConfirm={() => this.handleConfirmAction()}
                    isMultiple={this.state.multipleAction}
                    downloadType={this.state.downloadType}
                />
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
            </DashboardLayout>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCompaniesPreview,
        updateCompanyStatus,
        downloadCsvCompany,
        clearCsvHandler,
        deleteCompany
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        companiesPreview: state.companyHandler.companiesPreview,
        fetchingCompaniesPreview: state.companyHandler.fetchingCompaniesPreview,
        numCompanies: state.companyHandler.numCompanies,

        companyStatusUpdating: state.companyHandler.companyStatusUpdating,
        companyStatusUpdateError: state.companyHandler.companyStatusUpdateError,

        sectors: state.mainHandler.sectors,
        subSectors: state.mainHandler.subSectors,
        countriesArray: state.mainHandler.countries,
        subStates: state.mainHandler.subStates,
        SDGs: state.mainHandler.SDGs,

        myUser: state.authHandler.myUser,

        pendingClaims: state.mainHandler.pendingClaims,
        activeClaims: state.mainHandler.activeClaims,

        requestingDownloadCsv: state.downloadCsvHandler.requestingDownloadCsv
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageCompanies);
