export const tabPositions = {
    'inner_team': {
        'startup': {
            'edit': {
                'profile': 0,
                'funding': 1,
                'socials': 2,
                'team': 3,
                'partnership': 4,
                'impact': 5,
                'financial': 6,
                'assessment': 7,
                // "kpi": 7,
                'docs': 8,
                'market': 9,
                'mentions': 10
            },
            'add': {
                'profile': 0,
                'funding': 1,
                'socials': 2,
                'team': 3,
                'partnership': 4,
                'impact': 5,
                'financial': 6,
                // "kpi": 7,
                'assessment': 7,
                'docs': 8,
                'market': 9,
                'mentions': 10
            },
            'show': {
                'profile': 0,
                'funding': 1,
                // "socials": 3,
                'team': 2,
                'partnership': 3,
                'impact': 4,
                'financial': 5,
                // "kpi": 6,
                'assessment': 6,
                'docs': 7,
                'market': 8,
                'mentions': 9
            }
        },
        'investor': {
            'edit': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'financial': 4,
                'assessment': 5,
                'docs': 6,
                'portfolio': 7,
                'mentions': 8
            },
            'add': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'financial': 4,
                'assessment': 5,
                'docs': 6,
                'portfolio': 7,
                'mentions': 8
            },
            'show': {
                'profile': 0,
                'team': 1,
                'impact': 2,
                'financial': 3,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            }
        },
        'other': {
            'edit': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'add': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'show': {
                'profile': 0,
                'team': 1,
                'impact': 2,
                'assessment': 3,
                'docs': 4,
                'portfolio': 5,
                'mentions': 6
            }
        }
    },
    'users': {
        'startup': {
            'edit': {
                'profile': 0,
                'funding': 1,
                'socials': 2,
                'team': 3,
                'partnership': 4,
                'impact': 5,
                // "financial": 6,
                // "kpi": 7,
                'assessment': 6,
                'docs': 7,
                'market': 8,
                'mentions': 9

            },
            'add': {
                'profile': 0,
                'funding': 1,
                'socials': 2,
                'team': 3,
                'partnership': 4,
                'impact': 5,
                // "financial": 6,
                // "kpi": 7,
                'assessment': 6,
                'docs': 7,
                'market': 8,
                'mentions': 9
            },
            'show': {
                'profile': 0,
                'funding': 1,
                // "socials": 3,
                'team': 2,
                'partnership': 3,
                'impact': 4,
                // "financial": 5,
                // "kpi": 6,
                'assessment': 5,
                'docs': 6,
                'market': 7,
                'mentions': 8
            }
        },
        'investor': {
            'edit': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                // "financial": 4,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'add': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                // "financial": 4,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'show': {
                'profile': 0,
                'team': 1,
                'impact': 2,
                // "financial": 3,
                'assessment': 3,
                'docs': 4,
                'portfolio': 5,
                'mentions': 6
            }
        },
        'other': {
            'edit': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'add': {
                'profile': 0,
                'socials': 1,
                'team': 2,
                'impact': 3,
                'assessment': 4,
                'docs': 5,
                'portfolio': 6,
                'mentions': 7
            },
            'show': {
                'profile': 0,
                'team': 1,
                'impact': 2,
                'assessment': 3,
                'docs': 4,
                'portfolio': 5,
                'mentions': 6
            }
        }
    }
};
