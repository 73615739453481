export const numberEmployeesTypes = [
    {
        id: 0,
        name: null,
        value: null
    },
    {
        id: 1,
        name: 'Self-employed',
        value: '0'
    },
    {
        id: 2,
        name: '1-10',
        value: '1'
    },
    {
        id: 3,
        name: '11-50',
        value: '11'
    },
    {
        id: 4,
        name: '51-200',
        value: '51'
    },
    {
        id: 5,
        name: '201-500',
        value: '201'
    },
    {
        id: 6,
        name: '501-1000',
        value: '501'
    },
    {
        id: 7,
        name: '1001-5000',
        value: '1001'
    },
    {
        id: 8,
        name: '501-1000',
        value: '501'
    },
    {
        id: 9,
        name: '5001-10,000',
        value: '5001'
    },
    {
        id: 10,
        name: '10,001+',
        value: '10001'
    }
];
