import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { Grid } from '@material-ui/core';
import PieChartBox from '../../components/Charts/PieChartBox';
import ColumnChartBox from '../../components/Charts/ColumnChartBox';
import CountryInvYearsMap from '../../components/Maps/CountryInvYearsMap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddIcon from '@material-ui/icons/Add';
import {
    clearStats,
    fetchStats,
    fetchStatsUnAuth
} from '../../actions/chartsHandler';
import GeneralStastBox from '../../components/GeneralStats/GeneralStatsBox';
import FilterFormBox from '../../components/FilterFormOverview/FilterFormBox';
import Spacer from '../../components/Spacer/Spacer';
import CompanyTableFiltered from '../../components/Table/Companies/CompanyTableFiltered';
import { setAuthbox } from '../../actions/authHandler';
import { actionTypes } from '../../helpers/actionTypes';
import history from '../../config/history';
import ButtonExtended from '../../components/UI/ButtonExtended/ButtonExtended';
import { fetchMainData, clickUpgrade } from '../../actions/mainHandler';
import TrendChartBox from '../../components/Charts/TrendChartBox/TrendChartBox';

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: 5,
            numTotalRows: 0,
            filter: null
        };
    }

    componentDidMount() {
        this.init();
        // window.location.reload(); // TODO
    }

    handleChangeSlider = () => {
        // TODO
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === false) {
            this.props.clearStats();
            this.init();
        }
        if (prevProps.iAmAuthenticated !== this.props.iAmAuthenticated && this.props.iAmAuthenticated === true) {
            // window.location.reload();
            this.init();
        }
    }

    init() {
        this.props.fetchMainData(this.props.myUser);
        if (this.props.myUser) {
            this.props.fetchStats(this.props.myUser);
        } else {
            this.props.fetchStatsUnAuth();
        }
    }

    handleOnSubmitFilterForm = (filterData) => {
        if (this.props.myUser && this.props.iAmAuthenticated) {
            this.props.fetchStats(this.props.myUser, filterData);
        } else {
            this.props.setAuthbox(true);
            this.props.setAuthbox(false);
        }
    };

    handleChangePageSize = (pageSize) => {
        this.setState({
            pageSize: pageSize,
            page: 0
        });
    };

    handlePageChange = (newPage) => {
        this.setState({
            page: newPage
        });
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps !== this.props) {
            return true;
        }
        return false;
    };

    onCompanyRowClicked = (company_id) => {
        if (!this.props.iAmAuthenticated) {
            this.props.setAuthbox(true);
        } else {
            if (this.props.activeClaims && this.props.activeClaims.includes(company_id)) {
                history.push({
                    pathname: '/company/' + actionTypes.EDIT + '/' + company_id
                });
            } else {
                history.push({
                    pathname: '/company/' + actionTypes.SHOW + '/' + company_id
                });
            }
        }
    };

    handleClickExport = () => {
        if (!this.props.iAmAuthenticated) {
            this.props.setAuthbox(true);
        }
    };

    handleAddCompany = () => {
        if (!this.props.iAmAuthenticated) {
            this.props.setAuthbox(true);
        } else {
            // if (this.props.myUser && this.props.myUser.role === 'admin') {
            if (this.props.myUser) {
                history.push({
                    pathname: '/company/' + actionTypes.ADD
                });
            }
        }
    };

    onSelectCompany = (company) => {
        if (company) {
            this.props.fetchStatsUnAuth(company);
        } else {
            this.init();
        }

    };

    render() {
        let {
            sectors,
            subSectors,
            SDGs
        } = this.props;

        return (
            <div>
                <DashboardLayout title="Home">
                    <FilterFormBox
                        submit={this.handleOnSubmitFilterForm}
                        sectors={sectors}
                        subSectors={subSectors}
                        SDGs={SDGs}
                        handleClickExport={this.handleClickExport}
                        selectCompany={this.onSelectCompany}
                        myUser={this.props.myUser}
                        subStates={this.props.subStates}
                    />
                    <Spacer small/>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sm={9} lg={9}>
                            <CountryInvYearsMap
                                data={this.props.statsCountries}
                                fetching={this.props.fetchingStatsSectors}
                                handleChangeSlider={this.handleChangeSlider}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <ButtonExtended
                                startIcon={<AddIcon/>}
                                variant="contained"
                                color='action'
                                style={{ 'text-transform': 'none', width: '100%' }}
                                onClick={this.handleAddCompany}
                            >
                                ADD COMPANY OR FUND
                            </ButtonExtended>
                            <Spacer small/>
                            <GeneralStastBox
                                generalStats={this.props.statsGeneral}
                            />
                        </Grid>
                        <Spacer small/>
                        <Grid xs={12}>
                            <CompanyTableFiltered
                                myUser={this.props.myUser}
                                fetching={this.props.fetchingStatsSectors}
                                rows={this.props.companyRows}
                                onClickRow={this.onCompanyRowClicked}
                                onClickUpgrade={this.props.clickUpgrade}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <ColumnChartBox
                                idChart={'column_2'}
                                title={'N° of STARTUPS'}
                                data={this.props.statsCountries}
                                categoryField={'country'}
                                valueField={'value'}
                                innerColor={'#8e9ada'}
                                maxColumns={10}
                                //fetching={this.props.fetchingStatsSectors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <PieChartBox
                                title={'SECTORS'}
                                idChart={'pie_1'}
                                data={this.props.statsSectors}
                                categoryField={'sector'}
                                valueField={'count'}
                                legend
                                legendAlignment={'left'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <ColumnChartBox
                                idChart={'column_3'}
                                title={'PRODUCTS'}
                                data={this.props.statsSubSectors}
                                categoryField={'sector'}
                                valueField={'count'}
                                innerColor={'#16bfa6'}
                                maxColumns={8}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <PieChartBox
                                title={'SUSTAINABLE DEVELOPMENT GOALS'}
                                idChart={'pie_2'}
                                data={this.props.statsSDGs}
                                categoryField={'sdg'}
                                valueField={'count'}
                                legend
                                legendAlignment={'right'}
                            />
                        </Grid>
                        <Grid item xs={15} sm={12} lg={12}>
                            <TrendChartBox
                                title={'MONTH-OVER-MONTH INVESTMENTS TRACKER'}
                                idChart={'trend_investments'}
                                data={this.props.aggregatedTrends}
                            />
                        </Grid>
                    </Grid>
                </DashboardLayout>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        clearStats,
        fetchStats,
        fetchStatsUnAuth,
        setAuthbox,
        fetchMainData,
        clickUpgrade
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        statsSectors: state.chartsHandler.statsSectors,
        statsSubSectors: state.chartsHandler.statsSubSectors,
        statsCountries: state.chartsHandler.statsCountries,
        statsSDGs: state.chartsHandler.statsSDGs,
        fetchingStatsSectors: state.chartsHandler.fetchingStatsSectors,
        statsGeneral: state.chartsHandler.statsGeneral,
        companyRows: state.chartsHandler.companyRows,
        aggregatedTrends: state.chartsHandler.aggregatedTrends,

        sectors: state.mainHandler.sectors,
        subSectors: state.mainHandler.subSectors,
        countriesArray: state.mainHandler.countries,
        SDGs: state.mainHandler.SDGs,
        subStates: state.mainHandler.subStates,

        myUser: state.authHandler.myUser,
        iAmAuthenticated: state.authHandler.iAmAuthenticated,
        iAmApproved: state.authHandler.iAmApproved,

        authBoxRequired: state.authHandler.authBoxRequired,

        pendingClaims: state.mainHandler.pendingClaims,
        activeClaims: state.mainHandler.activeClaims
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
