import React from 'react';
import CompanyProfileForm from '../../components/Company/CompanyProfileForm';
import CompanyInfoForm from '../../components/Company/CompanyInfoForm';
import AvatarBoxForm from '../../components/Company/CompanyAvatarForm';
import { Grid } from '@material-ui/core';
import Spacer from '../../components/Spacer/Spacer';
import ContactsBoxProfile from '../../components/ContactsBox/contactsBox';
import { can, DOWNLOAD_CSV } from '../../helpers/Roles/roleActions';


function ProfileCompany(props) {
    const {
        formDisabled,
        formikProps,
        companyData,
        sectors,
        subSectors,
        technologies,
        onRemoveClick,
        urlAvatarSaved,
        avatarFile,
        onAvatarLoaded,
        checkCompanyName,
        countriesArray,
        subStatesArray,
        nameCompanyAvailable,
        category,
        myUser,
        actionMode,

        handleSuggestUpdate,
        handleRequestManage,
        requestManageDisabled,
        pendingClaims,
        activeClaims,

        handleFollowCompany,
        companiesFollowed,
        disabledFollow,
        visitCounter,
        handleClickDownloadCsv,
        requestingDownloadCsv,
        companyInvestments
    } = props;

    return (
        <div>
            {/*<fieldset disabled={formDisabled} style={{ border: 'none' }}>*/}
            <fieldset style={{ border: 'none' }}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} lg={3}>
                        <AvatarBoxForm
                            name={companyData.company.name}
                            company_id={companyData.company.company_id}
                            editable={!formDisabled}
                            myUser={myUser}

                            urlAvatarSaved={urlAvatarSaved}
                            avatarFile={avatarFile}

                            onRemoveClick={onRemoveClick}
                            onAvatarLoaded={onAvatarLoaded}

                            handleSuggestUpdate={handleSuggestUpdate}
                            handleRequestManage={handleRequestManage}
                            handleFollowCompany={handleFollowCompany}

                            requestManageDisabled={requestManageDisabled}
                            pendingClaims={pendingClaims}
                            activeClaims={activeClaims}
                            companiesFollowed={companiesFollowed}
                            disabledFollow={disabledFollow}
                        />
                        <Spacer/>
                        <ContactsBoxProfile
                            email={companyData.company.email}
                            website={companyData.company.website}
                            socials={companyData.social}
                            myUser={myUser}
                        />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <CompanyProfileForm
                            formik={formikProps}
                            checkCompanyName={checkCompanyName}
                            nameCompanyAvailable={nameCompanyAvailable}
                            countriesArray={countriesArray}
                            subStatesArray={subStatesArray}
                            editable={!formDisabled}
                            myUser={myUser}
                            actionMode={actionMode}
                            visitCounter={visitCounter}
                            downloadEnabled={can(myUser, DOWNLOAD_CSV)}
                            handleClickDownloadCsv={handleClickDownloadCsv}
                            requestingDownloadCsv={requestingDownloadCsv}
                            companyInvestments={companyInvestments}
                        />
                        <Spacer/>

                        <CompanyInfoForm
                            formik={formikProps}
                            sectorsArray={sectors}
                            subSectorsArray={subSectors}
                            technologiesArray={technologies}
                            category={category}
                            editable={!formDisabled}
                            myUser={myUser}
                            actionMode={actionMode}
                        />
                    </Grid>
                </Grid>
            </fieldset>
        </div>
    );
}

export default ProfileCompany;
