import { getRandomId, getIndexColumn } from '../DBhelper';
import { parseNameLastname } from '../Parser';
import sectors from '../../data/ids/sectors';
import sub_sectors from '../../data/ids/sub_sectors';
import { CATEGORIES } from '../../models/company/company';
import { cloneDeep } from 'lodash';
import { impactObj } from '../../data/tableObjects';
import { getCountryCodeByName } from '../../data/location/utilsLocation';

export default () => {

    // var uuid = require("uuid");

    // --- Columns ----------------------------

    const personaFields = {
        role: ['role', '2nd exec role (co-founders) ', '3rd exec role', '4th exec role'],
        name: ['name', '2nd exec name', '3rd exec name', '4th exec name'],
        ethnicity: ['white, black, asian, middle eastern, latino'],
        gender: ['gender'],
        education: ['education (level; degree; univeristy)'],
        studied: ['studied abroad/ outside africa/mena ?'],
        email: ['contact email - strictly confidential'],
        linkedin: ['exec linkedin']
    };

    const companyFields = {
        active: ['active?'],
        name: ['company name'],
        description: ['description'],
        founding_date: ['founding date'],
        ownership_structure: ['Ownership Structure'],
        website: ['website'],
        email: ['generic email'],
        contact_number: ['contact number'],
        stage: ['Company Stage'],
        operating_status: ['Status']
    };

    const geographyFileds = {
        regions: ['region(s)'],
        country: ['country'],
        hq: ['hq'],
        city_id_code: ['city id code'],
        other_cities: ['other cities'],
        address: ['address'],
        ops_countries: ['Ops Countries']
    };

    const socialFields = {
        twitter: ['twitter'],
        facebook: ['facebook'],
        linkedin: ['LinkedIn'],
        instagram: ['Instagram']
    };

    const financialMetricFields = {
        // year: ??
        annual_revenue: ['annual revenues'],
        yoy_growth: ['yoy growth'],
        mom_growth: ['mom growth']
    };

    const operationalMetricFields = {
        users: ['annual revenues'],
        date: ['date'],
        reference: ['reference'],
        media_mentions: ['media mentions']
    };

    const startupFields = {
        whos_customer: ['who is the customer']
    };

    /*    const investmentFields = {
            amount: ['total funding $'],
            stage: ['investment stage'],
            type: ['last funding type'],
            deal_source: ['source']
        };*/

    /*    const investorFields = {
            investors: ['investors']
        };*/

    const sectorFields = {
        category: ['category'],
        first_sector: ['sector'],
        second_sector: ['sector 2'],
        subsector: ['subsector(s)']
    };

    // --- Functions ----------------------------

    const getSectorCompany = (sector_name, errors) => {
        let sector_company = { ...require('../../data/tableObjects').sector_companyObj };

        if (sectors[sector_name]) {
            sector_company.sector_id = sectors[sector_name];
            return sector_company;
        } else {
            return null;
        }
    };

    const getSubSectorsCompany = (sector_name_2, subSectorsNames, errors) => {
        let sub_sector_company_array = [];
        let sub_sector_company = { ...require('../../data/tableObjects').sub_sector_companyObj };

        if (sub_sectors[sector_name_2]) {
            sub_sector_company.sub_sector_id = sub_sectors[sector_name_2];
            sub_sector_company_array.push(sub_sector_company);
        }

        for (let i = 0; i < subSectorsNames.length; i++) {
            let sub_sector_company_temp = { ...require('../../data/tableObjects').sub_sector_companyObj };
            if (sub_sectors[subSectorsNames[i]]) {
                sub_sector_company_temp.sub_sector_id = sub_sectors[subSectorsNames[i]];
                sub_sector_company_array.push(sub_sector_company_temp);
            }
        }

        if (sub_sector_company_array.length > 0) {
            return sub_sector_company_array;
        } else {
            return null;
        }

    };

    // ------------------------ Sector ----------------------------

    const extractSectorsNames = (row, header, sectorIndexes) => {

        let category_name = row[getIndexColumn(sectorFields.category[0], header)];
        let sector_name = row[getIndexColumn(sectorFields.first_sector[0], header)];
        let sector_name_2 = row[getIndexColumn(sectorFields.second_sector[0], header)];

        let subSectorsNames = [];

        for (let i = 0; i < sectorIndexes.length; i++) {
            subSectorsNames.push(row[sectorIndexes[i]]);
        }

        // sector_name = sector_name.toLowerCase();
        // sector_name_2 = sector_name.toLowerCase();

        return [category_name, sector_name, sector_name_2, subSectorsNames];
    };

    // ------------------------ Geography ----------------------------

    const extractGeography = (row, header) => {
        let geography = { ...require('../../data/tableObjects').geographyObj };
        const separator = ',';

        geography.geography_id = getRandomId();

        // let geography_regions = [];

        const regionsString = row[getIndexColumn(geographyFileds.regions[0], header)];
        if (regionsString && regionsString !== '') {
            const regionsSplitted = regionsString.split(separator);
            for (let i = 0; i < regionsSplitted.length; i++) {
                // let geography_region = { ...require('../../data/tableObjects').geography_regionObj };
                // geography_region.geography_id = // TODO
                // geography_region.region_id = // TODO

                // geography_regions.push(regionsSplitted[i]); // TODO
            }
        }

        geography.hq_country = row[getIndexColumn(geographyFileds.country[0], header)];
        geography.hq_city = row[getIndexColumn(geographyFileds.hq[0], header)];
        // geography.city_id_code = row[getIndexColumn(geographyFileds.city_id_code[0], header)]; // TODO

        const citiesString = row[getIndexColumn(geographyFileds.other_cities[0], header)];
        if (citiesString && regionsString !== '') {
            let citiesArray = [];
            const citiesSplitted = citiesString.split(separator);
            for (let i = 0; i < citiesSplitted.length; i++) {
                citiesArray.push(citiesSplitted[i]);
            }
            // geography.other_cities = citiesArray; // TODO
        }

        geography.hq_address = row[getIndexColumn(geographyFileds.address[0], header)];

        return geography;
    };

    // ------------------------ People ----------------------------

    const getFirstRolePersonaIndex = (header) => {
        let index = getIndexColumn(personaFields.role[0], header);
        // console.log("Index first role: " + index);
        return index;
    };

    const extractPersona_Social = (row, role_index, header) => {
        const persona = { ...require('../../data/tableObjects').personaObj };
        const social = { ...require('../../data/tableObjects').socialObj };

        persona.persona_id = getRandomId();
        social.social_id = getRandomId();

        let isEmptyPersona = true;

        let namePersona = '';
        let lastnamePersona = '';

        for (var i = role_index; i < (role_index + 8); i++) {
            let tempValue = null;
            tempValue = row[i];

            if (tempValue !== '' && tempValue !== null) {
                isEmptyPersona = false;

                switch (header[i]) {
                    case personaFields.role[0]:
                    case personaFields.role[1]:
                    case personaFields.role[2]:
                    case personaFields.role[3]:
                        persona.role = tempValue;
                        break;
                    case personaFields.name[0]:
                    case personaFields.name[1]:
                    case personaFields.name[2]:
                    case personaFields.name[3]:
                        [namePersona, lastnamePersona] = parseNameLastname(tempValue);
                        persona.name = namePersona;
                        persona.lastname = lastnamePersona;
                        break;
                    case personaFields.ethnicity[0]:
                        persona.ethnicity = tempValue;
                        break;
                    case personaFields.gender[0]:
                        persona.gender = tempValue;
                        break;
                    case personaFields.education[0]:
                        if (tempValue) {
                            let educationFields = tempValue.split(';');
                            if(educationFields[0]) {
                                persona.level_education = educationFields[0].trim();
                            }
                            if(educationFields[1]) {
                                persona.degree = educationFields[1].trim();
                            }
                            if(educationFields[2]) {
                                persona.university = educationFields[2].trim();
                            }
                        }
                        break;
                    case personaFields.studied[0]:
                        // TODO
                        break;
                    case personaFields.email[0]:
                        persona.email = tempValue;
                        break;
                    case personaFields.linkedin[0]:
                        social.linkedin = tempValue;
                        break;
                    default:
                        break;
                }
            }
        }

        if (isEmptyPersona) {
            // console.log("Empty persona");
            return [null, null];
        } else {
            return [persona, social];
        }
    };

    const extractGeography_Countries_code = (row, header) => {
        let countries_code_array = [];
        let country_list_string = row[getIndexColumn(geographyFileds.ops_countries[0], header)];

        const separator = ',';

        const countries_splitted = country_list_string.split(separator);

        for (let i = 0; i < countries_splitted.length; i++) {
            let country_code = getCountryCodeByName(countries_splitted[i]);
            if(country_code) {
                countries_code_array.push(country_code.toString());
            }
        }
        return countries_code_array;
    };

    const extractPersonas_Socials = (row, header) => {
        const numPersonasToExtract = 4;
        const indexIncrementPersona = 8;

        let personas = [];
        let socials = [];

        let firstIndexPersonaRole = getFirstRolePersonaIndex(header);
        if (firstIndexPersonaRole === -1) {
            // console.log('No personas found'); // TODO
            return [null, null];
        }

        let indexToStart = firstIndexPersonaRole;

        for (var i = 0; i < numPersonasToExtract; i++) {
            let tempPersona = null;
            let tempSocial = null;
            [tempPersona, tempSocial] = extractPersona_Social(row, indexToStart, header);

            if (tempPersona !== null) {
                if (tempSocial !== null) {
                    tempPersona.social_id = tempSocial.social_id;
                    socials.push(tempSocial);
                }
                personas.push(tempPersona);
            }

            indexToStart = indexToStart + indexIncrementPersona;
        }

        // console.log("Num personas found: " + personas.length);
        return [personas, socials];
    };

    // ------------------------ Investors ----------------------------

    /**
     * get investors field and split in multiple strings
     *
     * separator: ","
     */

    /*    const extractInvestors = (row, header) => {
            let investorsArray = [];
            const separator = ',';

            const indexInvestors = getIndexColumn(investorFields.investors[0], header);
            if (indexInvestors === -1 || !indexInvestors) {
                // console.log('No investors found');
                return null;
            } else {
                const investmentsString = row[indexInvestors];
                const investorsSplitted = investmentsString.split(separator);

                for (var i = 0; i < investorsSplitted.length; i++) {
                    investorsArray.push(investorsSplitted[i]);
                }
            }

            return investorsArray;
        };*/

    // ------------------------ Social ----------------------------

    const extractSocial = (row, header) => {
        let social = { ...require('../../data/tableObjects').socialObj };
        social.social_id = getRandomId();
        social.twitter = row[getIndexColumn(socialFields.twitter[0], header)];
        social.facebook = row[getIndexColumn(socialFields.facebook[0], header)];
        social.linkedin = row[getIndexColumn(socialFields.linkedin[0], header)];
        social.instagram = row[getIndexColumn(socialFields.instagram[0], header)];
        return social;
    };

    // ------------------------ Financial Metric ----------------------------

    const extractFinancialMetric = (row, header) => {
        let financialMetric = { ...require('../../data/tableObjects').financialMetricObj };

        financialMetric.financial_metric_id = getRandomId();
        // financialMetric.year = parseInt(row[getIndexColumn(financialMetricFields.year[0], header)]);
        financialMetric.annual_revenue = row[getIndexColumn(financialMetricFields.annual_revenue[0], header)];
        financialMetric.yoy_growth = row[getIndexColumn(financialMetricFields.yoy_growth[0], header)];
        financialMetric.mom_growth = row[getIndexColumn(financialMetricFields.mom_growth[0], header)];
        // financialMetric.cac
        // financialMetric.ebitda

        return financialMetric;
    };

    // ------------------------ Operational Metric ----------------------------

    const extractOperationalMetric = (row, header) => {
        let operationalMetric = { ...require('../../data/tableObjects').operationalMetricObj };

        operationalMetric.operational_metric_id = getRandomId();
        // operationalMetric.date = row[getIndexColumn(operationalMetricFields.date[0], header)]; // TODO
        operationalMetric.users = parseInt(row[getIndexColumn(operationalMetricFields.users[0], header)]);
        operationalMetric.media_mention = row[getIndexColumn(operationalMetricFields.media_mentions[0], header)];
        // operationalMetric.social_followers = parseInt();

        return operationalMetric;
    };

    // ------------------------ Investment ----------------------------

    /*    const extractInvestment = (row, header) => {
            let investment = { ...require('../../data/tableObjects').investmentObj };
            return investment;
        };*/

    // ------------------------ Startup ----------------------------

    const extractStartup = (row, header) => {
        let startup = { ...require('../../data/tableObjects').startupObj };

        startup.business_model = row[getIndexColumn(startupFields.whos_customer[0], header)];

        return startup;
    };

    // ------------------------ Overview ----------------------------

    const extractCompany = (index_row, indexRowCsv, row, header, errors) => {
        const ACTIVE_TRUE = 'y';
        // const ACTIVE_FALSE = 'n';

        let company = { ...require('../../data/tableObjects').companyObj };

        // company.company_id = uuid.v4();
        company.company_id = getRandomId();
        company.impact_id = getRandomId();

        company.name = row[getIndexColumn(companyFields.name[0], header)];
        if (company.name === '') {
            errors.push('Row (' + indexRowCsv + ') - empty Overview name');
        }

        let isActive = row[getIndexColumn(companyFields.active[0], header)];
        // let isActiveBoolean = (isActive === ACTIVE_TRUE) ? true : false; // TODO check false or null
        let isActiveBoolean = true;
        company.active = isActiveBoolean;

        company.description = row[getIndexColumn(companyFields.description[0], header)];
        company.stage = row[getIndexColumn(companyFields.stage[0], header)];
        company.operating_status = row[getIndexColumn(companyFields.operating_status[0], header)];

        const founding_date = row[getIndexColumn(companyFields.founding_date[0], header)];
        if (founding_date && founding_date !== '') {
            const founding_date_int = parseInt(founding_date);
            // if year
            if (founding_date.length === 4 && 1900 < founding_date_int < 2100) {
                company.founding_date = founding_date + "-01-01 00:00:00"
            } else {
                errors.push('Row (' + index_row + ') - invalid Founding Date');
            }
        }

        company.website = row[getIndexColumn(companyFields.website[0], header)];
        company.contact_number = row[getIndexColumn(companyFields.contact_number[0], header)];
        company.email = row[getIndexColumn(companyFields.email[0], header)];
        company.ownership_structure = row[getIndexColumn(companyFields.ownership_structure[0], header)];

        // company.category = CATEGORIES.STARTUP.category_name;

        return company;
    };

    // --- Main ----------------------------

    /**
     * extractStartupTables(row)
     *
     * given a row, extract every tables to insert into the DB
     * returns rows for every table defined
     *
     * TABLES:
     *
     * - company
     * - social
     * - geography
     * - company_persona
     *
     **/

    const exctractTableStartup = (indexRow, indexRowCsv, row, header) => {
        let errors = [];

        let [personas_array, socials_array] = extractPersonas_Socials(row, header); // socials of personas
        let countries_code_array = extractGeography_Countries_code(row, header);
        //console.log(JSON.stringify(personas, null, 2))
        //console.log(JSON.stringify(socials, null, 2))
        let company = extractCompany(indexRow, indexRowCsv, row, header, errors);
        //console.log(JSON.stringify(company, null, 2))
        let social = extractSocial(row, header); // social of company
        socials_array.push(social);
        company.social_id = social.social_id;
        //console.log(JSON.stringify(social, null, 2))
        // let investors = extractInvestors(row, header); // TODO
        //console.log(JSON.stringify(investors, null, 2))
        let financial_metric = extractFinancialMetric(row, header);
        //console.log(JSON.stringify(financial_metric, null, 2))
        let operational_metric = extractOperationalMetric(row, header);
        //console.log(JSON.stringify(operational_metric, null, 2))
        let geography = extractGeography(row, header);
        //console.log(JSON.stringify(geography, null, 2))
        let startup = extractStartup(row, header);
        startup.company_id = company.company_id;

        let impact = cloneDeep(impactObj);
        impact.impact_id = company.impact_id;

        const subsectorsIndexes = [];
        for (let i = 0; i < header.length; i++) {
            if (header[i] === sectorFields.subsector[0]) {
                subsectorsIndexes.push(i);
            }
        }
        let [category_name, sector_name, sector_name_2, subSectorsNames] = extractSectorsNames(row, header, subsectorsIndexes);

        // if(category_name === "Startup" || category_name === "Company"){
        //     company.category = CATEGORIES.STARTUP.category_name;
        // }
        company.category = CATEGORIES.STARTUP.category_name;

        let sector_company = getSectorCompany(sector_name, errors);
        if (sector_company) sector_company.company_id = company.company_id;

        let sub_sectors_company = getSubSectorsCompany(sector_name_2, subSectorsNames, errors);

        // let investment = extractInvestment(row, header); // TODO

        company.geography_id = geography.geography_id;


        // -------- FOREIGN KEYS N-N ----------------------------

        let company_persona_array = [];
        for (let i = 0; i < personas_array.length; i++) {
            let company_persona = null;
            company_persona = { ...require('../../data/tableObjects').company_personaObj };
            company_persona.company_id = company.company_id;
            company_persona.persona_id = personas_array[i].persona_id;
            company_persona_array.push(company_persona);
        }

        let company_countries_array = [];
        for (let i = 0; i < countries_code_array.length; i++) {
            let geography_country = null;
            geography_country = { ...require('../../data/tableObjects').geography_countryObj };
            geography_country.geography_id = company.geography_id;
            geography_country.country_code = countries_code_array[i];
            company_countries_array.push(geography_country);
        }

        let company_financial = { ...require('../../data/tableObjects').company_financialObj };
        company_financial.company_id = company.company_id;
        company_financial.financial_id = financial_metric.financial_metric_id;

        let company_metric = { ...require('../../data/tableObjects').company_metricObj };
        company_metric.company_id = company.company_id;
        company_metric.operational_metric_id = operational_metric.operational_metric_id;

        // let geography_country = { ...require('../../data/tableObjects').geography_countryObj };
        // geography_country.geography_id = company.geography_id;
        // geography_country.country_code =

        //--------------------------------

        // aggregate tables
        let startups_tables = { ...require('../../data/tableObjects').startupTablesObj };
        startups_tables.indexRow = indexRow;
        startups_tables.indexRowCsv = indexRowCsv;

        startups_tables.company = company;
        startups_tables.personas = personas_array;
        startups_tables.socials = socials_array;
        startups_tables.financial_metric = financial_metric;
        startups_tables.operational_metric = operational_metric;
        startups_tables.geography = geography;
        startups_tables.startup = startup;
        startups_tables.impact = impact;

        startups_tables.company_persona = company_persona_array;
        startups_tables.company_countries = company_countries_array;
        startups_tables.company_financial = company_financial;
        startups_tables.company_metric = company_metric;
        if (sector_company) startups_tables.sector_company = sector_company;
        // TODO
        if (sub_sectors_company) {
            let sub_sectors_company_array = [];
            for (let i = 0; i < sub_sectors_company.length; i++) {
                let sub_sector_company = null;
                sub_sector_company = { ...require('../../data/tableObjects').sub_sector_companyObj };
                sub_sector_company.company_id = company.company_id;
                sub_sector_company.sub_sector_id = sub_sectors_company[i].sub_sector_id;
                sub_sectors_company_array.push(sub_sector_company);
            }
            startups_tables.sub_sector_company = sub_sectors_company_array;
        }

        // console.log(JSON.stringify(startups_tables, null, 2))

        return [errors, startups_tables];
    };

    /** NOTES
     *
     * Instead of console.log(my_object)
     * my_object dict() / Json
     *
     * use
     * console.log(JSON.stringify(my_object, null, 2))
     *
     * */


    return [exctractTableStartup];
};
