import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { tableActionTypes } from '../../helpers/actionTypes';
import { DIALOG_CONTENT_ACTIONS } from '../../strings/genericStrings';

const dialogTitle = (actionType, status) => {
    // console.log("STATUS", status);
    switch (actionType) {
        case tableActionTypes.DELETE:
            return 'DELETE ?';
        case tableActionTypes.DOWNLOAD:
            return 'DOWNLOAD';
        case tableActionTypes.STATUS:
            if (status) {
                return 'Set to REVIEW';
            } else {
                return 'ACTIVATE';
            }
        default:
            return 'DELETE ?';
    }
};

const dialogContent = (tableName, actionType, active, downloadType) => {
    switch (actionType) {
        case tableActionTypes.DELETE:
            return <Typography
                variant='body1'>{DIALOG_CONTENT_ACTIONS[tableName] ? DIALOG_CONTENT_ACTIONS[tableName].delete : null}</Typography>;
        case tableActionTypes.STATUS:
            if (active) {
                return <Typography
                    variant='body1'>{DIALOG_CONTENT_ACTIONS[tableName] ? DIALOG_CONTENT_ACTIONS[tableName].status.review : null}</Typography>;
            } else {
                return <Typography
                    variant='body1'>{DIALOG_CONTENT_ACTIONS[tableName] ? DIALOG_CONTENT_ACTIONS[tableName].status.activate : null}</Typography>;
            }
        case tableActionTypes.DOWNLOAD:
            return <Typography
                variant='body1'>{DIALOG_CONTENT_ACTIONS[tableName] ? DIALOG_CONTENT_ACTIONS[tableName]["download"][downloadType] : null}</Typography>;
        default:
            return <Typography
                variant='body1'>{DIALOG_CONTENT_ACTIONS[tableName] ? DIALOG_CONTENT_ACTIONS[tableName].delete : null}</Typography>;
    }
};

export default function ActionDialog(props) {
    let {
        tableName,
        actionType,
        visible,
        onClose,
        rowClicked,
        updating,
        onConfirm,
        downloadType
    } = props;

    return (
        <div>
            <Dialog open={visible}
                    onClose={onClose}
            >
                <DialogTitle
                    id="alert-dialog-title">{rowClicked && (dialogTitle(actionType, rowClicked.status) || dialogTitle(actionType, true))}
                </DialogTitle>
                <DialogContent
                    style={{ minWidth: '300px', minHeight: '60px' }}
                >
                    {/*{updating*/}
                    {/*    ? <CircularProgress/>*/}
                    {/*    : rowClicked && (dialogContent(tableName, actionType, rowClicked.status) || dialogContent(tableName, actionType, true))*/}
                    {/*}*/}
                    {dialogContent(tableName, actionType, (rowClicked && rowClicked.status) ? rowClicked.status : false, downloadType)}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={updating}
                        onClick={onClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={updating}
                        onClick={onConfirm}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
