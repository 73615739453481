import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    fetchPersonaData,
    fetchPersonaDataEmpty,
    updatePersona,
    createPersona, cleanPersona, removePersonaUrl
} from '../../actions/peopleHandler';
import ProfilePersona from './profilePersona';
import { actionTypes, isFormDisabled } from '../../helpers/actionTypes';
import * as Yup from 'yup';
import { Formik } from 'formik';
import history from '../../config/history';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import SimpleSnackbar from '../../components/Feedback/SimpleSnackbar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Spacer from '../../components/Spacer/Spacer';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

class Persona extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actionMode: actionTypes.SHOW,
            snackVisible: false,
            snackbarVariant: '',
            dialogOpen: false,
            titleDialog: '',
            contentDialog: '',
            isNewSocial: false,

            avatarLoaded: null
        };

        this.props.cleanPersona();
    }


    componentDidMount() {
        const actionType = this.props.match.params.actionType;
        const persona_id = this.props.match.params.persona_id;

        this.setState({
            ...this.state,
            actionMode: actionType
        });

        switch (actionType) {
            case actionTypes.SHOW:
                this.props.fetchPersonaData(persona_id, this.props.myUser);
                break;
            case actionTypes.ADD:
                this.props.fetchPersonaDataEmpty();
                break;
            case actionTypes.EDIT:
                this.props.fetchPersonaData(persona_id, this.props.myUser);
                break;
            default:
                break;
        }
    }

    handleClickOkDialog() {
        history.push({
            pathname: '/managepeople'
        });
    }

    handleCloseSnack() {
        this.setState({
            ...this.state,
            snackVisible: false
        });
    }

    openSnack = (variant) => {
        this.setState({
            ...this.state,
            snackbarVariant: variant,
            snackVisible: true
        });
    };

    openDialogCreation(success) {
        if (success) {
            this.setState({
                ...this.state,
                dialogOpen: true,
                titleDialog: 'People created with success!'
            });
        }
    }

    submitForm = (values, actions) => {
        switch (this.state.actionMode) {
            case actionTypes.ADD:
                this.props.createPersona(
                    this.props.personaData,
                    values,
                    this.props.myUser,
                    this.state.avatarLoaded);
                break;
            case actionTypes.EDIT:
                this.props.updatePersona(
                    this.props.personaData,
                    values,
                    this.props.myUser,
                    this.state.isNewSocial,
                    this.state.avatarLoaded
                );
                break;
            default:
                break;
        }
    };

    handleRemoveAvatarClick = () => {
        this.setState({
            avatarLoaded: null
        }, () => {
            if (this.props.personaData.persona.avatar_url) {
                this.props.removePersonaUrl();
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.personaUpdating && !this.props.personaUpdating) {
            let variant = 'info';

            switch (this.state.actionMode) {
                case actionTypes.ADD:
                    this.openDialogCreation(true);
                    break;
                case actionTypes.EDIT:
                    if (!this.props.personaUpdateError) {
                        variant = 'success';
                    } else {
                        variant = 'error';
                    }
                    this.openSnack(variant);
                    break;
                default:
                    break;
            }
        }
    }

    handleAvatarLoaded = (file) => {
        this.setState({
            avatarLoaded: file
        });
    };

    render() {

        let {
            personaData,
            personaUpdating
        } = this.props;

        return (
            <DashboardLayout title="Team Member">
                {personaData
                    ?
                    <div>
                        <AppBar position="static"
                                color="inherit"
                                style={{
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    borderBottom: 'solid 1px #ddd'
                                }}
                        >
                            <Toolbar>
                                <div style={{ flexGrow: 1 }}/>
                                {this.state.actionMode !== actionTypes.SHOW ?
                                    <Button
                                        variant="contained"
                                        disabled={false}
                                        form="general-persona-form"
                                        type="submit"
                                        value="Submit"
                                        color="primary"
                                        style={{ backgroundColor: '#45B880' }}
                                    >
                                        {this.state.actionMode === actionTypes.ADD
                                            ? 'CREATE PERSONA'
                                            : 'SAVE PERSONA'
                                        }
                                    </Button> : null
                                }
                            </Toolbar>
                            {personaUpdating ? <LinearProgress/> : null}
                        </AppBar>
                        <Spacer/>
                        <TabPanel value={0} index={0}>
                            <Formik
                                enableReinitialize

                                initialValues={{
                                    persona: {
                                        name: personaData.persona.name,
                                        lastname: personaData.persona.lastname,
                                        role: personaData.persona.role,
                                        email: personaData.persona.email,
                                        ethnicity: personaData.persona.ethnicity,
                                        gender: personaData.persona.gender,
                                        companies: personaData.persona.companies
                                    },
                                    social: {
                                        facebook: personaData.social.facebook,
                                        linkedin: personaData.social.linkedin,
                                        twitter: personaData.social.twitter,
                                        instagram: personaData.social.instagram
                                    }
                                }}

                                onSubmit={this.submitForm}

                                validationSchema={Yup.object().shape({
                                    persona: Yup.object().shape({
                                        name: Yup.string()
                                            .required('Required').nullable(),
                                        lastname: Yup.string()
                                            .required('Required').nullable(),
                                        email: Yup.string()
                                            .email('must be a valid email')
                                            .nullable()
                                    })
                                })}
                            >
                                {formikProps => (
                                    <form id="general-persona-form"
                                          onKeyDown={onKeyDown}
                                          onSubmit={formikProps.handleSubmit}
                                    >
                                        <ProfilePersona
                                            formDisabled={isFormDisabled(this.state.actionMode)}
                                            formikProps={formikProps}
                                            personaData={personaData}
                                            editable={this.state.actionMode === actionTypes.EDIT}
                                            myUser={this.props.myUser}

                                            onRemoveClick={this.handleRemoveAvatarClick}
                                            urlAvatarSaved={personaData.persona.avatar_url}
                                            avatarFile={this.state.avatarLoaded}
                                            onAvatarLoaded={this.handleAvatarLoaded}
                                        />
                                    </form>
                                )}
                            </Formik>
                        </TabPanel>
                    </div>
                    : <LinearProgress/>
                }
                <SimpleSnackbar
                    open={this.state.snackVisible}
                    handleClose={this.handleCloseSnack.bind(this)}
                    variant={this.state.snackbarVariant}
                />
                <AlertDialog
                    openDialog={this.state.dialogOpen}
                    title={this.state.titleDialog}
                    content={this.state.contentDialog}
                    clicked={this.handleClickOkDialog.bind(this)}
                />
            </DashboardLayout>
        );
    }
}

const mapDispatchToProps = {
    fetchPersonaData,
    fetchPersonaDataEmpty,
    updatePersona,
    createPersona,
    cleanPersona,
    removePersonaUrl
};

const mapStateToProps = (state) => {
    return {
        persona_id: state.peopleHandler.persona_id,
        personaData: state.peopleHandler.personaData,

        personaUpdating: state.peopleHandler.personaUpdating,
        personaUpdateError: state.peopleHandler.personaUpdateError,

        myUser: state.authHandler.myUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Persona);
