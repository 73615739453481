export const operatingStatusList = [
    {
        id: 0,
        name: null,
        value: null,
    },
    {
        id: 1,
        name: 'Active',
        value: 'y'
    },
    {
        id: 2,
        name: 'Under Review',
        value: 'c'
    },
    {
        id: 3,
        name: 'Shut Down',
        value: 'n'
    }
];
