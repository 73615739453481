export const getFakeFinancialMetrics = () => {
    let financials = {
        2020: {
            financial_metric_id: null,
            year: 2020,
            annual_revenue: 0,
            yoy_growth: 0,
            mom_growth: 0,
            cac: 0,
            ebitda: 0
        },
        2019: {
            financial_metric_id: null,
            year: 2019,
            annual_revenue: 0,
            yoy_growth: 0,
            mom_growth: 0,
            cac: 0,
            ebitda: 0
        },
        2018: {
            financial_metric_id: null,
            year: 2018,
            annual_revenue: 0,
            yoy_growth: 0,
            mom_growth: 0,
            cac: 0,
            ebitda: 0
        },
        2017: {
            financial_metric_id: null,
            year: 2017,
            annual_revenue: 0,
            yoy_growth: 0,
            mom_growth: 0,
            cac: 0,
            ebitda: 0
        }
    };
    return financials;
};

export const getFakeOperationalMetrics = () => {
    let operationals = {
        2020: {
            financial_metric_id: null,
            year: 2020,
            annual_revenue: 0,
            yoy_growth: 0,
            mom_growth: 0,
            cac: 0,
            ebitda: 0
        }
    };
    return operationals;
};

