export const capitalSoughtTypes = [
    {
        id: 5,
        name: 'Bank Loan',
        value: 5
    },
    {
        id: 4,
        name: 'Convertible',
        value: 4
    },
    {
        id: 2,
        name: 'Debt',
        value: 2
    },
    {
        id: 1,
        name: 'Equity',
        value: 1
    },
    {
        id: 6,
        name: 'Equity-Free',
        value: 6
    },
    {
        id: 3,
        name: 'Grant',
        value: 3
    },
    {
        id: 7,
        name: 'Other',
        value: 7
    }
];
