import {
    PEOPLE_PREVIEW_FETCH,
    PEOPLE_PREVIEW_FETCH_SUCCESS,
    PEOPLE_PREVIEW_FETCH_ERROR,

    PERSONA_COUNT_FETCH,
    PERSONA_COUNT_FETCH_SUCCESS,
    PERSONA_COUNT_FETCH_ERROR,

    PERSONA_DATA_FETCH,
    PERSONA_DATA_FETCH_SUCCESS,
    PERSONA_DATA_FETCH_ERROR,

    PERSONA_UPDATE,
    PERSONA_UPDATE_SUCCESS,
    PERSONA_UPDATE_ERROR,

    PERSONA_CREATE,
    PERSONA_CREATE_SUCCESS,
    PERSONA_CREATE_ERROR, CLEAN_PERSONA,

    DELETE_PERSONA,
    DELETE_PERSONA_SUCCESS,
    DELETE_PERSONA_ERROR, REMOVE_PERSONA_URL
} from '../actions/peopleHandler';
import { REMOVE_COMPANY_URL } from '../actions/companyHandler';
import { cloneDeep } from 'lodash';

const initialState = {
    peoplePreview: [],
    fetchingPeoplePreview: false,
    peoplePreviewError: null,

    numPersona: 0,
    fetchingPersonaCount: false,
    personaCountError: null,

    personaData: null,
    persona_id: null,
    fetchingPersonaData: false,
    personaDataError: null,

    personaUpdating: false,
    personaUpdateError: null
};

export default function peopleHandler(state = initialState, action) {
    switch (action.type) {
        case CLEAN_PERSONA:
            return initialState;

        case REMOVE_PERSONA_URL:
            let newPersonaDataNoUrl = cloneDeep(state.personaData);
            newPersonaDataNoUrl.persona.avatar_url = null;
            return {
                ...state,
                personaData: newPersonaDataNoUrl
            };

        case PEOPLE_PREVIEW_FETCH:
            return {
                ...state,
                peoplePreview: [],
                fetchingPeoplePreview: true,
                peoplePreviewError: null
            };
        case PEOPLE_PREVIEW_FETCH_SUCCESS:
            const peoplePreviewRows = action.payload;
            for (let row of peoplePreviewRows) {
                row['idrow'] = row.persona_id;
            }
            return {
                ...state,
                peoplePreview: peoplePreviewRows,
                fetchingPeoplePreview: false
            };
        case PEOPLE_PREVIEW_FETCH_ERROR:
            return {
                ...state,
                fetchingPeoplePreview: false,
                peoplePreviewError: action.payload
            };

        case PERSONA_COUNT_FETCH:
            return {
                ...state,
                numPersona: 0,
                fetchingPersonaCount: true,
                personaCountError: null
            };
        case PERSONA_COUNT_FETCH_SUCCESS:
            return {
                ...state,
                numPersona: action.payload,
                fetchingPersonaCount: false
            };
        case PERSONA_COUNT_FETCH_ERROR:
            return {
                ...state,
                fetchingPersonaCount: false,
                personaCountError: action.payload
            };


        case PERSONA_DATA_FETCH:
            return {
                ...state,
                personaData: null,
                fetchingPersonaData: true,
                personaDataError: null
            };
        case PERSONA_DATA_FETCH_SUCCESS:
            let newPersonaData = action.payload;
            return {
                ...state,
                personaData: newPersonaData,
                fetchingPersonaData: false
            };
        case PERSONA_DATA_FETCH_ERROR:
            return {
                ...state,
                fetchingPersonaData: false,
                personaDataError: action.payload
            };


        case PERSONA_UPDATE:
            return {
                ...state,
                personaUpdating: true,
                personaUpdateError: null
            };
        case PERSONA_UPDATE_SUCCESS:
            //let personaUpdated = cloneDeep(action.payload.personaUpdated);
            return {
                ...state,
                //personaData: personaUpdated,
                personaUpdating: false
            };
        case PERSONA_UPDATE_ERROR:
            return {
                ...state,
                personaUpdating: false,
                personaUpdateError: action.payload
            };


        case PERSONA_CREATE:
            return {
                ...state,
                personaUpdating: true,
                personaUpdateError: null
            };
        case PERSONA_CREATE_SUCCESS:
            return {
                ...state,
                personaUpdating: false
            };
        case PERSONA_CREATE_ERROR:
            return {
                ...state,
                personaUpdating: false,
                personaUpdateError: action.payload
            };

        case DELETE_PERSONA:
            return {
                ...state,
                personaUpdating: true,
                personaUpdateError: null
            };
        case DELETE_PERSONA_SUCCESS:
            // persona_id = action.payload.persona_id;
            return {
                ...state,
                personaUpdating: false,
                personaUpdateError: null
            };
        case DELETE_PERSONA_ERROR:
            return {
                ...state,
                personaUpdating: false,
                personaUpdateError: action.payload
            };

        default:
            return state;
    }
}
