import React from 'react';
import classNames from 'classnames';
import { withStyles, Grid, Typography } from '@material-ui/core';
import {
    Paper,
    PortletHeader,
    PortletLabel,
    PortletContent
} from '../../index';

import styles from '../styles';
import EnhancedTable from '../../Table/EnhanceTable';
import { tableTypes } from '../../Table/rowSelector';
import { getHeaderCells, getHeaderCellsCompanies, getHeaderCellsDealsForm } from '../../../models/headerCells';
import { can, DOWNLOAD_CSV, INNER_TEAM } from '../../../helpers/Roles/roleActions';
import { DEAL_FORM } from '../../../models/emptyTable';
import history from '../../../config/history';
import { actionTypes } from '../../../helpers/actionTypes';

const DealsTable = (props) => {
    const {
        classes, className,
        deals,
        actionMode, myUser, ...rest
    } = props;
    const rootClassName = classNames(props.classes.root, props.className);

    const onCompanyRowClicked = (company_id) => {
        // console.log('*** onCompanyRowClicked', company_id);
    };

    return (
        <Paper
            {...rest}
            className={rootClassName}
            elevation={1}
        >
            <PortletHeader>
                <PortletLabel
                    title="Deals"
                />
            </PortletHeader>
            <PortletContent>
                <Grid
                    container
                    className={classes.form_container}
                >
                    <EnhancedTable
                        rows={deals && deals.length > 0 ? deals : []}
                        headCells={getHeaderCells(DEAL_FORM)}
                        tableType={tableTypes.DEAL_ROW}

                        myUser={myUser ? myUser : 'user'}

                        onClickRow={onCompanyRowClicked}
                        onClickInvestor={onCompanyRowClicked}

                        toolbarVisible={false}
                        paginationVisible={false}
                        sortable={false}
                        checkable={false}
                    />

                </Grid>
                {(!deals || deals && deals.length === 0) ?
                    <Typography variant="h5">
                        No available funding data
                    </Typography>
                    : null}
            </PortletContent>
        </Paper>

    );
};

export default withStyles(styles)(DealsTable);
