export const ROLES = [
    {
        id: 0,
        name: 'Admin',
        value: 'admin'
    },
    {
        id: 1,
        name: 'Team Collaborator',
        value: 'collaborator'
    },
    {
        id: 2,
        name: 'External Collaborator',
        value: 'external_collaborator'
    },
    {
        id: 3,
        name: 'Paying User',
        value: 'paying_user'
    },
    {
        id: 4,
        name: 'User',
        value: 'user'
    }
];

export const DASHBOARD = 'dashboard';
export const SIDEBAR_MANAGE = 'sidebarManage';
export const EDIT_ROW = 'edit_row';
export const SHOW_STATUS = 'show_status';
export const DELETE_ROW = 'delete_row';
export const ADD_PERSON_TEAM = 'add_person_team';
export const ADD_PARTNERSHIP = 'add_partnership';
export const DELETE_PARTNERSHIP = 'delete_partnership';
export const DELETE_ACCELERATOR = 'delete_accelerator';
export const ADD_PORTFOLIO = 'add_portfolio';
export const DELETE_PORTFOLIO = 'delete_portfolio';
export const DELETE_PERSON_TEAM = 'delete_person_team';
export const DELETE_DOCUMENT = 'delete_document';
export const EDIT_COMPANY = 'edit_company';
export const DOWNLOAD_CSV = 'download_csv';
export const ASSESSMENT_TOOLKIT = 'assessment_toolkit';
export const SEE_COMPANIES = 'see_companies';

export const ALL = ['admin', 'collaborator', 'external_collaborator', 'paying_user', 'user'];
export const INNER_TEAM = ['admin', 'collaborator'];
export const TEAM = ['admin', 'collaborator', 'external_collaborator'];
export const PAYING_USER = 'paying_user';
export const USERS = [PAYING_USER, 'user'];


export const ACTIONS = {
    [DASHBOARD]: ALL,
    [SIDEBAR_MANAGE]: TEAM,
    [EDIT_ROW]: INNER_TEAM,
    [DELETE_ROW]: ['admin'],
    [ADD_PERSON_TEAM]: ALL,
    [ADD_PARTNERSHIP]: ALL,
    [DELETE_PARTNERSHIP]: ALL,
    [DELETE_ACCELERATOR]: ALL,
    [ADD_PORTFOLIO]: ALL,
    [DELETE_PORTFOLIO]: ALL,
    [DELETE_PERSON_TEAM]: ALL,
    [EDIT_COMPANY]: TEAM,
    [DELETE_DOCUMENT]: TEAM,
    [DOWNLOAD_CSV]: ['admin'],
    [ASSESSMENT_TOOLKIT]: TEAM,
    [SEE_COMPANIES]: ALL,
};

export const can = (user, action) => {
    if (user && action) {
        return !!ACTIONS[action].includes(user.role);
    } else {
        return false;
    }
};
