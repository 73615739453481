import React from 'react';
import { Typography } from '@material-ui/core';
import Spacer from '../../components/Spacer/Spacer';
import { IndentedElement } from './indentedElement';


const BodyTerms = () => {
    return (
        <div>
            <Typography
                variant="h3"
                align={'center'}
            >
                OUR ONLINE STANDARD TERMS AND CONDITIONS <br/> FOR BUSINESS CUSTOMERS
            </Typography>
            <Spacer/>
            <Typography
                variant="body1"
                style={{ align: 'justify' }}
            >
                <div>
                    <br/>
                    These Conditions, together with any and all other documents referred to herein,
                    set out the
                    terms on which we provide our business intelligence platform (“Services”) to
                    registered account
                    holders
                    through this site. Please read these Conditions carefully and ensure that you
                    understand them
                    before
                    subscribing with us. If you do not agree to comply with and be bound by these
                    Conditions, you
                    will
                    not be able to subscribe for our Services.
                    <br/><br/>
                    The site is owned and operated by Briter Bridges Ltd, registered number
                    11653314, (the
                    “Supplier”)
                    whose registered office is at Chaddesley Sanford, 3rd Floor, 3, Fitzhardinge
                    Street, London,
                    England, W1H 6EF.
                    <br/><br/>
                    All personal information that we may collect from you will be collected, used
                    and held in
                    accordance
                    with your rights under data protection legislation.
                    <br/><br/>

                </div>

                <div>
                    <a href="https://docs.google.com/document/d/1Me7XTvu3GGHB4djvf3v1XFWrEpL2j5rNOK7CRoESWu0/edit?usp=sharing">SEE
                        ALL THE TERMS</a>
                </div>
            </Typography>

            <IndentedElement number={'1'}>Interpretation</IndentedElement>
            <IndentedElement number={'1.1'}>In these Conditions, the following definitions apply:</IndentedElement>
            <IndentedElement number={'1.1.1'}>Business Day: a day (other than a Saturday, Sunday or public holiday) when
                banks in London are open for business.
            </IndentedElement>
            <IndentedElement number={'1.1.2'}>Charges: the charges payable by the Customer for the purchase of our
                Premium Subscription in accordance with clause 5 (“Charges and Payment”) below.
            </IndentedElement>
            <IndentedElement number={'1.1.3'}>Conditions: the terms and conditions set out in this document as amended
                from time to time in accordance with the Conditions.
            </IndentedElement>
            <IndentedElement number={'1.1.4'}>Customer: the person or firm who purchases a subscription on behalf of
                their company from the Supplier.
            </IndentedElement>
            <IndentedElement number={'1.1.5'}>Intellectual Property Rights: patents, rights to inventions, copyright and
                related rights, trade marks, business names and domain names, rights in get-up, goodwill and the right
                to sue for passing off, rights in designs, database rights, rights to use, and protect the
                confidentiality of, confidential information (including know-how), and all other intellectual property
                rights, in each case whether registered or unregistered and including all applications and rights to
                apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and
                all similar or equivalent rights or forms of protection which subsist or will subsist now or in the
                future in any part of the world.
            </IndentedElement>
            <IndentedElement number={'1.1.6'}>Premium Subscription: an annual or monthly paid subscription to use the
                Services.
            </IndentedElement>
            <IndentedElement number={'1.1.7'}>Services: a business intelligence platform whereby business Customers have
                access to other company’s information made available on the platform.
            </IndentedElement>
            <IndentedElement number={'1.1.8'}>Subscription: a free annual subscription to use the Services with limited
                access.
            </IndentedElement>
            <IndentedElement number={'1.19'}>
                Site: the website at https://briterintelligence.com.
            </IndentedElement>
            <IndentedElement number={'1.2'}>Construction. In these Conditions, the following rules apply:
            </IndentedElement>
            <IndentedElement number={'1.2.1'}>a person includes a natural person, corporate or unincorporated body
                (whether or not having separate legal personality);
            </IndentedElement>
            <IndentedElement number={'1.2.2'}>a reference to a party includes its personal representatives, successors
                and permitted assigns;
            </IndentedElement>
            <IndentedElement number={'1.2.3'}>a reference to a statute or statutory provision is a reference to such
                statute or statutory provision as amended or re-enacted includes any subordinate legislation made under
                that statute or statutory provision as amended or re-enacted;
            </IndentedElement>
            <IndentedElement number={'1.2.4'}>any obligation on a party not to do something includes an obligation not
                to allow that thing to be done;
            </IndentedElement>
            <IndentedElement number={'1.2.5'}>any phrase introduced by the terms including, include, in particular or
                any similar expression, shall be construed as illustrative and shall not limit the sense of the words
                preceding those terms; and
            </IndentedElement>
            <IndentedElement number={'1.2.6'}>a reference to writing or written includes faxes and emails.
            </IndentedElement>
            <IndentedElement number={'2'}>Basis of contract</IndentedElement>
            <IndentedElement number={'2.1'}>Customers may only register for or purchase a Subscription or Premium
                Subscription through the Site and via email, social media login, Linkedin or Gmail if they are at least
                18 years of age.
            </IndentedElement>
            <IndentedElement number={'2.2'}>The Website will guide the Customer through the registration process. The
                registration of an account constitutes an offer by the Customer to have access to and/or purchase
                Services in accordance with these Conditions.
            </IndentedElement>
            <IndentedElement number={'2.3'}>If you choose, or you are provided with, a user identification code,
                password or any other piece of information as part of our security procedures, you must treat such
                information as confidential. You must not disclose it to any third party.
            </IndentedElement>
            <IndentedElement number={'2.4'}>We have the right to disable any user identification code or password,
                whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to
                comply with any of the provisions of these terms of use.
            </IndentedElement>
            <IndentedElement number={'2.5'}>If you know or suspect that anyone other than you knows your user
                identification code or password, you must promptly notify us at support@briterbridges.com.
            </IndentedElement>
            <IndentedElement number={'2.6'}>Registration of a Subscription shall be deemed accepted upon users being
                given limited access to the Services. The registration of a Premium Subscription account shall only be
                deemed to be accepted when the Supplier issues an email confirmation of the Premium Subscription once
                payment has been made in accordance with the Charges clause. Premium Subscription confirmations will
                include details of the Premium Subscription, including its duration (including the start date and
                renewal/expiry date), price (if relevant) and full details of the main characteristics of the Services
                available as part of it.
            </IndentedElement>
            <IndentedElement number={'2.7'}>The Contract constitutes the entire agreement between the parties in
                relation to its subject matter. The Customer acknowledges that it has not relied on any statement,
                promise, representation, assurance or warranty made or given by or on behalf of the Supplier which is
                not set out in the Contract.
            </IndentedElement>
            <IndentedElement number={'2.8'}>Any samples, drawings, descriptive matter or advertising issued by the
                Supplier, and any descriptions or illustrations contained in the Supplier's promotional materials, are
                issued or published for the sole purpose of giving an approximate idea of the Services described in
                them. They shall not form part of the Contract or have any contractual force.
            </IndentedElement>
            <IndentedElement number={'2.9'}>
                These Conditions apply to the Contract to the exclusion of any other terms that the Customer seeks to
                impose or incorporate, or which are implied by trade, custom, practice or course of dealing.
            </IndentedElement>
            <IndentedElement number={'3'}>Services and Subscriptions</IndentedElement>
            <IndentedElement number={'3.1'}>The Supplier shall supply the Services and either the Subscription or
                Premium Subscription to the Customer in accordance with these Conditions in all material respects.
            </IndentedElement>
            <IndentedElement number={'3.2'}>The Supplier shall use all reasonable endeavours to meet any performance
                dates, but any such dates shall be estimates only and time shall not be of the essence.
            </IndentedElement>
            <IndentedElement number={'3.3'}>The Supplier shall have the right to make any changes to the Services
                and/or either Subscription or Premium Subscription which are necessary to comply with any applicable law
                or safety requirement, or which do not materially affect the nature or quality of the Services, and the
                Supplier shall make reasonable endeavours to notify the Customer in any such event.
            </IndentedElement>
            <IndentedElement number={'3.4'}>The Customer may have the option to make available certain information of
                their company on the platform and share this information with the other customers and Site users.
            </IndentedElement>
            <IndentedElement number={'3.5'}>The Supplier warrants to the Customer that the Services and Subscription
                will be provided using reasonable care and skill.
            </IndentedElement>
            <IndentedElement number={'4'}>Customer's obligations</IndentedElement>
            <IndentedElement number={'4.1'}>The Customer shall:</IndentedElement>
            <IndentedElement number={'4.1.1'}>ensure that it shall comply with the terms of the Services, the
                Subscription or Premium Subscription and any reasonable instruction provided by the Supplier;
            </IndentedElement>
            <IndentedElement number={'4.1.2'}>co-operate with the Supplier in all matters relating to the Services and
                Subscription;
            </IndentedElement>
            <IndentedElement number={'4.1.3'}>provide the Supplier with such information and materials as the Supplier
                may reasonably require in order to supply the Services and Subscription or Premium Subscription, and
                ensure that such information is accurate and kept up to date in all material respects; and
            </IndentedElement>
            <IndentedElement number={'4.1.4'}>obtain and maintain all necessary licences, permissions and consents which
                may be required before the date on which the Services or Subscription or Premium Subscription starts.
            </IndentedElement>
            <IndentedElement number={'4.2'}>The Customer shall not:</IndentedElement>
            <IndentedElement number={'4.2.1'}>“crawl,” “scrape,” or “spider” any aspect of the Services or to reverse
                engineer or attempt to obtain the source code of the Services;
            </IndentedElement>
            <IndentedElement number={'4.2.2'}>
                misuse the Services by knowingly introducing any material which is malicious or technologically harmful:
            </IndentedElement>
            <IndentedElement number={'4.2.3'}>
                attempt to gain unauthorised access to the Services, the server on which any of the Services are stored
                or any server, computer or database connected to the Services;
            </IndentedElement>
            <IndentedElement number={'4.2.4'}>
                attack the Services via a denial-of-service attack or a distributed denial-of service attack;
            </IndentedElement>
            <IndentedElement number={'4.2.5'}>
                rent, distribute, publish copy, lease, sub-license, loan, translate, merge, adapt, vary, alter or
                modify, the whole or any part of the Services;
            </IndentedElement>
            <IndentedElement number={'4.2.6'}>
                share their account and/or grant access to the Services to any other party.
            </IndentedElement>
            <IndentedElement number={'4.3'}>By failing to comply with the provisions in clause 4.2, the Customer would
                commit a criminal offence and the Customers right to use the Services will cease immediately. The
                Supplier and we will report your actions to the relevant authorities.
            </IndentedElement>
            <IndentedElement number={'4.4'}>If the Supplier's performance of any of its obligations under the Contract
                is prevented or delayed by any act or omission by the Customer or failure by the Customer to perform any
                relevant obligation (“Customer Default”):
            </IndentedElement>
            <IndentedElement number={'4.4.1'}>the Supplier shall, without limiting its other rights or remedies, have
                the right to suspend the Services and the Subscription until the Customer remedies the Customer Default,
                and rely on the Customer Default to relieve it from the performance of any of its obligations to the
                extent the Customer Default prevents or delays the Supplier's performance of any of its obligations;
            </IndentedElement>
            <IndentedElement number={'4.4.2'}>the Supplier shall not be liable for any costs or losses sustained or
                incurred by the Customer arising directly or indirectly from the Supplier's failure or delay to perform
                any of its obligations as set out in this clause; and
            </IndentedElement>
            <IndentedElement number={'4.4.3'}>the Customer shall reimburse the Supplier on written demand for any costs
                or losses sustained or incurred by the Supplier arising directly or indirectly from the Customer
                Default.
            </IndentedElement>
            <IndentedElement number={'5'}>Charges and payment</IndentedElement>
            <IndentedElement number={'5.1'}>The Charges for the Premium Subscription shall be as set out on the Site.
                If there are any discrepancies between prices published on our Site and prices appearing in the email
                confirmation, the prices in the email confirmation shall prevail.
            </IndentedElement>
            <IndentedElement number={'5.2'}>The Charges shall be payable in full cleared funds upon the registration
                of the Premium Subscription. Unrestricted access to the Services shall not be granted until payment of
                the Charges has been received by the Supplier. Payment shall be made via credit card, debit card or bank
                transfer to the bank account nominated by the Supplier and specified in the invoice. Time of payment is
                of the essence.
            </IndentedElement>
            <IndentedElement number={'5.3'}>Unless otherwise stated, the price of the Premium Subscription will be
                inclusive of amounts in respect of value added tax (“VAT”).
            </IndentedElement>
            <IndentedElement number={'5.4'}>The Supplier reserves the right to amend the Charges at any time and to
                add, alter, or remove special offers from time to time. Changes in price will not affect any Services
                and/or Subscription that a Customer has already purchased but will apply to any future Services and
                Subscription.
            </IndentedElement>
            <IndentedElement number={'5.5'}>The Customer shall pay all amounts due under the Contract in full without
                any set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by
                law). The Supplier may at any time, without limiting its other rights or remedies, set off any amount
                owing to it by the Customer against any amount payable by the Supplier to the Customer.
            </IndentedElement>
            <IndentedElement number={'6'}>Intellectual property rights</IndentedElement>
            <IndentedElement number={'6.1'}>All Intellectual Property Rights in or arising out of or in connection
                with the Site, the Services and the Subscriptions shall be owned by the Supplier or its licensors. The
                Supplier shall assert all moral rights arising out of Chapter IV of the Copyright, Designs and Patents
                Act 1988. Throughout the term of the Contract, the Supplier shall be automatically deemed to a limited,
                royalty-free, non-exclusive, non-sublicensable, non-transferable licence of any and all such rights to
                the Customer solely to the extent necessary to use the Services and benefit from the Subscription, in
                accordance with these Conditions and the Contract.
            </IndentedElement>
            <IndentedElement number={'6.2'}>
                The Customer acknowledges that, in respect of any third-party Intellectual Property Rights, the
                Customer's use of any such Intellectual Property Rights is conditional on the Supplier obtaining a
                written licence from the relevant licensor on such terms as will entitle the Supplier to license such
                rights to the Customer.
            </IndentedElement>
            <IndentedElement number={'7'}>Confidentiality</IndentedElement>
            <IndentedElement number={'7.1'}>Each party (‘receiving party’) shall keep in strict confidence all
                technical or commercial know-how, specifications, inventions, processes or initiatives which are of a
                confidential nature and have been disclosed to the receiving party by the other party (‘disclosing
                party’), its employees, agents or subcontractors, and any other confidential information concerning the
                disclosing party, its business, its products and services which the receiving party may obtain or which
                is disclosed to that party by the disclosing party pursuant to or in connection with the Contract
                (whether orally or in writing or any other medium, and whether or not the information is expressly
                stated to be confidential or marked as such).
            </IndentedElement>
            <IndentedElement number={'7.2'}>The Supplier shall only disclose such confidential information referred to
                in this agreement to those of its employees, agents and subcontractors who need to know it for the
                purpose of discharging its obligations under the Contract, and shall ensure that such employees, agents
                and subcontractors comply with the obligations set out in this clause as though they were a party to the
                Contract. The receiving party may also disclose the disclosing party's confidential information as is
                required to be disclosed by law, any governmental or regulatory authority or by a court of competent
                jurisdiction. This clause shall survive termination of the Contract. The restrictions in this clause
                shall not apply to any information which is or becomes publicly available otherwise than through a
                breach of these Conditions, is already or rightly comes into the receiving party’s possession without an
                accompanying obligation of confidence, or which is independently developed by the receiving company.
            </IndentedElement>
            <IndentedElement number={'8'}>Limitation of liability and indemnity</IndentedElement>
            <IndentedElement number={'8.1'}>Nothing in these Conditions shall limit or exclude the Supplier's (or its
                employees’, agents’ or subcontractors’) liability for:
            </IndentedElement>
            <IndentedElement number={'8.1.1'}>death or personal injury caused by its negligence, or the negligence of
                its employees, agents or subcontractors;
            </IndentedElement>
            <IndentedElement number={'8.1.2'}>fraud or fraudulent misrepresentation; or</IndentedElement>
            <IndentedElement number={'8.1.3'}>any other liability to the extent such liability may not be excluded or
                limited as a matter of law.
            </IndentedElement>
            <IndentedElement number={'8.2'}>Subject to the aforesaid:</IndentedElement>
            <IndentedElement number={'8.2.1'}>the Supplier shall under no circumstances whatever be liable to the
                Customer, whether in contract, tort (including negligence), breach of statutory duty or otherwise, for
                any loss (whether direct or indirect) of actual or anticipated income, savings or profits, contracts,
                business, business opportunities, revenue, turnover, savings, goodwill, reputation loss or corruption of
                data or information, or wasted expenditure, or for any indirect or consequential loss arising under or
                in connection with the Contract; and
            </IndentedElement>
            <IndentedElement number={'8.2.2'}>the Supplier's total liability to the Customer in respect of all other
                losses arising under or in connection with the Contract, whether in contract, tort (including
                negligence), breach of statutory duty or otherwise, shall in no circumstances exceed the Charges paid by
                the Customer to the Supplier in the 6-month period prior to the claim.
            </IndentedElement>
            <IndentedElement number={'8.3'}>The terms implied by sections 3 to 5 of the Supply of Goods and Services
                Act 1982 are, to the fullest extent permitted by law, excluded from the Contract.
            </IndentedElement>
            <IndentedElement number={'8.4'}>The Customer shall indemnify the Supplier against any costs, liability,
                damages, loss, expenses, claims or proceedings arising from loss or damage (including that belonging to
                any third parties appointed by the Supplier) caused by any breach of these Conditions or any other
                liabilities arising out of the use of the Website or the Services by the Customer or its agents or
                employees.
            </IndentedElement>
            <IndentedElement number={'8.5'}>This clause shall survive termination of the Contract.</IndentedElement>
            <IndentedElement number={'9'}>Termination</IndentedElement>
            <IndentedElement number={'9.1'}>If the Customer is a consumer in the European Union, these Conditions do
                not apply to you and your registration for our Services and Subscription.
            </IndentedElement>
            <IndentedElement number={'9.2'}>The Customer may end the Contract at any time in the following
                circumstances:
            </IndentedElement>
            <IndentedElement number={'9.2.1'}>if the Supplier has informed it of a forthcoming material change to its
                Subscription or Services, or to these Conditions that the Customer does not agree to, which
                detrimentally alter the way in which the Customer can use the Services. If such change is set to take
                effect or apply before the end of the current Subscription, the Supplier will issue the Customer with a
                pro-rated refund equal to the remaining time left in that Subscription; or
            </IndentedElement>
            <IndentedElement number={'9.2.2'}>if the Supplier is in breach of this Contract.</IndentedElement>
            <IndentedElement number={'9.3'}>Without limiting its other rights or remedies, the Supplier may terminate
                the Contract at any time by giving the Customer immediate notice in writing and, unless termination is
                the fault of the Customer and/or caused by improper use of the Services, the Supplier shall refund to
                the Customer any Charges paid for Services and Subscription not yet received. The Supplier may suspend
                provision of the Services and Subscription under the Contract if the Customer fails to pay any amount
                due under this Contract on the due date for payment or the Supplier believes that the Customer may be
                unable to pay its debts as and when they fall due, or if the Customer stops carrying on business or
                threatens to do so.
            </IndentedElement>
            <IndentedElement number={'9.4'}>Termination of the Contract, however arising, shall not affect any of the
                parties' rights, remedies, obligations and liabilities that have accrued as at termination.
            </IndentedElement>
            <IndentedElement number={'10'}>Consequences of termination</IndentedElement>
            <IndentedElement number={'10.1'}>On termination of the Contract for any reason:</IndentedElement>
            <IndentedElement number={'10.1.1'}>the Customer shall immediately pay to the Supplier any outstanding Charges
                and interest due;
            </IndentedElement>
            <IndentedElement number={'10.1.2'}>the accrued rights, remedies, obligations and liabilities of the parties
                as at expiry or termination shall be unaffected, including the right to claim damages in respect of any
                breach of the Contract which existed at or before the date of termination or expiry; and
            </IndentedElement>
            <IndentedElement number={'10.1.3'}>clauses which expressly or by implication survive termination shall
                continue in full force and effect.
            </IndentedElement>
            <IndentedElement number={'11'}>Force majeure</IndentedElement>
            <IndentedElement number={'11.1'}>For the purposes of this Contract, Force Majeure Event means an event
                beyond the reasonable control of the Supplier including but not limited to strikes, lock-outs or other
                industrial disputes (whether involving the workforce of the Supplier or any other party), failure of a
                utility service or transport network, act of God, war, riot, civil commotion, malicious damage,
                compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of
                plant or machinery, fire, flood, storm or default of suppliers or subcontractors.
            </IndentedElement>
            <IndentedElement number={'1.1.2'}>The Supplier shall not be liable to the Customer as a result of any delay
                or failure to perform its obligations under this Contract as a result of a Force Majeure Event.
            </IndentedElement>
            <IndentedElement number={'11.3'}>If the Force Majeure Event prevents the Supplier from providing any of the
                Services for more than 45 (forty-five) days the Supplier shall, without limiting its other rights or
                remedies, have the right to terminate this Contract immediately by giving written notice to the
                Customer.
            </IndentedElement>
            <IndentedElement number={'12'}>General</IndentedElement>
            <IndentedElement number={'12.1'}>Assignment and other dealings.</IndentedElement>
            <IndentedElement number={'12.1.1'}>The Supplier may at any time assign, transfer, mortgage, charge,
                subcontract or deal in any other manner with all or any of its rights under the Contract and may
                subcontract or delegate in any manner any or all of its obligations under the Contract to any third
                party or agent.
            </IndentedElement>
            <IndentedElement number={'12.1.2'}>The Customer shall not, without the prior written consent of the Supplier,
                assign, transfer, mortgage, charge, subcontract, declare a trust over or deal in any other manner with
                any or all of its rights or obligations under the Contract.
            </IndentedElement>
            <IndentedElement number={'12.2'}>Notices.</IndentedElement>
            <IndentedElement number={'12.2.1'}>Any notice or other communication given to a party under or in connection
                with the Contract shall be in writing, addressed to that party at its registered office (if it is a
                company) or its principal place of business (in any other case) or such other address as that party may
                have specified to the other party in writing in accordance with this clause, and shall be delivered
                personally, sent by pre-paid first class post or other next working day delivery service, commercial
                courier or email.
            </IndentedElement>
            <IndentedElement number={'12.2.2'}>A notice or other communication shall be deemed to have been received: if
                delivered personally, when left at the address referred to in clause 12.2 (“Notices”); if sent by
                pre-paid first class post or other next working day delivery service, at 12.00 pm on the second Business
                Day after posting; if delivered by commercial courier, on the date and at the time that the courier's
                delivery receipt is signed; or, if sent by fax or email, one Business Day after transmission.
            </IndentedElement>
            <IndentedElement number={'12.2.3'}>The provisions of this clause shall not apply to the service of any
                proceedings or other documents in any legal action.
            </IndentedElement>
            <IndentedElement number={'12.3'}>Severance.</IndentedElement>
            <IndentedElement number={'12.3.1'}>If any provision or part-provision of the Contract is or becomes invalid,
                illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid,
                legal and enforceable. If such modification is not possible, the relevant provision or part-provision
                shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this
                clause shall not affect the validity and enforceability of the rest of the Contract.
            </IndentedElement>
            <IndentedElement number={'12.3.2'}>If any provision or part-provision of this Contract is invalid, illegal
                or
                unenforceable, the parties shall negotiate in good faith to amend such provision so that, as amended, it
                is legal, valid and enforceable, and, to the greatest extent possible, achieves the intended commercial
                result of the original provision.
            </IndentedElement>
            <IndentedElement number={'12.4'}>Waiver. A waiver of any right under the Contract or law is only effective
                if it is in writing and shall not be deemed to be a waiver of any subsequent breach or default. No
                failure or delay by a party in exercising any right or remedy provided under the Contract or by law
                shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict its
                further exercise of that or any other right or remedy. No single or partial exercise of such right or
                remedy shall prevent or restrict the further exercise of that or any other right or remedy.
            </IndentedElement>
            <IndentedElement number={'12.5'}>No partnership or agency. Nothing in the Contract is intended to, or shall
                be deemed to, establish any partnership or joint venture between the parties, nor constitute either
                party the agent of the other for any purpose. Neither party shall have authority to act as agent for, or
                to bind, the other party in any way.
            </IndentedElement>
            <IndentedElement number={'12.6'}>Third parties. A person who is not a party to the Contract shall not have
                any rights to enforce its terms.
            </IndentedElement>
            <IndentedElement number={'12.7'}>Variation. The Supplier may revise these Conditions from time to time in
                response to changes in relevant laws and other regulatory requirements. If the Supplier changes these
                Conditions as they relate to the Customer’s Services and Subscription, it will give the Customer
                reasonable advance notice of the changes and provide details of how to cancel if the Customer is not
                happy with them.
            </IndentedElement>
            <IndentedElement number={'12.8'}>Governing law. This Contract and any dispute or claim arising out of or in
                connection with it or its subject matter or formation (including non-contractual disputes or claims),
                shall be governed by and construed in accordance with the law of England and Wales.
            </IndentedElement>
            <IndentedElement number={'12.9'}>Jurisdiction. Each party irrevocably agrees that the courts of England and
                Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection
                with this Contract or its subject matter or formation (including non-contractual disputes or claims).
            </IndentedElement>

        </div>
    );
};

export default BodyTerms;
