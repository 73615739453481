import { TOGGLE_ADMIN_LIST } from '../actions/dashboardHandler';

const initialState = {
    adminListOpen: true,
};


export default function claimHandler(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_ADMIN_LIST:
            return {
                ...state,
                adminListOpen: !state.adminListOpen,
            };

        default:
            return state;
    }
}
