import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import { Typography } from '@material-ui/core';

class Initiatives extends Component {
    render() {
        return (
            <DashboardLayout title="Initiatives">
                <Typography
                    variant="h4"
                >
                    COMING SOON
                </Typography>
            </DashboardLayout>
        );
    }
}

export default Initiatives;
